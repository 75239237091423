import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { Divider, ListItemIcon, Menu, MenuItem, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse, faImage, faKey, faRightFromBracket, faUser } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { clearAuthData } from '../../../../store/authSlice';
import { clearChangePasswordStatus, selectChangePasswordStatus, selectCurrentUserInfo } from '../../../../store/userSlice';
import { selectWallpaper } from '../../../../store/langSlice';
import useAccessRight from '../../../../hooks/useAccessRight';
import useTranslate from '../../../../hooks/useTranslate';
import { LOGIN } from '../../../../constants/routes';
import { USER } from '../../../../constants/accessRights';
import { ACCESS_TOKEN, BACKGROUND_IMAGE, REFRESH_TOKEN } from '../../../../constants/cookieNames';
import { backgroundColor, colorPrimary, colorRed, colorSecondary } from '../../../../constants/colors';
import FormChangePassword from '../../../Forms/FormChangePassword/FormChangePassword';
import Notification from '../../../Notification/Notification';
import { IUserMenuProps } from './UserMenu.props';
import styles from './UserMenu.module.scss';

const UserMenu = ({ setShowPage }: IUserMenuProps): JSX.Element => {
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null); // якорь для меню пользователя
	const [showModalChangePass, setShowModalChangePass] = useState<boolean>(false); // показ формы для изменения пароля
	const [showNotification, setShowNotification] = useState<boolean>(false); // показ уведомления

	const dispatch = useAppDispatch();
	const currentUserInfo = useAppSelector(selectCurrentUserInfo); // store - информация о текущем пользователе
	const wallpaper = useAppSelector(selectWallpaper); // store - обои

	const navigate = useNavigate(); // hook для навигации
	const [cookies, setCookie, removeCookies] = useCookies([ACCESS_TOKEN, REFRESH_TOKEN, BACKGROUND_IMAGE]); // hook для работы с cookie
	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// обработчик открытия формы изменения пароля
	const changePasswordHandler = (): void => {
		setAnchorEl(null); // закрываем меню
		setShowModalChangePass(true); // открываем форму
	};

	// logout
	const logout = (): void => {
		setAnchorEl(null); // закрываем меню
		setShowPage(false); // уводим страницу в фон
		setTimeout(() => {
			removeCookies(ACCESS_TOKEN, { path: '/' });
			removeCookies(REFRESH_TOKEN, { path: '/' });
			dispatch(clearAuthData());
			navigate(LOGIN);
		}, 500); // после скрытия страницы переходим через 500мс
	};

	// навигация домой
	const navigateToHome = (): void => {
		setAnchorEl(null); // закрываем меню
		setShowPage(false); // уводим страницу в фон
		setTimeout(() => {
			navigate('/');
		}, 500); // после скрытия страницы переходим через 500мс
	};

	// вкл/выкл фонового изображения
	const toggleBackgroundImage = (): void => {
		setCookie(
			BACKGROUND_IMAGE,
			(!cookies.backgroundImage || cookies.backgroundImage === 'true') ? 'false' : 'true',
			{ path: '/', maxAge: 31_536_000 }
		); // устанавливаем cookie на год
	};

	return (
		<>
			<div onClick={e => setAnchorEl(e.currentTarget)} title={translate('buttonTitle_userMenu')}>
				{currentUserInfo.image ?
					<div className={styles.image}>
						<img src={currentUserInfo.image} alt="avatar" />
					</div>
					:
					<FontAwesomeIcon icon={faUser} size="2xl" color={backgroundColor} />
				}
			</div>

			<Menu
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={() => setAnchorEl(null)}
			>
				<Typography color={colorSecondary} marginBottom={1} padding='0 10px'>
					{currentUserInfo.fullName}
				</Typography>
				<Divider />
				{isAccess(USER.PASSWORD) &&
					<MenuItem onClick={changePasswordHandler} sx={{ color: colorPrimary }}>
						<ListItemIcon>
							<FontAwesomeIcon icon={faKey} size='lg' color={colorPrimary} />
						</ListItemIcon>
						{translate('selectItem_changePassword')}
					</MenuItem>
				}
				{wallpaper.dataUrl &&
					<MenuItem onClick={toggleBackgroundImage} sx={{ color: colorPrimary }}>
						<ListItemIcon>
							<FontAwesomeIcon icon={faImage} size="lg" color={colorPrimary} />
						</ListItemIcon>
						{translate((!cookies.backgroundImage || cookies.backgroundImage === 'true') ? 'selectItem_hideBackground' : 'selectItem_showBackground')}
					</MenuItem>
				}
				<MenuItem onClick={navigateToHome} sx={{ color: colorPrimary }}>
					<ListItemIcon>
						<FontAwesomeIcon icon={faHouse} size="lg" color={colorPrimary} />
					</ListItemIcon>
					{translate('selectItem_toMainPage')}
				</MenuItem>
				<MenuItem onClick={logout} sx={{ color: colorRed }}>
					<ListItemIcon>
						<FontAwesomeIcon icon={faRightFromBracket} size='lg' color={colorRed} />
					</ListItemIcon>
					{translate('selectItem_logout')}
				</MenuItem>
			</Menu>

			{showModalChangePass &&
				<FormChangePassword
					showModal={showModalChangePass}
					setShowModal={setShowModalChangePass}
					setShowNotification={setShowNotification}
				/>
			}

			<section style={{ position: 'absolute' }}>
				{showNotification &&
					<Notification
						showNotification={showNotification}
						setShowNotification={setShowNotification}
						selectDataResponse={selectChangePasswordStatus}
						clearDataResponse={clearChangePasswordStatus}
						titleFailed='noticeChange_failed'
						titleSuccess='noticeChange_success'
					/>
				}
			</section>
		</>
	);
};

export default UserMenu;
