import { memo, useState } from 'react';
import { FormControl, InputAdornment, TextField } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import cn from 'classnames';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { changePhraseInException, deletePhraseInException, selectActiveRobotVersion } from '../../../store/sesRobotSlice';
import useAccessRight from '../../../hooks/useAccessRight';
import useTranslate from '../../../hooks/useTranslate';
import { SES } from '../../../constants/accessRights';
import { colorPrimary, colorRed } from '../../../constants/colors';
import { IPhraseProps } from './Phrase.props';
import styles from './Phrase.module.scss';

const Phrase = ({ phrase, idxPhrase, arrayPhrases, exception, changeFlg, setChangeFlg }: IPhraseProps): JSX.Element => {
	const [inputPhrase, setInputPhrase] = useState<string>(phrase); // фраза
	const [maxRows, setMaxRows] = useState<number>(2); // max кол-во строк

	const dispatch = useAppDispatch();
	const activeRobotVersion = useAppSelector(selectActiveRobotVersion); // store - версия активного робота

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// обработчик увода фокуса с поля фразы
	const blurHandler = (): void => {
		setMaxRows(2);
		if (inputPhrase !== phrase) {
			dispatch(changePhraseInException({
				exception,
				idxPhrase,
				text: inputPhrase,
			})); // изменяем фразу в исключении
			(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('exceptions')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'exceptions'] }));  // ставим флаг о несохраненных данных
		}
	};

	// обработчик удаления фразы
	const deletePhraseHandler = (): void => {
		dispatch(deletePhraseInException({
			exception,
			idxPhrase,
		})); // удаляем фразу в исключении
		(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('exceptions')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'exceptions'] }));  // ставим флаг о несохраненных данных
	};

	return (
		<div className={cn(styles.phraseBlock, {
			[styles.phraseBlockOpacity]: arrayPhrases.length - 1 === idxPhrase && inputPhrase === '' && arrayPhrases.length > 1,
		})}>
			<FormControl fullWidth margin='dense'>
				<TextField
					required={arrayPhrases.length - 1 !== idxPhrase || arrayPhrases.length === 1}
					multiline
					disabled={!isAccess(SES.ROBOT_EDIT) || activeRobotVersion !== 'draft'}
					label={`${translate('input_phrase')} ${idxPhrase + 1}`}
					variant="outlined"
					maxRows={maxRows}
					value={inputPhrase}
					onChange={(e) => setInputPhrase(e.target.value)}
					onBlur={blurHandler}
					onFocus={() => setMaxRows(5)}
					InputProps={{
						style: {
							padding: '8px 13px',
							fontSize: 13,
							color: colorPrimary,
						},
						endAdornment: (
							<InputAdornment position="end">
								{isAccess(SES.ROBOT_EDIT) && arrayPhrases.length > 1 && arrayPhrases.length - 1 !== idxPhrase && activeRobotVersion === 'draft' &&
									<div className={styles.deletePhraseButton}>
										<FontAwesomeIcon
											icon={faTrashCan}
											color={colorRed}
											size='sm'
											onClick={deletePhraseHandler}
											title={translate('buttonTitle_deletePhrase')}
											style={{ cursor: 'pointer' }}
										/>
									</div>
								}
							</InputAdornment>
						),
					}}
					InputLabelProps={{
						style: {
							fontSize: 13,
						},
					}}
					sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
				/>
			</FormControl>
		</div>
	);
};

export default memo(Phrase);
