import { useAppSelector } from '../store/hooks';
import { selectTranslate } from '../store/langSlice';

// hook для перевода текста
const useTranslate = () => {
	const translate = useAppSelector(selectTranslate); // state - словарь для перевода текста

	return (code: string): string => {
		if (translate === null) return code; // если не загрузился словарь - оставляем как есть

		if (translate.hasOwnProperty(code)) return translate[code];	// если в словаре есть перевод - переводим
		else {
			const partialTranslationFound = Object.keys(translate).find(translateKey => code.includes(translateKey));
			// если в словаре есть часть перевода - переводим частично
			if (partialTranslationFound) return code.replace(partialTranslationFound, translate[partialTranslationFound]);
			else return code; // иначе оставляем как есть
		}
	};
};

export default useTranslate;
