import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError, AxiosResponse } from 'axios';
import { RootState } from './store';
import axiosApi from '../helpers/axios';
import { ENDPOINTS_SBS, URL_SBS } from '../constants/api_endpoints';
import { IAddSpeakerProps, IAnalyzeData, IEditSpeakerProps, IResponse, IResponseAddingSpeaker, IResponseSearchSpeaker, ISbsState, ISearchSpeakerProps, ISpeaker, IVerifyData, IVerifyProps } from '../types/sbsTypes';
import { RequestStatus, ResponseStatus } from '../types/statusTypes';

const initialState: ISbsState = {
  speakers: {
    data: [],
    activeSpeaker: null,
    status: RequestStatus.IDLE,
    error: ResponseStatus.SUCCESS,
    message: '',
  },
  addSpeaker: {
    status: RequestStatus.IDLE,
    error: ResponseStatus.SUCCESS,
    message: '',
    id: null,
  },
  editSpeaker: {
    status: RequestStatus.IDLE,
    error: ResponseStatus.SUCCESS,
    message: '',
  },
  deleteSpeaker: {
    status: RequestStatus.IDLE,
    error: ResponseStatus.SUCCESS,
    message: '',
  },
  analyze: {
    data: null,
    status: RequestStatus.IDLE,
  },
  verify: {
    data: null,
    status: RequestStatus.IDLE,
  },
  searchSpeaker: {
    data: null,
    status: RequestStatus.IDLE,
  },
};

// список спикеров
export const getSpeakers = createAsyncThunk(
  'sbs/getSpeakers',
  async (_, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<ISpeaker[] | IResponse> = await axiosApi.get(`${URL_SBS}/${ENDPOINTS_SBS.SPEAKERS}`);
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// добавление спикера
export const addSpeaker = createAsyncThunk(
  'sbs/addSpeaker',
  async ({ speakerName, formData, text }: IAddSpeakerProps, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IResponseAddingSpeaker> = await axiosApi.post(`${URL_SBS}/${ENDPOINTS_SBS.SPEAKER_ADD}`, {
        wav: formData.get('file'),
        name: speakerName,
        text,
      }, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
      });
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// изменение спикера
export const editSpeaker = createAsyncThunk(
  'sbs/editSpeaker',
  async ({ speakerId, formData, text }: IEditSpeakerProps, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IResponse> = await axiosApi.post(`${URL_SBS}/${ENDPOINTS_SBS.SPEAKER_EDIT}/${speakerId}`, {
        wav: formData.get('file'),
        text,
      }, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
      });
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// удаление спикера
export const deleteSpeaker = createAsyncThunk(
  'sbs/deleteSpeaker',
  async (speakerId: string, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IResponse> = await axiosApi.delete(`${URL_SBS}/${ENDPOINTS_SBS.SPEAKER_DELETE}/${speakerId}`);
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// анализ речи
export const analyze = createAsyncThunk(
  'sbs/analyze',
  async (formData: FormData): Promise<IAnalyzeData | IResponse> => {
    const response: AxiosResponse<IAnalyzeData | IResponse> = await axiosApi.post(`${URL_SBS}/${ENDPOINTS_SBS.ANALYZE}`, { wav: formData.get('file') }, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response.data;
  }
);

// соответствие спикера и аудиозаписи
export const verify = createAsyncThunk(
  'sbs/verify',
  async ({ speakerId, formData, text }: IVerifyProps): Promise<IVerifyData> => {
    const response: AxiosResponse<IVerifyData> = await axiosApi.post(`${URL_SBS}/${ENDPOINTS_SBS.VERIFY}/${speakerId}`, {
      wav: formData.get('file'),
      text,
    }, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response.data;
  }
);

// поиск спикера по аудиозаписи
export const searchSpeaker = createAsyncThunk(
  'sbs/searchSpeaker',
  async ({ threshold, formData, text }: ISearchSpeakerProps, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IResponseSearchSpeaker | IResponse> = await axiosApi.post(`${URL_SBS}/${ENDPOINTS_SBS.SEARCH}`, {
        wav: formData.get('file'),
        threshold,
        text,
      }, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
      });
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

const sbsSlice = createSlice({
  name: 'sbs',
  initialState,
  reducers: {
    // добавление активного спикера
    addActiveSpeaker: (state, action: PayloadAction<ISpeaker>) => {
      state.speakers.activeSpeaker = action.payload;
    },
    // очистка state
    clearState: (state) => {
      state.speakers = initialState.speakers;
      state.addSpeaker = initialState.addSpeaker;
      state.editSpeaker = initialState.editSpeaker;
      state.deleteSpeaker = initialState.deleteSpeaker;
      state.analyze = initialState.analyze;
      state.verify = initialState.verify;
      state.searchSpeaker = initialState.searchSpeaker;
    },
    // очистка списка спикеров
    clearSpeakers: (state) => {
      state.speakers = initialState.speakers;
    },
    // очистка статуса добавления спикера
    clearAddSpeaker: (state) => {
      state.addSpeaker = initialState.addSpeaker;
    },
    // очистка статуса изменения спикера
    clearEditSpeaker: (state) => {
      state.editSpeaker = initialState.editSpeaker;
    },
    // очистка статуса удаления спикера
    clearDeleteSpeaker: (state) => {
      state.deleteSpeaker = initialState.deleteSpeaker;
    },
    // очистка данных анализа
    clearAnalyze: (state) => {
      state.analyze = initialState.analyze;
    },
    // очистка данных соответствия
    clearVerify: (state) => {
      state.verify = initialState.verify;
    },
    // очистка данных поиска спикера
    clearSearchSpeaker: (state) => {
      state.searchSpeaker = initialState.searchSpeaker;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSpeakers.pending, (state) => {
        state.speakers.status = RequestStatus.LOADING;
      })
      .addCase(getSpeakers.fulfilled, (state, action) => {
        if (Array.isArray(action.payload)) {
          state.speakers.status = RequestStatus.IDLE;
          state.speakers.data = action.payload.sort((a, b) => {
            if (a.name > b.name) return 1;
            else if (a.name < b.name) return -1;
            else return 0;
          });;

        } else state.speakers.status = RequestStatus.FAILED;
      })
      .addCase(getSpeakers.rejected, (state, action: PayloadAction<unknown>) => {
        state.speakers.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.speakers.error = action.payload.response?.data.error;
          state.speakers.message = action.payload.response?.data.message;
        }
      })
      .addCase(addSpeaker.pending, (state) => {
        state.addSpeaker.status = RequestStatus.LOADING;
      })
      .addCase(addSpeaker.fulfilled, (state, action) => {
        state.addSpeaker.status = RequestStatus.IDLE;
        if (action.payload) {
          state.addSpeaker.error = action.payload.error;
          state.addSpeaker.message = action.payload.message;
          if (action.payload.id) state.addSpeaker.id = action.payload.id;
        }
      })
      .addCase(addSpeaker.rejected, (state, action: PayloadAction<unknown>) => {
        state.addSpeaker.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.addSpeaker.error = action.payload.response?.data.error;
          state.addSpeaker.message = action.payload.response?.data.message;
        }
      })
      .addCase(editSpeaker.pending, (state) => {
        state.editSpeaker.status = RequestStatus.LOADING;
      })
      .addCase(editSpeaker.fulfilled, (state, action) => {
        state.editSpeaker.status = RequestStatus.IDLE;
        if (action.payload) {
          state.editSpeaker.error = action.payload.error;
          state.editSpeaker.message = action.payload.message;
        }
      })
      .addCase(editSpeaker.rejected, (state, action: PayloadAction<unknown>) => {
        state.editSpeaker.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.editSpeaker.error = action.payload.response?.data.error;
          state.editSpeaker.message = action.payload.response?.data.message;
        }
      })
      .addCase(deleteSpeaker.pending, (state) => {
        state.deleteSpeaker.status = RequestStatus.LOADING;
      })
      .addCase(deleteSpeaker.fulfilled, (state, action) => {
        state.deleteSpeaker.status = RequestStatus.IDLE;
        if (action.payload) {
          state.deleteSpeaker.error = action.payload.error;
          state.deleteSpeaker.message = action.payload.message;
        }
      })
      .addCase(deleteSpeaker.rejected, (state, action: PayloadAction<unknown>) => {
        state.deleteSpeaker.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.deleteSpeaker.error = action.payload.response?.data.error;
          state.deleteSpeaker.message = action.payload.response?.data.message;
        }
      })
      .addCase(analyze.pending, (state) => {
        state.analyze.status = RequestStatus.LOADING;
      })
      .addCase(analyze.fulfilled, (state, action: PayloadAction<IAnalyzeData | IResponse>) => {
        state.analyze.status = RequestStatus.IDLE;
        state.analyze.data = action.payload;
      })
      .addCase(analyze.rejected, (state) => {
        state.analyze.status = RequestStatus.FAILED;
      })
      .addCase(verify.pending, (state) => {
        state.verify.status = RequestStatus.LOADING;
      })
      .addCase(verify.fulfilled, (state, action: PayloadAction<IVerifyData>) => {
        state.verify.status = RequestStatus.IDLE;
        state.verify.data = action.payload;
      })
      .addCase(verify.rejected, (state) => {
        state.verify.status = RequestStatus.FAILED;
      })
      .addCase(searchSpeaker.pending, (state) => {
        state.searchSpeaker.status = RequestStatus.LOADING;
      })
      .addCase(searchSpeaker.fulfilled, (state, action) => {
        state.searchSpeaker.status = RequestStatus.IDLE;
        if (action.payload) state.searchSpeaker.data = action.payload;
      })
      .addCase(searchSpeaker.rejected, (state, action: PayloadAction<unknown>) => {
        state.searchSpeaker.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.searchSpeaker.data = action.payload.response?.data;
        }
      });
  },
});

export const { addActiveSpeaker, clearState, clearSpeakers, clearAddSpeaker, clearEditSpeaker, clearDeleteSpeaker, clearAnalyze, clearVerify, clearSearchSpeaker } = sbsSlice.actions;

export const selectSpeakers = (state: RootState) => state.sbs.speakers;
export const selectAddSpeaker = (state: RootState) => state.sbs.addSpeaker;
export const selectEditSpeaker = (state: RootState) => state.sbs.editSpeaker;
export const selectDeleteSpeaker = (state: RootState) => state.sbs.deleteSpeaker;
export const selectAnalyze = (state: RootState) => state.sbs.analyze;
export const selectVerify = (state: RootState) => state.sbs.verify;
export const selectSearchSpeaker = (state: RootState) => state.sbs.searchSpeaker;

export default sbsSlice.reducer;
