import { useEffect, useState } from 'react';
import { Slide } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { clearQuestionManipulation, getQuestionsList, selectApproveCandidateList } from '../../../store/qasSlice';
import { selectDataServers } from '../../../store/serverSlice';
import useAccessRight from '../../../hooks/useAccessRight';
import useTranslate from '../../../hooks/useTranslate';
import { QAS, SERVER } from '../../../constants/accessRights';
import { RequestStatus, ResponseStatus } from '../../../types/statusTypes';
import UserMenu from '../Buttons/UserMenu/UserMenu';
import Categories from '../../Categories/Categories';
import Channels from '../../Channels/Channels';
import ServerStatus from '../../ServerStatus/ServerStatus';
import ScreenLock from '../../ScreenLock/ScreenLock';
import Notification from '../../Notification/Notification';
import { ICandidatesControlsProps } from './CandidatesControls.props';
import styles from './CandidatesControls.module.scss';

const CandidatesControls = ({ setShowPage }: ICandidatesControlsProps): JSX.Element => {
	const [showNotificationApprove, setShowNotificationApprove] = useState<boolean>(false); // показ уведомления утверждения кандидатов

	const [showScreenLock, setShowScreenLock] = useState<{ isShow: boolean, title: string }>({ isShow: false, title: '' }); // показ экрана блокировки и подпись
	const [showCategories, setShowCategories] = useState<boolean>(false); // показ вкладки категорий
	const [showChannels, setShowChannels] = useState<boolean>(false); // показ вкладки каналов
	const [showServers, setShowServers] = useState<boolean>(false); // показ вкладки серверов

	const dispatch = useAppDispatch();
	const approveCandidateList = useAppSelector(selectApproveCandidateList); // store - статус утверждения кандидатов
	const dataServers = useAppSelector(selectDataServers); // store - информация о серверах

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// следим за статусом утверждения кандадитов
	useEffect(() => {
		if (approveCandidateList.status === RequestStatus.LOADING) {
			setShowScreenLock({ isShow: true, title: approveCandidateList.deleteOnly ? 'spinnerTitle_deletion' : 'spinnerTitle_approving' }); // включаем экран блокировки
			setShowNotificationApprove(true); // включаем уведомление
		}
		else setShowScreenLock({ isShow: false, title: '' }); // иначе выключаем экран блокировки

		// если утверждение кандидатов прошло успешно
		if (approveCandidateList.status === RequestStatus.IDLE && approveCandidateList.error === ResponseStatus.SUCCESS && approveCandidateList.message !== '') dispatch(getQuestionsList({})); // получиаем заново список вопросов
	}, [approveCandidateList]);

	return (
		<>
			<div className={styles.controls}>
				<Slide direction="down" in={true} mountOnEnter unmountOnExit timeout={500}>
					<div className={styles.controlsWrapper}>
						<div className={styles.controlsTitle}>{translate('qas')}</div>

						<section className={styles.controlsTypeAndFunc}>
							<div className={styles.controlsFunctions}></div>

							<div className={styles.controlsFunctions}>
								<div className={styles.controlsFunctionsRightBlock}>
									<UserMenu setShowPage={setShowPage} />
								</div>
							</div>
						</section>
					</div>
				</Slide>

				{/* бирки */}
				<Slide direction="left" in={true} mountOnEnter unmountOnExit timeout={1000}>
					<section className={styles.tags}>
						{isAccess(QAS.CATEGORY_LIST) &&
							<div className={styles.categoriesTag} onClick={() => setShowCategories(prev => !prev)}>
								<span className={styles.tagTitle}>{translate('tag_categories')}</span>
							</div>
						}
						{isAccess(QAS.CHANNEL_LIST) &&
							<div className={styles.channelsTag} onClick={() => setShowChannels(prev => !prev)}>
								<span className={styles.tagTitle}>{translate('tag_channels')}</span>
							</div>
						}
						{isAccess(SERVER.ADDRESSES) &&
							<div className={styles.serversTag} onClick={() => setShowServers(prev => !prev)}>
								<span className={styles.tagTitle}>
									<span className={dataServers && dataServers.fail && !Object.keys(dataServers?.fail).length ? styles.serversTagStatus : styles.serversTagStatusFailed}></span>
									{translate('tag_servers')}
								</span>
							</div>
						}
					</section>
				</Slide>

				<Categories showCategories={showCategories} setShowCategories={setShowCategories} />
				<Channels showChannels={showChannels} setShowChannels={setShowChannels} />
				<ServerStatus showServers={showServers} setShowServers={setShowServers} />
			</div>

			{showScreenLock.isShow && <ScreenLock title={translate(showScreenLock.title)} />}

			{/* уведомление для утверждения кандидата */}
			{showNotificationApprove &&
				<Notification
					showNotification={showNotificationApprove}
					setShowNotification={setShowNotificationApprove}
					selectDataResponse={selectApproveCandidateList}
					clearDataResponse={clearQuestionManipulation}
					titleFailed='noticeApproval_failed'
					titleSuccess='noticeApproval_success'
				/>
			}
		</>
	);
};

export default CandidatesControls;
