import { useEffect, useState } from 'react';
import { Fade } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { clearState, getDashboardsList, selectDashboardsList } from '../../store/resSlice';
import { clearDataServers, getDataServers } from '../../store/serverSlice';
import useAccessRight from '../../hooks/useAccessRight';
import useTranslate from '../../hooks/useTranslate';
import { RES, SERVER, SERVICE } from '../../constants/accessRights';
import { RequestStatus } from '../../types/statusTypes';
import PageWrapper from '../../HOC/PageWrapper/PageWrapper';
import ReportsControls from '../../components/Controls/ReportsControls/ReportsControls';
import ReportNavbar from '../../components/Navbars/ReportNavbar/ReportNavbar';
import { IReportsProps } from './Reports.props';
import styles from './Reports.module.scss';

const Reports = ({ serviceType }: IReportsProps): JSX.Element => {
	const [showPage, setShowPage] = useState<boolean>(true); // показ страницы

	const dispatch = useAppDispatch();
	const dashboardsList = useAppSelector(selectDashboardsList); // store - список рабочих столов

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	useEffect(() => {
		isAccess(RES.DASHBOARDS) && dispatch(getDashboardsList()); // получаем список рабочих столов
		isAccess(SERVER.ADDRESSES) && dispatch(getDataServers({ serviceType })); // получаем данные о серверах

		// автозапрос списка серверов каждые 30 сек
		const interval = setInterval(() => {
			isAccess(SERVER.ADDRESSES) && dispatch(getDataServers({ serviceType }));
		}, 30000);

		// при уходе со страницы
		return () => {
			clearInterval(interval); // удаляем автозапрос получения данных о серверах
			dispatch(clearDataServers()); // очищаем данные по серверам
			dispatch(clearState()); // очищаем state RES
		};
	}, []);

	return (
		<PageWrapper showPage={showPage} setShowPage={setShowPage} accessToService={[SERVICE.RES, RES.DASHBOARDS]}>
			<>
				<ReportsControls setShowPage={setShowPage} />
				<ReportNavbar />

				<Fade in={true} timeout={700} style={{ transitionDelay: '500ms' }}>
					<div className={styles.wrapper}>
						{dashboardsList.status === RequestStatus.IDLE && dashboardsList.activeDashboardLink ?
							<iframe className={styles.iframe} title="Dashboard" src={dashboardsList.activeDashboardLink} />
							:
							<div className={styles.noData}><div>{translate('title_noData')}</div></div>
						}
					</div>
				</Fade>
			</>
		</PageWrapper>
	);
};

export default Reports;
