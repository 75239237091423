import { ChangeEvent, useEffect, useState } from 'react';
import { Checkbox, Fade, FormControl, InputAdornment, InputLabel, ListItemText, MenuItem, Select, TextField } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera, faEye, faEyeSlash, faUser } from '@fortawesome/free-solid-svg-icons';
import cn from 'classnames';
import { useAppSelector } from '../../store/hooks';
import { selectDatasetsList, selectRolesList } from '../../store/authSlice';
import { selectCurrentUserInfo, selectUserInfo } from '../../store/userSlice';
import useAccessRight from '../../hooks/useAccessRight';
import useTranslate from '../../hooks/useTranslate';
import { USER } from '../../constants/accessRights';
import { colorPrimary } from '../../constants/colors';
import { RequestStatus } from '../../types/statusTypes';
import ProgressCircle from '../ProgressCircle/ProgressCircle';
import { IUserInfoProps } from './UserInfo.props';
import styles from './UserInfo.module.scss';

const UserInfo = ({ userName, setUserName, password, setPassword, userRoleId, setUserRoleId, datasetIdDefault, setDatasetIdDefault, datasetsListAvailable, setDatasetsListAvailable, avatarBase64, setAvatarBase64, setChangeFlg }: IUserInfoProps): JSX.Element => {
	const [showPassword, setShowPassword] = useState<boolean>(false); // флаг отображения пароля

	const userInfo = useAppSelector(selectUserInfo); // store - информация о пользователе
	const currentUserInfo = useAppSelector(selectCurrentUserInfo); // store - информация о текущем пользователе
	const rolesList = useAppSelector(selectRolesList); // store - список ролей
	const datasetsList = useAppSelector(selectDatasetsList); // store - список наборов данных

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// следим за информацией о пользователе и заполняем поля
	useEffect(() => {
		if (userInfo.data && 'username' in userInfo.data) {
			setUserName(userInfo.data.fullname);
			setShowPassword(false);
			setUserRoleId(userInfo.data.role);
			setDatasetIdDefault(userInfo.data.dataset);
			setDatasetsListAvailable(userInfo.data.datasets);
			setAvatarBase64(userInfo.data.image);
		}
	}, [userInfo.data]);

	// следим за набором данных по умолчанию и дублируем в доступные наборы данных
	useEffect(() => {
		datasetIdDefault && !datasetsListAvailable.includes(datasetIdDefault) && setDatasetsListAvailable(prev => [...prev, datasetIdDefault].sort());
	}, [datasetIdDefault]);

	// обработчик выбора доступных наборов данных
	const changeDatasetsListAvailable = (e: ChangeEvent<HTMLInputElement>, datasetId: string): void => {
		if (e.target.checked) {
			setDatasetsListAvailable(prev => [...prev, datasetId].sort());
		} else {
			setDatasetsListAvailable(prev => prev.filter(datasetIdItem => datasetIdItem !== datasetId));
		}
		setChangeFlg(true); // ставим флаг о несохраненных данных
	};

	// обработчик закрытия списка
	const closeHandlerSelect = (): void => {
		// если в доступных наборах данных не содержится набора данных по умолчанию - дописываем
		!datasetsListAvailable.includes(datasetIdDefault) && setDatasetsListAvailable(prev => [...prev, datasetIdDefault].sort());
	};

	// обработчик выбора аватара
	const avatarHandler = (file: File) => {
		if (file) {
			const reader = new FileReader();
			reader.readAsDataURL(file); // читаем файл
			reader.onload = () => {
				if (typeof reader.result === 'string') setAvatarBase64(reader.result);
				else console.error('File reading error.');
			};
			reader.onerror = () => console.error(reader.error);
			setChangeFlg(true); // включаем флаг о несохраненных данных
		}
	};

	return (
		<div className={styles.info}>
			{/* загрузка инфо пользователя */}
			{userInfo.status === RequestStatus.LOADING &&
				<div className={styles.loading}><ProgressCircle title={translate('spinnerTitle_loading')} /></div>
			}

			{/* ошибка получения инфо пользователя */}
			{userInfo.status === RequestStatus.FAILED &&
				<div className={styles.failed}>{translate(userInfo.data && 'message' in userInfo.data ? userInfo.data?.message : 'title_notFound')}</div>
			}

			{/* инфо пользователя*/}
			{userInfo.status === RequestStatus.IDLE && userInfo.data && userInfo.userName &&
				<Fade in={true} timeout={500}>
					<div className={styles.infoMain}>
						<input
							className={styles.inputFile}
							type="file"
							id="avatar"
							accept="image/*"
							onChange={(e: ChangeEvent<HTMLInputElement>) => e.target.files && avatarHandler(e.target.files[0])}
							disabled={!isAccess(USER.MODIFY) || (userInfo.userName === 'admin' && currentUserInfo.userName !== 'admin')}
						/>
						<label htmlFor='avatar' className={cn(styles.labelFile, {
							[styles.labelFileDefaultCursor]: userInfo.userName === 'admin' && currentUserInfo.userName !== 'admin',
						})}>
							{avatarBase64 ?
								<img className={styles.labelFileImage} src={avatarBase64} alt="avatar" />
								:
								<FontAwesomeIcon icon={faUser} size='10x' />
							}
							{(userInfo.userName !== 'admin' || currentUserInfo.userName === 'admin') &&
								<div className={styles.labelFileIcon}>
									<FontAwesomeIcon icon={faCamera} color='#fff' size='3x' />
								</div>
							}
						</label>
						<div className={styles.infoMainLogin}>{userInfo.userName}</div>
						<FormControl margin='dense'>
							<TextField
								id="fullName"
								variant="outlined"
								label={translate("input_fullName")}
								value={userName}
								onChange={e => { setUserName(e.target.value); setChangeFlg(true); }}
								disabled={!isAccess(USER.MODIFY) || (userInfo.userName === 'admin' && currentUserInfo.userName !== 'admin')}
								InputProps={{
									style: {
										height: 33,
										width: 250,
										fontSize: 13,
										color: colorPrimary,
									},
								}}
								InputLabelProps={{
									style: {
										fontSize: 13,
									},
								}}
								sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
							/>
						</FormControl>
						<FormControl margin='dense'>
							<TextField
								id="password"
								variant="outlined"
								label={translate("input_password")}
								type={showPassword ? 'text' : 'password'}
								value={password}
								onChange={e => { setPassword(e.target.value); setChangeFlg(true); }}
								disabled={!isAccess([USER.MODIFY, USER.PASSWORD]) || (userInfo.userName === 'admin' && currentUserInfo.userName !== 'admin')}
								InputProps={{
									endAdornment: (
										<InputAdornment position="end" >
											<FontAwesomeIcon
												icon={showPassword ? faEyeSlash : faEye}
												onClick={() => isAccess(USER.MODIFY) && (userInfo.data && 'username' in userInfo.data && userInfo.data.username !== 'admin') && setShowPassword(prev => !prev)}
												style={(userInfo.userName !== 'admin' || currentUserInfo.userName === 'admin') ? { cursor: 'pointer' } : undefined}
											/>
										</InputAdornment>
									),
									style: {
										height: 33,
										width: 250,
										fontSize: 13,
										color: colorPrimary,
									},
								}}
								InputLabelProps={{
									style: {
										fontSize: 13,
									},
								}}
								sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
							/>
						</FormControl>
						<FormControl margin='dense' sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}>
							<InputLabel id="userRoleId-label" sx={{ fontSize: 13 }}>{translate('select_role')}</InputLabel>
							<Select
								id="userRoleId"
								labelId="userRoleId-label"
								label={translate('select_role')}
								value={userRoleId}
								onChange={(e) => { setUserRoleId(e.target.value); setChangeFlg(true); }}
								disabled={!isAccess(USER.MODIFY) || (userInfo.userName === 'admin' && currentUserInfo.userName !== 'admin')}
								style={{ fontSize: 13, height: 33, width: 250, color: colorPrimary }}
							>
								{/* для случаев, если роли пользователя нет в общем списке ролей */}
								{rolesList.data.length > 0 && !rolesList.data.find(role => role.id === userRoleId) &&
									<MenuItem value={userRoleId} sx={{ fontSize: 13, color: colorPrimary }}>{userRoleId} ({translate('selectItem_notFound')})</MenuItem>
								}
								{rolesList.data.length > 0 ?
									rolesList.data.map((role) => (
										<MenuItem key={role.id} value={role.id} sx={{ fontSize: 13, color: colorPrimary }}>{role.name}</MenuItem>
									))
									:
									<MenuItem value={userRoleId} sx={{ fontSize: 13, color: colorPrimary }}>{userRoleId}</MenuItem>
								}
							</Select>
						</FormControl>
						<FormControl margin='dense' sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}>
							<InputLabel id="datasetIdDefault-label" sx={{ fontSize: 13 }}>{translate('select_defaultDataset')}</InputLabel>
							<Select
								id="datasetIdDefault"
								labelId="datasetIdDefault-label"
								label={translate('select_defaultDataset')}
								value={datasetIdDefault}
								onChange={(e) => { setDatasetIdDefault(e.target.value); setChangeFlg(true); }}
								onClose={closeHandlerSelect}
								renderValue={() => datasetsList.data.find(dataset => dataset.id === datasetIdDefault)?.name || datasetIdDefault}
								disabled={!isAccess(USER.MODIFY) || (userInfo.userName === 'admin' && currentUserInfo.userName !== 'admin')}
								style={{ fontSize: 13, height: 33, width: 250, color: colorPrimary }}
							>
								{/* для случаев, если в списке датасетов пользователя нет датасета из общего списка */}
								{datasetsListAvailable.map((datasetId) => {
									if (datasetsList.data.find(dataset => dataset.id === datasetId)) return undefined;
									return (
										<MenuItem key={datasetId} value={datasetId} sx={{ textAlign: 'left', padding: '5px', color: colorPrimary }}>
											<Checkbox
												checked={datasetsListAvailable.includes(datasetId)}
												onChange={e => changeDatasetsListAvailable(e, datasetId)}
												onClick={e => e.stopPropagation()}
												size='small'
												sx={{ height: 25, width: 25 }}
											/>
											<ListItemText primary={datasetId} primaryTypographyProps={{ marginLeft: '10px', fontSize: 13 }} />
											<ListItemText primary={`(${translate('selectItem_notFound')})`} primaryTypographyProps={{ fontSize: 13 }} />
										</MenuItem>
									);
								})}
								{datasetsList.data.length > 0 &&
									datasetsList.data.map((dataset) => (
										<MenuItem key={dataset.id} value={dataset.id} sx={{ textAlign: 'left', padding: '5px', color: colorPrimary }}>
											<Checkbox
												checked={datasetsListAvailable.includes(dataset.id)}
												onChange={e => changeDatasetsListAvailable(e, dataset.id)}
												onClick={e => e.stopPropagation()}
												size='small'
												sx={{ height: 25, width: 25 }}
											/>
											<ListItemText primary={dataset.name} primaryTypographyProps={{ marginLeft: '10px', fontSize: 13 }} />
										</MenuItem>
									))
								}
							</Select>
						</FormControl>
					</div>
				</Fade>
			}
		</div>
	);
};

export default UserInfo;
