import { useEffect, useMemo, useRef, useState } from 'react';
import { flexRender, getCoreRowModel, useReactTable, ColumnDef, getFilteredRowModel, getPaginationRowModel, SortingState, getSortedRowModel } from '@tanstack/react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import cn from 'classnames';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import useTranslate from '../../../hooks/useTranslate';
import useAccessRight from '../../../hooks/useAccessRight';
import { editDictionaryDataCell, getDictionary, selectDictionary } from '../../../store/ttsSlice';
import { TTS } from '../../../constants/accessRights';
import { RequestStatus } from '../../../types/statusTypes';
import Filter from './Filter/Filter';
import TableCheckbox from '../../Tables/TableCheckbox/TableCheckbox';
import CustomFooter from './CustomFooter/CustomFooter';
import ProgressCircle from '../../ProgressCircle/ProgressCircle';
import { DictionaryRow } from '../../../types/tableTypes';
import { IStressDictionaryProps } from './StressDictionary.props';
import styles from './StressDictionary.module.scss';

const StressDictionary = ({ setChangeFlg }: IStressDictionaryProps) => {
	const [data, setData] = useState<DictionaryRow[]>([]); // словарь ударений, преобразованный для работы в таблице
	const [sorting, setSorting] = useState<SortingState>([]); // сортированный словарь ударений
	const [rowSelection, setRowSelection] = useState<Record<number, boolean>>({}); // список выделенных строк 
	const tableContainerRef = useRef<HTMLDivElement>(null); // ссылка на контейнер таблицы
	const tBodyRef = useRef<HTMLTableSectionElement>(null); // ссылка на body таблицы

	const dispatch = useAppDispatch();
	const dictionary = useAppSelector(selectDictionary); // словарь ударений
	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// получаем словарь ударений
	useEffect(() => {
		dispatch(getDictionary());
	}, []);

	// следим за словарем ударений
	useEffect(() => {
		// если есть данные и без ошибок - преобразуем в формат таблицы
		if (dictionary.status === RequestStatus.IDLE && Array.isArray(dictionary.data)) {
			setData(dictionary.data.map(row => { return { phrase: row[0], modifiedPhrase: row[1] }; }));
		}
	}, [dictionary.data]);

	const defaultColumn: Partial<ColumnDef<DictionaryRow>> = {
		// Give our default column cell renderer editing superpowers!
		cell: ({ getValue, row, column: { id }, table }) => {
			/* eslint-disable */ // от безысходности
			const inputRef = useRef<HTMLInputElement>(null); // ссылка на input ячейки
			const initialValue = getValue(); // начальное значение ячейки
			// We need to keep and update the state of the cell normally
			/* eslint-disable */ // от безысходности
			const [value, setValue] = useState(initialValue); // значение ячейки
			// When the input is blurred, we'll call our table meta's updateData function
			const onBlur = () => {
				table.options.meta?.updateData(row.index, id, value); // для обновления отображения в таблице
				// если была изменена ячейка в столбце "Фраза"
				if (id === 'phrase' && row.original.phrase !== value) {
					dispatch(editDictionaryDataCell({ index: row.index, row: [String(value), row.original.modifiedPhrase] })); // обновляем данные в store
					setChangeFlg(true); // ставим флаг о не сохраненных данных
				}
				// иначе если была изменена ячейка в столбце "Измененная фраза"
				else if (id === 'modifiedPhrase' && row.original.modifiedPhrase !== value) {
					dispatch(editDictionaryDataCell({ index: row.index, row: [row.original.phrase, String(value)] })); // обновляем данные в store
					setChangeFlg(true); // ставим флаг о не сохраненных данных
				}
			};
			// обработчик нажатия клавиши
			const keyDownHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
				// если нажата клавиша Enter - уводим фокус с ячейки
				if (e.code === 'Enter') {
					inputRef.current?.blur();
					// если нажата клавиша Escape - возвращаем начальное состояние ячейки
				} else if (e.code === 'Escape') {
					id === 'phrase' && setValue(row.original.phrase);
					id === 'modifiedPhrase' && setValue(row.original.modifiedPhrase);
					setTimeout(() => inputRef.current?.blur()); // уводим фокус с ячейки
				}
			};
			// If the initialValue is changed external, sync it up with our state
			/* eslint-disable */ // от безысходности
			useEffect(() => {
				setValue(initialValue);
			}, [initialValue]);

			return (
				<input
					ref={inputRef}
					className={styles.cellInput}
					value={value as string}
					onChange={e => setValue(e.target.value)}
					onBlur={onBlur}
					onKeyDown={(e) => keyDownHandler(e)}
					disabled={!isAccess(TTS.DICTIONARY_SAVE)}
				/>
			);
		},
	};

	const columns = useMemo<ColumnDef<DictionaryRow, string>[]>(() => [
		{
			id: 'select',
			header: ({ table }) => (
				<TableCheckbox
					{...{
						checked: table.getIsAllRowsSelected(),
						indeterminate: table.getIsSomeRowsSelected(),
						onChange: table.getToggleAllRowsSelectedHandler(),
						disabled: !(isAccess(TTS.DICTIONARY_SAVE)),
						tabIndex: -1,
						className: styles.checkbox,
					}}
				/>
			),
			cell: ({ row }) => (
				<TableCheckbox
					{...{
						checked: row.getIsSelected(),
						disabled: !row.getCanSelect(),
						indeterminate: row.getIsSomeSelected(),
						onChange: row.getToggleSelectedHandler(),
						tabIndex: -1,
						className: styles.checkbox,
					}}
				/>
			),
			minSize: 20,
			maxSize: 20,
			size: 20,
		},
		{
			id: 'phrase',
			header: translate('columnTitle_phrase'),
			accessorKey: 'phrase',
			size: 160
		}, {
			id: 'modifiedPhrase',
			header: translate('columnTitle_modifiedPhrase'),
			accessorKey: 'modifiedPhrase',
			size: 320,
		}
	], []);

	const table = useReactTable({
		data,
		columns,
		initialState: {
			pagination: {
				pageIndex: 0,
				pageSize: 100
			}
		},
		state: {
			rowSelection,
			sorting,
		},
		filterFns: {
			myCustomFilter: () => {
				return false;
			}
		},
		defaultColumn, // для редактирования
		enableRowSelection: isAccess(TTS.DICTIONARY_SAVE), // checkbox
		enableColumnResizing: true, // изменение ширины столбца
		onRowSelectionChange: setRowSelection, // checkbox
		onSortingChange: setSorting, // для сортировки
		getSortedRowModel: getSortedRowModel(), // для сортировки
		getCoreRowModel: getCoreRowModel(),
		getFilteredRowModel: getFilteredRowModel(), // для фильтрации
		getPaginationRowModel: getPaginationRowModel(), // для нумерации страниц
		columnResizeMode: 'onChange', // изменение ширины столбца в runtime
		// Provide our updateData function to our table meta
		// meta: {
		// 	updateData: (rowIndex, columnId, value) => {
		// 		setData(old =>
		// 			old.map((row, index) => {
		// 				if (index === rowIndex) {
		// 					return {
		// 						...old[rowIndex]!,
		// 						[columnId]: value,
		// 					};
		// 				}
		// 				return row;
		// 			})
		// 		);
		// 	},
		// },
	});

	return (
		<>
			{dictionary.status === RequestStatus.LOADING && <div className={styles.loading}><ProgressCircle title={translate('spinnerTitle_loading')} /></div>}

			{dictionary.status === RequestStatus.FAILED && <div className={styles.failed}>{translate('title_loadFailed')}</div>}

			{dictionary.status === RequestStatus.IDLE && <div className={styles.wrapper}>
				<div className={styles.container} ref={tableContainerRef}>
					<table className={styles.table} style={{ width: table.getCenterTotalSize() }}>
						<thead className={cn(styles.thead, {
							[styles.theadBoxShadow]: tableContainerRef.current && tableContainerRef.current.scrollTop > 0, // тень из под шапки и размытие под ним, когда скроллим таблицу
						})}>
							{table.getHeaderGroups().map(headerGroup => (
								<tr className={styles.tr} key={headerGroup.id}>
									{headerGroup.headers.map(header => (
										<th className={styles.th} {...{
											key: header.id,
											colSpan: header.colSpan,
											style: { width: header.getSize() }
										}}>
											{!header.isPlaceholder &&
												<>
													<div onClick={header.column.getToggleSortingHandler()} className={header.id === 'select' ? styles.thTitleSelect : styles.thTitle}>
														{flexRender(header.column.columnDef.header, header.getContext())}
														{{ asc: <FontAwesomeIcon icon={faArrowUp} />, desc: <FontAwesomeIcon icon={faArrowDown} /> }[header.column.getIsSorted() as string] ?? null}
													</div>
													{header.column.getCanFilter() &&
														<Filter table={table} column={header.column} rowSelection={rowSelection} />
													}
												</>
											}
											{header.id === 'phrase' &&
												<div {...{
													onMouseDown: header.getResizeHandler(),
													onTouchStart: header.getResizeHandler(),
													className: styles.resizerWrapper,
												}}>
													<div className={styles.resizerDelimiter}></div>
												</div>
											}
										</th>
									))}
								</tr>
							))}
						</thead>
						<tbody className={styles.tbody} ref={tBodyRef}>
							{table.getRowModel().rows.map(row => (
								<tr className={cn(styles.tr, { [styles.trSelected]: table.getSelectedRowModel().flatRows.includes(row) })} key={row.id}>
									{row.getVisibleCells().map(cell => (
										<td className={styles.td} title={cell.getValue() !== undefined ? String(cell.getValue()) : ''} {...{
											key: cell.id,
											style: {
												width: cell.column.getSize()
											},
										}}>
											{flexRender(cell.column.columnDef.cell, cell.getContext())}
										</td>
									)
									)}
								</tr>
							))}
						</tbody>
					</table>
					{table.getRowModel().rows.length === 0 && <div className={styles.notFound}>{translate('title_notFound')}</div>}
				</div>
				<CustomFooter setChangeFlg={setChangeFlg} table={table} rowSelection={rowSelection} tableContainerRef={tableContainerRef} />
			</div>}
		</>
	);
};

export default StressDictionary;
