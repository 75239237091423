import { ChangeEvent, FormEvent, useEffect, useRef, useState } from 'react';
import { FormControl, TextField } from '@mui/material';
import { useReactMediaRecorder } from "react-media-recorder-2";
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis, faMicrophone, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import useTranslate from '../../../hooks/useTranslate';
import { backgroundColor, colorRed } from '../../../constants/colors';
import { IFormSendAudioProps } from './FormSendAudio.props';
import styles from './FormSendAudio.module.scss';

const FormSendAudio = ({ formFor, disableFile, disableSubmit, submitFunction }: IFormSendAudioProps): JSX.Element => {
	const [file, setFile] = useState<File>(); // для отправки файла
	const [recordingFlg, setRecordingFlg] = useState<boolean>(false); // флаг записи с микрофона
	const [text, setText] = useState<string>(''); // текст для проверки аудио при добавлении спикера
	const [threshold, setThreshold] = useState<number>(70); // порог достоверности для поиска спикера
	const [typeAudio, setTypeAudio] = useState<null | 'microphone' | 'file'>(null); // тип аудио для отправки
	const formRef = useRef<HTMLFormElement>(null);

	const { status, startRecording, stopRecording } = useReactMediaRecorder({
		audio: true,
		onStop: (_: string, blob: Blob) => {
			blob.size > 0 && submitHandler(blob); // запускаем обработчик отправки аудиозаписи, если есть запись с микрофона
		}
	}); // hook для записи аудио
	const translate = useTranslate(); // hook для перевода текста

	// следим за флагом и статусом записи микрофона
	useEffect(() => {
		// и если кнопка мыши не зажата в плашке в момент подготовки или начала записи - останавливаем запись
		(status === 'acquiring_media' || status === 'recording') && recordingFlg === false && stopRecording();
	}, [status, recordingFlg]);

	// следим за выбранным аудио-файлом и устанавливаем тип для отображения имени файла
	useEffect(() => {
		file && setTypeAudio('file');
	}, [file]);

	// обработчик отправки аудиозаписи
	const submitHandler = (audio: File | Blob | undefined, e?: FormEvent<HTMLFormElement>): void => {
		e?.preventDefault();

		const formData = new FormData();
		// если есть запись (файл или с микрофона) - создаем пару
		if (audio) {
			formData.append('file', audio);
		}

		// если отправка идет с микрофона
		if (!e) {
			formRef.current?.reset(); // сбрасываем форму
			setFile(undefined); // очищаем файл
			setTypeAudio('microphone'); // устанавливаем тип отправки
		}

		submitFunction({ formData, text, threshold });
	};

	return (
		<form className={styles.form} onSubmit={e => submitHandler(file, e)} ref={formRef}>
			{/* текст для проверки аудио */}
			{(formFor === 'addingSpeaker' || formFor === 'additionSpeaker' || formFor === 'search') &&
				<FormControl fullWidth margin='dense' sx={{ marginBottom: formFor === 'search' ? '8px' : '12px' }}>
					<TextField
						label={translate('input_textForAudioCheck')}
						variant="outlined"
						multiline
						maxRows={4}
						value={text}
						onChange={(e) => setText(e.target.value)}
						InputProps={{
							style: {
								padding: '8px 13px',
								fontSize: 13,
							},
						}}
						InputLabelProps={{
							style: {
								fontSize: 13,
							},
						}}
						sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
					/>
				</FormControl>
			}

			{/* кнопка микрофона */}
			<div className={styles.formInner}>
				<button className={cn(styles.formMicrophone)}
					type='button'
					onMouseDown={() => {
						startRecording();
						setRecordingFlg(true);
					}}
					onMouseUp={() => setRecordingFlg(false)}
					onMouseLeave={() => setRecordingFlg(false)}
					title={translate('buttonTitle_pressAndHold')}
					disabled={disableSubmit}
				>
					<FontAwesomeIcon icon={faMicrophone} size="xl" color={status === 'recording' ? colorRed : backgroundColor} fade={status === 'recording'} />
				</button>

				<div>{translate('title_or').toLowerCase()}</div>

				<input
					className={styles.formFileInput}
					type="file"
					id={formFor}
					required
					accept="audio/wav"
					onChange={(e: ChangeEvent<HTMLInputElement>) => e.target.files && setFile(e.target.files[0])}
					disabled={disableFile}
				/>

				<label htmlFor={formFor} className={styles.formFileLabel}>
					<div className={cn(styles.formFileLabelIcon, {
						[styles.formFileLabelIconNotAllow]: disableFile
					})} title={translate('input_audioFile').toLowerCase()}>
						<FontAwesomeIcon icon={faEllipsis} color={backgroundColor} size="2xl" />
					</div>
					<div className={cn(styles.formFileLabelName, {
						[styles.formFileLabelNameNotAllow]: disableFile
					})} title={file?.name}>
						{typeAudio === 'microphone' ?
							translate('input_recordMicrophone')
							:
							file ?
								file.name
								:
								translate('input_audioFile').toLowerCase()
						}
					</div>
				</label>

				{formFor === 'search' &&
					<FormControl fullWidth>
						<TextField
							id="threshold"
							label={translate('input_threshold')}
							variant="outlined"
							type='number'
							value={threshold}
							onChange={(e) => setThreshold(Number(e.target.value))}
							InputProps={{
								style: {
									height: 32,
									fontSize: 13
								},
								inputProps: { step: 1, min: 0, max: 100 }
							}}
							InputLabelProps={{
								style: {
									fontSize: 13,
								},
							}}
							sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
						/>
					</FormControl>
				}

				{/* отправка */}
				<button
					className={cn(styles.formSubmitBtn)}
					type="submit"
					title={translate('buttonTitle_send')}
					disabled={!file || disableSubmit}
				>
					<FontAwesomeIcon icon={faPaperPlane} color={backgroundColor} size="lg" />
				</button>
			</div>
		</form>
	);
};

export default FormSendAudio;
