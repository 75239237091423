import { FormEvent, useEffect, useMemo, useRef, useState } from 'react';
import { TableCell, TableRow } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faPause, faPen, faPlay, faXmark } from '@fortawesome/free-solid-svg-icons';
import cn from 'classnames';
import { useAppDispatch } from '../../../../store/hooks';
import { renameSpeaker } from '../../../../store/transcriptionSlice';
import useAccessRight from '../../../../hooks/useAccessRight';
import useTranslate from '../../../../hooks/useTranslate';
import { timeConversion } from '../../../../helpers/timeConversion';
import { TRANSCRIPTION } from '../../../../constants/accessRights';
import { backgroundColor, colorGreen, colorPrimary, colorRed } from '../../../../constants/colors';
import { ISpeakerRowProps } from './SpeakerRow.props';
import styles from './SpeakerRow.module.scss';

const SpeakerRow = ({ speaker, idx, audioUrl, from, setChangeFlg, speakerNameList, renameSpeakerFlg, setRenameSpeakerFlg, playableAudioFlg, setPlayableAudioFlg }: ISpeakerRowProps): JSX.Element => {
	const [inputSpeakerName, setInputSpeakerName] = useState<string>(''); // имя спикера для переименования
	const [isPlay, setIsPlay] = useState<boolean>(false); // флаг проигрывания аудио
	const cellRef = useRef<HTMLTableCellElement>(); // ссылка на ячейку второго столбца таблицы

	// следим за ссылкой аудио и началом самого длинного фрагмента
	const audioEl = useMemo(() => {
		const audioEl = new Audio(audioUrl);
		audioEl.currentTime = speaker.startLongestFragment / 1000; // ставим время начала воспроизведения в секундах
		return audioEl;
	}, [audioUrl, speaker.startLongestFragment]); // получаем аудио-отрезок спикера

	const dispatch = useAppDispatch();

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// следим за состоянием воспроизведения
	useEffect(() => {
		let handler: NodeJS.Timer;
		// ставим таймер на выключение аудио, если начало воспроизводиться
		if (isPlay) {
			handler = setTimeout(() => {
				pauseHandler();
			}, (speaker.stopLongestFragment - speaker.startLongestFragment + 500));
		}
		// сбрасываем таймер при выходе или смене статуса воспроизведения
		return () => {
			if (isPlay) {
				clearTimeout(handler);
				pauseHandler();
			}
		};
	}, [isPlay]);

	// следим за родительским флагом воспроизведения
	useEffect(() => {
		if (playableAudioFlg.isPlay && playableAudioFlg.idx !== idx) pauseHandler(); // ставим на паузу, если стартанула другая запись
	}, [playableAudioFlg]);

	// обработчик паузы
	const pauseHandler = (): void => {
		audioEl.pause(); // аудио на паузу
		setIsPlay(false); // смена флага воспроизведения
		setPlayableAudioFlg({ isPlay: false, idx: -1 }); // смена родительского флага воспроизведения 
		audioEl.currentTime = speaker.startLongestFragment / 1000; // установка начала воспроизведения по умолчанию
	};

	// функция воспроизведения/паузы аудио
	const playPause = (): void => {
		if (audioUrl) {
			if (isPlay) pauseHandler();
			else {
				audioEl.play(); // старт аудио
				setIsPlay(true); // смена флага воспроизведения
				setPlayableAudioFlg({ isPlay: true, idx }); // смена родительского флага воспроизведения 
			}
		}
	};

	// обработчик нажатия кнопки переименования спикера (открытие формы)
	const renameSpeakerHandler = (speakerName: string, idx: number): void => {
		setRenameSpeakerFlg({ isOpen: true, idx }); // открываем форму для переименования
		setInputSpeakerName(speakerName); // записываем в input имя спикера
	};

	// обработчик формы переименования спикера
	const submitRenameSpeakerHandler = (e: FormEvent<HTMLFormElement>, oldSpeakerName: string, speakerIdx: number): void => {
		e.preventDefault();
		if (speakerNameList.includes(inputSpeakerName) && oldSpeakerName !== inputSpeakerName) return; // если имя существует - не даем переименовать
		setRenameSpeakerFlg({ isOpen: false, idx: -1 }); // закрываем форму переименования спикера
		if (oldSpeakerName === inputSpeakerName) return; // если имя не поменялось - выходим без изменений
		setChangeFlg && setChangeFlg(true); // ставим флаг о несохраненных данных
		dispatch(renameSpeaker({ speakerIdx, newName: inputSpeakerName })); // переименование спикера
	};

	// обработчик нажатия клавиши в поле переименования спикера
	const keyDownRenameSpeakerHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.code === 'Escape') setRenameSpeakerFlg({ isOpen: false, idx: -1 }); // закрываем форму переименования спикера
	};

	return (
		<TableRow key={idx} sx={{ '&:last-child td, &:last-child th': { border: 0 }, height: '35px' }}>
			<TableCell>
				<button className={cn(styles.playBtn, {
					[styles.playBtnNotActive]: !audioUrl
				})} onClick={playPause}>
					<FontAwesomeIcon icon={isPlay ? faPause : faPlay} size="sm" color={backgroundColor} />
				</button>
			</TableCell>
			<TableCell
				ref={cellRef}
				sx={(from === 'transcript' && isAccess(TRANSCRIPTION.SAVE)) ? { minWidth: '210px' } : undefined}
			>
				{renameSpeakerFlg.isOpen && renameSpeakerFlg.idx === idx ?
					<form
						className={styles.renameForm}
						onSubmit={e => submitRenameSpeakerHandler(e, speaker.id, idx)}
						style={{ width: cellRef.current && cellRef.current.clientWidth - 32 }}
					>
						<input className={styles.renameFormInput} type='text' value={inputSpeakerName} onChange={(e) => setInputSpeakerName(e.target.value)} autoFocus required onKeyDown={(e) => keyDownRenameSpeakerHandler(e)} />
						<button className={styles.renameFormBtn} type="submit" title={translate('buttonTitle_rename')}>
							<FontAwesomeIcon icon={faCheck} style={{ cursor: 'pointer' }} color={colorGreen} size="xl" />
						</button>
						<button className={styles.renameFormBtn} type="reset" title={translate('buttonTitle_cancel')}>
							<FontAwesomeIcon icon={faXmark} style={{ cursor: 'pointer' }} color={colorRed} size="xl" onClick={(e) => {
								e.stopPropagation();
								setRenameSpeakerFlg({ isOpen: false, idx: -1 });
							}} />
						</button>
					</form>
					:
					<div className={styles.speakerBlock}>
						<span>{speaker.id}</span>
						{from === 'transcript' && isAccess(TRANSCRIPTION.SAVE) &&
							<FontAwesomeIcon
								icon={faPen}
								size="sm"
								color={colorPrimary}
								title={translate('buttonTitle_rename')}
								style={{ cursor: 'pointer' }}
								onClick={() => renameSpeakerHandler(speaker.id, idx)}
							/>
						}
					</div>
				}
			</TableCell>
			<TableCell align="left" sx={{ color: colorPrimary, fontSize: 11 }}>{speaker.fragments}</TableCell>
			<TableCell align="left" sx={{ color: colorPrimary, fontSize: 11 }}>{timeConversion({ time: speaker.duration, from: 'ms', to: 'withMs' })}</TableCell>
			{speaker.gender &&
				<TableCell align="left" sx={{ color: colorPrimary, fontSize: 11 }}>{translate(speaker.gender)}</TableCell>
			}
			{speaker.age &&
				<TableCell align="left" sx={{ color: colorPrimary, fontSize: 11 }}>{translate(speaker.age)}</TableCell>
			}
		</TableRow>
	);
};

export default SpeakerRow;
