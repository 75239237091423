import { useState } from 'react';
import { FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import cn from 'classnames';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { addSlaveData, selectDataElem, selectDataList } from '../../../store/sesSlice';
import useTranslate from '../../../hooks/useTranslate';
import { colorPrimary } from '../../../constants/colors';
import { IFormAddingSlaveDataProps } from './FormAddingSlaveData.props';
import styles from './FormAddingSlaveData.module.scss';

const FormAddingSlaveData = ({ changeFlg, setChangeFlg }: IFormAddingSlaveDataProps): JSX.Element => {
	const translate = useTranslate(); // hook для перевода текста

	const [inputDataElementIds, setInputDataElementIds] = useState<string>(translate('selectItem_chooseElement')); // список id элементов данных

	const dispatch = useAppDispatch();
	const dataElemList = useAppSelector(selectDataList); // список элементов данных
	const dataElem = useAppSelector(selectDataElem); // store - элемент данных

	// обработчик выбора условия
	const handleChange = (event: SelectChangeEvent<string>): void => {
		dispatch(addSlaveData(event.target.value)); // добавляем условие
		dataElem.element?.id && (!changeFlg.thisIs || !changeFlg.listOfChanges.includes('slaveData')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'slaveData'] }));  // ставим флаг о несохраненных данных
		setInputDataElementIds(translate('selectItem_chooseElement')); // сбрасываем поле
	};

	return (
		<div className={cn({
			[styles.slaveDataOpacity]: inputDataElementIds === translate('selectItem_chooseElement')
		})}>
			<FormControl fullWidth margin='dense' sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}>
				<Select
					required
					value={inputDataElementIds}
					onChange={e => handleChange(e)}
					style={{ fontSize: 13, height: 33, color: colorPrimary }}
				>
					<MenuItem value={translate('selectItem_chooseElement')} sx={{ fontSize: 13, color: colorPrimary }} disabled>{translate('selectItem_chooseElement')}</MenuItem>
					{dataElemList.data.map((elem) => {
						if (elem.type === 'script') return undefined;
						else return (
							<MenuItem key={elem.id} value={elem.id} sx={{ fontSize: 13, color: colorPrimary }}>{elem.name}</MenuItem>
						);
					})}
				</Select>
			</FormControl>
		</div>
	);
};

export default FormAddingSlaveData;
