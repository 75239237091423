import { FormEvent, useRef, useState } from 'react';
import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import cn from 'classnames';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { addRow, deleteRows, selectDocument } from '../../../../store/qasSlice';
import useAccessRight from '../../../../hooks/useAccessRight';
import useTranslate from '../../../../hooks/useTranslate';
import { wordDeclination } from '../../../../helpers/wordDeclination';
import { QAS } from '../../../../constants/accessRights';
import { colorPrimary, colorRed } from '../../../../constants/colors';
import TableCustomPagination from '../../TableCustomPagination/TableCustomPagination';
import { ICustomFooterProps } from './CustomFooter.props';
import styles from './CustomFooter.module.scss';

const CustomFooter = ({ setChangeFlg, table, rowSelection, tableContainerRef, data }: ICustomFooterProps): JSX.Element => {
	const [inputAbbreviation, setInputAbbreviation] = useState<string>(''); // сокращение для добавления в глоссарий
	const [inputTranscript, setInputTranscript] = useState<string>(''); // расшифровка для добавления в глоссарий
	const [existWarnAbbreviation, setExistWarnAbbreviation] = useState<boolean>(false); // уведомление о существующем сокращении

	const inputAbbreviationRef = useRef<HTMLInputElement>(); // ссылка на input сокращения

	const dispatch = useAppDispatch();
	const documentFile = useAppSelector(selectDocument); // store - документ (файл)

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// обработчик добавления строки в глоссарий
	const submitAddRowHandler = (e: FormEvent<HTMLFormElement>): void => {
		e.preventDefault();
		// если сокращение существует - ставим оповещение
		if (inputAbbreviation in documentFile.glossary) {
			setExistWarnAbbreviation(true);
		} else {
			existWarnAbbreviation && setExistWarnAbbreviation(false); // убираем оповещение о существущем сокращении
			dispatch(addRow({ abbreviation: inputAbbreviation, transcript: inputTranscript })); // добавление строки в глоссарий
			setChangeFlg(true); // включаем флаг о не сохраненных данных
			setInputAbbreviation(''); // очищаем поле "Сокращение"
			setInputTranscript(''); // очищаем поле "Расшифровка"
			inputAbbreviationRef.current?.focus(); // ставим фокус на поле "Сокращение"
		}
	};

	// обработчик нажатой клавиши
	const kewDownHandler = (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
		// если нажата клавиша Tab - ставим фокус на поле "Сокращение"
		if (e.code === 'Tab') {
			e.preventDefault();
			inputAbbreviationRef.current?.focus();
		}
	};

	// обработчик удаления выделенных строк
	const deleteRowsHandler = () => {
		const indicesHighlightedRows = Object.keys(rowSelection).map(value => +value); // индексы выделенных строк
		const abbreviationList = data.filter((_row, idx) => indicesHighlightedRows.includes(idx)).map(row => row.abbreviation); // список аббревиатур на удаление
		dispatch(deleteRows(abbreviationList)); // удаляем строки из таблицы глоссария
		setChangeFlg(true); // включаем флаг о не сохраненных данных
		table.resetRowSelection(); // очищаем выделенные строки
	};

	return (
		<div className={cn(styles.footer, {
			[styles.footerBoxShadow]: tableContainerRef.current && tableContainerRef.current.scrollHeight - tableContainerRef.current.offsetHeight > Math.ceil(tableContainerRef.current.scrollTop) + 1
		})}>
			{Object.keys(rowSelection).length > 0 && isAccess(QAS.DOC_EDIT) &&
				<div className={styles.selectedRow}>
					<span>{translate('title_selected')}: {Object.keys(rowSelection).length} {wordDeclination(Object.keys(rowSelection).length, [translate('title_row'), translate('title_rows'), translate('title_rows2')])}</span>
					<FontAwesomeIcon icon={faTrashCan} color={colorRed} onClick={deleteRowsHandler} title={translate('buttonTitle_deleteRows')} style={{ cursor: 'pointer' }} />
				</div>
			}
			<Box sx={{ display: 'flex', gap: '8px', alignItems: 'center', justifyContent: 'flex-end' }}>
				{isAccess(QAS.DOC_EDIT) &&
					<form className={styles.form} onSubmit={e => submitAddRowHandler(e)}>
						<TextField
							inputRef={inputAbbreviationRef}
							required
							label={translate('input_abbreviation')}
							value={inputAbbreviation}
							onChange={e => setInputAbbreviation(e.target.value)}
							error={existWarnAbbreviation}
							helperText={existWarnAbbreviation ? <div className={styles.formExistAbbreviation}>{translate('title_abbreviationExists')}</div> : ''}
							variant="outlined"
							InputProps={{
								style: {
									height: 33,
									fontSize: 13,
									color: colorPrimary,
								},
							}}
							InputLabelProps={{
								style: {
									fontSize: 13,
								},
							}}
							sx={{ flexGrow: 1, '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
						/>
						<TextField
							required
							label={translate('input_transcript')}
							value={inputTranscript}
							onChange={e => setInputTranscript(e.target.value)}
							variant="outlined"
							InputProps={{
								onKeyDown: (e) => kewDownHandler(e),
								style: {
									height: 33,
									fontSize: 13,
									color: colorPrimary,
								},
							}}
							InputLabelProps={{
								style: {
									fontSize: 13,
								},
							}}
							sx={{ flexGrow: 1, '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
						/>
						<Button variant="outlined" type='submit' sx={{ fontSize: 11, overflow: 'hidden' }} disabled={!inputAbbreviation || !inputTranscript}>
							{translate('button_add')}
						</Button>
					</form>
				}
				<div className={styles.pagination}>
					<FormControl sx={{ width: 96, '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}>
						<InputLabel id="demo-simple-select-label" sx={{ fontSize: 13 }}>{translate('select_quantityRows')}</InputLabel>
						<Select
							labelId="demo-simple-select-label"
							id="simple-select"
							value={table.getState().pagination.pageSize}
							label={translate('select_quantityRows')}
							onChange={e => table.setPageSize(Number(e.target.value))}
							style={{ fontSize: 13, height: 33, color: colorPrimary }}

						>
							{[10, 20, 50, 100, 500, 1000].map(value => <MenuItem key={value} value={value} sx={{ fontSize: 13, color: colorPrimary }}>{value}</MenuItem>)}
						</Select>
					</FormControl>
					<TableCustomPagination table={table} tableContainerRef={tableContainerRef} />
				</div>
			</Box>
		</div>
	);
};

export default CustomFooter;
