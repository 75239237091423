import { FormEvent, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Button, FormControl, TextField } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { addRobot, clearRobotList, getRobotList, selectAddingRobot, selectRobotList } from '../../../store/sesRobotSlice';
import useTranslate from '../../../hooks/useTranslate';
import { ROBOT_ID } from '../../../constants/cookieNames';
import { RequestStatus, ResponseStatus } from '../../../types/statusTypes';
import ModalFormWindow from '../../../HOC/ModalFormWindow/ModalFormWindow';
import ProgressCircle from '../../ProgressCircle/ProgressCircle';
import { IFormAddingRobotProps } from './FormAddingRobot.props';

const FormAddingRobot = ({ showModal, setShowModal, setShowNotification }: IFormAddingRobotProps): JSX.Element => {
	const [inputName, setInputName] = useState<string>(''); // название робота
	const [showErrorExistingRobot, setShowErrorExistingRobot] = useState<boolean>(false); // флаг существующего имени робота

	const dispatch = useAppDispatch();
	const robotList = useAppSelector(selectRobotList); // store - список роботов
	const addingRobotStatus = useAppSelector(selectAddingRobot); // store - статус добавления робота

	const [_cookies, setCookie] = useCookies([ROBOT_ID]); // hook для работы с cookie
	const translate = useTranslate(); // hook для перевода текста

	// следим за статусом добавления робота
	useEffect(() => {
		// если добавление прошло успешно 
		if (addingRobotStatus.error === ResponseStatus.SUCCESS && addingRobotStatus.status === RequestStatus.IDLE && addingRobotStatus.message !== '') {
			setCookie(ROBOT_ID, addingRobotStatus.id, { path: '/', maxAge: 2_592_000 }); // устанавливаем cookie на месяц
			dispatch(clearRobotList()); // очищаем список роботов
			dispatch(getRobotList()); // получаем заново список роботов
		}
		// если были изменения в статусе добавления
		if (addingRobotStatus.error !== ResponseStatus.SUCCESS || addingRobotStatus.status === RequestStatus.FAILED || addingRobotStatus.message !== '') {
			handleClose(); // закрываем форму
			setShowNotification(true); // включаем уведомление
		}
	}, [addingRobotStatus]);

	// обработчик добавления робота
	const submitHandler = (e: FormEvent<HTMLFormElement>): void => {
		e.preventDefault();
		const existingRobotName = robotList.data.find(robot => robot.name === inputName); // существующее имя робота
		if (existingRobotName) {
			setShowErrorExistingRobot(true); // подсвечиваем ошибку
		} else {
			showErrorExistingRobot && setShowErrorExistingRobot(false); // убираем ошибку, если была
			dispatch(addRobot(inputName)); // добавление робота
		}
	};

	// обработчик закрытия формы
	const handleClose = (): void => {
		// если идет добавление робота - запрещаем покидать форму
		if (addingRobotStatus.status === RequestStatus.LOADING) return;
		setShowModal(false);
	};

	return (
		<ModalFormWindow showModal={showModal} setShowModal={setShowModal} headerTitle='formHeader_addingRobot' close={handleClose}>
			<form onSubmit={(e) => submitHandler(e)}>
				<FormControl fullWidth margin='dense'>
					<TextField
						autoFocus
						required
						id="robotName"
						label={translate('input_name')}
						variant="outlined"
						value={inputName}
						onChange={(e) => setInputName(e.target.value)}
						disabled={addingRobotStatus.status === RequestStatus.LOADING}
						error={showErrorExistingRobot}
						helperText={showErrorExistingRobot ? translate('title_robotExists') : ''}
						InputProps={{
							style: {
								height: 33,
								fontSize: 13
							},
						}}
						InputLabelProps={{
							style: {
								fontSize: 13,
							},
						}}
						sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
					/>
				</FormControl>
				<FormControl fullWidth margin='dense'>
					<Button
						variant="outlined"
						type="submit"
						disabled={addingRobotStatus.status === RequestStatus.LOADING}
						sx={{ fontSize: 11 }}
					>
						{addingRobotStatus.status === RequestStatus.LOADING ?
							<>
								{translate('button_add')}
								<ProgressCircle isBtnDisabled />
							</>
							:
							translate('button_add')
						}
					</Button>
				</FormControl>
			</form>
		</ModalFormWindow>
	);
};

export default FormAddingRobot;
