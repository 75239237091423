import { useEffect } from 'react';
import { Autocomplete, Button, FormControl, Slide, TextField, createFilterOptions } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { addCategory, addCategoryInList, changeActiveFileCategory, changePlaceAddingCategory, clearAddingCategory, selectAddingCategory, selectCategoriesList, selectDocument } from '../../store/qasSlice';
import useAccessRight from '../../hooks/useAccessRight';
import useTranslate from '../../hooks/useTranslate';
import { QAS } from '../../constants/accessRights';
import { colorPrimary } from '../../constants/colors';
import { RequestStatus, ResponseStatus } from '../../types/statusTypes';
import Glossary from '../Tables/Glossary/Glossary';
import ProgressCircle from '../ProgressCircle/ProgressCircle';
import { IDocumentParametersProps } from './DocumentParameters.props';
import styles from './DocumentParameters.module.scss';

const DocumentParameters = ({ showDocumentParams, setShowDocumentParams, changeFlg, setChangeFlg, inputCategory, setInputCategory, errorCategoryFlg, setErrorCategoryFlg, setShowAlertDialogSave, setShowAlertDialogDelete, writeCategoryInField }: IDocumentParametersProps): JSX.Element => {

	const dispatch = useAppDispatch();
	const addingCategoryStatus = useAppSelector(selectAddingCategory); // store - статус добавления категории
	const categoriesList = useAppSelector(selectCategoriesList); // store - список категорий
	const documentFile = useAppSelector(selectDocument); // store - документ (файл)

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// следим за списком категорий
	useEffect(() => {
		// если есть - обновляем имя активной категории в документе
		if (categoriesList.data.length > 0) writeCategoryInField();
	}, [categoriesList.data]);

	// следим за статусом добавления категории
	useEffect(() => {
		// если добавлялось из документа
		if (addingCategoryStatus.from === 'document') {
			// и успешно
			if (addingCategoryStatus.id) {
				dispatch(clearAddingCategory()); // очищаем статус добавления категории
				dispatch(addCategoryInList({ id: addingCategoryStatus.id, name: inputCategory })); // добавлем категорию в список
				dispatch(changeActiveFileCategory({ categoryName: addingCategoryStatus.id, change: true })); // изменяем категорию в документе
				setChangeFlg(true); // ставим флаг о несохраненных данных
			}
			// если не успешно
			else if (addingCategoryStatus.status === RequestStatus.FAILED || addingCategoryStatus.error === ResponseStatus.FAILED) {
				setInputCategory(''); // очищаем категорию
				dispatch(changeActiveFileCategory({ categoryName: '', change: true })); // изменяем категорию в документе
				setErrorCategoryFlg(true); // подсвечиваем ошибку
				setChangeFlg(true); // ставим флаг о несохраненных данных
			}
		}
	}, [addingCategoryStatus]);

	// обработчик изменения поля категории
	const changeCategory = (value: string | null): void => {
		errorCategoryFlg && setErrorCategoryFlg(false); // сбрасываем ошибку, если была
		if (value) {
			setInputCategory(value); // записываем имя в state
			// ищем категорию из input'а в списке категорий
			const foundCategory = categoriesList.data.find(categoryItem => categoryItem.name === value);
			// если выбрана категория из существующих
			if (foundCategory) {
				dispatch(changeActiveFileCategory({ categoryName: foundCategory.id, change: true })); // изменяем категорию в документе
				setChangeFlg(true); // ставим флаг о несохраненных данных
			} else {
				dispatch(changePlaceAddingCategory('document')); // изменяем место, откуда будет добавляться категория
				isAccess(QAS.CATEGORY_ADD) && dispatch(addCategory(value)); // если нет категории - добавлем новую
			}
		} else {
			setInputCategory(''); // очищаем название категории
			dispatch(changeActiveFileCategory({ categoryName: '', change: true })); // изменяем категорию в документе
			setChangeFlg(true); // ставим флаг о несохраненных данных
		}
	};

	// функция формирования глоссария в строку для сохранения в .csv файле
	const formatGlossaryInCsvFile = (): string => {
		let str: string = '';
		for (const abbreviation in documentFile.glossary) {
			str += abbreviation + '\t' + documentFile.glossary[abbreviation] + '\n';
		}
		return str;
	};

	// обработчик скачивания глоссария
	const downloadHandler = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
		Object.keys(documentFile.glossary).length === 0 && e.preventDefault(); // если пусто - не качаем
	};

	// функция, определяющая отфильтрованные параметры, которые будут отображаться при поиске
	const filter = createFilterOptions<string>();

	// обработчик закрытия вкладки
	const closeHandler = (): void => {
		setShowDocumentParams(false);
	};

	return (
		<Slide direction="left" in={showDocumentParams} mountOnEnter unmountOnExit>
			<div className={styles.modal} onMouseDown={closeHandler}>
				<div className={styles.documentParameters} onMouseDown={(e) => e.stopPropagation()}>
					<div className={styles.documentParametersTop}>
						<FormControl fullWidth margin='dense'>
							<Autocomplete
								freeSolo={isAccess(QAS.CATEGORY_ADD)}
								autoHighlight
								options={categoriesList.data.map(categoryItem => categoryItem.name)}
								noOptionsText={<div className={styles.documentNoDataTitle}>{translate('title_notFound')}</div>}
								disabled={addingCategoryStatus.status === RequestStatus.LOADING || !isAccess(QAS.DOC_EDIT)}
								value={inputCategory}
								onChange={(_, value) => changeCategory(value)}
								filterOptions={(options, state) => {
									const filtered = filter(options, state);
									// если есть доступ к добавлению категории
									if (isAccess(QAS.CATEGORY_ADD)) {
										if (state.inputValue.length > 0 && options.findIndex(category => category === state.inputValue) === -1) filtered.push(state.inputValue);
										return filtered;
									}
									return filtered;
								}}
								renderInput={(params) =>
									<TextField
										{...params}
										label={translate('input_category')}
										error={errorCategoryFlg}
										helperText={errorCategoryFlg ? translate(addingCategoryStatus.message || "title_failedAddingCategory") : undefined}
										variant="outlined"
										InputLabelProps={{
											style: {
												fontSize: 13,
											},
										}}
										InputProps={{
											...params.InputProps, // важно прокинуть параметры
											endAdornment: (
												<>
													{addingCategoryStatus.status === RequestStatus.LOADING && addingCategoryStatus.from === 'document' &&
														<ProgressCircle isBtnDisabled />
													}
													{params.InputProps.endAdornment} {/* важно дописать параметры */}
												</>
											),
										}}
										sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
									/>
								}
								sx={{
									".MuiInputBase-root": { height: 33, fontSize: 13, color: colorPrimary },
									".MuiInputBase-input": { marginTop: -1 },
								}}
								getOptionLabel={option => option}
								renderOption={(props, option, _state, ownerState) => {
									const match = ownerState.options.filter(category => category === option);
									return (
										<li {...props} style={{ fontSize: 13 }}>
											{match.length === 0 ?
												<>{translate('title_addCategory')} "{option}"</>
												:
												<span>{option}</span>
											}
										</li>
									);
								}}
							/>
						</FormControl>

						<Glossary setChangeFlg={setChangeFlg} />
					</div>
					<div className={styles.documentParametersBottom}>
						{/* удалить документ */}
						{isAccess(QAS.DOC_DELETE) &&
							<div className={styles.documentParametersBottomDeleteBtn} onClick={() => setShowAlertDialogDelete(true)}>
								{translate('link_deleteDocument')}
							</div>
						}
						<div className={styles.documentParametersBottomRight}>
							{/* сохранить изменения */}
							{isAccess(QAS.DOC_EDIT) &&
								<Button
									variant="outlined"
									disabled={!changeFlg}
									sx={{ fontSize: 11 }}
									onClick={() => setShowAlertDialogSave(true)}
								>
									{translate('button_save')}
								</Button>
							}
							{/* экспорт глоссария */}
							<a
								href={'data:text/plain;charset=utf-8,' + encodeURIComponent(formatGlossaryInCsvFile())}
								target='_blank'
								rel="noreferrer"
								download={`QAS_${documentFile.fileName}_glossary.csv`}
								onClick={e => downloadHandler(e)}
								className={Object.keys(documentFile.glossary).length === 0 ? styles.documentParametersBottomRightExport : undefined}
							>
								<Button variant="outlined" sx={{ fontSize: 11 }} disabled={Object.keys(documentFile.glossary).length === 0}>
									{translate('button_export')}
								</Button>
							</a>
						</div>
					</div>
				</div>

				<div className={styles.tagClose} onClick={closeHandler}>
					{translate('tag_close')}
				</div>
			</div>
		</Slide>
	);
};

export default DocumentParameters;
