import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Fade, Slide } from '@mui/material';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { addUserName, clearAddUserStatus, clearUserInfo, getUserInfo, getUserList, selectAddUserStatus, selectCurrentUserInfo, selectUserInfo, selectUserList } from '../../../store/userSlice';
import useAccessRight from '../../../hooks/useAccessRight';
import useTranslate from '../../../hooks/useTranslate';
import { ROLES } from '../../../constants/routes';
import { ROLE, USER } from '../../../constants/accessRights';
import { RequestStatus, ResponseStatus } from '../../../types/statusTypes';
import FormAddingUser from '../../Forms/FormAddingUser/FormAddingUser';
import ProgressCircle from '../../ProgressCircle/ProgressCircle';
import Notification from '../../Notification/Notification';
import { IUserNavbarProps } from './UserNavbar.props';
import styles from './UserNavbar.module.scss';

const UserNavbar = ({ setChangeFlg, setPassword, setShowPage }: IUserNavbarProps): JSX.Element => {
	const [activeUser, setActiveUser] = useState<string>(); // активный пользователь
	const [showModal, setShowModal] = useState<boolean>(false); // показ формы добавления пользователя
	const [showNotification, setShowNotification] = useState<boolean>(false); // показ уведомлений
	const listRef = useRef<HTMLUListElement | null>(null); // ссылка на список пользователей

	const dispatch = useAppDispatch();
	const userList = useAppSelector(selectUserList); // store - список пользователей
	const userInfo = useAppSelector(selectUserInfo); // store - информация о пользователе
	const currentUserInfo = useAppSelector(selectCurrentUserInfo); // store - информация о текущем пользователе
	const addStatusUser = useAppSelector(selectAddUserStatus); // store - статус добавления пользователя

	const navigate = useNavigate(); // hook для навигации
	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// следим за списком пользователей
	useEffect(() => {
		// только если список пользователей не пустой
		if (Array.isArray(userList.data) && userList.data.length > 0) {
			// и если добавление пользователя прошло успешно
			if (addStatusUser.status === RequestStatus.IDLE && addStatusUser.error === ResponseStatus.SUCCESS && addStatusUser.message === 'success') {
				addStatusUser.newName && addStatusUser.id && userHandler(addStatusUser.newName, addStatusUser.id); // передаем в обработчик выбора активного пользователя - имя добавленного пользователя из store
			} else {
				// иначе выбираем из списка текущего пользователя
				const indexCurrentUser = userList.data.findIndex(user => user.username === currentUserInfo.userName);
				if (indexCurrentUser >= 0) userHandler(userList.data[indexCurrentUser].username, userList.data[indexCurrentUser].id);
				// или если не нашлось - первого из списка
				else userHandler(userList.data[0].username, userList.data[0].id);
			}
		}
	}, [userList.data]);

	// следим за активным пользователем
	useEffect(() => {
		userList.data && Array.isArray(userList.data) && listRef.current?.children[userList.data.findIndex(user => user.username === activeUser)]?.scrollIntoView({ block: "center" }); // показ активного пользователя в центре списка с имеющейся полосой прокрутки
	}, [activeUser]);

	// обработчик выбора активного пользователя
	const userHandler = (userName: string, id: string): void => {
		userInfo.data && dispatch(clearUserInfo()); // очищаем все данные о пользователе, если есть
		setChangeFlg(false); // сбрасываем флаг о не сохраненных данных
		setPassword(""); // очищаем пароль
		setActiveUser(userName); // устанавливаем имя активного пользователя
		dispatch(addUserName(userName)); // добавляем имя активного пользователя в store
		isAccess(USER.USER_INFO) && dispatch(getUserInfo(id)); // получаем инфо пользователя
	};

	// задержка для перехода на другую страницу
	const delayToHidePage = (): void => {
		setShowPage(false); // уводим страницу в фон
		setTimeout(() => {
			navigate(ROLES);
		}, 500);
	};

	return (
		<Slide direction="right" in={true} mountOnEnter unmountOnExit timeout={800}>
			<div className={styles.sidebar}>

				<div className={styles.navbar}>
					{/* ошибка загрузки списка пользователей */}
					{(userList.status === RequestStatus.FAILED || (userList.data && 'error' in userList.data)) &&
						<div className={styles.navbarFailedText}>
							{userList.data && 'message' in userList.data ?
								<span>{translate(userList.data.message)}</span>
								:
								<span>{translate('title_loadFailed')}</span>
							}
							{isAccess(USER.LIST) &&
								<span className={styles.navbarFailedUpdate} onClick={() => dispatch(getUserList())}>{translate('link_update')}</span>
							}
						</div>
					}

					{/* пустой список пользователей */}
					{userList.status === RequestStatus.IDLE && Array.isArray(userList.data) && userList.data.length === 0 &&
						<div className={styles.navbarNoUsers}>{translate('title_emptyList')}</div>
					}

					{/* загрузка списка пользователей */}
					{userList.status === RequestStatus.LOADING &&
						<div className={styles.navbarLoading}>
							<ProgressCircle title={translate('spinnerTitle_loading')} />
						</div>
					}

					{/* список пользователей */}
					<ul className={styles.navbarUserList} ref={listRef}>
						{Array.isArray(userList.data) && userList.data.map(({ id, username }) => (
							<li className={styles.navbarUser} key={id}>
								<Fade in={userList.status !== RequestStatus.LOADING || (Array.isArray(userList.data) && userList.data.length > 0)} timeout={500}>
									<div
										className={cn({
											[styles.navbarUserLink]: activeUser !== username,
											[styles.navbarUserLinkActive]: activeUser === username,
										})}
										onClick={() => username !== activeUser && userHandler(username, id)}
									>
										<div className={styles.navbarUserLinkLeftBlock} title={username}>
											{username}
										</div>
										<FontAwesomeIcon icon={faAngleRight} />
									</div>
								</Fade>
							</li>
						))}
					</ul>

					<div className={styles.functionButtons}>
						{/* добавление пользователя */}
						{isAccess(USER.ADD) && userList.status === RequestStatus.IDLE &&
							<Fade in={true} timeout={500}>
								<div className={styles.functionButtonsAddUser} onClick={() => setShowModal(true)} >
									{translate('link_addUser')}
								</div>
							</Fade>
						}
						{/* табы */}
						{isAccess(ROLE.LIST) &&
							<div className={styles.functionButtonsTabs}>
								<div className={styles.functionButtonsTab}>{translate('tab_users')}</div>
								<div
									className={cn(styles.functionButtonsTab, styles.functionButtonsTabNonActive)}
									onClick={delayToHidePage}>
									{translate('tab_roles')}
								</div>
							</div>
						}
					</div>
				</div>

				{showModal && <FormAddingUser showModal={showModal} setShowModal={setShowModal} setShowNotification={setShowNotification} />}
				{showNotification && <Notification showNotification={showNotification} setShowNotification={setShowNotification} selectDataResponse={selectAddUserStatus} clearDataResponse={clearAddUserStatus} titleFailed={translate('noticeAddition_failed')} titleSuccess={translate('noticeAddition_success')} />}
			</div>
		</Slide>
	);
};

export default UserNavbar;
