import { Fade, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import useTranslate from '../../../hooks/useTranslate';
import { backgroundColor, colorPrimary, colorSecondary } from '../../../constants/colors';
import { IAutoMarkTableProps } from './AutoMarkTable.props';

const AutoMarkTable = ({ automarkCorpus }: IAutoMarkTableProps) => {
	const translate = useTranslate(); // hook для перевода текста

	return (
		<Fade in={true} timeout={500}>
			<TableContainer component={Paper} sx={{ backgroundColor: backgroundColor, boxShadow: '0 0 8px #cacaca', borderRadius: '4px', flexGrow: 1 }}>
				<Table stickyHeader aria-label="simple table" size="small">
					<TableHead>
						<TableRow>
							<TableCell sx={{ color: colorSecondary, fontSize: 13, backgroundColor: backgroundColor/* , width: '50%'  */ }}>{translate('columnTitle_classes')}</TableCell>
							<TableCell sx={{ color: colorSecondary, fontSize: 13, backgroundColor: backgroundColor/* , width: '50%' */ }}>{translate('columnTitle_phrase')}</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{automarkCorpus.map((row, idx) => (
							<TableRow key={idx} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
								<TableCell align="left" sx={{ color: colorPrimary, fontSize: 11, whiteSpace: 'pre-wrap' }}>{row[0].join(', ')}</TableCell>
								<TableCell align="left" sx={{ color: colorPrimary, fontSize: 11, whiteSpace: 'pre-wrap' }}>{row[1]}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</Fade>
	);
};

export default AutoMarkTable;
