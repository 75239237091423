import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError, AxiosResponse } from 'axios';
import { RootState } from './store';
import axiosApi from '../helpers/axios';
import { ENDPOINTS_SMC, URL_SMC } from '../constants/api_endpoints';
import { IResponse, ISmcState, IStopTrainModelProps, ITestModelProps, ITrainModelProps, IManualCheckModelProps, ICompressPhraseProps, ICompressionPhraseResponse, IGetEmotionProps, IGetEmotionResponse, IManualCheckData, IUpdateGroupsProps, IGetPunctuationProps, IPunctuateResponse, ICorrectProps, ICorrectResponse, INormalizationResponse, IToxicityResponse } from '../types/smcTypes';
import { RequestStatus, ResponseStatus } from '../types/statusTypes';

const initialState: ISmcState = {
  trainModel: {
    status: RequestStatus.IDLE,
    error: ResponseStatus.SUCCESS,
    message: '',
  },
  stopTrainModel: {
    status: RequestStatus.IDLE,
    error: ResponseStatus.SUCCESS,
    message: '',
  },
  updateGroups: {
    status: RequestStatus.IDLE,
    error: ResponseStatus.SUCCESS,
    message: '',
  },
  testModel: {
    status: RequestStatus.IDLE,
    error: ResponseStatus.SUCCESS,
    message: '',
  },
  manualCheckModel: {
    status: RequestStatus.IDLE,
    data: null,
  },
  emotionAnalysis: {
    status: RequestStatus.IDLE,
    data: null,
  },
  compressionPhrase: {
    status: RequestStatus.IDLE,
    data: null,
  },
  punctuate: {
    status: RequestStatus.IDLE,
    data: null,
  },
  correct: {
    status: RequestStatus.IDLE,
    data: null,
  },
  normalization: {
    status: RequestStatus.IDLE,
    data: null,
  },
  toxicity: {
    status: RequestStatus.IDLE,
    error: ResponseStatus.SUCCESS,
    message: '',
    data: null,
  },
};

// обучение модели
export const trainModelSmc = createAsyncThunk(
  'smc/trainModel',
  async ({ modelName, corpusName, isMarks }: ITrainModelProps, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IResponse> = await axiosApi.post(`${URL_SMC}/${ENDPOINTS_SMC.TRAIN}/${modelName}`, {
        corpus: corpusName,
        isMarks,
      });
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// остановка обучения модели
export const stopTrainModelSmc = createAsyncThunk(
  'smc/stopTrainModel',
  async ({ modelName }: IStopTrainModelProps): Promise<IResponse> => {
    const response: AxiosResponse<IResponse> = await axiosApi.post(`${URL_SMC}/${ENDPOINTS_SMC.STOP_TRAIN}/${modelName}`);
    return response.data;
  }
);

// обновление групп в модели
export const updateGroups = createAsyncThunk(
  'smc/updateGroups',
  async ({ modelName, corpusName }: IUpdateGroupsProps, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IResponse> = await axiosApi.post(`${URL_SMC}/${ENDPOINTS_SMC.GROUPS}/${modelName}`, {
        corpus: corpusName,
      });
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// тестирование модели
export const testModelSmc = createAsyncThunk(
  'smc/testModel',
  async ({ modelName, corpusName }: ITestModelProps, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IResponse> = await axiosApi.post(`${URL_SMC}/${ENDPOINTS_SMC.TEST}/${modelName}`, {
        corpus: corpusName,
      });
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// ручная проверка модели
export const manualCheckModelSmc = createAsyncThunk(
  'smc/manualCheckModel',
  async ({ modelName, text, confidenceThreshold }: IManualCheckModelProps): Promise<IManualCheckData | IResponse> => {
    const response: AxiosResponse<IManualCheckData | IResponse> = await axiosApi.get(`${URL_SMC}/${ENDPOINTS_SMC.MANUAL_CHECK}/${modelName}`, {
      params: {
        text,
        confidenceThreshold,
      }
    });
    return response.data;
  }
);

// анализ эмоциональности
export const getEmotionAnalysis = createAsyncThunk(
  'smc/getEmotionAnalysis',
  async ({ text }: IGetEmotionProps, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IResponse | IGetEmotionResponse> = await axiosApi.get(`${URL_SMC}/${ENDPOINTS_SMC.EMOTION}`, {
        params: {
          text,
        }
      });
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// сжатие фразы
export const compressPhrase = createAsyncThunk(
  'smc/compressPhrase',
  async ({ text, threshold }: ICompressPhraseProps, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IResponse | ICompressionPhraseResponse> = await axiosApi.get(`${URL_SMC}/${ENDPOINTS_SMC.COMPRESS}`, {
        params: {
          text,
          threshold,
        }
      });
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// пунктуация
export const punctuate = createAsyncThunk(
  'smc/punctuate',
  async ({ text }: IGetPunctuationProps, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IResponse | IPunctuateResponse> = await axiosApi.get(`${URL_SMC}/${ENDPOINTS_SMC.PUNCTUATE}`, {
        params: {
          text,
        }
      });
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// коррекция ошибок
export const correct = createAsyncThunk(
  'smc/correct',
  async ({ text }: ICorrectProps, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IResponse | ICorrectResponse> = await axiosApi.get(`${URL_SMC}/${ENDPOINTS_SMC.CORRECT}`, {
        params: {
          text,
        }
      });
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// обратная нормализация
export const normalize = createAsyncThunk(
  'smc/normalize',
  async ({ text }: ICorrectProps, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IResponse | INormalizationResponse> = await axiosApi.get(`${URL_SMC}/${ENDPOINTS_SMC.NORMALIZE}`, {
        params: {
          text,
        }
      });
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// анализ на токсичность
export const getToxicityAnalysis = createAsyncThunk(
  'smc/getToxicityAnalysis',
  async (text: string, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IResponse | IToxicityResponse> = await axiosApi.get(`${URL_SMC}/${ENDPOINTS_SMC.TOXICITY}`, {
        params: {
          text,
        }
      });
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

const smcSlice = createSlice({
  name: 'smc',
  initialState,
  reducers: {
    // очистка статуса обучения модели
    clearTrainModelSmc: (state) => {
      state.trainModel = initialState.trainModel;
    },
    // очистка статуса остановки обучения модели
    clearStopTrainModelSmc: (state) => {
      state.stopTrainModel = initialState.stopTrainModel;
    },
    // очистка статуса обновления групп в модели
    clearUpdateGroups: (state) => {
      state.updateGroups = initialState.updateGroups;
    },
    // очистка статуса тестирования модели
    clearTestModelSmc: (state) => {
      state.testModel = initialState.testModel;
    },
    // очистка результата ручной проверки модели
    clearManualCheckModelSmc: (state) => {
      state.manualCheckModel = initialState.manualCheckModel;
    },
    // очистка результата определения эмоции
    clearEmotionAnalysis: (state) => {
      state.emotionAnalysis = initialState.emotionAnalysis;
    },
    // очистка результата сжатия фразы
    clearCompressionPhrase: (state) => {
      state.compressionPhrase = initialState.compressionPhrase;
    },
    // очистка результата пунктуации
    clearPunctuate: (state) => {
      state.punctuate = initialState.punctuate;
    },
    // очистка результата коррекции ошибок
    clearCorrect: (state) => {
      state.correct = initialState.correct;
    },
    // очистка результата обратной нормализации
    clearNormalization: (state) => {
      state.normalization = initialState.normalization;
    },
    // очистка результата анализа на токсичность
    clearToxicity: (state) => {
      state.toxicity = initialState.toxicity;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(trainModelSmc.pending, (state) => {
        state.trainModel.status = RequestStatus.LOADING;
      })
      .addCase(trainModelSmc.fulfilled, (state, action) => {
        state.trainModel.status = RequestStatus.IDLE;
        if (action.payload) {
          state.trainModel.error = action.payload.error;
          state.trainModel.message = action.payload.message;
        }
      })
      .addCase(trainModelSmc.rejected, (state, action: PayloadAction<unknown>) => {
        state.trainModel.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.trainModel.error = action.payload.response?.data.error;
          state.trainModel.message = action.payload.response?.data.message;
        }
      })
      .addCase(stopTrainModelSmc.pending, (state) => {
        state.stopTrainModel.status = RequestStatus.LOADING;
      })
      .addCase(stopTrainModelSmc.fulfilled, (state, action) => {
        state.stopTrainModel.status = RequestStatus.IDLE;
        state.stopTrainModel.error = action.payload.error;
        state.stopTrainModel.message = action.payload.message;
      })
      .addCase(stopTrainModelSmc.rejected, (state) => {
        state.stopTrainModel.status = RequestStatus.FAILED;
      })
      .addCase(updateGroups.pending, (state) => {
        state.updateGroups.status = RequestStatus.LOADING;
      })
      .addCase(updateGroups.fulfilled, (state, action) => {
        state.updateGroups.status = RequestStatus.IDLE;
        if (action.payload) {
          state.updateGroups.error = action.payload.error;
          state.updateGroups.message = action.payload.message;
        }
      })
      .addCase(updateGroups.rejected, (state, action: PayloadAction<unknown>) => {
        state.updateGroups.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.updateGroups.error = action.payload.response?.data.error;
          state.updateGroups.message = action.payload.response?.data.message;
        }
      })
      .addCase(testModelSmc.pending, (state) => {
        state.testModel.status = RequestStatus.LOADING;
      })
      .addCase(testModelSmc.fulfilled, (state, action) => {
        state.testModel.status = RequestStatus.IDLE;
        if (action.payload) {
          state.testModel.error = action.payload.error;
          state.testModel.message = action.payload.message;
        }
      })
      .addCase(testModelSmc.rejected, (state, action: PayloadAction<unknown>) => {
        state.testModel.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.testModel.error = action.payload.response?.data.error;
          state.testModel.message = action.payload.response?.data.message;
        }
      })
      .addCase(manualCheckModelSmc.pending, (state) => {
        state.manualCheckModel.status = RequestStatus.LOADING;
      })
      .addCase(manualCheckModelSmc.fulfilled, (state, action) => {
        if (typeof action.payload === 'string') state.manualCheckModel.status = RequestStatus.FAILED;
        else {
          state.manualCheckModel.status = RequestStatus.IDLE;
          state.manualCheckModel.data = action.payload;
        }
      })
      .addCase(manualCheckModelSmc.rejected, (state) => {
        state.manualCheckModel.status = RequestStatus.FAILED;
      })
      .addCase(getEmotionAnalysis.pending, (state) => {
        state.emotionAnalysis.status = RequestStatus.LOADING;
      })
      .addCase(getEmotionAnalysis.fulfilled, (state, action) => {
        state.emotionAnalysis.status = RequestStatus.IDLE;
        if (action.payload) {
          state.emotionAnalysis.data = action.payload;
        }
      })
      .addCase(getEmotionAnalysis.rejected, (state, action: PayloadAction<unknown>) => {
        state.emotionAnalysis.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.emotionAnalysis.data = action.payload.response?.data;
        }
      })
      .addCase(compressPhrase.pending, (state) => {
        state.compressionPhrase.status = RequestStatus.LOADING;
      })
      .addCase(compressPhrase.fulfilled, (state, action) => {
        state.compressionPhrase.status = RequestStatus.IDLE;
        if (action.payload) {
          state.compressionPhrase.data = action.payload;
        }
      })
      .addCase(compressPhrase.rejected, (state, action: PayloadAction<unknown>) => {
        state.compressionPhrase.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.compressionPhrase.data = action.payload.response?.data;
        }
      })
      .addCase(punctuate.pending, (state) => {
        state.punctuate.status = RequestStatus.LOADING;
      })
      .addCase(punctuate.fulfilled, (state, action) => {
        state.punctuate.status = RequestStatus.IDLE;
        if (action.payload) {
          state.punctuate.data = action.payload;
        }
      })
      .addCase(punctuate.rejected, (state, action: PayloadAction<unknown>) => {
        state.punctuate.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.punctuate.data = action.payload.response?.data;
        }
      })
      .addCase(correct.pending, (state) => {
        state.correct.status = RequestStatus.LOADING;
      })
      .addCase(correct.fulfilled, (state, action) => {
        state.correct.status = RequestStatus.IDLE;
        if (action.payload) {
          state.correct.data = action.payload;
        }
      })
      .addCase(correct.rejected, (state, action: PayloadAction<unknown>) => {
        state.correct.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.correct.data = action.payload.response?.data;
        }
      })
      .addCase(normalize.pending, (state) => {
        state.normalization.status = RequestStatus.LOADING;
      })
      .addCase(normalize.fulfilled, (state, action) => {
        state.normalization.status = RequestStatus.IDLE;
        if (action.payload) {
          state.normalization.data = action.payload;
        }
      })
      .addCase(normalize.rejected, (state, action: PayloadAction<unknown>) => {
        state.normalization.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.normalization.data = action.payload.response?.data;
        }
      })
      .addCase(getToxicityAnalysis.pending, (state) => {
        state.toxicity.status = RequestStatus.LOADING;
      })
      .addCase(getToxicityAnalysis.fulfilled, (state, action) => {
        if (action.payload && typeof action.payload === 'object' && 'insult' in action.payload) {
          state.toxicity.status = RequestStatus.IDLE;
          state.toxicity.data = action.payload;
        } else state.toxicity.status = RequestStatus.FAILED;
      })
      .addCase(getToxicityAnalysis.rejected, (state, action: PayloadAction<unknown>) => {
        state.toxicity.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.toxicity.error = action.payload.response?.data.error;
          state.toxicity.message = action.payload.response?.data.message;
        }
      });
  },
});

export const { clearTrainModelSmc, clearStopTrainModelSmc, clearUpdateGroups, clearTestModelSmc, clearManualCheckModelSmc, clearEmotionAnalysis, clearCompressionPhrase, clearPunctuate, clearCorrect, clearNormalization, clearToxicity } = smcSlice.actions;

export const selectTrainModelSmc = (state: RootState) => state.smc.trainModel;
export const selectStopTrainModelSmc = (state: RootState) => state.smc.stopTrainModel;
export const selectUpdateGroups = (state: RootState) => state.smc.updateGroups;
export const selectTestModelSmc = (state: RootState) => state.smc.testModel;
export const selectManualCheckModelSmc = (state: RootState) => state.smc.manualCheckModel;
export const selectEmotionAnalysis = (state: RootState) => state.smc.emotionAnalysis;
export const selectCompressionPhrase = (state: RootState) => state.smc.compressionPhrase;
export const selectPunctuate = (state: RootState) => state.smc.punctuate;
export const selectErrorCorrection = (state: RootState) => state.smc.correct;
export const selectNormalization = (state: RootState) => state.smc.normalization;
export const selectToxicity = (state: RootState) => state.smc.toxicity;

export default smcSlice.reducer;
