import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import cn from 'classnames';
import useTranslate from '../../../../hooks/useTranslate';
import { backgroundColor } from '../../../../constants/colors';
import { IToggleAutosaveProps } from './ToggleAutosave.props';
import styles from './ToggleAutosave.module.scss';

const ToggleAutosave = ({ styleNotActive, isAvailable, cookieName, changeFlg, saveFunction }: IToggleAutosaveProps): JSX.Element => {
	const [cookies, setCookie] = useCookies([cookieName]); // hook для работы с cookie
	const translate = useTranslate(); // hook для перевода текста

	// следим за флагом сохранения и автосохранения
	useEffect(() => {
		let interval: NodeJS.Timer;
		// если стоит флаг автосохранения и есть что сохранять
		if (cookies[cookieName] === 'true' && changeFlg) {
			// автосохранение каждые 10 сек
			interval = setInterval(() => {
				saveFunction(); // функция сохранения
			}, 10000);
		}
		return () => {
			clearInterval(interval); // при перерисовке - удаляем автозапрос
		};
	}, [cookies[cookieName], changeFlg]);

	// функция изменения состояния автосохранения в cookie
	const toggleAutosave = (): void => {
		if (cookies[cookieName] === 'true') {
			setCookie(cookieName, 'false', { path: '/', maxAge: 2_592_000 }); // устанавливаем cookie на месяц
		} else {
			setCookie(cookieName, 'true', { path: '/', maxAge: 2_592_000 }); // устанавливаем cookie на месяц
		}
	};

	return (
		<div
			className={cn({
				[styleNotActive]: !isAvailable,
				[styles.active]: cookies[cookieName] === 'true',
			})}
			onClick={() => isAvailable && toggleAutosave()}
			title={translate(cookies[cookieName] === 'true' ? 'buttonTitle_autosaveOff' : 'buttonTitle_autosaveOn')}
		>
			<FontAwesomeIcon
				icon={faClock}
				size="2xl"
				color={backgroundColor}
			/>
		</div>
	);
};

export default ToggleAutosave;
