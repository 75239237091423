import { useEffect, useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import cn from 'classnames';
import { useAppDispatch } from '../../../store/hooks';
import { addServiceData } from '../../../store/sesRobotSlice';
import useTranslate from '../../../hooks/useTranslate';
import { ROBOT_SERVICE_TYPE_EXTENDED_LIST, TypeExtended, } from '../../../constants/robotConfigLists';
import { colorPrimary } from '../../../constants/colors';
import { ServiceTypeRobot } from '../../../types/cloudTypes';
import { IFormAddingServiceDataProps } from './FormAddingServiceData.props';
import styles from './FormAddingServiceData.module.scss';

const FormAddingServiceData = ({ serviceDataName, changeFlg, setChangeFlg }: IFormAddingServiceDataProps): JSX.Element => {
	const [selectType, setSelectType] = useState<TypeExtended>('chooseType'); // тип

	const dispatch = useAppDispatch();

	const translate = useTranslate(); // hook для перевода текста

	// следим за именем сервисных данных и сбрасываем поля
	useEffect(() => {
		resetInputAndSelect();
	}, [serviceDataName]);

	// функция сброса всех полей
	const resetInputAndSelect = (): void => {
		setSelectType('chooseType');
	};

	// обработчик добавления сервисных данных
	const addServiceDataHandler = (e: SelectChangeEvent<TypeExtended>): void => {
		setSelectType(e.target.value as ServiceTypeRobot);
		dispatch(addServiceData({ serviceDataName, type: e.target.value as ServiceTypeRobot }));
		(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('servicedata')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'servicedata'] }));  // ставим флаг о несохраненных данных
		resetInputAndSelect(); // очищаем поля
	};

	return (
		<div className={cn(styles.container, {
			[styles.containerOpacity]: selectType === 'chooseType'
		})}>

			{/* тип */}
			<FormControl fullWidth margin='dense' sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}>
				<InputLabel sx={{ fontSize: 13 }}>{translate('select_type')}</InputLabel>
				<Select
					required
					label={translate('select_type')}
					value={selectType}
					onChange={addServiceDataHandler}
					style={{ fontSize: 13, height: 33, color: colorPrimary }}
				>
					{ROBOT_SERVICE_TYPE_EXTENDED_LIST.map(({ type, translation }) =>
						<MenuItem key={type} value={type} sx={{ fontSize: 13, color: colorPrimary }} disabled={type === 'chooseType'}>
							{translate(translation)}
						</MenuItem>
					)}
				</Select>
			</FormControl>

		</div>
	);
};

export default FormAddingServiceData;
