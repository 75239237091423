import { FormEvent, useEffect, useRef, useState } from 'react';
import { useReactMediaRecorder } from 'react-media-recorder-2';
import ReactJson from 'react-json-view';
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';
import { useCookies } from 'react-cookie';
import { Autocomplete, Fade, FormControl, InputAdornment, TextField } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faCode, faMicrophone, faPaperPlane, faRotate, faXmark } from '@fortawesome/free-solid-svg-icons';
import cn from 'classnames';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { additionLastReplicaWithText, addReplicaInDialog, clearDebuggerAnswer, clearDebuggerSession, getDebuggerAnswer, getDebuggerSession, selectDebuggerAnswer, selectDebuggerSession } from '../../store/sesSlice';
import { selectActiveRobotId, selectActiveRobotVersion, selectRobot } from '../../store/sesRobotSlice';
import { selectChannelList } from '../../store/qasSlice';
import useAccessRight from '../../hooks/useAccessRight';
import useTranslate from '../../hooks/useTranslate';
import { SES } from '../../constants/accessRights';
import { backgroundColor, colorPrimary, colorRed } from '../../constants/colors';
import { ROBOT_AUDIO_ANSWERS, ROBOT_AUTO_PLAY } from '../../constants/cookieNames';
import AudioPlayer from '../AudioPlayer/AudioPlayer';
import VariablesBlock from './VariablesBlock/VariablesBlock';
import { RequestStatus } from '../../types/statusTypes';
import { IChatWidgetProps } from './ChatWidget.props';
import styles from './ChatWidget.module.scss';

const ChatWidget = ({ showChatWidget, setShowChatWidget }: IChatWidgetProps): JSX.Element => {
	const [inputChannel, setInputChannel] = useState<string>('default'); // канал
	const [inputMessage, setInputMessage] = useState<string>(''); // сообщение
	const inputMessageRef = useRef<HTMLInputElement>(null); // ссылка на поле для ввода сообщения
	const messageListRef = useRef<HTMLDivElement>(null); // ссылка на блок сообщений
	const [showJsonBlock, setShowJsonBlock] = useState<boolean>(false); // показ JSON блока
	const [deltaPosition, setDeltaPosition] = useState<{ x: number, y: number }>({ x: 0, y: 0 }); // позиция чата
	const [recordingFlg, setRecordingFlg] = useState<boolean>(false); // флаг записи с микрофона
	const [audioAnswers, setAudioAnswers] = useState<boolean>(false); // аудио ответы
	const [autoPlay, setAutoPlay] = useState<boolean>(false); // автовоспроизведение
	const [indexPlayingAudio, setIndexPlayingAudio] = useState<number>(0); // индекс аудио, которое воспроизводится
	const [showAdditionalSetting, setShowAdditionalSetting] = useState<boolean>(false); // показ доп. настроек
	const [variablesData, setVariablesData] = useState<[string, string, number][]>([['', '', 0]]); // данные для блока переменных

	const dispatch = useAppDispatch();
	const robotInfo = useAppSelector(selectRobot); // store - информация о роботе
	const activeRobotId = useAppSelector(selectActiveRobotId); // store - id активного робота
	const activeRobotVersion = useAppSelector(selectActiveRobotVersion); // store - версия активного робота
	const debuggerAnswer = useAppSelector(selectDebuggerAnswer); // store - ответ робота
	const debuggerSession = useAppSelector(selectDebuggerSession); // store - сессия робота
	const channelList = useAppSelector(selectChannelList); // store - список каналов

	const { status, startRecording, stopRecording } = useReactMediaRecorder({
		audio: true,
		onStop: (_: string, blob: Blob) => {
			blob.size > 0 && submitHandler(undefined, blob); // запускаем обработчик отправки аудиозаписи, если есть запись с микрофона
		}
	}); // hook для записи аудио
	const [cookies, setCookie] = useCookies([ROBOT_AUDIO_ANSWERS, ROBOT_AUTO_PLAY]); // hook для работы с cookie
	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	useEffect(() => {
		// расставляем флаги из cookies
		(!cookies.robotAudioAnswers || cookies.robotAudioAnswers === 'false') ? setAudioAnswers(false) : setAudioAnswers(true);
		(!cookies.robotAutoPlay || cookies.robotAutoPlay === 'false') ? setAutoPlay(false) : setAutoPlay(true);
	}, []);

	// следим за id и версией робота 
	useEffect(() => {
		clearDebuggerData(); // очищаем данные ответа и сессии
		setShowChatWidget(false); // закрываем чат
		setShowJsonBlock(false); // закрываем JSON блок
		setDeltaPosition({ x: 0, y: 0 }); // обнуляем позицию
	}, [activeRobotId, activeRobotVersion]);

	// следим за открытием чата
	useEffect(() => {
		showChatWidget && inputMessageRef.current?.focus(); // ставим фокус в поле сообщения
	}, [showChatWidget]);

	// следим за диалогом
	useEffect(() => {
		messageListRef.current?.scrollTo({
			top: messageListRef.current.scrollHeight,
			behavior: "smooth",
		}); // скролл в конец диалога
		inputMessageRef.current?.focus(); // ставим фокус в поле сообщения
	}, [debuggerAnswer.dialog]);

	// следим за статусом ответа
	useEffect(() => {
		// при ошибке - добавляем сообщение об ошибке
		if (debuggerAnswer.status === RequestStatus.FAILED) {
			dispatch(addReplicaInDialog({
				who: 'responseText',
				message: `<${translate('title_error')}: ${translate(debuggerAnswer.message || 'title_errorOccurred')}>`,
			})); // добавление реплики в диалог
		}
	}, [debuggerAnswer.status]);

	// следим за ответом
	useEffect(() => {
		if (debuggerAnswer.question && debuggerAnswer.dialog.length > 0 && debuggerAnswer.dialog[debuggerAnswer.dialog.length - 1].message === '') {
			dispatch(additionLastReplicaWithText(debuggerAnswer.question)); // дописываем в последнюю реплику распознанный текст
		}
		// если пустой ответ - добавляем сообщение об этом
		if (debuggerAnswer.answer?.length === 0) {
			dispatch(addReplicaInDialog({ who: 'responseText', message: translate('title_noAnswer') })); // добавление реплики в диалог
		} else {
			debuggerAnswer.answer?.forEach((answerItem) => {
				answerItem.messages.forEach((message, messageIdx) => {
					// если есть аудиоответ
					if (Array.isArray(answerItem.audio) && answerItem.audio[messageIdx]) {
						setIndexPlayingAudio(debuggerAnswer.dialog.length); // устанавливаем индекс следующего аудио, которое будет воспроизводиться
						const url = answerItem.audio[messageIdx]; // base64
						fetch('data:audio/wav;base64,' + url)
							.then(res => res.blob())
							.then(blob => {
								dispatch(addReplicaInDialog({
									who: 'responseAudio',
									message: message || translate('title_noAnswer'),
									audioUrl: window.URL.createObjectURL(new Blob([blob], { type: "audio/wav" })),
								})); // добавление реплики в диалог
							});
					} else {
						dispatch(addReplicaInDialog({
							who: 'responseText',
							message: message || translate('title_noAnswer'),
						})); // добавление реплики в диалог
					}
				});
			});
		}
		// если есть доступ, id робота, есть ответ, id сессии
		isAccess(SES.SESSION) && activeRobotId && debuggerAnswer.answer && debuggerAnswer.session && dispatch(getDebuggerSession({ robotId: activeRobotId, sessionId: debuggerAnswer.session })); // обновляем данные сессии
	}, [debuggerAnswer.answer]);

	// следим за флагом и статусом записи микрофона
	useEffect(() => {
		// и если кнопка мыши не зажата в плашке в момент подготовки или начала записи - останавливаем запись
		(status === 'acquiring_media' || status === 'recording') && recordingFlg === false && stopRecording();
	}, [status, recordingFlg]);

	// следим за флагом аудио-ответов
	useEffect(() => {
		// если аудио-ответы выключены, а флаг автовоспроизведения включен - выключаем последний
		!audioAnswers && autoPlay && setAutoPlay(false);
		// если аудио-ответы включены, а флаг автовоспроизведения выключен - включаем последний
		audioAnswers && !autoPlay && setAutoPlay(true);
		setCookie(ROBOT_AUDIO_ANSWERS, audioAnswers, { path: '/', maxAge: 2_592_000 }); // устанавливаем cookie на месяц
	}, [audioAnswers]);

	// следим за флагом автовоспроизведения
	useEffect(() => {
		setCookie(ROBOT_AUTO_PLAY, autoPlay, { path: '/', maxAge: 2_592_000 }); // устанавливаем cookie на месяц
	}, [autoPlay]);

	// перетаскивание чата
	const handleDrag = (_e: DraggableEvent, ui: DraggableData) => {
		setDeltaPosition(prev => ({
			x: prev.x + ui.deltaX,
			y: prev.y + ui.deltaY,
		}));
	};

	// отправка запроса
	const submitHandler = (e?: FormEvent<HTMLFormElement>, audio?: Blob): void => {
		e?.preventDefault();
		if (activeRobotId) {
			const formData = new FormData();
			// если есть запись - создаем пару
			if (audio) formData.append('file', audio);

			dispatch(addReplicaInDialog({
				who: audio ? 'clientAudio' : 'clientText',
				message: inputMessage,
				audioUrl: audio ? window.URL.createObjectURL(audio) : undefined,
			})); // добавление реплики клиента в диалог

			// переводим переменные в объект
			const variablesObj: Record<string, string> = {};
			variablesData.forEach(([variable, value]) => {
				if (variable !== '' && value !== '') variablesObj[variable] = value;
			});

			isAccess(SES.ASK) && dispatch(getDebuggerAnswer({
				robotId: activeRobotId,
				sessionId: debuggerAnswer.session ? debuggerAnswer.session : undefined,
				channelId: inputChannel === 'default' ? undefined : inputChannel,
				text: audio ? undefined : inputMessage,
				formData: audio ? formData : undefined,
				audioAnswers: audioAnswers ? 1 : undefined,
				variables: (Object.keys(variablesObj).length > 0 && !debuggerAnswer.session) ? variablesObj : undefined,
			})); // отправка запроса

			inputMessage && setInputMessage(''); // очистка поля сообщения
		}
	};

	// очистка данных ответа, сессии, индекса воспроизводимого аудио, переменных
	const clearDebuggerData = (): void => {
		dispatch(clearDebuggerAnswer());
		dispatch(clearDebuggerSession());
		setIndexPlayingAudio(0);
		setVariablesData([['', '', 0]]);
	};

	// максимальная ширина JSON блока
	const getMaxWidthJsonBlock = (): number => {
		const windowWidth = window.innerWidth; // ширина окна
		const chatWidth = 400; // ширина чата
		const marginChat = 40; // отступ чата от края
		const marginJsonBock = 10; // отступ JSON блока от края
		const gap = 10; // интервал между чатом и JSON блоком
		const deltaPositionChat = deltaPosition.x < 0 ? Math.abs(deltaPosition.x) : -deltaPosition.x; // позиция сдвига чата
		if ((Math.abs(deltaPosition.x) + marginChat) > (window.innerWidth / 2 - chatWidth / 2)) {
			return windowWidth - (windowWidth - deltaPositionChat) + marginChat - gap - marginJsonBock; // JSON блок справа
		} else {
			return windowWidth - chatWidth - marginChat - gap - marginJsonBock - deltaPositionChat; // JSON блок слева
		}
	};

	return (
		<Draggable
			defaultClassName={styles.container}
			handle='h2' // за что таскаем
			bounds='html' // границы перетаскивания
			position={{ x: deltaPosition.x, y: deltaPosition.y }}
			onDrag={handleDrag}
		>
			<Fade in={showChatWidget} timeout={300}>
				<div>
					<div className={styles.headerWrapper}>
						{/* кнопка закрытия чата */}
						<button
							className={styles.buttonCloseChat}
							onClick={() => setShowChatWidget(false)}
							title={translate('buttonTitle_close')}
						>
							<FontAwesomeIcon icon={faXmark} size='2x' color='#fff' />
						</button>
						<h2 className={styles.header}>{robotInfo.data?.name || translate('title_robot')}</h2>
					</div>

					<div className={styles.controls}>
						{/* кнопка открытия данных сессии */}
						{isAccess(SES.SESSION) &&
							<button
								className={cn(styles.button)}
								type='button'
								title={translate('buttonTitle_sessionData')}
								onClick={() => setShowJsonBlock(prev => !prev)}
							>
								<FontAwesomeIcon icon={faCode} color={backgroundColor} size="lg" />
							</button>
						}

						{/* канал */}
						<FormControl fullWidth>
							<Autocomplete
								options={channelList.data.map(channel => channel.name)}
								value={inputChannel}
								onChange={(_, value) => setInputChannel(value ? value : '')}
								disabled={debuggerAnswer.status === RequestStatus.LOADING}
								renderInput={(params) =>
									<TextField
										{...params}
										label={translate('input_channel')}
										onChange={(e) => setInputChannel(e.target.value)}
										InputLabelProps={{
											style: {
												fontSize: 13,
											},
										}}
										sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
									/>
								}
								sx={{
									".MuiInputBase-root": { height: 33, fontSize: 13, color: colorPrimary },
									".MuiInputBase-input": { marginTop: -1 },
								}}
								getOptionLabel={option => option}
								renderOption={(props, option) => {
									return (
										<span {...props} style={{ fontSize: 13, color: colorPrimary }}>
											{option}
										</span>
									);
								}}
							/>
						</FormControl>

						{/* кнопка перезагрузки сессии */}
						<button
							className={cn(styles.button, {
								[styles.buttonNotActive]: debuggerAnswer.status === RequestStatus.LOADING || debuggerSession.status === RequestStatus.LOADING,
							})}
							type='button'
							title={translate('buttonTitle_restartSession')}
							disabled={debuggerAnswer.status === RequestStatus.LOADING || debuggerSession.status === RequestStatus.LOADING}
							onClick={clearDebuggerData}
						>
							<FontAwesomeIcon icon={faRotate} color={backgroundColor} size="lg" />
						</button>
					</div>

					<div className={styles.messageList} ref={messageListRef}>
						{debuggerAnswer.dialog.map((replica, idx) => (
							<div key={replica.message + replica.who + idx} className={cn(styles.messageItem, {
								[styles.messageItemClient]: replica.who === 'clientText' || replica.who === 'clientAudio',
							})}>
								<div className={cn(styles.messageText, {
									[styles.messageTextClient]: replica.who === 'clientText' || replica.who === 'clientAudio',
									[styles.messageTextAudio]: replica.who === 'clientAudio' || replica.who === 'responseAudio',
								})}>
									{(replica.who === 'clientAudio' || replica.who === 'responseAudio') ?
										<>
											<AudioPlayer
												url={replica.audioUrl || null}
												index={idx} // индекс для идентификации
												downloadOption={false} // отключении опции скачивания
												setIndexPlayingAudio={replica.who === 'responseAudio' ? setIndexPlayingAudio : undefined} // установка воспроизведения по порядку
												play={autoPlay && replica.who === 'responseAudio' && indexPlayingAudio === idx} // команда старта воспроизведения
												smallSize
											/>
											<Fade in={replica.message.length > 0} timeout={300} mountOnEnter unmountOnExit>
												<p className={styles.messageTextAudioTranscript}>{replica.message}</p>
											</Fade>
										</>
										:
										replica.message
									}
								</div>
							</div>
						))}

						{debuggerAnswer.status === RequestStatus.LOADING &&
							<div className={cn(styles.messageItem)}>
								<div className={cn(styles.messageText)}>
									<span className={styles.messageLoaderDots}></span>
									<span className={styles.messageLoaderDots}></span>
									<span className={styles.messageLoaderDots}></span>
								</div>
							</div>
						}
					</div>

					<div className={cn(styles.setting, {
						[styles.settingFullSize]: showAdditionalSetting,
					})}>
						{/* кнопка показа доп.настроек */}
						<button className={styles.settingShowButton} onClick={() => setShowAdditionalSetting(prev => !prev)}>
							<FontAwesomeIcon icon={showAdditionalSetting ? faChevronDown : faChevronUp} />
						</button>

						{/* доп. настройки */}
						<div className={styles.settingBlock}>
							<div className={styles.settingBlockInner}>
								{/* аудио ответы */}
								<label className={styles.settingCheckbox}>
									<input
										type="checkbox"
										checked={audioAnswers}
										onChange={e => setAudioAnswers(e.target.checked)}
										disabled={debuggerAnswer.status === RequestStatus.LOADING}
									/>
									{translate('checkbox_audioAnswers')}
								</label>
								{/* автооспроизведение */}
								<label className={styles.settingCheckbox}>
									<input
										type="checkbox"
										checked={autoPlay}
										onChange={e => setAutoPlay(e.target.checked)}
										disabled={!audioAnswers}
									/>
									{translate('checkbox_autoplay')}
								</label>
							</div>

							<div className={cn(styles.settingBlockInner, styles.settingBlockInnerHeader)}>{translate('title_setVariables')}</div>

							{variablesData.map(([variable, value, key], idx) => (
								<div className={styles.settingBlockInner} key={key}>
									<VariablesBlock
										variable={variable}
										value={value}
										index={idx}
										variablesData={variablesData}
										setVariablesData={setVariablesData}
									/>
								</div>
							))}

						</div>
					</div>

					<form className={styles.sender} onSubmit={submitHandler}>
						{/* сообщение */}
						<FormControl fullWidth>
							<TextField
								inputRef={inputMessageRef}
								variant="outlined"
								placeholder={translate(debuggerAnswer.endOfSession ? 'title_restartSession' : 'title_writeQuestion') + '...'}
								value={inputMessage}
								onChange={(e) => setInputMessage(e.target.value)}
								disabled={debuggerAnswer.status === RequestStatus.LOADING || debuggerAnswer.endOfSession}
								InputProps={{
									style: {
										height: 33,
										fontSize: 13,
										color: colorPrimary,
									},
									endAdornment: (
										<InputAdornment position="end" >
											{inputMessage !== '' &&
												<FontAwesomeIcon
													icon={faXmark}
													onClick={() => {
														setInputMessage('');
														inputMessageRef.current?.focus();
													}}
													style={{ cursor: 'pointer ' }}
												/>
											}
										</InputAdornment>
									),
								}}
								InputLabelProps={{
									style: {
										fontSize: 13,
									},
								}}
								sx={{
									'.MuiInputLabel-root[data-shrink="false"]': { top: -8 },
									'.MuiInputBase-input': { padding: '0 14px' },
								}}
							/>
						</FormControl>

						{/* кнопка отправки сообщения */}
						<button
							className={cn(styles.button, {
								[styles.buttonNotActive]: debuggerAnswer.status === RequestStatus.LOADING || inputMessage === '' || debuggerAnswer.endOfSession,
							})}
							type='submit'
							title={translate('buttonTitle_send')}
							disabled={debuggerAnswer.status === RequestStatus.LOADING || inputMessage === '' || debuggerAnswer.endOfSession}
						>
							<FontAwesomeIcon icon={faPaperPlane} color={backgroundColor} size="lg" />
						</button>

						{/* кнопка отправки аудио с микрофона */}
						<button
							className={cn(styles.button, {
								[styles.buttonNotActive]: debuggerAnswer.status === RequestStatus.LOADING || inputMessage !== '' || debuggerAnswer.endOfSession,
							})}
							type='button'
							title={translate('buttonTitle_pressAndHold')}
							disabled={debuggerAnswer.status === RequestStatus.LOADING || inputMessage !== '' || debuggerAnswer.endOfSession}
							onMouseDown={() => {
								startRecording();
								setRecordingFlg(true);
							}}
							onMouseUp={() => setRecordingFlg(false)}
							onMouseLeave={() => setRecordingFlg(false)}
						>
							<FontAwesomeIcon icon={faMicrophone} size="xl" color={status === 'recording' ? colorRed : backgroundColor} fade={status === 'recording'} />
						</button>
					</form>

					<Fade in={showJsonBlock} timeout={300}>
						<div className={cn(styles.jsonBlock, {
							// абсолютное значение по Х со сдвигом от края > (половина ширины экрана - половина ширины блока чата)
							[styles.jsonBlockPositionRight]: (Math.abs(deltaPosition.x) + 40) > (window.innerWidth / 2 - 400 / 2),
						})} style={{ maxWidth: getMaxWidthJsonBlock() }}>
							<div className={styles.jsonBlockInner}>
								{(debuggerSession.sessionData || debuggerSession.status === RequestStatus.FAILED) ?
									<ReactJson
										src={debuggerSession.sessionData || (debuggerSession.status === RequestStatus.FAILED && { error: translate(debuggerSession.message || 'title_errorOccurred') }) || { data: null }}
										name={'root'} // имя root объекта
										displayObjectSize={false} // кол-во элементов
										enableClipboard={false} // значок копирования
										displayDataTypes={false} // тип данных
										shouldCollapse={(field) => field.name !== 'root' && !field.namespace.includes('current')} // ограничение на сворачивание объектов
									/>
									:
									<div className={styles.jsonBlockEmpty}>{translate('title_noData')}</div>
								}
							</div>
						</div>
					</Fade>
				</div>
			</Fade>
		</Draggable >
	);
};

export default ChatWidget;
