import { FormEvent, useEffect, useState } from 'react';
import { Button, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getCorpusList, selectCorpusList } from '../../../store/corpusSlice';
import { testModelSmc } from '../../../store/smcSlice';
import useTranslate from '../../../hooks/useTranslate';
import { RequestStatus } from '../../../types/statusTypes';
import ModalFormWindow from '../../../HOC/ModalFormWindow/ModalFormWindow';
import ProgressCircle from '../../ProgressCircle/ProgressCircle';
import { IFormExportProps } from './FormTest.props';

const FormTest = ({ showModal, setShowModal, modelName, setShowNotification, serviceType }: IFormExportProps): JSX.Element => {
	const [corpusName, setCorpusName] = useState<string>(''); // имя корпуса для тестирования
	const dispatch = useAppDispatch();
	const corpusList = useAppSelector(selectCorpusList); // store - список корпусов
	const translate = useTranslate(); // hook для перевода текста

	useEffect(() => {
		dispatch(getCorpusList({ serviceType })); // получение списка корпусов
	}, []);

	// обработчик тестирования модели
	const submitHandlerModel = (e: FormEvent<HTMLFormElement>): void => {
		e.preventDefault();
		modelName && corpusName && dispatch(testModelSmc({ modelName, corpusName })); // тестирование модели
		setShowModal(false); // закрываем форму
		setShowNotification(true); // включаем уведомление
	};

	return (
		<ModalFormWindow showModal={showModal} setShowModal={setShowModal} headerTitle={`${translate('formHeader_modelTesting')} "${modelName}"`}>
			{corpusList.status === RequestStatus.LOADING ?
				<ProgressCircle title={translate('spinnerTitle_loading')} />
				:
				<form onSubmit={(e) => submitHandlerModel(e)}>
					<FormControl fullWidth>
						{(corpusList.status === RequestStatus.FAILED || corpusList.data.length < 1 || !Array.isArray(corpusList.data)) ?
							<div>{translate('title_emptyList')}</div>
							:
							<>
								<FormControl fullWidth margin='dense' sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}>
									<InputLabel id="corpusName-label" sx={{ fontSize: 13 }}>{translate('select_corpus')}</InputLabel>
									<Select
										required
										labelId="corpusName-label"
										id="corpusName"
										label={translate('select_corpus')}
										value={corpusName}
										onChange={(e) => setCorpusName(e.target.value)}
										style={{ fontSize: 13, height: 33 }}
									>
										{corpusList.data.map((corpus) => (
											<MenuItem key={corpus} value={corpus} sx={{ fontSize: 13 }}>{corpus}</MenuItem>
										))}
									</Select>
								</FormControl>
								<FormControl fullWidth margin='dense'>
									<Button variant="outlined" type="submit" sx={{ fontSize: 11 }}>{translate('button_test')}</Button>
								</FormControl>
							</>
						}
					</FormControl>
				</form>
			}
		</ModalFormWindow>
	);
};

export default FormTest;
