import { FormEvent, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, Checkbox, Fade, FormControl, FormControlLabel, InputAdornment, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { clearAnswerToQuestion, getAnswer, getQuestionsList, selectAnswerToQuestion, selectCategoriesList, selectChannelList, selectFilter, setupUpdateQuestionsList } from '../../store/qasSlice';
import useTranslate from '../../hooks/useTranslate';
import { QUESTION, QUESTIONNAIRE } from '../../constants/routes';
import { colorPrimary } from '../../constants/colors';
import { CachingType } from '../../types/qasTypes';
import { RequestStatus } from '../../types/statusTypes';
import AlertDialog from '../AlertDialog/AlertDialog';
import ProgressCircle from '../ProgressCircle/ProgressCircle';
import { IAnswerToQuestionProps } from './AnswerToQuestion.props';
import styles from './AnswerToQuestion.module.scss';

const AnswerToQuestion = ({ showAnswerToQuestion, changeFlg }: IAnswerToQuestionProps): JSX.Element => {
	const [inputQuestion, setInputQuestion] = useState<string>(''); // вопрос
	const [selectCategoryId, setSelectCategoryId] = useState<string>(''); // id категории
	const [selectChannelId, setSelectChannelId] = useState<string>(''); // id канала
	const [selectCaching, setSelectCaching] = useState<CachingType>('all'); // кеширование
	const [checkGPT, setCheckGPT] = useState<boolean>(true); // использование gpt
	const [checkCreative, setCheckCreative] = useState<boolean>(false); // использование творческого режима
	const [addAlias, setAddAlias] = useState<boolean>(true); // добавление вопроса в похожие в кэше, если похожая формулировка найдена
	const inputQuestionRef = useRef<HTMLInputElement>(null); // ссылка на поле для ввода вопроса
	const [promiseGetAnswer, setPromiseGetAnswer] = useState<Promise<any> | null>(null); // промис для отмены запроса
	const [showAlertDialog, setShowAlertDialog] = useState<boolean>(false); // показ диалогового окна при запросе с не сохраненными данными

	const dispatch = useAppDispatch();
	const filter = useAppSelector(selectFilter); // store - фильтр
	const categoriesList = useAppSelector(selectCategoriesList); // store - список категорий
	const channelList = useAppSelector(selectChannelList); // store - список каналов
	const answer = useAppSelector(selectAnswerToQuestion); // store - данные поиска ответа на вопрос

	const location = useLocation(); // hook для определения адреса
	const translate = useTranslate(); // hook для перевода текста

	useEffect(() => {
		return () => {
			if (answer.status === RequestStatus.LOADING) {
				// @ts-ignore
				promiseGetAnswer.abort(); // прерываем запрос, если был при уходе со страницы
			}
		};
	}, []);

	// следим за ответом
	useEffect(() => {
		// если изменился, стоит флаг генерации и страница вопросов
		if (answer.data && 'text' in answer.data && checkGPT && location.pathname === `${QUESTIONNAIRE}/${QUESTION}`) {
			dispatch(setupUpdateQuestionsList()); // ставим флаг обновления списка вопросов
			dispatch(getQuestionsList({ answer: filter.answer, question: filter.question })); // обновляем список вопросов
		}
	}, [answer.data]);

	// следим за флагом открытия вкладки и очищаем поля и данные
	useEffect(() => {
		if (showAnswerToQuestion === false && answer.status === RequestStatus.LOADING) {
			// @ts-ignore
			promiseGetAnswer.abort(); // прерываем запрос, если был при закрытии вкладки
		}
		inputQuestion !== '' && setInputQuestion('');
		selectCategoryId !== '' && setSelectCategoryId('');
		selectChannelId !== '' && setSelectChannelId('');
		selectCaching !== 'all' && setSelectCaching('all');
		!checkGPT && setCheckGPT(true);
		checkCreative && setCheckCreative(false);
		!addAlias && setAddAlias(true);
		(answer.data || answer.status !== RequestStatus.IDLE) && dispatch(clearAnswerToQuestion());
	}, [showAnswerToQuestion]);

	// следим за checkbox'ом gpt
	useEffect(() => {
		!checkGPT && setCheckCreative(false); // если убран gpt - убираем творческий режим
	}, [checkGPT]);

	// обрабочик отправки запроса
	const askHandler = (): void => {
		(answer.data || answer.status !== RequestStatus.IDLE) && dispatch(clearAnswerToQuestion()); // удаление данных поиска ответа, если были
		const promise = dispatch(getAnswer({
			question: inputQuestion,
			categoryId: selectCategoryId ? selectCategoryId : undefined,
			channel: selectChannelId ? selectChannelId : undefined,
			caching: selectCaching,
			gpt: checkGPT ? 'yes' : 'no',
			creative: checkCreative ? 'yes' : 'no',
			addAlias: addAlias ? 'yes' : 'no',
		})); // поиск ответа на вопрос
		setPromiseGetAnswer(promise); // устанавливаем промис для случая с отменой запроса
		showAlertDialog && setShowAlertDialog(false); // закрываем диалоговое окно, если было открыто
	};

	// обработчик отправки вопроса
	const submitHandler = (e: FormEvent<HTMLFormElement>): void => {
		e.preventDefault();
		// если есть не сохраненные данные (для страницы вопросов) и стоит флаг использования генерации - включаем уведомление
		if (changeFlg && checkGPT) setShowAlertDialog(true);
		else askHandler();
	};

	return (
		<>
			<h3 className={styles.header}>{translate('title_searchAnswer')}</h3>

			<form onSubmit={(e) => submitHandler(e)}>
				<FormControl fullWidth margin='dense'>
					<TextField
						inputRef={inputQuestionRef}
						required
						autoFocus={showAnswerToQuestion}
						id="question"
						label={translate('input_question')}
						variant="outlined"
						multiline
						minRows={2}
						maxRows={4}
						disabled={answer.status === RequestStatus.LOADING}
						value={inputQuestion}
						onChange={(e) => setInputQuestion(e.target.value)}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end" >
									{inputQuestion.length > 0 &&
										<FontAwesomeIcon
											icon={faXmark}
											onClick={() => {
												if (answer.status !== RequestStatus.LOADING) {
													setInputQuestion('');
													inputQuestionRef.current?.focus();
												}
											}}
											style={{ cursor: answer.status !== RequestStatus.LOADING ? 'pointer ' : 'not-allowed' }}
										/>
									}
								</InputAdornment>
							),
							style: {
								fontSize: 13,
								padding: 8,
								color: colorPrimary,
							},
						}}
						InputLabelProps={{
							style: {
								fontSize: 13,
							},
						}}
						sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
					/>
				</FormControl>

				{categoriesList.data.length > 0 &&
					<FormControl fullWidth margin='dense' sx={{
						'.MuiInputLabel-root[data-shrink="false"]': { top: -8 },
						'.MuiInputBase-input': { padding: '8px 14px' },
					}}>
						<InputLabel id="category-label" sx={{ fontSize: 13 }}>{translate('select_category')}</InputLabel>
						<Select
							labelId="category-label"
							id="category"
							label={translate('select_category')}
							disabled={answer.status === RequestStatus.LOADING}
							value={selectCategoryId}
							onChange={(e) => setSelectCategoryId(e.target.value)}
							style={{ fontSize: 13, height: 33, color: colorPrimary, textAlign: 'left' }}
						>
							<MenuItem value='' sx={{ fontSize: 13 }}>{translate('selectItem_notSelected')}</MenuItem>
							{categoriesList.data.map((category) => (
								<MenuItem key={category.id} value={category.id} sx={{ fontSize: 13, color: colorPrimary }}>{category.name}</MenuItem>
							))}
						</Select>
					</FormControl>
				}

				{channelList.data.length > 0 &&
					<FormControl fullWidth margin='dense' sx={{
						'.MuiInputLabel-root[data-shrink="false"]': { top: -8 },
						'.MuiInputBase-input': { padding: '8px 14px' },
					}}>
						<InputLabel id="channel-label" sx={{ fontSize: 13 }}>{translate('select_channel')}</InputLabel>
						<Select
							labelId="channel-label"
							id="channel"
							label={translate('select_channel')}
							disabled={answer.status === RequestStatus.LOADING}
							value={selectChannelId}
							onChange={(e) => setSelectChannelId(e.target.value)}
							style={{ fontSize: 13, height: 33, color: colorPrimary, textAlign: 'left' }}
						>
							{channelList.data.map((channel) => (
								<MenuItem key={channel.id} value={channel.id} sx={{ fontSize: 13, color: colorPrimary }}>{channel.name}</MenuItem>
							))}
						</Select>
					</FormControl>
				}

				<FormControl fullWidth margin='dense' sx={{
					'.MuiInputLabel-root[data-shrink="false"]': { top: -8 },
					'.MuiInputBase-input': { padding: '8px 14px' },
				}}>
					<InputLabel id="caching-label" sx={{ fontSize: 13 }}>{translate('select_useAnswersCache')}</InputLabel>
					<Select
						labelId="caching-label"
						id="caching"
						label={translate('select_useAnswersCache')}
						disabled={answer.status === RequestStatus.LOADING}
						value={selectCaching}
						onChange={(e) => setSelectCaching(e.target.value as CachingType)}
						style={{ fontSize: 13, height: 33, color: colorPrimary, textAlign: 'left' }}
					>
						<MenuItem value='no' sx={{ fontSize: 13, color: colorPrimary }}>{translate('selectItem_doNotUse')}</MenuItem>
						<MenuItem value='all' sx={{ fontSize: 13, color: colorPrimary }}>{translate('selectItem_anyAnswers')}</MenuItem>
						<MenuItem value='checked' sx={{ fontSize: 13, color: colorPrimary }}>{translate('selectItem_onlyCheckedAnswers')}</MenuItem>
					</Select>
				</FormControl>

				<FormControlLabel sx={{ width: '100%', overflow: 'hidden', marginTop: '-5px', '.MuiTypography-root': { fontSize: 13, marginTop: '3px' } }} control={
					<Checkbox
						checked={checkGPT}
						disabled={answer.status === RequestStatus.LOADING}
						onChange={e => setCheckGPT(e.target.checked)}
						size='small'
					/>
				} label={translate('checkbox_useGpt')} />

				<FormControlLabel sx={{ width: '100%', overflow: 'hidden', marginTop: '-15px', marginBottom: '-10px', '.MuiTypography-root': { fontSize: 13, marginTop: '3px' } }} control={
					<Checkbox
						checked={checkCreative}
						disabled={answer.status === RequestStatus.LOADING || !checkGPT}
						onChange={e => setCheckCreative(e.target.checked)}
						size='small'
					/>
				} label={translate('checkbox_useCreative')} />

				<FormControlLabel sx={{ width: '100%', overflow: 'hidden', marginTop: '-3px', marginBottom: '-10px', '.MuiTypography-root': { fontSize: 13, marginTop: '3px', textAlign: 'left' } }} control={
					<Checkbox
						checked={addAlias}
						disabled={answer.status === RequestStatus.LOADING}
						onChange={e => setAddAlias(e.target.checked)}
						size='small'
					/>
				} label={translate('checkbox_useCacheAlias')} />

				<FormControl fullWidth margin='dense'>
					<Button
						id='searchFragmentBtn'
						variant="outlined"
						sx={{ fontSize: 11 }}
						type='submit'
						disabled={answer.status === RequestStatus.LOADING}
					>
						{translate('button_getAnswer')}
						{answer.status === RequestStatus.LOADING && <ProgressCircle isBtnDisabled />}
					</Button>
				</FormControl>
			</form>

			<div className={styles.data}>
				{answer.status === RequestStatus.LOADING &&
					<div><ProgressCircle title={translate('spinnerTitle_search')} /></div>
				}

				{(answer.status === RequestStatus.FAILED || (answer.data && typeof answer.data === 'object' && 'message' in answer.data)) &&
					<p>{translate((answer.data && typeof answer.data === 'object' && 'message' in answer.data) ? answer.data.message : 'title_loadFailed')}</p>
				}

				{answer.status === RequestStatus.IDLE && answer.data && 'text' in answer.data &&
					<Fade in={true} timeout={500}>
						<div>
							<div className={styles.dataConfidence}>
								<span className={styles.dataConfidenceTitle}>{translate('title_confidence')}:</span> {answer.data.confidence.toFixed(2)}
							</div>
							<p className={styles.dataAnswer}>{answer.data.text}</p>
						</div>
					</Fade>
				}
			</div>

			{showAlertDialog &&
				<AlertDialog
					showAlertDialog={showAlertDialog}
					setShowAlertDialog={setShowAlertDialog}
					submitHandler={askHandler}
					title='dialog_warnUnsavedChanges'
					description='dialog_warnUnsavedChangesConfirm'
				/>
			}
		</>
	);
};

export default AnswerToQuestion;
