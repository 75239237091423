import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import useTranslate from '../../../../hooks/useTranslate';
import { clearEditSpeaker, selectEditSpeaker } from '../../../../store/sbsSlice';
import { backgroundColor } from '../../../../constants/colors';
import FormAddingSpeaker from '../../../Forms/FormAddingSpeaker/FormAddingSpeaker';
import Notification from '../../../Notification/Notification';
import { IAdditionSpeakerProps } from './AdditionSpeaker.props';

const AdditionSpeaker = ({ styleNotActive, isAvailable }: IAdditionSpeakerProps): JSX.Element => {
	const [showModal, setShowModal] = useState<boolean>(false); // показ формы дополнения спикера
	const [showNotification, setShowNotification] = useState<boolean>(false); // показ уведомления

	const translate = useTranslate(); // hook для перевода текста

	return (
		<>
			<div
				className={isAvailable ? '' : styleNotActive}
				onClick={() => isAvailable && setShowModal(true)}
				title={translate('buttonTitle_additionSpeaker')}>
				<FontAwesomeIcon icon={faPencil} size="2xl" color={backgroundColor} />
			</div>

			{showModal &&
				<FormAddingSpeaker
					showModal={showModal}
					setShowModal={setShowModal}
					setShowNotification={setShowNotification}
					isAddition
				/>
			}

			<section style={{ position: 'absolute' }}>
				{showNotification &&
					<Notification
						showNotification={showNotification}
						setShowNotification={setShowNotification}
						selectDataResponse={selectEditSpeaker}
						clearDataResponse={clearEditSpeaker}
						titleFailed='noticeAdditionSpeaker_failed'
						titleSuccess='noticeAdditionSpeaker_success'
					/>
				}
			</section>
		</>
	);
};

export default AdditionSpeaker;
