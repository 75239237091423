import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Slide } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { changeDataInFilesList, clearDeletingFileStatus, clearEditingFileStatus, clearFilesList, clearQuestionMakerStatus, deleteFile, editFile, getFilesList, selectDeletingFileStatus, selectDocument, selectEditingFileStatus, selectFilesList, selectFilter, selectGeneratingQuestionsStopStatus } from '../../../store/qasSlice';
import { selectDataServers } from '../../../store/serverSlice';
import useAccessRight from '../../../hooks/useAccessRight';
import useTranslate from '../../../hooks/useTranslate';
import { QAS, SERVER } from '../../../constants/accessRights';
import { QAS_FILE_ID } from '../../../constants/cookieNames';
import { IFileData } from '../../../types/qasTypes';
import { RequestStatus, ResponseStatus } from '../../../types/statusTypes';
import UserMenu from '../Buttons/UserMenu/UserMenu';
import Generating from '../Buttons/Generating/Generating';
import Question from '../Buttons/Question/Question';
import AnswerToQuestion from '../../AnswerToQuestion/AnswerToQuestion';
import Categories from '../../Categories/Categories';
import Channels from '../../Channels/Channels';
import ServerStatus from '../../ServerStatus/ServerStatus';
import AlertDialog from '../../AlertDialog/AlertDialog';
import Notification from '../../Notification/Notification';
import ScreenLock from '../../ScreenLock/ScreenLock';
import { IQuestionnarieControlsProps } from './QuestionnarieControls.props';
import styles from './QuestionnarieControls.module.scss';

const QuestionnarieControls = ({ changeFlg, setChangeFlg, setShowPage, showAlertDialogSave, setShowAlertDialogSave, showAlertDialogDelete, setShowAlertDialogDelete, searchDocument }: IQuestionnarieControlsProps): JSX.Element => {
	const [showNotificationSave, setShowNotificationSave] = useState<boolean>(false); // показ уведомления сохранения
	const [showNotificationDelete, setShowNotificationDelete] = useState<boolean>(false); // показ уведомления удаления

	const [showScreenLock, setShowScreenLock] = useState<{ isShow: boolean, title: string }>({ isShow: false, title: '' }); // показ экрана блокировки и подпись
	const [showCategories, setShowCategories] = useState<boolean>(false); // показ вкладки категорий
	const [showChannels, setShowChannels] = useState<boolean>(false); // показ вкладки каналов
	const [showServers, setShowServers] = useState<boolean>(false); // показ вкладки серверов
	const [showAnswerToQuestion, setShowAnswerToQuestion] = useState<boolean>(false); // показ вкладки ответа на вопрос

	const dispatch = useAppDispatch();
	const filter = useAppSelector(selectFilter); // store - фильтр
	const filesList = useAppSelector(selectFilesList); // store - список файлов
	const documentFile = useAppSelector(selectDocument); // store - документ (файл)
	const editStatus = useAppSelector(selectEditingFileStatus); // store - статус сохранения файла
	const deleteStatus = useAppSelector(selectDeletingFileStatus); // store - статус удаления файла
	const stopGenerationStatus = useAppSelector(selectGeneratingQuestionsStopStatus); // store - статус остановки генерации вопросов
	const dataServers = useAppSelector(selectDataServers); // store - информация о серверах

	const [_cookies, setCookie] = useCookies([QAS_FILE_ID]); // hook для работы с cookie
	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// следим за статусом удаления файла, сохранения файла, остановки генерации ответов
	useEffect(() => {
		if (deleteStatus.status === RequestStatus.LOADING) setShowScreenLock({ isShow: true, title: 'spinnerTitle_deletion' }); // если идет удаление файла
		else if (editStatus.status === RequestStatus.LOADING) setShowScreenLock({ isShow: true, title: 'spinnerTitle_saving' }); // если идет сохранение документа
		else if (stopGenerationStatus.status === RequestStatus.LOADING) setShowScreenLock({ isShow: true, title: 'spinnerTitle_stop' }); // если идет остановка генерации ответов
		else setShowScreenLock({ isShow: false, title: '' }); // иначе выключаем экран блокировки

		// если сохранение прошло успешно
		if (editStatus.status === RequestStatus.IDLE && editStatus.error === ResponseStatus.SUCCESS && editStatus.message !== '') {
			setChangeFlg(false); // выключаем флаг
			editStatus.changesNameOrCategory && dispatch(changeDataInFilesList()); // меняем данные в списке документов без перезагрузки, если были изменены имя файла или категория
		}
		// если удаление прошло успешно
		if (deleteStatus.status === RequestStatus.IDLE && deleteStatus.error === ResponseStatus.SUCCESS && deleteStatus.message !== '') {
			if (Array.isArray(filesList.data)) {
				const foundIdxDeleteDocument = filesList.data.filter(filterFunction).findIndex(document => document.id === documentFile.fileId); // idx удаленного документа
				// если найден удаленный документ и в списке больше одного документа
				if (foundIdxDeleteDocument >= 0 && filesList.data.filter(filterFunction).length > 1) {
					// id предыдущего документа, если удаляемый документ был последним, или следующего, если есть
					const fileId = filesList.data.filter(filterFunction).length - 1 === foundIdxDeleteDocument ?
						filesList.data.filter(filterFunction)[foundIdxDeleteDocument - 1].id
						:
						filesList.data.filter(filterFunction)[foundIdxDeleteDocument + 1].id;
					setCookie(QAS_FILE_ID, fileId, { path: '/', maxAge: 2_592_000 }); // устанавливаем cookie на месяц
				}
			}
			dispatch(clearFilesList()); // очищаем список документов
			dispatch(getFilesList()); // получаем заново список документов
		}
		// если остановка генерации вопросов прошла успешно - очищаем статус процесса генерации
		if (stopGenerationStatus.status === RequestStatus.IDLE && stopGenerationStatus.error === ResponseStatus.SUCCESS && stopGenerationStatus.message !== '') dispatch(clearQuestionMakerStatus());
	}, [deleteStatus, editStatus, stopGenerationStatus]);

	// обработчик сохранения изменений
	const saveHandler = (): void => {
		// если есть не сохраненные изменения
		if (changeFlg) {
			setShowAlertDialogSave(false); // закрываем диалоговое окно
			setShowNotificationSave(true); // включаем уведомление

			if (documentFile.fileId && documentFile.fileName && documentFile.category && typeof documentFile.data === 'string') {
				dispatch(editFile({
					fileId: documentFile.fileId, data: {
						title: documentFile.fileName,
						category: documentFile.category,
						text: documentFile.data,
						glossary: documentFile.glossary,
					}
				})); // сохранение
			}
		}
	};

	// обработчик удаления файла
	const deleteHandler = (): void => {
		setShowAlertDialogDelete(false); // закрываем диалоговое окно
		setShowNotificationDelete(true); // включаем уведомление
		documentFile.fileId && dispatch(deleteFile(documentFile.fileId)); // удаление файла
	};

	// функция получения исходного имени документа из списка
	const getNameForAlertDialog = (): string | undefined => {
		if (Array.isArray(filesList.data)) {
			const fileInfo = filesList.data.find(fileItem => fileItem.id === documentFile.fileId);
			return fileInfo?.title;
		}
	};

	// функция фильтрации списка документов
	const filterFunction = (document: IFileData): boolean => {
		return document.title.toLowerCase().includes(searchDocument.toLowerCase()) && (document.category === filter.category || filter.category === '');
	};

	return (
		<>
			<div className={styles.controls}>
				<Slide direction="down" in={true} mountOnEnter unmountOnExit timeout={500}>
					<div className={styles.controlsWrapper}>
						<div className={styles.controlsTitle}>{translate('qas')}</div>
						<section className={styles.controlsTypeAndFunc}>
							<div className={styles.controlsFunctions}></div>

							<div className={styles.controlsFunctions}>

								<div className={styles.controlsFunctionsCenterBlock}>
									{isAccess([QAS.QUESTION_MAKER_START, QAS.QUESTION_MAKER_STOP, QAS.QUESTION_MAKER_STATUS]) &&
										<Generating
											styleNotActive={styles.controlsFunctionBtnNotActive}
											isAvailable={Array.isArray(filesList.data) && filesList.data.length > 0 && documentFile.status !== RequestStatus.LOADING}
											generate='questions'
										/>
									}
									{isAccess(QAS.ASK) &&
										<Question
											styleNotActive={styles.controlsFunctionBtnNotActive}
											isAvailable={Array.isArray(filesList.data) && filesList.data.length > 0 && documentFile.status !== RequestStatus.LOADING}
											setShowAnswerToQuestion={setShowAnswerToQuestion}
										/>
									}
								</div>

								<div className={styles.controlsFunctionsRightBlock}>
									<UserMenu setShowPage={setShowPage} />
								</div>
							</div>
						</section>
					</div>
				</Slide>

				{/* бирки */}
				<Slide direction="left" in={true} mountOnEnter unmountOnExit timeout={1000}>
					<section className={styles.tags}>
						{isAccess(QAS.CATEGORY_LIST) &&
							<div className={styles.categoriesTag} onClick={() => setShowCategories(prev => !prev)}>
								<span className={styles.tagTitle}>{translate('tag_categories')}</span>
							</div>
						}
						{isAccess(QAS.CHANNEL_LIST) &&
							<div className={styles.channelsTag} onClick={() => setShowChannels(prev => !prev)}>
								<span className={styles.tagTitle}>{translate('tag_channels')}</span>
							</div>
						}
						{isAccess(SERVER.ADDRESSES) &&
							<div className={styles.serversTag} onClick={() => setShowServers(prev => !prev)}>
								<span className={styles.tagTitle}>
									<span className={dataServers && dataServers.fail && !Object.keys(dataServers?.fail).length ? styles.serversTagStatus : styles.serversTagStatusFailed}></span>
									{translate('tag_servers')}
								</span>
							</div>
						}
					</section>
				</Slide>

				<Categories showCategories={showCategories} setShowCategories={setShowCategories} />
				<Channels showChannels={showChannels} setShowChannels={setShowChannels} />
				<ServerStatus showServers={showServers} setShowServers={setShowServers} />

				<Slide direction="left" in={showAnswerToQuestion} mountOnEnter unmountOnExit>
					<div className={styles.modal} onMouseDown={() => setShowAnswerToQuestion(false)}>
						<div className={styles.wrapperAnswer} onMouseDown={(e) => e.stopPropagation()}>
							<AnswerToQuestion showAnswerToQuestion={showAnswerToQuestion} />
						</div>
					</div>
				</Slide>
			</div>

			{showAlertDialogSave &&
				<AlertDialog
					showAlertDialog={showAlertDialogSave}
					setShowAlertDialog={setShowAlertDialogSave}
					submitHandler={saveHandler}
					title='dialog_editFile'
					description='dialog_editFileConfirm'
					name={getNameForAlertDialog()}
				/>
			}
			{showAlertDialogDelete &&
				<AlertDialog
					showAlertDialog={showAlertDialogDelete}
					setShowAlertDialog={setShowAlertDialogDelete}
					submitHandler={deleteHandler}
					title='dialog_deleteFile'
					description='dialog_deleteFileConfirm'
					name={getNameForAlertDialog()}
				/>
			}

			{showScreenLock.isShow && <ScreenLock title={translate(showScreenLock.title)} />}

			{/* уведомление для добавления документа */}
			{showNotificationSave &&
				<Notification
					showNotification={showNotificationSave}
					setShowNotification={setShowNotificationSave}
					selectDataResponse={selectEditingFileStatus}
					clearDataResponse={clearEditingFileStatus}
					titleFailed='noticeSaving_failed'
					titleSuccess='noticeSaving_success'
				/>
			}
			{/* уведомление для удаления документа */}
			{showNotificationDelete &&
				<Notification
					showNotification={showNotificationDelete}
					setShowNotification={setShowNotificationDelete}
					selectDataResponse={selectDeletingFileStatus}
					clearDataResponse={clearDeletingFileStatus}
					titleFailed='noticeDeletion_failed'
					titleSuccess='noticeDeletion_success'
				/>
			}
		</>
	);
};

export default QuestionnarieControls;
