import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Autocomplete, Button, FormControl, TextField, createFilterOptions } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { addCategory, addCategoryInList, addFile, changePlaceAddingCategory, clearAddingCategory, clearFilesList, getFilesList, selectAddingCategory, selectAddingFileStatus, selectCategoriesList } from '../../../store/qasSlice';
import useAccessRight from '../../../hooks/useAccessRight';
import useTranslate from '../../../hooks/useTranslate';
import { QAS } from '../../../constants/accessRights';
import { QAS_FILE_ID } from '../../../constants/cookieNames';
import { RequestStatus, ResponseStatus } from '../../../types/statusTypes';
import ModalFormWindow from '../../../HOC/ModalFormWindow/ModalFormWindow';
import ProgressCircle from '../../ProgressCircle/ProgressCircle';
import ProgressLinear from '../../ProgressLinear/ProgressLinear';
import { IFormAddingDocumentFileProps } from './FormAddingDocumentFile.props';
import styles from './FormAddingDocumentFile.module.scss';

const FormAddingDocumentFile = ({ showModal, setShowModal, setShowNotification }: IFormAddingDocumentFileProps): JSX.Element => {
	const [fileNameInput, setFileNameInput] = useState<string>(''); // имя файла
	const [categoryInput, setCategoryInput] = useState<string>(''); // категория
	const [file, setFile] = useState<File>(); // для импорта из файла
	const [errorCategoryFlg, setErrorCategoryFlg] = useState<boolean>(false); // флаг ошибки добавления категории

	const dispatch = useAppDispatch();
	const categoriesList = useAppSelector(selectCategoriesList); // store - список категорий
	const addingFileStatus = useAppSelector(selectAddingFileStatus); // store - статус добавления файла
	const addingCategoryStatus = useAppSelector(selectAddingCategory); // store - статус добавления категории

	const [_cookies, setCookie] = useCookies([QAS_FILE_ID]); // hook для работы с cookie
	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// следим за статусом добавления файла
	useEffect(() => {
		// если файл добавился и без ошибок
		if (addingFileStatus.error === ResponseStatus.SUCCESS && addingFileStatus.status === RequestStatus.IDLE && addingFileStatus.message !== '') {
			setCookie(QAS_FILE_ID, addingFileStatus.id, { path: '/', maxAge: 2_592_000 }); // устанавливаем cookie на месяц
			dispatch(clearFilesList()); // очищаем список документов
			isAccess(QAS.DOC_LIST) && dispatch(getFilesList()); // получаем заново список документов
		}

		// если изменился статус добавления файла
		if (addingFileStatus.error !== ResponseStatus.SUCCESS || addingFileStatus.status === RequestStatus.FAILED || addingFileStatus.message !== '') {
			handleClose(); // закрываем форму
			setShowNotification(true); // включаем уведомление
		}
	}, [addingFileStatus]);

	// следим за статусом добавления категории
	useEffect(() => {
		// если добавлялось из формы
		if (addingCategoryStatus.from === 'formAddingDocument') {
			// если добавилось успешно
			if (addingCategoryStatus.id) {
				dispatch(clearAddingCategory()); // очищаем статус добавления категории
				dispatch(addCategoryInList({ id: addingCategoryStatus.id, name: categoryInput })); // добавлем категорию в список
			}
			// если добавилось не успешно
			else if (addingCategoryStatus.status === RequestStatus.FAILED || addingCategoryStatus.error === ResponseStatus.FAILED) {
				setCategoryInput(''); // очищаем категорию
				setErrorCategoryFlg(true); // подсвечиваем ошибку
			}
		}
	}, [addingCategoryStatus]);

	// обработчик добавления файла
	const submitHandler = (e: FormEvent<HTMLFormElement>): void => {
		e.preventDefault();
		const formData = new FormData();
		if (file) {
			formData.append('file', file);
			const foundCategoryId = categoriesList.data.find(categoryItem => categoryItem.name === categoryInput)?.id;
			foundCategoryId && dispatch(addFile({ title: fileNameInput, categoryId: foundCategoryId, formData })); // добавление файла
		}
	};

	// функция, определяющая отфильтрованные параметры, которые будут отображаться при поиске
	const filter = createFilterOptions<string>();

	// обработчик изменения поля категории
	const changeCategoryHandler = (value: string | null): void => {
		errorCategoryFlg && setErrorCategoryFlg(false); // сбрасываем ошибку, если была
		if (value) {
			setCategoryInput(value); // записываем имя в state
			// ищем категорию из input'а в списке категорий
			const foundCategory = categoriesList.data.find(categoryItem => categoryItem.name === value);
			// если нет категории
			if (!foundCategory) {
				dispatch(changePlaceAddingCategory('formAddingDocument')); // изменяем место, откуда будет добавляться категория
				isAccess(QAS.CATEGORY_ADD) && dispatch(addCategory(value)); // добавлем новую
			}
		} else {
			setCategoryInput('');
		}
	};

	// обработчик закрытия формы
	const handleClose = (): void => {
		// если идет добавление файла или добавление категории - запрещаем покидать форму
		if (addingFileStatus.status === RequestStatus.LOADING || addingCategoryStatus.status === RequestStatus.LOADING) return;
		setShowModal(false); // закрытие модального окна
		// очищаем статус добавления категории, если был
		if (addingCategoryStatus.id || addingCategoryStatus.status === RequestStatus.FAILED || addingCategoryStatus.error === ResponseStatus.FAILED) dispatch(clearAddingCategory());
	};

	return (
		<ModalFormWindow showModal={showModal} setShowModal={setShowModal} headerTitle='formHeader_addingDocument' close={handleClose}>
			<>
				<form onSubmit={(e) => submitHandler(e)}>
					<FormControl fullWidth margin='dense'>
						<TextField
							autoFocus
							required
							disabled={addingFileStatus.status === RequestStatus.LOADING}
							id="fileName"
							label={translate("input_name")}
							variant="outlined"
							value={fileNameInput}
							onChange={(e) => setFileNameInput(e.target.value)}
							InputProps={{
								style: {
									height: 33,
									fontSize: 13
								},
							}}
							InputLabelProps={{
								style: {
									fontSize: 13,
								},
							}}
							sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
						/>
					</FormControl>
					<FormControl fullWidth margin='dense'>
						<TextField
							id="file"
							disabled={addingFileStatus.status === RequestStatus.LOADING}
							variant="outlined"
							type="file"
							required
							onChange={(e: ChangeEvent<HTMLInputElement>) => e.target.files && setFile(e.target.files[0])}
							InputProps={{
								style: {
									height: 33,
									fontSize: 13
								},
								inputProps: { accept: "text/plain" }
							}}
							InputLabelProps={{
								style: {
									fontSize: 13,
								},
							}}
							sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
						/>
					</FormControl>
					<FormControl fullWidth margin='dense'>
						<Autocomplete
							freeSolo={isAccess(QAS.CATEGORY_ADD)}
							autoHighlight
							options={categoriesList.data.map(categoryItem => categoryItem.name)}
							noOptionsText={<div className={styles.formNoDataTitle}>{translate('title_notFound')}</div>}
							disabled={addingFileStatus.status === RequestStatus.LOADING || addingCategoryStatus.status === RequestStatus.LOADING}
							value={categoryInput}
							onChange={(_, value) => changeCategoryHandler(value)}
							filterOptions={(options, state) => {
								const filtered = filter(options, state);
								// если есть доступ к добавлению категории
								if (isAccess(QAS.CATEGORY_ADD)) {
									if (state.inputValue.length > 0 && options.findIndex(category => category === state.inputValue) === -1) filtered.push(state.inputValue);
									return filtered;
								}
								return filtered;
							}}
							renderInput={(params) =>
								<TextField
									{...params}
									required
									label={translate('input_category')}
									error={errorCategoryFlg}
									helperText={errorCategoryFlg ? translate(addingCategoryStatus.message || "title_failedAddingCategory") : undefined}
									variant="outlined"
									InputLabelProps={{
										style: {
											fontSize: 13,
										},
									}}
									InputProps={{
										...params.InputProps, // важно прокинуть параметры
										endAdornment: (
											<>
												{addingCategoryStatus.status === RequestStatus.LOADING && addingCategoryStatus.from === 'formAddingDocument' &&
													<ProgressCircle isBtnDisabled />
												}
												{params.InputProps.endAdornment} {/* важно дописать параметры  */}
											</>
										),
									}}
									sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
								/>
							}
							sx={{
								".MuiInputBase-root": { height: 33, fontSize: 13 },
								".MuiInputBase-input": { marginTop: -1 },
							}}
							getOptionLabel={option => option}
							renderOption={(props, option, _state, ownerState) => {
								const match = ownerState.options.filter(category => category === option);
								return (
									<li {...props} style={{ fontSize: 13 }}>
										{match.length === 0 ?
											<>{translate('title_addCategory')} "{option}"</>
											:
											<span>{option}</span>
										}
									</li>
								);
							}}
						/>
					</FormControl>
					<FormControl fullWidth margin='dense'>
						<Button
							variant="outlined"
							type="submit"
							disabled={addingFileStatus.status === RequestStatus.LOADING || addingCategoryStatus.status === RequestStatus.LOADING}
							sx={{ fontSize: 11 }}
						>
							{addingFileStatus.status === RequestStatus.LOADING ?
								<>
									{translate('button_add')}
									<ProgressCircle isBtnDisabled />
								</>
								:
								translate('button_add')
							}
						</Button>
					</FormControl>
				</form>
				{/* ползунок загрузки */}
				{addingFileStatus.status === RequestStatus.LOADING &&
					<ProgressLinear value={addingFileStatus.progress} />
				}
			</>
		</ModalFormWindow>
	);
};

export default FormAddingDocumentFile;
