import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError, AxiosResponse } from 'axios';
import { RootState } from './store';
import axiosApi from '../helpers/axios';
import { ENDPOINTS_RES, URL_RES } from '../constants/api_endpoints';
import { IResponseDashboardsList, IResState } from '../types/resTypes';
import { RequestStatus, ResponseStatus } from '../types/statusTypes';

const initialState: IResState = {
  dashboardsList: {
    data: {},
    activeDashboardLink: null,
    status: RequestStatus.IDLE,
    error: ResponseStatus.SUCCESS,
    message: '',
  },
};

// список рабочих столов
export const getDashboardsList = createAsyncThunk(
  'res/getDashboardsList',
  async (_, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IResponseDashboardsList> = await axiosApi.get(`${URL_RES}/${ENDPOINTS_RES.DASHBOARDS}`);
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

const resSlice = createSlice({
  name: 'res',
  initialState,
  reducers: {
    // добавление ссылки на отчет
    addActiveDashboardLink: (state, action: PayloadAction<string>) => {
      state.dashboardsList.activeDashboardLink = action.payload;
    },
    // очистка state
    clearState: (state) => {
      state.dashboardsList = initialState.dashboardsList;
    },
    // очистка списка рабочих столов
    clearDashboardsList: (state) => {
      state.dashboardsList = initialState.dashboardsList;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDashboardsList.pending, (state) => {
        state.dashboardsList.status = RequestStatus.LOADING;
      })
      .addCase(getDashboardsList.fulfilled, (state, action) => {
        if (action.payload && typeof action.payload === 'object' && 'dashboards' in action.payload && action.payload.dashboards) {
          state.dashboardsList.status = RequestStatus.IDLE;
          state.dashboardsList.data = action.payload.dashboards;
        } else state.dashboardsList.status = RequestStatus.FAILED;
      })
      .addCase(getDashboardsList.rejected, (state, action: PayloadAction<unknown>) => {
        state.dashboardsList.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.dashboardsList.error = action.payload.response?.data.error;
          state.dashboardsList.message = action.payload.response?.data.message;
        }
      });
  },
});

export const { addActiveDashboardLink, clearState, clearDashboardsList } = resSlice.actions;

export const selectDashboardsList = (state: RootState) => state.res.dashboardsList;

export default resSlice.reducer;
