import { useEffect, useState } from 'react';
import { TextField } from '@mui/material';
import useTranslate from '../../../../hooks/useTranslate';
import { colorPrimary } from '../../../../constants/colors';
import { IFilterProps } from './Filter.props';
// import styles from './Filter.module.scss';

// const filterMethodOptions: { method: MethodFilter, desc: string }[] = [
// 	{ method: 'or', desc: 'selectItem_or' },
// 	{ method: 'and', desc: 'selectItem_and' },
// 	{ method: 'except', desc: 'selectItem_except' },
// ];

const Filter = ({ table, column, rowSelection, /* showDoubles, setShowDoubles, */ }: IFilterProps) => {
	const [inputFilter, setInputFilter] = useState<string>(''); // значение для фильтрации
	// const [inputClasses, setInputClasses] = useState<string[]>([]); // значение для множественной фильтрации по классам
	// const [selectMethodFilterByClass, setSelectMethodFilterByClass] = useState<MethodFilter>('or'); // переключатель метода фильтрации по классам

	const translate = useTranslate(); // hook для перевода текста

	// следим за названием корпуса и сбрасываем значения для фильтрации
	// useEffect(() => {
	// 	setInputPhrase('');
	// 	setInputClasses([]);
	// }, [corpus.corpusName]);

	// следим за флагом поиска дубликатов
	// useEffect(() => {
	// 	// если флаг включен - сбрасываем значения для фильтрации
	// 	if (showDoubles) {
	// 		setInputClasses([]);
	// 		setInputPhrase('');
	// 	}
	// }, [showDoubles]);

	// следим за значениями для фильтрации
	useEffect(() => {
		Object.keys(rowSelection).length > 0 && table.resetRowSelection(); // если выбраны строки - сбрасываем
		// if (showDoubles && inputClasses.length === 0 && inputPhrase.length === 0) return; // выходим если сбросились поля фильтра и стоит флаг поиска дубликатов фраз
		// (inputClasses.length > 0 || inputPhrase.length > 0) && setShowDoubles(false); // если появился хотя бы 1 символ для фильтрации - убираем флаг поиска дубликатов
		// column.id === 'class' && inputClasses.length === 0 && corpus.classNameFiltering && dispatch(addFilterByClass(null)); // сбрасываем имя класса для фильтра из store
		// фильтруем через пол-секунды бездействия после окончания ввода значения для фильтрации
		const handler = setTimeout(() => {
			// if (column.id === 'class') column.setFilterValue({ type: selectMethodFilterByClass, data: inputClasses });
			// else column.setFilterValue(inputPhrase);
			column.setFilterValue(inputFilter);
		}, 500);

		return () => {
			clearTimeout(handler); // сбрасываем timeout, если продолжается ввод значения для фильтрации
		};
	}, [/* inputClasses, */ inputFilter]);

	// следим за переключателем и фильтруем с другими аргументами
	// useEffect(() => {
	// 	if (column.id === 'class') column.setFilterValue({ type: selectMethodFilterByClass, data: inputClasses });
	// }, [selectMethodFilterByClass]);

	// следим за именем класса для фильтрации и вписываем в поле фильтрации по классу, иначе очищаем поле
	// useEffect(() => {
	// 	if (corpus.classNameFiltering && column.id === 'class' && !inputClasses.includes(corpus.classNameFiltering)) {
	// 		setInputClasses(prev => [...prev, String(corpus.classNameFiltering)]);
	// 	} else setInputClasses([]);
	// }, [corpus.classNameFiltering]);

	return (
		// <>
		// 	{column.id === 'class' ?
		// 		<div className={styles.filterClasses}>
		// 			<Autocomplete
		// 				multiple
		// 				// limitTags={1}
		// 				disableCloseOnSelect
		// 				autoHighlight
		// 				openOnFocus
		// 				noOptionsText={<div className={styles.blockNoOptions}>{translate('selectItem_notFound')}</div>}
		// 				options={corpus.classes}
		// 				value={inputClasses}
		// 				onChange={(_, value) => {
		// 					value === null ? setInputClasses([]) : setInputClasses(value.map(className => className.replace(/,/g, '')));
		// 				}}
		// 				filterSelectedOptions
		// 				renderInput={(params) =>
		// 					<TextField
		// 						{...params}
		// 						label={translate('input_filter')}
		// 						InputLabelProps={{
		// 							style: {
		// 								fontSize: 13,
		// 							},
		// 						}}
		// 						sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
		// 					/>
		// 				}
		// 				sx={{
		// 					".MuiInputBase-root": { minHeight: 33, fontSize: 13, color: colorPrimary },
		// 					".MuiOutlinedInput-root": { padding: '0 0 0 3px', paddingRight: "30px!important" },
		// 					".MuiAutocomplete-popupIndicator": { display: 'none' },
		// 					backgroundColor: 'transparent',
		// 					// position: 'absolute', top: 31, maxWidth: 'calc(100% - 8px)',
		// 					flexGrow: 1,
		// 					width: '90px', // не увеличивает ширину столбца при длинном теге в фильтре
		// 				}}
		// 				getOptionLabel={option => option}
		// 				renderOption={(props, option) =>
		// 					<li {...props} style={{ padding: '2px 5px', textAlign: 'left', fontSize: 12, color: colorPrimary }}>
		// 						{option}
		// 					</li>
		// 				}
		// 				renderTags={(value: readonly string[], getTagProps) =>
		// 					value.map((option: string, index: number) =>
		// 						<Chip label={option} {...getTagProps({ index })} sx={{ maxWidth: 'calc(100% - 50px)!important', height: 17, color: colorPrimary }} />
		// 					)
		// 				}
		// 				PopperComponent={(props) =>
		// 					<Popper {...props} sx={{ boxShadow: `0 0 5px 1px ${colorPrimary}`, borderRadius: 3 }}>
		// 						{props.children}
		// 					</Popper>
		// 				}
		// 				ListboxComponent={forwardRef((props, ref: Ref<HTMLUListElement>) =>
		// 					<ul {...props} ref={ref}>
		// 						{props.children}
		// 						<div className={styles.blockFillGradientTop} />
		// 						<div className={styles.blockFillGradientBottom} />
		// 					</ul>
		// 				)}
		// 			/>
		// 			<Select
		// 				id="filterClassesSwitch"
		// 				value={selectMethodFilterByClass}
		// 				onChange={e => setSelectMethodFilterByClass(e.target.value as MethodFilter)}
		// 				style={{ fontSize: 13, height: 33, color: colorPrimary }}
		// 			>
		// 				{filterMethodOptions.map(value =>
		// 					<MenuItem key={value.method} value={value.method} sx={{ fontSize: 13, padding: '0 5px', color: colorPrimary }}>{translate(value.desc).toUpperCase()}</MenuItem>
		// 				)}
		// 			</Select>
		// 		</div>
		// 		:
		<TextField
			label={translate('input_filter')}
			value={inputFilter}
			onChange={e => setInputFilter(e.target.value)}
			variant="outlined"
			InputProps={{
				style: {
					height: 34,
					fontSize: 13,
					color: colorPrimary,
				},
			}}
			InputLabelProps={{
				style: {
					fontSize: 13,
				},
			}}
			sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 }, width: '100%' }}
		/>
		// 	}
		// </>
	);
};

export default Filter;
