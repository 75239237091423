import { useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch, useAppSelector, } from '../../../store/hooks';
import { changeTypeConditionRunActionRobot, changeValuesConditionRunActionRobot, deleteConditionRunActionRobot, selectActiveRobotVersion, selectRobot } from '../../../store/sesRobotSlice';
import { changeTypeConditionRunActionDataElem, changeTypeConditionRunActionEndpoint, changeValuesConditionRunActionDataElem, changeValuesConditionRunActionEndpoint, deleteConditionRunActionDataElem, deleteConditionRunActionEndpoint, selectDataList } from '../../../store/sesSlice';
import useAccessRight from '../../../hooks/useAccessRight';
import useTranslate from '../../../hooks/useTranslate';
import { SES } from '../../../constants/accessRights';
import { CONDITION_RUN_ACTION_TYPE_LIST, OPERATION_CONDITIONS_LIST } from '../../../constants/robotConfigLists';
import { colorPrimary, colorRed } from '../../../constants/colors';
import { ConditionType, ConditionVariantType, DataElemActionEventType, OperationType } from '../../../types/sesTypes';
import { RobotActionEventType } from '../../../types/sesRobotTypes';
import { IConditionDataProps } from './ConditionData.props';
import styles from './ConditionData.module.scss';

const ConditionData = ({ conditionData, itsAction, actionEvent, channel, channelIdx, conditionBlockIdx, conditionIdx, changeFlg, setChangeFlg }: IConditionDataProps): JSX.Element => {
	const [conditionType, setConditionType] = useState<ConditionType>(conditionData.type); // тип условия запуска действия
	const [inputDepth, setInputDepth] = useState<number>(conditionData.type === 'data' ? conditionData.depth : 5); // глубина
	const [inputId, setInputId] = useState<string>(conditionData.id); // id
	const [inputOperation, setInputOperation] = useState<OperationType>((conditionData.type === 'data' || conditionData.type === 'variable') ? conditionData.operation : 'exists'); // операция
	const [inputValue, setInputValue] = useState<string>((conditionData.type === 'data' || conditionData.type === 'variable') ? conditionData.value : ''); // значение

	const dispatch = useAppDispatch();
	const activeRobotVersion = useAppSelector(selectActiveRobotVersion); // store - версия активного робота
	const robotInfo = useAppSelector(selectRobot); // store - информация о роботе
	const dataElemList = useAppSelector(selectDataList); // список элементов данных

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	const creatingObjectWithCondition = (type: ConditionType): ConditionVariantType => {
		switch (type) {
			case 'data':
				return {
					type,
					depth: inputDepth,
					id: inputId,
					operation: inputOperation,
					value: inputValue,
				};
			case 'variable':
				return {
					type,
					id: inputId,
					operation: inputOperation,
					value: inputValue,
				};
			case 'interval':
				return {
					type,
					id: inputId,
				};
			default:
				const defaultType: never = type;
				return defaultType;
		};
	};

	// обработчик изменения типа условия запуска действия
	const changeTypeConditionRunActionHandler = (e: SelectChangeEvent<ConditionType>): void => {
		setConditionType(e.target.value as ConditionType);
		itsAction === 'robot' && dispatch(changeTypeConditionRunActionRobot({
			actionEvent: actionEvent as RobotActionEventType,
			channel,
			channelIdx,
			conditionBlockIdx,
			conditionIdx,
			conditionType: e.target.value as ConditionType,
		}));
		itsAction === 'dataElement' && dispatch(changeTypeConditionRunActionDataElem({
			actionEvent: actionEvent as DataElemActionEventType,
			channel,
			channelIdx,
			conditionBlockIdx,
			conditionIdx,
			conditionType: e.target.value as ConditionType,
		}));
		itsAction === 'endpoint' && dispatch(changeTypeConditionRunActionEndpoint({
			channel,
			channelIdx,
			conditionBlockIdx,
			conditionIdx,
			conditionType: e.target.value as ConditionType,
		}));
		(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('actions')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'actions'] }));  // ставим флаг о несохраненных данных
	};

	// обработчик изменения значений условия запуска действия
	const changeValuesConditionRunActionHandler = (): void => {
		itsAction === 'robot' && dispatch(changeValuesConditionRunActionRobot({
			actionEvent: actionEvent as RobotActionEventType,
			channel,
			channelIdx,
			conditionBlockIdx,
			conditionIdx,
			data: creatingObjectWithCondition(conditionData.type),
		}));
		itsAction === 'dataElement' && dispatch(changeValuesConditionRunActionDataElem({
			actionEvent: actionEvent as DataElemActionEventType,
			channel,
			channelIdx,
			conditionBlockIdx,
			conditionIdx,
			data: creatingObjectWithCondition(conditionData.type),
		}));
		itsAction === 'endpoint' && dispatch(changeValuesConditionRunActionEndpoint({
			channel,
			channelIdx,
			conditionBlockIdx,
			conditionIdx,
			data: creatingObjectWithCondition(conditionData.type),
		}));
		(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('actions')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'actions'] }));  // ставим флаг о несохраненных данных
	};

	// обработчик удаления условия
	const deleteConditionHandler = (): void => {
		itsAction === 'robot' && dispatch(deleteConditionRunActionRobot({
			actionEvent: actionEvent as RobotActionEventType,
			channel,
			channelIdx,
			conditionBlockIdx,
			conditionIdx,
		}));
		itsAction === 'dataElement' && dispatch(deleteConditionRunActionDataElem({
			actionEvent: actionEvent as DataElemActionEventType,
			channel,
			channelIdx,
			conditionBlockIdx,
			conditionIdx,
		}));
		itsAction === 'endpoint' && dispatch(deleteConditionRunActionEndpoint({
			channel,
			channelIdx,
			conditionBlockIdx,
			conditionIdx,
		}));
		(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('actions')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'actions'] }));  // ставим флаг о несохраненных данных
	};

	return (
		<div className={styles.conditionData}>
			{/* тип условия запуска действия */}
			<FormControl margin='dense' sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 }, flexShrink: 0 }}>
				<InputLabel sx={{ fontSize: 13 }}>{translate('select_type')}</InputLabel>
				<Select
					required
					label={translate('select_type')}
					value={conditionType}
					onChange={changeTypeConditionRunActionHandler}
					disabled={(itsAction === 'robot' && !isAccess(SES.ROBOT_EDIT)) || (itsAction === 'dataElement' && !isAccess(SES.DATA_EDIT)) || (itsAction === 'endpoint' && !isAccess(SES.ENDPOINT_EDIT)) || activeRobotVersion !== 'draft'}
					style={{ fontSize: 13, height: 33, color: colorPrimary }}
				>
					{CONDITION_RUN_ACTION_TYPE_LIST.map(({ type, translation }) =>
						<MenuItem key={type} value={type} sx={{ fontSize: 13, color: colorPrimary }}>
							{translate(translation)}
						</MenuItem>
					)}
				</Select>
			</FormControl>

			{/* глубина */}
			{conditionType === 'data' &&
				<FormControl margin='dense' sx={{ flexShrink: 0 }}>
					<TextField
						label={translate('input_depth')}
						variant="outlined"
						type='number'
						value={inputDepth}
						onChange={(e) => setInputDepth(+e.target.value)}
						onBlur={changeValuesConditionRunActionHandler}
						disabled={(itsAction === 'robot' && !isAccess(SES.ROBOT_EDIT)) || (itsAction === 'dataElement' && !isAccess(SES.DATA_EDIT)) || (itsAction === 'endpoint' && !isAccess(SES.ENDPOINT_EDIT)) || activeRobotVersion !== 'draft'}
						InputProps={{
							style: {
								height: 33,
								fontSize: 13,
								color: colorPrimary,
							},
							inputProps: { min: 1, max: 20 }
						}}
						InputLabelProps={{
							style: {
								fontSize: 13,
							},
						}}
						sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
					/>
				</FormControl>
			}

			{/* название */}
			{conditionType === 'interval' &&
				<FormControl fullWidth margin='dense' sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}>
					<InputLabel sx={{ fontSize: 13 }}>{translate('select_name')}</InputLabel>
					<Select
						label={translate('select_name')}
						disabled={(itsAction === 'robot' && !isAccess(SES.ROBOT_EDIT)) || (itsAction === 'dataElement' && !isAccess(SES.DATA_EDIT)) || (itsAction === 'endpoint' && !isAccess(SES.ENDPOINT_EDIT)) || activeRobotVersion !== 'draft'}
						value={inputId}
						onChange={(e) => setInputId(e.target.value)}
						onBlur={changeValuesConditionRunActionHandler}
						error={inputId === ''}
						style={{ fontSize: 13, height: 33, color: colorPrimary }}
					>
						{/* на случай, если не найден интервал из списка */}
						{robotInfo.data && !Object.keys(robotInfo.data.timeintervals).includes(inputValue) && inputValue !== '' &&
							<MenuItem value={inputValue} sx={{ fontSize: 12, color: colorPrimary }}>{inputValue} ({translate('selectItem_notFound')})</MenuItem>
						}
						{robotInfo.data && Object.keys(robotInfo.data.timeintervals).map((name) =>
							<MenuItem key={name} value={name} sx={{ fontSize: 13, color: colorPrimary }}>{name}</MenuItem>
						)}
					</Select>
				</FormControl>
			}
			{conditionType === 'data' &&
				<FormControl fullWidth margin='dense' sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}>
					<InputLabel sx={{ fontSize: 13 }}>{translate('select_name')}</InputLabel>
					<Select
						label={translate('select_name')}
						disabled={(itsAction === 'robot' && !isAccess(SES.ROBOT_EDIT)) || (itsAction === 'dataElement' && !isAccess(SES.DATA_EDIT)) || (itsAction === 'endpoint' && !isAccess(SES.ENDPOINT_EDIT)) || activeRobotVersion !== 'draft'}
						value={inputId}
						onChange={(e) => setInputId(e.target.value)}
						onBlur={changeValuesConditionRunActionHandler}
						error={inputId === ''}
						style={{ fontSize: 13, height: 33, color: colorPrimary }}
						title={dataElemList.dictionary[inputId]}
					>
						{/* не найденный элемент данных из списка */}
						{!dataElemList.data.find(elem => elem.id === inputId) && inputId !== '' &&
							<MenuItem value={inputId} sx={{ fontSize: 13, color: colorRed }}>{inputId} ({translate('selectItem_notFound')})</MenuItem>
						}
						{dataElemList.data.map((elem) => (
							<MenuItem key={elem.id} value={elem.id} sx={{ fontSize: 13, color: colorPrimary }}>{elem.name}</MenuItem>
						))}
					</Select>
				</FormControl>
			}
			{conditionType === 'variable' &&
				<FormControl fullWidth margin='dense'>
					<TextField
						label={translate('input_name')}
						variant="outlined"
						value={inputId}
						onChange={(e) => setInputId(e.target.value)}
						onBlur={changeValuesConditionRunActionHandler}
						disabled={(itsAction === 'robot' && !isAccess(SES.ROBOT_EDIT)) || (itsAction === 'dataElement' && !isAccess(SES.DATA_EDIT)) || (itsAction === 'endpoint' && !isAccess(SES.ENDPOINT_EDIT)) || activeRobotVersion !== 'draft'}
						error={inputId === ''}
						InputProps={{
							style: {
								height: 33,
								fontSize: 13,
								color: colorPrimary,
							},
						}}
						InputLabelProps={{
							style: {
								fontSize: 13,
							},
						}}
						sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
					/>
				</FormControl>
			}

			{(conditionType === 'data' || conditionType === 'variable') &&
				<>
					{/* операция */}
					<FormControl margin='dense' sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 }, flexShrink: 0 }}>
						<Select
							required
							value={inputOperation}
							onChange={(e) => setInputOperation(e.target.value as OperationType)}
							onBlur={changeValuesConditionRunActionHandler}
							disabled={(itsAction === 'robot' && !isAccess(SES.ROBOT_EDIT)) || (itsAction === 'dataElement' && !isAccess(SES.DATA_EDIT)) || (itsAction === 'endpoint' && !isAccess(SES.ENDPOINT_EDIT)) || activeRobotVersion !== 'draft'}
							style={{ fontSize: 13, height: 33, color: colorPrimary }}
						>
							{OPERATION_CONDITIONS_LIST.map(({ operation, translation }) =>
								<MenuItem key={operation} value={operation} sx={{ fontSize: 13, color: colorPrimary }}>{translate(translation)}</MenuItem>
							)}
						</Select>
					</FormControl>
					{/* значение */}
					<FormControl fullWidth margin='dense'>
						<TextField
							label={translate('input_value')}
							variant="outlined"
							value={inputValue}
							onChange={(e) => setInputValue(e.target.value)}
							onBlur={changeValuesConditionRunActionHandler}
							disabled={(itsAction === 'robot' && !isAccess(SES.ROBOT_EDIT)) || (itsAction === 'dataElement' && !isAccess(SES.DATA_EDIT)) || (itsAction === 'endpoint' && !isAccess(SES.ENDPOINT_EDIT)) || activeRobotVersion !== 'draft'}
							InputProps={{
								style: {
									height: 33,
									fontSize: 13,
									color: colorPrimary,
								},
							}}
							InputLabelProps={{
								style: {
									fontSize: 13,
								},
							}}
							sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
						/>
					</FormControl>
				</>
			}

			{/* удаление условия */}
			{((itsAction === 'robot' && isAccess(SES.ROBOT_EDIT)) || (itsAction === 'dataElement' && isAccess(SES.DATA_EDIT)) || (itsAction === 'endpoint' && isAccess(SES.ENDPOINT_EDIT))) && activeRobotVersion === 'draft' &&
				<FontAwesomeIcon
					icon={faTrashCan}
					color={colorRed}
					size='lg'
					onClick={deleteConditionHandler}
					title={translate('buttonTitle_deleteCondition')}
					style={{ cursor: 'pointer' }}
				/>
			}
		</div>
	);
};

export default ConditionData;
