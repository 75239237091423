import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { Cookies } from 'react-cookie';
import { RootState } from './store';
import { ENDPOINTS_AUTH, URL_AUTH } from '../constants/api_endpoints';
import { ACCESS_TOKEN, REFRESH_TOKEN } from '../constants/cookieNames';
import axiosApi from '../helpers/axios';
import { IAccessRightsData, IAddRoleProps, IAuthState, IDataset, IEditDatasetProps, IEditRoleProps, ILoginProps, ILoginResponse, IRefreshTokenResponse, IResponse, IRole, IRoleAddResponse, IVersion } from '../types/authTypes';
import { RequestStatus, ResponseStatus } from '../types/statusTypes';

const initialState: IAuthState = {
  status: RequestStatus.IDLE,
  error: ResponseStatus.SUCCESS,
  message: '',
  token: {
    access: null,
    refresh: null,
  },
  accessRights: {
    status: RequestStatus.IDLE,
    data: null,
  },
  rolesList: {
    status: RequestStatus.IDLE,
    data: [],
    error: ResponseStatus.SUCCESS,
    message: '',
  },
  roleAdd: {
    status: RequestStatus.IDLE,
    error: ResponseStatus.SUCCESS,
    message: '',
    id: null,
  },
  roleEdit: {
    status: RequestStatus.IDLE,
    error: ResponseStatus.SUCCESS,
    message: '',
  },
  roleDelete: {
    status: RequestStatus.IDLE,
    error: ResponseStatus.SUCCESS,
    message: '',
  },
  datasetsList: {
    status: RequestStatus.IDLE,
    data: [],
    error: ResponseStatus.SUCCESS,
    message: '',
  },
  datasetAdd: {
    status: RequestStatus.IDLE,
    error: ResponseStatus.SUCCESS,
    message: '',
  },
  datasetEdit: {
    status: RequestStatus.IDLE,
    error: ResponseStatus.SUCCESS,
    message: '',
  },
  datasetDelete: {
    status: RequestStatus.IDLE,
    error: ResponseStatus.SUCCESS,
    message: '',
  },
  version: null,
};

const setCookie = (cookieName: string, value: string, maxAge: number): void => {
  new Cookies().set(cookieName, value, { path: '/', maxAge });
};

const removeCookie = (cookieName: string): void => {
  new Cookies().remove(cookieName, { path: '/' });
};

// авторизация пользователя
export const login = createAsyncThunk(
  'auth/login',
  async ({ userName, password }: ILoginProps, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<ILoginResponse | IResponse> = await axios.post(`${URL_AUTH}/${ENDPOINTS_AUTH.ACCESS}`, {
        username: userName,
        password,
      });
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// обновление токена авторизации
export const refreshToken = createAsyncThunk(
  'auth/refreshToken',
  async (_, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IRefreshTokenResponse | IResponse> = await axios.get(`${URL_AUTH}/${ENDPOINTS_AUTH.REFRESH}`, {
        headers: {
          'x-refresh-token': new Cookies().get(REFRESH_TOKEN),
        }
      });
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// права доступа
export const getAccessRightsList = createAsyncThunk(
  'auth/getAccessRightsList',
  async (): Promise<IAccessRightsData> => {
    const response: AxiosResponse<IAccessRightsData> = await axiosApi.get(`${URL_AUTH}/${ENDPOINTS_AUTH.RIGHTS}`);
    return response.data;
  }
);

// список ролей
export const getRolesList = createAsyncThunk(
  'auth/getRolesList',
  async (_, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IRole[] | IResponse> = await axiosApi.get(`${URL_AUTH}/${ENDPOINTS_AUTH.ROLES}`);
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// добавление роли
export const addRole = createAsyncThunk(
  'auth/addRole',
  async (data: IAddRoleProps, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IRoleAddResponse> = await axiosApi.post(`${URL_AUTH}/${ENDPOINTS_AUTH.ROLE_ADD}`, {
        data: JSON.stringify(data),
      });
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// изменение роли
export const editRole = createAsyncThunk(
  'auth/editRole',
  async ({ roleId, rights }: IEditRoleProps, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IResponse> = await axiosApi.post(`${URL_AUTH}/${ENDPOINTS_AUTH.ROLE_EDIT}/${roleId}`, {
        data: JSON.stringify({ rights }),
      });
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// удаление роли
export const deleteRole = createAsyncThunk(
  'auth/deleteRole',
  async (roleId: string, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IResponse> = await axiosApi.delete(`${URL_AUTH}/${ENDPOINTS_AUTH.ROLE_DELETE}/${roleId}`);
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// список наборов данных
export const getDatasetsList = createAsyncThunk(
  'auth/getDatasetsList',
  async (_, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IDataset[] | IResponse> = await axiosApi.get(`${URL_AUTH}/${ENDPOINTS_AUTH.DATASETS}`);
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// добавление набора данных
export const addDataset = createAsyncThunk(
  'auth/addDataset',
  async (datasetName: string, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IResponse> = await axiosApi.post(`${URL_AUTH}/${ENDPOINTS_AUTH.DATASET_ADD}`, {
        data: JSON.stringify({ name: datasetName }),
      });
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// изменение набора данных
export const editDataset = createAsyncThunk(
  'auth/editDataset',
  async ({ datasetId, datasetName }: IEditDatasetProps, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IResponse> = await axiosApi.post(`${URL_AUTH}/${ENDPOINTS_AUTH.DATASET_EDIT}/${datasetId}`, {
        data: JSON.stringify({ name: datasetName }),
      });
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// удаление набора данных
export const deleteDataset = createAsyncThunk(
  'auth/deleteDataset',
  async (datasetId: string, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IResponse> = await axiosApi.delete(`${URL_AUTH}/${ENDPOINTS_AUTH.DATASET_DELETE}/${datasetId}`);
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// версия системы
export const getVersion = createAsyncThunk(
  'auth/getVersion',
  async (): Promise<IVersion | IResponse> => {
    const response: AxiosResponse<IVersion | IResponse> = await axiosApi.get(`${URL_AUTH}/${ENDPOINTS_AUTH.VERSION}`);
    return response.data;
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    // добавление прав в роль
    addRightsToRole: (state, action: PayloadAction<{ rights: string[], idx: number }>) => {
      state.rolesList.data[action.payload.idx].rights = action.payload.rights;
    },
    // очистка данных авторизации
    clearAuthData: (state) => {
      state.status = initialState.status;
      state.token = initialState.token;
      state.error = initialState.error;
      state.message = initialState.message;
    },
    // очистка списка прав доступа
    clearAccessRightsList: (state) => {
      state.accessRights = initialState.accessRights;
    },
    // очистка списка ролей
    clearRolesList: (state) => {
      state.rolesList = initialState.rolesList;
    },
    // очистка статуса добавления роли
    clearRoleAdd: (state) => {
      state.roleAdd = initialState.roleAdd;
    },
    // очистка статуса изменения роли
    clearRoleEdit: (state) => {
      state.roleEdit = initialState.roleEdit;
    },
    // очистка статуса удаления роли
    clearRoleDelete: (state) => {
      state.roleDelete = initialState.roleDelete;
    },
    // очистка списка наборов данных
    clearDatasetsList: (state) => {
      state.datasetsList = initialState.datasetsList;
    },
    // очистка статуса добавления набора данных
    clearDatasetAdd: (state) => {
      state.datasetAdd = initialState.datasetAdd;
    },
    // очистка статуса изменения набора данных
    clearDatasetEdit: (state) => {
      state.datasetEdit = initialState.datasetEdit;
    },
    // очистка статуса удаления набора данных
    clearDatasetDelete: (state) => {
      state.datasetDelete = initialState.datasetDelete;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.status = RequestStatus.LOADING;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.status = RequestStatus.IDLE;
        if (action.payload) {
          if ('x-access-token' in action.payload) {
            state.token.access = action.payload['x-access-token'];
            state.token.refresh = action.payload['x-refresh-token'];
            setCookie(ACCESS_TOKEN, action.payload['x-access-token'], 86_400); // на сутки
            setCookie(REFRESH_TOKEN, action.payload['x-refresh-token'], 2_592_000); // на месяц
          }
          if ('error' in action.payload) {
            state.error = action.payload.error;
            state.message = action.payload.message;
          }
        }
      })
      .addCase(login.rejected, (state, action: PayloadAction<unknown>) => {
        state.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.error = action.payload.response?.data.error;
          state.message = action.payload.response?.data.message;
        }
      })
      .addCase(refreshToken.pending, (state) => {
        state.status = RequestStatus.LOADING;
      })
      .addCase(refreshToken.fulfilled, (state, action) => {
        state.status = RequestStatus.IDLE;
        if (action.payload && typeof action.payload !== 'string') {
          if ('x-access-token' in action.payload) {
            state.token.access = action.payload['x-access-token'];
            state.token.refresh = action.payload['x-refresh-token'];
            setCookie(ACCESS_TOKEN, action.payload['x-access-token'], 86_400); // на сутки
            setCookie(REFRESH_TOKEN, action.payload['x-refresh-token'], 2_592_000); // на месяц
          }
          if ('error' in action.payload) {
            state.error = action.payload.error;
            state.message = action.payload.message;
            removeCookie(ACCESS_TOKEN);
            removeCookie(REFRESH_TOKEN);
          }
        } else {
          removeCookie(ACCESS_TOKEN);
          removeCookie(REFRESH_TOKEN);
        }
      })
      .addCase(refreshToken.rejected, (state, action: PayloadAction<unknown>) => {
        state.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.error = action.payload.response?.data.error;
          state.message = action.payload.response?.data.message;
          removeCookie(ACCESS_TOKEN);
          removeCookie(REFRESH_TOKEN);
        }
      })
      .addCase(getAccessRightsList.pending, (state) => {
        state.accessRights.status = RequestStatus.LOADING;
      })
      .addCase(getAccessRightsList.fulfilled, (state, action: PayloadAction<IAccessRightsData>) => {
        if (typeof action.payload !== 'string') {
          state.accessRights.status = RequestStatus.IDLE;
          state.accessRights.data = action.payload;
        } else {
          state.accessRights.status = RequestStatus.FAILED;
        }
      })
      .addCase(getAccessRightsList.rejected, (state) => {
        state.accessRights.status = RequestStatus.FAILED;
      })
      .addCase(getRolesList.pending, (state) => {
        state.rolesList.status = RequestStatus.LOADING;
      })
      .addCase(getRolesList.fulfilled, (state, action) => {
        if (Array.isArray(action.payload)) {
          state.rolesList.status = RequestStatus.IDLE;
          state.rolesList.data = action.payload.sort((a, b) => {
            if (a.name > b.name) return 1;
            else if (a.name < b.name) return -1;
            else return 0;
          });
        } else {
          state.rolesList.status = RequestStatus.FAILED;
        }
      })
      .addCase(getRolesList.rejected, (state, action: PayloadAction<unknown>) => {
        state.rolesList.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.rolesList.error = action.payload.response?.data.error;
          state.rolesList.message = action.payload.response?.data.message;
        }
      })
      .addCase(addRole.pending, (state) => {
        state.roleAdd.status = RequestStatus.LOADING;
      })
      .addCase(addRole.fulfilled, (state, action) => {
        if (action.payload) {
          state.roleAdd.status = RequestStatus.IDLE;
          state.roleAdd.error = action.payload.error;
          state.roleAdd.message = action.payload.message;
          if (action.payload.role_id) state.roleAdd.id = action.payload.role_id;
        } else {
          state.roleAdd.status = RequestStatus.FAILED;
        }
      })
      .addCase(addRole.rejected, (state, action: PayloadAction<unknown>) => {
        state.roleAdd.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.roleAdd.error = action.payload.response?.data.error;
          state.roleAdd.message = action.payload.response?.data.message;
        }
      })
      .addCase(editRole.pending, (state) => {
        state.roleEdit.status = RequestStatus.LOADING;
      })
      .addCase(editRole.fulfilled, (state, action) => {
        if (action.payload) {
          state.roleEdit.status = RequestStatus.IDLE;
          state.roleEdit.error = action.payload.error;
          state.roleEdit.message = action.payload.message;
        } else {
          state.roleEdit.status = RequestStatus.FAILED;
        }
      })
      .addCase(editRole.rejected, (state, action: PayloadAction<unknown>) => {
        state.roleEdit.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.roleEdit.error = action.payload.response?.data.error;
          state.roleEdit.message = action.payload.response?.data.message;
        }
      })
      .addCase(deleteRole.pending, (state) => {
        state.roleDelete.status = RequestStatus.LOADING;
      })
      .addCase(deleteRole.fulfilled, (state, action) => {
        if (action.payload) {
          state.roleDelete.status = RequestStatus.IDLE;
          state.roleDelete.error = action.payload.error;
          state.roleDelete.message = action.payload.message;
        } else {
          state.roleDelete.status = RequestStatus.FAILED;
        }
      })
      .addCase(deleteRole.rejected, (state, action: PayloadAction<unknown>) => {
        state.roleDelete.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.roleDelete.error = action.payload.response?.data.error;
          state.roleDelete.message = action.payload.response?.data.message;
        }
      })
      .addCase(getDatasetsList.pending, (state) => {
        state.datasetsList.status = RequestStatus.LOADING;
      })
      .addCase(getDatasetsList.fulfilled, (state, action) => {
        if (Array.isArray(action.payload)) {
          state.datasetsList.status = RequestStatus.IDLE;
          state.datasetsList.data = action.payload.sort((a, b) => {
            if (a.name > b.name) return 1;
            else if (a.name < b.name) return -1;
            else return 0;
          });
        } else {
          state.datasetsList.status = RequestStatus.FAILED;
        }
      })
      .addCase(getDatasetsList.rejected, (state, action: PayloadAction<unknown>) => {
        state.datasetsList.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.datasetsList.error = action.payload.response?.data.error;
          state.datasetsList.message = action.payload.response?.data.message;
        }
      })
      .addCase(addDataset.pending, (state) => {
        state.datasetAdd.status = RequestStatus.LOADING;
      })
      .addCase(addDataset.fulfilled, (state, action) => {
        if (action.payload) {
          state.datasetAdd.status = RequestStatus.IDLE;
          state.datasetAdd.error = action.payload.error;
          state.datasetAdd.message = action.payload.message;
        } else {
          state.datasetAdd.status = RequestStatus.FAILED;
        }
      })
      .addCase(addDataset.rejected, (state, action: PayloadAction<unknown>) => {
        state.datasetAdd.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.datasetAdd.error = action.payload.response?.data.error;
          state.datasetAdd.message = action.payload.response?.data.message;
        }
      })
      .addCase(editDataset.pending, (state) => {
        state.datasetEdit.status = RequestStatus.LOADING;
      })
      .addCase(editDataset.fulfilled, (state, action) => {
        if (action.payload) {
          state.datasetEdit.status = RequestStatus.IDLE;
          state.datasetEdit.error = action.payload.error;
          state.datasetEdit.message = action.payload.message;
        } else {
          state.datasetEdit.status = RequestStatus.FAILED;
        }
      })
      .addCase(editDataset.rejected, (state, action: PayloadAction<unknown>) => {
        state.datasetEdit.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.datasetEdit.error = action.payload.response?.data.error;
          state.datasetEdit.message = action.payload.response?.data.message;
        }
      })
      .addCase(deleteDataset.pending, (state) => {
        state.datasetDelete.status = RequestStatus.LOADING;
      })
      .addCase(deleteDataset.fulfilled, (state, action) => {
        if (action.payload) {
          state.datasetDelete.status = RequestStatus.IDLE;
          state.datasetDelete.error = action.payload.error;
          state.datasetDelete.message = action.payload.message;
        } else {
          state.datasetDelete.status = RequestStatus.FAILED;
        }
      })
      .addCase(deleteDataset.rejected, (state, action: PayloadAction<unknown>) => {
        state.datasetDelete.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.datasetDelete.error = action.payload.response?.data.error;
          state.datasetDelete.message = action.payload.response?.data.message;
        }
      })
      .addCase(getVersion.fulfilled, (state, action) => {
        if (typeof action.payload !== 'string') state.version = action.payload;
      });
  },
});

export const { addRightsToRole, clearAuthData, clearAccessRightsList, clearRolesList, clearRoleAdd, clearRoleEdit, clearRoleDelete, clearDatasetsList, clearDatasetAdd, clearDatasetEdit, clearDatasetDelete } = authSlice.actions;

export const selectAuthData = (state: RootState) => state.auth;
export const selectTokenAuth = (state: RootState) => state.auth.token;
export const selectAccessRights = (state: RootState) => state.auth.accessRights;
export const selectRolesList = (state: RootState) => state.auth.rolesList;
export const selectRoleAddStatus = (state: RootState) => state.auth.roleAdd;
export const selectRoleEditStatus = (state: RootState) => state.auth.roleEdit;
export const selectRoleDeleteStatus = (state: RootState) => state.auth.roleDelete;
export const selectDatasetsList = (state: RootState) => state.auth.datasetsList;
export const selectDatasetAddStatus = (state: RootState) => state.auth.datasetAdd;
export const selectDatasetEditStatus = (state: RootState) => state.auth.datasetEdit;
export const selectDatasetDeleteStatus = (state: RootState) => state.auth.datasetDelete;
export const selectVersion = (state: RootState) => state.auth.version;

export default authSlice.reducer;
