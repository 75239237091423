import { Ref, forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { flexRender, getCoreRowModel, useReactTable, ColumnDef, getFilteredRowModel, getPaginationRowModel, SortingState, getSortedRowModel, Row } from '@tanstack/react-table';
import { useVirtual } from 'react-virtual';
import cn from 'classnames';
import { Autocomplete, Checkbox, FormControlLabel, Popper, TextField, createFilterOptions } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import useAccessRight from '../../../hooks/useAccessRight';
import useTranslate from '../../../hooks/useTranslate';
import { deleteClass, editCorpusDataCell, selectCorpus } from '../../../store/corpusSlice';
import { CORPUS } from '../../../constants/accessRights';
import { colorPrimary, colorSecondary } from '../../../constants/colors';
import Filter from './Filter/Filter';
import TableCheckbox from '../TableCheckbox/TableCheckbox';
import CustomFooter from './CustomFooter/CustomFooter';
import { CorpusRow, IFilterByClass } from '../../../types/tableTypes';
import { ICorpusProps } from './Corpus.props';
import styles from './Corpus.module.scss';

const Corpus = ({ setChangeFlg, serviceType, typeCorpusData }: ICorpusProps): JSX.Element => {
	const [data, setData] = useState<CorpusRow[]>([]); // корпус данных, преобразованный для работы в таблице
	const [sorting, setSorting] = useState<SortingState>([]); // сортированный корпус данных
	const [rowSelection, setRowSelection] = useState<Record<number, boolean>>({}); // список выделенных строк 
	const [showDoubles, setShowDoubles] = useState<boolean>(false); // флаг поиска дубликатов фраз
	const tableContainerRef = useRef<HTMLDivElement>(null); // ссылка на контейнер таблицы
	const tBodyRef = useRef<HTMLTableSectionElement>(null); // ссылка на body таблицы

	const dispatch = useAppDispatch();
	const corpus = useAppSelector(selectCorpus); // store - корпус

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// ставим слушатель на нажатие клавиш
	useEffect(() => {
		const keyDownHandler = (e: KeyboardEvent) => {
			if (e.code === 'PageDown') {
				e.preventDefault();
				table.getCanNextPage() && table.nextPage();
			} else if (e.code === 'PageUp') {
				e.preventDefault();
				table.getCanPreviousPage() && table.previousPage();
			}
		};

		document.addEventListener('keydown', keyDownHandler);

		// по уходу со страницы - убираем слушатель
		return () => {
			document.removeEventListener('keydown', keyDownHandler);
		};
	}, []);

	// следим за данными корпуса
	useEffect(() => {
		setData(corpus.data[typeCorpusData].map(row => {
			return {
				class: serviceType === 'smc' ? row[0] : row[0].slice(0, 1),
				phrase: row[1],
			};
		})); // преобразуем в формат таблицы
		table.resetRowSelection(); // очищаем выделенные строки
		showDoubles && searchDoublePhrase(); // если включен флаг поиска дубликатов - перезапускаем функцию поиска
	}, [corpus.data]);

	// следим за статусом получения информации корпуса
	useEffect(() => {
		table.resetColumnFilters(); // очищаем фильтры
		table.resetSorting(); // очищаем сортировку
		table.resetColumnSizing(); // обнуляем ширину столбцов
		table.initialState.pagination.pageIndex = 0; // переключаемся на первую страницу таблицы
		setShowDoubles(false); // выключаем флаг поиска дубликатов фраз
	}, [corpus.status]);

	// следим за флагом поиска дубликатов фраз
	useEffect(() => {
		showDoubles ? searchDoublePhrase() : table.resetColumnFilters(); // если включен флаг - ищем дубликаты фраз, иначе очищаем фильтры (т.к. дубликаты ищутся по методам фильтрации)
		table.resetRowSelection(); // очищаем выделенные строки
	}, [showDoubles]);

	const defaultColumn: Partial<ColumnDef<CorpusRow>> = {
		// Give our default column cell renderer editing superpowers!
		cell: ({ getValue, row, column: { id } }) => {
			/* eslint-disable */ // от безысходности
			const inputRef = useRef<HTMLInputElement>(null); // ссылка на input фразы
			const initialValue = getValue(); // начальное значение ячейки
			// We need to keep and update the state of the cell normally
			const [value, setValue] = useState(initialValue as string); // значение ячейки
			const [classNames, setClassNames] = useState<string[]>(initialValue as Array<string>); // список классов в select'е для мультиклассового корпуса
			const [classNamesStrike, setClassNamesStrike] = useState<string[]>([]); // список перечеркнутых классов для отображения в ячейке
			const [extendedCell, setExtendedCell] = useState<boolean>(false); // флаг расширенной ячейки

			// переопределили данные корпуса в ячейке по default'у
			const corpus = useAppSelector(selectCorpus); // store - корпус

			// If the initialValue is changed external, sync it up with our state
			useEffect(() => {
				Array.isArray(initialValue) ? setClassNames(initialValue) : setValue(initialValue as string);
			}, [initialValue]);

			// функция, определяющая отфильтрованные параметры, которые будут отображаться при поиске
			const filter = createFilterOptions<string>();

			// изменение классов
			const changeOfClass = (): void => {
				// для корпусов smc
				if (serviceType === 'smc') {
					// для классов если значение изменилось
					if (id === 'class' && (row.original.class.toString() !== classNames.toString() || classNamesStrike.length > 0)) {
						setClassNames(prev => prev.filter(classItem => !classNamesStrike.includes(classItem))); // убираем перечеркнутые классы
						dispatch(editCorpusDataCell({
							index: row.index,
							row: [classNames.filter(classItem => !classNamesStrike.includes(classItem)).sort(), row.original.phrase],
							type: typeCorpusData,
						})); // обновляем данные в store
						if (typeCorpusData === 'data') {
							// перебираем классы на удаление какие были в единственном числе
							classNamesStrike.forEach(className => {
								corpus.classAndCount[className] === 1 && dispatch(deleteClass(className)); // удаляем класс
							});
						}
						setChangeFlg(true); // ставим флаг о не сохраненных данных
					}
					setClassNamesStrike([]); // очищаем список перечеркнутых классов для отображения в ячейке
				} else {
					if (id === 'class' && row.original.class[0] !== value) {
						dispatch(editCorpusDataCell({
							index: row.index,
							row: [[value ? String(value) : translate('title_emptyInQuotes')], row.original.phrase],
							type: typeCorpusData,
						})); // обновляем данные в store
						value === '' && setValue(translate('title_emptyInQuotes'));
						setChangeFlg(true); // ставим флаг о не сохраненных данных
					}
				}
			};

			// изменение фразы/группы
			const changeOfPhraseOrGroup = (): void => {
				// для фразы/группы если значение изменилось
				if (id === 'phrase' && row.original.phrase !== value) {
					dispatch(editCorpusDataCell({
						index: row.index,
						row: [row.original.class, value ? String(value) : translate('title_emptyInQuotes')],
						type: typeCorpusData
					})); // обновляем данные в store
					value === '' && setValue(translate('corpusTable_emptyTtitle_emptyInQuotesitle'));
					setChangeFlg(true); // ставим флаг о не сохраненных данных
				}
			};

			// обработчик нажатия клавиши
			const keyDownHandler = (e: React.KeyboardEvent<HTMLDivElement | HTMLSelectElement>): void => {
				// если нажата клавиша Enter - уводим фокус с ячейки
				if (e.code === 'Enter') {
					id === 'phrase' && inputRef.current?.blur();
				}
				// если нажата клавиша Escape - возвращаем начальное состояние ячейки
				else if (e.code === 'Escape') {
					// if (id === 'class') {
					// 	Array.isArray(row.original.class) ? setClassNames(row.original.class) : setValue(row.original.class);
					// }
					id === 'phrase' && setValue(row.original.phrase);
					setTimeout(() => inputRef.current?.blur()); // уводим фокус с ячейки
				}
				// если нажата клавиша Ctrl для навигации
				else if (e.ctrlKey) {
					if (e.code === 'ArrowDown') {
						tBodyRef.current &&
							(Array.from(tBodyRef.current.children)
								.find(tr => tr.getAttribute('data-row-id') === String(+row.id + 1))?.children[id === 'class' ? (typeCorpusData === 'data' ? 1 : 2) : (typeCorpusData === 'data' ? 2 : 1)]?.children[0] as (HTMLInputElement | HTMLSelectElement))?.focus();
					} else if (e.code === 'ArrowUp') {
						tBodyRef.current &&
							(Array.from(tBodyRef.current.children)
								.find((tr, idx) => {
									// поднимаем скролл на высоту строки, т.к. строка подлезает под липкий header
									tr.getAttribute('data-row-id') === String(+row.id - 1) && (idx < 3) && tableContainerRef.current?.scrollBy(0, -18);
									return tr.getAttribute('data-row-id') === String(+row.id - 1);
								})?.children[id === 'class' ? (typeCorpusData === 'data' ? 1 : 2) : (typeCorpusData === 'data' ? 2 : 1)]?.children[0] as (HTMLInputElement | HTMLSelectElement))?.focus();
					} else if (e.code === 'ArrowLeft') {
						tBodyRef.current &&
							(Array.from(tBodyRef.current.children)
								.find(tr => tr.getAttribute('data-row-id') === row.id)?.children[1]?.children[0] as (HTMLInputElement | HTMLSelectElement))?.focus();
					} else if (e.code === 'ArrowRight') {
						tBodyRef.current &&
							(Array.from(tBodyRef.current.children)
								.find(tr => tr.getAttribute('data-row-id') === row.id)?.children[2]?.children[0] as (HTMLInputElement | HTMLSelectElement))?.focus();
					}
				}
			};

			return id === 'class' ?
				// ячейка для классов
				<>
					{!extendedCell ?
						// поле для отображения
						<input
							className={styles.cellInput}
							value={classNames.join(', ')}
							onChange={e => setClassNames(prev => [...prev, e.target.value])} // фикция
							onFocus={() => setExtendedCell(true)}
							disabled={!(isAccess(CORPUS.SAVE))}
						/>
						:
						// поле для манипуляций
						<Autocomplete
							multiple={serviceType === 'smc'}
							disableCloseOnSelect={serviceType === 'smc'}
							options={corpus.classes}
							freeSolo={serviceType === 'smc' && typeCorpusData === 'data'}
							noOptionsText={<div className={styles.blockNoOptions}>{translate('selectItem_notFound')}</div>}
							autoHighlight
							openOnFocus
							filterOptions={(options, state) => {
								const filtered = filter(options, state);
								if (state.inputValue.length > 0 && options.findIndex(className => className === state.inputValue) === -1 && typeCorpusData !== 'groups') filtered.push(state.inputValue);
								return filtered;
								// фильтрация по введенному значению в поле и сортировкой выбранных классов
								// return options
								// 	.filter(classItem => classItem.includes(state.inputValue))
								// 	.sort((a, b) => {
								// 		if (classNames.includes(a) && !classNames.includes(b)) return -1;
								// 		else if (!classNames.includes(a) && classNames.includes(b)) return 1;
								// 		else if ((classNames.includes(a) && classNames.includes(b) || !classNames.includes(a) && !classNames.includes(b)) && a > b) return 1;
								// 		else if ((classNames.includes(a) && classNames.includes(b) || !classNames.includes(a) && !classNames.includes(b)) && a < b) return -1;
								// 		else return 0;
								// 	});
							}}
							filterSelectedOptions={serviceType === 'smc'}
							value={serviceType === 'smc' ? classNames : String(value)}
							onKeyDown={(e) => keyDownHandler(e)}
							onChange={(_, value) => {
								if (Array.isArray(value)) {
									setClassNames(value.map(className => className.replace(/,/g, '')));
								} else {
									setValue(value === null ? '' : value);
								}
							}}
							onBlur={() => { setExtendedCell(false); changeOfClass(); }}
							renderInput={(params) =>
								<TextField
									autoFocus
									{...params}
									variant="outlined"
									multiline
									maxRows={5}
								/>
							}
							sx={{
								".MuiInputBase-root": { minHeight: 17, fontSize: 12, color: colorPrimary },
								".MuiAutocomplete-endAdornment": { display: 'none' },
								".MuiOutlinedInput-root": { padding: '0 0 0 3px', paddingRight: "5px!important" },
								".MuiAutocomplete-input.MuiAutocomplete-input": { padding: 0, color: colorSecondary },
								".Mui-focused .MuiOutlinedInput-notchedOutline": { border: `1px solid ${colorPrimary}!important`, borderRadius: '3px', backgroundColor: 'rgba(0, 0, 0, 0.07)' },
								overflow: 'hidden',
							}}
							getOptionLabel={option => option}
							renderOption={(props, option, _state, ownerState) => {
								const match = ownerState.options.filter(classItem => classItem === option);
								return (
									<li {...props} style={{ padding: '0 5px', textAlign: 'left', fontSize: 11, color: colorPrimary }}>
										{match.length === 0 ?
											<>{translate('selectItem_addNewClass')} "{option}"</>
											:
											<>{option}</>
										}
									</li>
								);
							}}
							renderTags={(value: readonly string[], getTagProps) =>
								value.map((option: string, index: number) => {
									return (
										<span {...getTagProps({ index })} className={cn(styles.tag, {
											[styles.tagStrike]: classNamesStrike.includes(option),
										})} onClick={() => {
											setClassNamesStrike(prev => {
												if (prev.includes(option)) {
													return prev.filter(classItem => classItem !== option);
												} else {
													return [...prev, option];
												}
											});
										}}>
											{option}{(value.length - 1) !== index && ','}
										</span>
									)
								})
							}
							PopperComponent={(props) =>
								<Popper {...props} placement={'top'} sx={{ ".MuiAutocomplete-listbox": { maxHeight: 130 }, boxShadow: `0 0 5px 1px ${colorPrimary}`, borderRadius: 3 }}>
									{props.children}
								</Popper>
							}
							ListboxComponent={forwardRef((props, ref: Ref<HTMLUListElement>) =>
								<ul {...props} ref={ref}>
									{props.children}
									<div className={styles.blockFillGradientTop} />
									<div className={styles.blockFillGradientBottom} />
								</ul>
							)}
						/>
					}
				</>
				:
				// ячейка для фраз/групп
				<>
					{!extendedCell ?
						// поле для отображения
						<input
							className={styles.cellInput}
							value={String(value)}
							onChange={e => setValue(e.target.value)}
							onFocus={() => setExtendedCell(true)}
							disabled={!(isAccess(CORPUS.SAVE))}
						/>
						:
						// поле для манипуляций с группами
						typeCorpusData === 'groups' ?
							<Autocomplete
								options={corpus.groups}
								freeSolo
								autoHighlight
								openOnFocus
								filterOptions={(options, state) => {
									const filtered = filter(options, state);
									if (state.inputValue.length > 0 && options.findIndex(groupName => groupName === state.inputValue) === -1) filtered.push(state.inputValue);
									return filtered;
								}}
								filterSelectedOptions
								value={value}
								onKeyDown={(e) => keyDownHandler(e)}
								onChange={(_, value) => setValue(value === null ? '' : value)}
								onBlur={() => { setExtendedCell(false); changeOfPhraseOrGroup(); }}
								renderInput={(params) =>
									<TextField
										autoFocus
										{...params}
										variant="outlined"
										multiline
										maxRows={5}
									/>
								}
								sx={{
									".MuiInputBase-root": { minHeight: 17, fontSize: 12, color: colorPrimary },
									".MuiAutocomplete-endAdornment": { display: 'none' },
									".MuiOutlinedInput-root": { padding: '0 0 0 3px', paddingRight: "5px!important" },
									".MuiAutocomplete-input.MuiAutocomplete-input": { padding: 0, color: colorSecondary },
									".Mui-focused .MuiOutlinedInput-notchedOutline": { border: `1px solid ${colorPrimary}!important`, borderRadius: '3px', backgroundColor: 'rgba(0, 0, 0, 0.07)' },
									overflow: 'hidden',
								}}
								getOptionLabel={option => option}
								renderOption={(props, option, _state, ownerState) => {
									const match = ownerState.options.filter(groupItem => groupItem === option);
									return (
										<li {...props} style={{ padding: '0 5px', textAlign: 'left', fontSize: 11, color: colorPrimary }}>
											{match.length === 0 ?
												<>{translate('selectItem_addNewGroup')} "{option}"</>
												:
												<>{option}</>
											}
										</li>
									);
								}}
								PopperComponent={(props) =>
									<Popper {...props} placement={'top'} sx={{ ".MuiAutocomplete-listbox": { maxHeight: 130 }, boxShadow: `0 0 5px 1px ${colorPrimary}`, borderRadius: 3 }}>
										{props.children}
									</Popper>
								}
								ListboxComponent={forwardRef((props, ref: Ref<HTMLUListElement>) =>
									<ul {...props} ref={ref}>
										{props.children}
										<div className={styles.blockFillGradientTop} />
										<div className={styles.blockFillGradientBottom} />
									</ul>
								)}
							/>
							:
							// поле для манипуляций с фразами
							<TextField
								inputRef={inputRef}
								autoFocus
								multiline
								maxRows={5}
								size="small"
								variant="outlined"
								value={value}
								onChange={e => setValue(e.target.value)}
								onBlur={() => { setExtendedCell(false); changeOfPhraseOrGroup(); }}
								onKeyDown={(e) => keyDownHandler(e)}
								InputProps={{
									onFocus: (e) => e.target.setSelectionRange(e.target.value.length, e.target.value.length), // курсор в конец строки
									style: {
										fontSize: 11,
										color: colorPrimary,
									},
								}}
								sx={{
									width: '100%',
									".MuiOutlinedInput-root": { padding: '0 0 0 3px' },
									".Mui-focused .MuiOutlinedInput-notchedOutline": { border: `1px solid ${colorPrimary}!important`, borderRadius: '3px', backgroundColor: 'rgba(0, 0, 0, 0.07)' },
								}}
							/>
					}
				</>
				;
		},
	};

	const columns = useMemo<ColumnDef<CorpusRow, string>[]>(() => [
		{
			id: 'select',
			header: ({ table }) => (
				<TableCheckbox
					{...{
						checked: table.getIsAllRowsSelected(),
						indeterminate: table.getIsSomeRowsSelected(),
						onChange: table.getToggleAllRowsSelectedHandler(),
						disabled: !(isAccess(CORPUS.SAVE)),
						tabIndex: -1,
						className: styles.checkbox,
					}}
				/>
			),
			cell: ({ row }) => (
				<TableCheckbox
					{...{
						checked: row.getIsSelected(),
						disabled: !row.getCanSelect(),
						indeterminate: row.getIsSomeSelected(),
						onChange: row.getToggleSelectedHandler(),
						tabIndex: -1,
						className: styles.checkbox,
					}}
				/>
			),
			minSize: 20,
			maxSize: 20,
			size: 20,
		},
		{
			id: typeCorpusData === 'data' ? 'class' : 'phrase',
			header: translate(typeCorpusData === 'data' ? 'columnTitle_classes' : 'columnTitle_group'),
			accessorKey: typeCorpusData === 'data' ? 'class' : 'phrase',
			size: 200,
			filterFn: 'myCustomFilter',
		},
		{
			id: typeCorpusData === 'data' ? 'phrase' : 'class',
			header: translate(typeCorpusData === 'data' ? 'columnTitle_phrase' : 'columnTitle_classes'),
			accessorKey: typeCorpusData === 'data' ? 'phrase' : 'class',
			size: 450,
			filterFn: 'myCustomFilter',
		}
	], []);

	const table = useReactTable({
		data,
		columns,
		initialState: {
			pagination: {
				pageIndex: 0,
				pageSize: 100,
			}
		},
		state: {
			rowSelection,
			sorting,
		},
		filterFns: {
			// кастомный фильтр
			myCustomFilter: (rows, columnId, filterValue: string | string[] | IFilterByClass) => {
				// если для фильтрации используется массив (поиск дубликатов фраз/меток)
				if (Array.isArray(filterValue)) {
					// для второго столбца "Фраза" в данных
					if (columnId === 'phrase' && typeCorpusData === 'data') return filterValue.includes(rows.original.phrase);
					// для второго столбца "Классы" в группах
					if (columnId === 'phrase' && typeCorpusData === 'groups') return filterValue.includes(rows.original.class.toString());
				}
				// если для фильтрации используется объект (для столбца классов)
				else if (typeof filterValue === 'object') {
					// для столбца "Классы"
					if (columnId === 'class' && filterValue.data.length > 0) {
						// для поиска "ИЛИ"
						if (filterValue.type === 'or') {
							if (filterValue.data.find(className => rows.original.class.includes(className))) return true;
							else return false;
						}
						// для поиска "И"
						else if (filterValue.type === 'and') {
							if (filterValue.data.filter(className => rows.original.class.includes(className)).length === filterValue.data.length) return true;
							else return false;
						}
						// для поиска "КРОМЕ"
						else if (filterValue.type === 'except') {
							if (filterValue.data.find(className => rows.original.class.includes(className))) return false;
							else return true;
						}
					}
				}
				// иначе обычный поиск по строке
				else {
					// if (columnId === 'class') return rows.original.class.toLowerCase().includes(filterValue.toLowerCase());
					if (columnId === 'phrase') return rows.original.phrase.toLowerCase().includes(filterValue.toLowerCase());
				}
			},
		},
		defaultColumn, // для редактирования
		enableRowSelection: isAccess(CORPUS.SAVE), // checkbox
		enableColumnResizing: true, // изменение ширины столбца
		onRowSelectionChange: setRowSelection, // checkbox
		onSortingChange: setSorting, // для сортировки
		getSortedRowModel: getSortedRowModel(), // для сортировки
		getCoreRowModel: getCoreRowModel(),
		getFilteredRowModel: getFilteredRowModel(), // для фильтрации
		getPaginationRowModel: getPaginationRowModel(), // для нумерации страниц
		columnResizeMode: 'onChange', // изменение ширины столбца в runtime
		// Provide our updateData function to our table meta
		// meta: {
		// 	updateData: (rowIndex, columnId, value) => {
		// 		setData(old =>
		// 			old.map((row, index) => {
		// 				if (index === rowIndex) {
		// 					return {
		// 						...old[rowIndex]!,
		// 						[columnId]: value,
		// 					};
		// 				}
		// 				return row;
		// 			})
		// 		);
		// 	},
		// },
	});

	// для разового вычисления высоты строки
	const estimateSize = useCallback(() => 18, []);
	// виртуализация строк
	const { rows } = table.getRowModel();
	const rowVirtualizer = useVirtual({ parentRef: tableContainerRef, size: rows.length, estimateSize });
	const { virtualItems: virtualRows, totalSize } = rowVirtualizer;
	const paddingTop = virtualRows?.length > 0 ? virtualRows?.[0]?.start || 0 : 0;
	const paddingBottom = virtualRows?.length > 0 ? totalSize - (virtualRows?.[virtualRows?.length - 1]?.end || 0) : 0;

	// функция поиска дубликатов фраз в данных / меток в группах 
	const searchDoublePhrase = (): void => {
		table.resetColumnFilters(); // очищаем фильтры
		const countItems: Record<string, number> = {}; // промежуточный результат

		// если поиск в данных по фразе
		if (typeCorpusData === 'data') {
			for (const row of corpus.data.data) {
				countItems[row[1]] = countItems[row[1]] ? countItems[row[1]] + 1 : 1; // если элемент уже был, то прибавляем 1, если нет - устанавливаем 1
			}
			const duplicates = Object.keys(countItems).filter((item) => countItems[item] > 1); // обрабатываем ключи объекта, отфильтровываем все, что меньше 1
			// аналог - фильтруем значения, индекс которых не равен индексу, который вернул метод indexOf().
			// const duplicates = corpus.data.data.filter((row, index, array) => {
			// 	return array.indexOf(row) !== index;
			// }).map(row => row[1]);
			table.getHeaderGroups()[0].headers[2].column.setFilterValue(duplicates); // устанавливаем фильтрацию в столбце "Фраза"
		}
		// если поиск в группах по меткам
		else if (typeCorpusData === 'groups') {
			for (const row of corpus.data.groups) {
				countItems[row[0].toString()] = countItems[row[0].toString()] ? countItems[row[0].toString()] + 1 : 1; // если элемент уже был, то прибавляем 1, если нет - устанавливаем 1
			}
			const duplicates = Object.keys(countItems).filter((item) => countItems[item] > 1); // обрабатываем ключи объекта, отфильтровываем все, что меньше 1
			table.getHeaderGroups()[0].headers[1].column.setFilterValue(duplicates); // устанавливаем фильтрацию в столбце "Классы"
		}
	};

	return (
		<div className={styles.wrapper}>
			<div className={styles.wrapperDuplicatePhrases}>
				<FormControlLabel labelPlacement="start" sx={{ marginRight: '8px', '.MuiTypography-root': { fontSize: 13 } }} control={
					<Checkbox
						checked={showDoubles}
						onChange={e => setShowDoubles(e.target.checked)}
						sx={{ '& .MuiSvgIcon-root': { fontSize: 16 } }}
					/>
				} label={translate(typeCorpusData === 'data' ? 'checkbox_showDuplicatePhrases' : 'checkbox_showDuplicateClasses')} />
			</div>
			<div className={styles.container} ref={tableContainerRef}>
				<table className={styles.table} style={{ width: table.getCenterTotalSize() }}>
					<thead className={cn(styles.thead, {
						[styles.theadBoxShadow]: tableContainerRef.current && tableContainerRef.current.scrollTop > 0, // тень из под шапки и размытие под ним, когда скроллим таблицу
					})}>
						{table.getHeaderGroups().map(headerGroup => (
							<tr className={styles.tr} key={headerGroup.id}>
								{headerGroup.headers.map(header => (
									<th className={styles.th} {...{
										key: header.id,
										colSpan: header.colSpan,
										style: { width: header.getSize() }
									}}>
										{!header.isPlaceholder &&
											<>
												<div onClick={header.column.getToggleSortingHandler()} className={header.id === 'select' ? styles.thTitleSelect : styles.thTitle}>
													{flexRender(header.column.columnDef.header, header.getContext())}
													{{ asc: <FontAwesomeIcon icon={faArrowUp} />, desc: <FontAwesomeIcon icon={faArrowDown} /> }[header.column.getIsSorted() as string] ?? null}
												</div>
												{header.column.getCanFilter() &&
													<Filter table={table} column={header.column} rowSelection={rowSelection} showDoubles={showDoubles} setShowDoubles={setShowDoubles} serviceType={serviceType} />
												}
											</>
										}
										{(typeCorpusData === 'data' && header.id === 'class' || typeCorpusData === 'groups' && header.id === 'phrase') &&
											<div {...{
												onMouseDown: header.getResizeHandler(),
												onTouchStart: header.getResizeHandler(),
												className: styles.resizerWrapper,
												// className: `${styles.resizer} ${header.column.getIsResizing() ? styles.isResizing : ""}`,
												// style: {
												// 	transform:
												// 		columnResizeMode === "onEnd" &&
												// 			header.column.getIsResizing()
												// 			? `translateX(${table.getState().columnSizingInfo.deltaOffset
												// 			}px)`
												// 			: ""
												// }
											}}>
												<div className={styles.resizerDelimiter}></div>
											</div>
										}
									</th>
								))}
							</tr>
						))}
					</thead>
					<tbody className={styles.tbody} ref={tBodyRef}>

						{paddingTop > 0 && (
							<tr>
								<td style={{ height: paddingTop }} />
							</tr>
						)}

						{virtualRows.map(virtualRow => {
							const row = rows[virtualRow.index] as Row<CorpusRow>
							return (
								<tr className={cn(styles.tr, { [styles.trSelected]: table.getSelectedRowModel().flatRows.includes(row) })} key={row.id} data-row-id={row.id}>
									{row.getVisibleCells().map(cell => (
										<td className={styles.td} title={cell.getValue() !== undefined ? String(cell.getValue()) : ''} {...{
											key: cell.id,
											style: {
												width: cell.column.getSize()
											},
										}}>
											{flexRender(cell.column.columnDef.cell, cell.getContext())}
										</td>
									))}
								</tr>
							)
						})}

						{paddingBottom > 0 && (
							<tr>
								<td style={{ height: paddingBottom }} />
							</tr>
						)}

					</tbody>
				</table>
				{table.getRowModel().rows.length === 0 && <div className={styles.notFound}>{translate('title_notFound')}</div>
				}
			</div >
			<CustomFooter setChangeFlg={setChangeFlg} table={table} rowSelection={rowSelection} tableContainerRef={tableContainerRef} serviceType={serviceType} typeCorpusData={typeCorpusData} />
		</div>
	);
};

export default Corpus;
