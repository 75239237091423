import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Fade, Slide } from '@mui/material';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { useCookies } from 'react-cookie';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { addCorpusName, clearCorpus, clearImportResponseCorpus, clearPutResponseCorpus, getCorpus, getCorpusList, putCorpus, selectCorpus, selectCorpusDeleteStatus, selectCorpusImportStatus, selectCorpusList, selectCorpusRenameStatus } from '../../../store/corpusSlice';
import useAccessRight from '../../../hooks/useAccessRight';
import useTranslate from '../../../hooks/useTranslate';
import { routeTranslation } from '../../../helpers/routeTranslation';
import { findServiceRightByType } from '../../../helpers/findServiceRightByType';
import { CORPUS } from '../../../constants/accessRights';
import { MARKS_LINK } from '../../../constants/routes';
import { RequestStatus, ResponseStatus } from '../../../types/statusTypes';
import AlertDialog from '../../AlertDialog/AlertDialog';
import HideSidebar from '../HideSidebar/HideSidebar';
import FormImportCorpus from '../../Forms/FormImportCorpus/FormImportCorpus';
import ScreenLock from '../../ScreenLock/ScreenLock';
import ProgressCircle from '../../ProgressCircle/ProgressCircle';
import Notification from '../../Notification/Notification';
import { INavBarProps } from './CorpusNavBar.props';
import styles from './CorpusNavBar.module.scss';

const CorpusNavBar = ({ changeFlg, setChangeFlg, serviceType, showSidebar, setShowSidebar, setShowPage }: INavBarProps) => {
	const [corpusList, setCorpusList] = useState<string[]>([]); // список корпусов
	const [activeCorpus, setActiveCorpus] = useState<string>(); // активный корпус
	const [showModalImport, setShowModalImport] = useState<boolean>(false); // показ формы импортирования корпуса
	const [showScreenLock, setShowScreenLock] = useState<boolean>(false); // показ экрана блокировки
	const [showNotification, setShowNotification] = useState<boolean>(false); // показ уведомлений
	const [showAlertDialog, setShowAlertDialog] = useState<boolean>(false); // показ диалогового окна о не сохраненных данных при переходе на другую страницу
	const [promiseGetCorpus, setPromiseGetCorpus] = useState<Promise<any> | null>(null); // промис для отмены запроса получения корпуса
	const [link, setLink] = useState<string>(''); // ссылка для переходда на другую страницу при не сохраненных данных

	const dispatch = useAppDispatch();
	const allCorpuses = useAppSelector(selectCorpusList); // store - список корпусов
	const corpus = useAppSelector(selectCorpus); // store - корпус
	const deleteStatus = useAppSelector(selectCorpusDeleteStatus); // store - статус об удалении корпуса
	const renameStatus = useAppSelector(selectCorpusRenameStatus); // store - статус о переименовании корпуса
	const importStatus = useAppSelector(selectCorpusImportStatus); // store - статус об импортировании корпуса

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста
	const navigate = useNavigate(); // hook для навигации
	const [cookies, setCookie] = useCookies([`${serviceType}CorpusName`]); // hook для работы с cookie
	const listRef = useRef<HTMLUListElement | null>(null); // ссылка на список корпусов

	// следим за статусом удаления корпуса
	useEffect(() => {
		if (deleteStatus.status === RequestStatus.LOADING) setShowScreenLock(true); // если идет удаление корпуса - включаем экран блокировки
		else setShowScreenLock(false); // иначе выключаем

		// если удаление прошло успешно
		if (deleteStatus.error === ResponseStatus.SUCCESS && deleteStatus.status === RequestStatus.IDLE && deleteStatus.message === 'success') {
			dispatch(addCorpusName(null)); // очищаем из store имя активного корпуса
			dispatch(getCorpusList({ serviceType })); // получаем заново список корпусов
		}
	}, [deleteStatus]);

	// следим за списком корпусов в store
	useEffect(() => {
		// если список не пустой и не в процессе загрузки - записываем в state
		(allCorpuses.data.length > 0 && allCorpuses.status !== RequestStatus.LOADING) && setCorpusList(allCorpuses.data);
		allCorpuses.data.length === 0 && setCorpusList([]); // если список пустой - обнуляем список в state
	}, [allCorpuses]);

	// следим за списком корпусов в state
	useEffect(() => {
		// только если список не пустой
		if (corpusList.length > 0) {
			// и если удаление прошло успешно или активного имени корпуса еще нет
			if (deleteStatus.message === 'success' || corpus.corpusName === null) {
				// если есть запись в cookie и список корпусов содержит эту запись, то передаем ее в обработчик выбора активного корпуса, иначе выбираем первый корпус из списка
				corpusList.includes(cookies[`${serviceType}CorpusName`]) && cookies[`${serviceType}CorpusName`] ?
					corpusHandler(cookies[`${serviceType}CorpusName`])
					:
					corpusHandler(corpusList[0]);
				// иначе если было переименование корпуса
			} else if (renameStatus.message !== '') {
				corpusHandler(corpus.corpusName); // передаем в обработчик выбора активного корпуса - обновленное имя корпуса из store
			} else {
				importStatus.newName && corpusHandler(importStatus.newName); // передаем в обработчик выбора активного корпуса - имя импортируемого корпуса из store
				dispatch(clearImportResponseCorpus()); // очищаем статус импортирования корпуса
				dispatch(clearPutResponseCorpus()); // очищаем статус создания корпуса
			}
		}
	}, [corpusList]);

	// следим за статусом загрузки корпуса
	useEffect(() => {
		// если закончилась загрузка корпуса - сбрасываем промис для отмены запроса
		corpus.status !== RequestStatus.LOADING && setPromiseGetCorpus(null);
		// если ошибка и нет ответа от сервера (случай с отменой запроса) - запрашиваем
		corpus.status === RequestStatus.FAILED && corpus.error === ResponseStatus.SUCCESS && corpus.message === '' && corpus.corpusName && corpusHandler(corpus.corpusName);
	}, [corpus.status]);

	// следим за активным корпусом
	useEffect(() => {
		activeCorpus && listRef.current?.children[allCorpuses.data.indexOf(activeCorpus)]?.scrollIntoView({ block: "center" }); // показ активного корпуса в центре списка с имеющейся полосой прокрутки
	}, [activeCorpus]);

	// обработчик выбора активного корпуса
	const corpusHandler = (corpusName: string): void => {
		// если сохранен промис для отмены запроса
		if (promiseGetCorpus) {
			// @ts-ignore
			promiseGetCorpus.abort(); // прерываем запрос
			setPromiseGetCorpus(null); // обнуляем промис
			renameStatus.message === '' && dispatch(addCorpusName(corpusName)); // если не было переименований - добавляем имя корпуса в store
			return;
		}
		renameStatus.message === '' && dispatch(clearCorpus()); // если не было переименований - очищаем все данные о корпуса
		setChangeFlg(false); // сбрасываем флаг о не сохраненных данных
		setActiveCorpus(corpusName); // устанавливаем имя активного корпуса
		renameStatus.message === '' && dispatch(addCorpusName(corpusName)); // если не было переименований - добавляем имя корпуса в store
		// если не было переименований
		if (renameStatus.message === '') {
			const promise = dispatch(getCorpus({ corpusName, serviceType })); // получаем корпус
			setPromiseGetCorpus(promise); // устанавливаем промис для случая с отменой запроса
		}
		setCookie(`${serviceType}CorpusName`, corpusName, { path: '/', maxAge: 2_592_000 }); // устанавливаем cookie на месяц
	};

	// обработчик сохранения корпуса при переходе на страницу моделей
	const goToModelsWithSaveHandler = (): void => {
		corpus.corpusName && dispatch(putCorpus({ corpusName: corpus.corpusName, text: corpus.data, serviceType })); // сохранение корпуса
		delayToHidePage(link);
	};

	// обработчик отказа сохранения корпуса при переходе на страницу моделей
	const goToModelsWithoutSaveHandler = (): void => {
		delayToHidePage(link);
	};

	// задержка для перехода на другую страницу
	const delayToHidePage = (link: string): void => {
		setShowAlertDialog(false); // закрываем диалоговое окно
		setShowPage(false); // уводим страницу в фон
		setTimeout(() => {
			navigate(link);
		}, 500);
	};

	return (
		<Slide direction="right" in={showSidebar} timeout={800} style={{ visibility: 'visible', zIndex: 1 }}>
			<div>
				<div className={styles.sidebar}>
					<HideSidebar showSidebar={showSidebar} setShowSidebar={setShowSidebar} />

					<div className={styles.navbar}>
						{allCorpuses.status === RequestStatus.FAILED && <div className={styles.navbarFailedText}>
							<span>{translate('title_loadFailed')}</span>
							<span className={styles.navbarFailedUpdate} onClick={() => dispatch(getCorpusList({ serviceType }))}>{translate('link_update')}</span>
						</div>}

						{allCorpuses.status === RequestStatus.IDLE && allCorpuses.data.length === 0 &&
							<div className={styles.navbarNoCorpuses}>{translate('title_emptyList')}</div>
						}

						{allCorpuses.status === RequestStatus.LOADING && <div className={cn(styles.navbarLoading, {
							[styles.navbarLoadingShort]: isAccess(findServiceRightByType(serviceType)),
						})}><ProgressCircle title={translate('spinnerTitle_loading')} /></div>}

						<ul className={styles.navbarCorpusList} ref={listRef}>
							{corpusList.map((corpusName) => (
								<li className={styles.navbarCorpus} key={corpusName}>
									<Fade in={allCorpuses.status !== RequestStatus.LOADING || allCorpuses.data.length > 0} timeout={500}>
										<div
											className={cn({
												[styles.navbarCorpusLink]: activeCorpus !== corpusName,
												[styles.navbarCorpusLinkActive]: activeCorpus === corpusName
											})}
											onClick={() => corpusName !== activeCorpus && corpusHandler(corpusName)}
										>
											<div className={styles.navbarCorpusLinkLeftBlock} title={corpusName}>
												{corpusName}
											</div>
											<FontAwesomeIcon icon={faAngleRight} />
										</div>
									</Fade>
								</li>
							))}
						</ul>

						<div className={styles.functionButtons}>
							<Fade in={isAccess(CORPUS.IMPORT) && (allCorpuses.status === RequestStatus.IDLE || allCorpuses.data.length > 0)} timeout={500}>
								<div className={styles.functionButtonsAddModel} onClick={() => setShowModalImport(true)}>
									{translate('link_addNewCorpus')}
								</div>
							</Fade>
							{isAccess(findServiceRightByType(serviceType)) &&
								<div className={styles.functionButtonsTabs}>
									<div
										className={cn(styles.functionButtonsTab, styles.functionButtonsTabNonActive)}
										onClick={() => {
											if (changeFlg) {
												setShowAlertDialog(true);
												setLink(routeTranslation(serviceType));
											} else delayToHidePage(routeTranslation(serviceType));
										}}>
										{translate('tab_models')}
									</div>
									<div className={styles.functionButtonsTab}>{translate('tab_corpus')}</div>
									{serviceType === 'smc' &&
										<div
											className={cn(styles.functionButtonsTab, styles.functionButtonsTabNonActive)}
											onClick={() => {
												if (changeFlg) {
													setShowAlertDialog(true);
													setLink(`${routeTranslation(serviceType)}/${MARKS_LINK}`);
												} else delayToHidePage(`${routeTranslation(serviceType)}/${MARKS_LINK}`);
											}}>
											{translate('tab_marks')}
										</div>
									}
								</div>
							}
						</div>
					</div>

					{showModalImport && <FormImportCorpus showModal={showModalImport} setShowModal={setShowModalImport} create serviceType={serviceType} setShowNotification={setShowNotification} />}
				</div>

				{showScreenLock && <ScreenLock title={translate('spinnerTitle_deletion')} />}
				{showNotification && <Notification showNotification={showNotification} setShowNotification={setShowNotification} selectDataResponse={selectCorpusImportStatus} clearDataResponse={clearImportResponseCorpus} titleFailed='noticeAddition_failed' titleSuccess='noticeAddition_success' />}
				<AlertDialog showAlertDialog={showAlertDialog} setShowAlertDialog={setShowAlertDialog} submitHandler={goToModelsWithSaveHandler} closeHandler={goToModelsWithoutSaveHandler} title='dialog_saveCorpus' description='dialog_saveCorpusBeforeLeavingConfirm' name={String(corpus.corpusName)} />
			</div>
		</Slide>
	);
};

export default CorpusNavBar;
