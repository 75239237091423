import { useEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Fade, Slide } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import cn from 'classnames';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { addActiveDashboardLink, getDashboardsList, selectDashboardsList } from '../../../store/resSlice';
import useAccessRight from '../../../hooks/useAccessRight';
import useTranslate from '../../../hooks/useTranslate';
import { RES } from '../../../constants/accessRights';
import { DASHBOARD_NAME } from '../../../constants/cookieNames';
import { RequestStatus, ResponseStatus } from '../../../types/statusTypes';
import ProgressCircle from '../../ProgressCircle/ProgressCircle';
// import { IReportNavbarProps } from './ReportNavbar.props';
import styles from './ReportNavbar.module.scss';

const ReportNavbar = (): JSX.Element => {
	const [activeDashboardName, setActiveDashboardName] = useState<string>(); // название активного отчета
	const listRef = useRef<HTMLUListElement | null>(null); // ссылка на список отчетов

	const dispatch = useAppDispatch();
	const dashboardsList = useAppSelector(selectDashboardsList); // store - список отчетов

	const [cookies, setCookie] = useCookies([DASHBOARD_NAME]); // hook для работы с cookie
	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// следим за списком отчетов
	useEffect(() => {
		// только если список отчетов не пустой
		if (Object.keys(dashboardsList.data).length > 0) {
			// если есть запись в cookie и список отчетов содержит эту запись, то передаем ее в обработчик выбора активного отчета, иначе выбираем первый отчет из списка
			const foundDashboardName = Object.keys(dashboardsList.data).find(dashboardName => dashboardName === cookies.dashboardName);
			dashboardHandler(foundDashboardName || Object.keys(dashboardsList.data)[0]);
		}
	}, [dashboardsList.data]);

	// следим за активным отчетом
	useEffect(() => {
		listRef.current?.children[Object.keys(dashboardsList.data).findIndex(dashboardName => dashboardName === activeDashboardName)]?.scrollIntoView({ block: "center" }); // показ активного отчета в центре списка с имеющейся полосой прокрутки
	}, [activeDashboardName]);

	// обработчик выбора активного отчета
	const dashboardHandler = (dashboardName: string): void => {
		setActiveDashboardName(dashboardName); // устанавливаем название активного отчета
		dispatch(addActiveDashboardLink(dashboardsList.data[dashboardName])); // добавляем ссылку на активный отчет
		setCookie(DASHBOARD_NAME, dashboardName, { path: '/', maxAge: 2_592_000 }); // устанавливаем cookie на месяц
	};

	return (
		<Slide direction="right" in={true} mountOnEnter unmountOnExit timeout={800}>
			<div className={styles.sidebar}>
				<div className={styles.navbar}>
					{/* ошибка загрузки списка отчетов */}
					{(dashboardsList.status === RequestStatus.FAILED || dashboardsList.error === ResponseStatus.FAILED) &&
						<div className={styles.navbarFailedText}>
							<span>{translate(dashboardsList.message || 'title_loadFailed')}</span>
							{isAccess(RES.DASHBOARDS) &&
								<span className={styles.navbarFailedUpdate} onClick={() => dispatch(getDashboardsList())}>{translate('link_update')}</span>
							}
						</div>
					}

					{/* пустой список отчетов */}
					{dashboardsList.status === RequestStatus.IDLE && Object.keys(dashboardsList.data).length === 0 &&
						<div className={styles.navbarNoReports}>{translate('title_emptyList')}</div>
					}

					{/* загрузка списка отчетов */}
					{dashboardsList.status === RequestStatus.LOADING &&
						<div className={styles.navbarLoading}>
							<ProgressCircle title={translate('spinnerTitle_loading')} />
						</div>
					}

					{/* список отчетов */}
					<ul className={styles.navbarReportList} ref={listRef}>
						{Object.keys(dashboardsList.data).map((dashboardName) => (
							<li className={styles.navbarReport} key={dashboardName}>
								<Fade in={dashboardsList.status !== RequestStatus.LOADING || Object.keys(dashboardsList.data).length > 0} timeout={500}>
									<div
										className={cn({
											[styles.navbarReportLink]: activeDashboardName !== dashboardName,
											[styles.navbarReportLinkActive]: activeDashboardName === dashboardName,
										})}
										onClick={() => dashboardName !== activeDashboardName && dashboardHandler(dashboardName)}
									>
										<div className={styles.navbarReportLinkLeftBlock} title={dashboardName}>
											{dashboardName}
										</div>
										<FontAwesomeIcon icon={faAngleRight} />
									</div>
								</Fade>
							</li>
						))}
					</ul>

					{/* <div className={styles.functionButtons}>
						 добавление отчета 
						{isAccess(SES.ROBOT_ADD) && robotList.status === RequestStatus.IDLE &&
							<Fade in={true} timeout={500}>
								<div className={styles.functionButtonsAddReport} onClick={() => setShowModal(true)}>
									{translate('link_addRobot')}
								</div>
							</Fade>
						}
					</div> */}
				</div>

				{/* {showModal && <FormAddingRobot showModal={showModal} setShowModal={setShowModal} setShowNotification={setShowNotification} />} */}
				{/* {showNotification && <Notification showNotification={showNotification} setShowNotification={setShowNotification} selectDataResponse={selectAddingRobot} clearDataResponse={clearAddingRobot} titleFailed={translate('noticeAddition_failed')} titleSuccess={translate('noticeAddition_success')} />} */}
			</div>
		</Slide>
	);
};

export default ReportNavbar;
