import { Fade, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import useTranslate from '../../../hooks/useTranslate';
import { backgroundColor, colorPrimary, colorSecondary } from '../../../constants/colors';
import { RequestStatus } from '../../../types/statusTypes';
import { IErrorsTableProps } from './ErrorsTable.props';

const ErrorsTable = ({ errors }: IErrorsTableProps) => {
	const translate = useTranslate(); // hook для перевода текста

	return (
		<Fade in={errors.status !== RequestStatus.FAILED} timeout={500}>
			<TableContainer component={Paper} sx={{ minHeight: '45%', maxHeight: '100%', backgroundColor: backgroundColor, boxShadow: '0 0 8px #cacaca', borderRadius: '4px' }}>
				<Table stickyHeader aria-label="simple table" size="small">
					<TableHead>
						<TableRow>
							<TableCell sx={{ color: colorSecondary, fontSize: 13, backgroundColor: backgroundColor, width: '33%' }}>{translate('columnTitle_class')}</TableCell>
							<TableCell sx={{ color: colorSecondary, fontSize: 13, backgroundColor: backgroundColor, width: '33%' }}>{translate('columnTitle_defined')}</TableCell>
							<TableCell sx={{ color: colorSecondary, fontSize: 13, backgroundColor: backgroundColor, width: '33%' }}>{translate('columnTitle_phrase')}</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{errors.errors.map((row, idx) => (
							<TableRow key={idx} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
								<TableCell align="left" sx={{ color: colorPrimary, fontSize: 11, whiteSpace: 'pre-wrap' }}>{row[0]}</TableCell>
								<TableCell align="left" sx={{ color: colorPrimary, fontSize: 11, whiteSpace: 'pre-wrap' }}>{row[1]}</TableCell>
								<TableCell align="left" sx={{ color: colorPrimary, fontSize: 11, whiteSpace: 'pre-wrap' }}>{row[2]}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</Fade>
	);
};

export default ErrorsTable;
