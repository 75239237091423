import { useEffect, useState } from 'react';
import { FormControl, TextField } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { addSpeaker, editSpeaker, selectAddSpeaker, selectEditSpeaker, selectSpeakers } from '../../../store/sbsSlice';
import useTranslate from '../../../hooks/useTranslate';
import { RequestStatus } from '../../../types/statusTypes';
import ModalFormWindow from '../../../HOC/ModalFormWindow/ModalFormWindow';
import FormSendAudio from '../FormSendAudio/FormSendAudio';
import { IFormAddingSpeakerProps } from './FormAddingSpeaker.props';

const FormAddingSpeaker = ({ showModal, setShowModal, setShowNotification, isAddition = false }: IFormAddingSpeakerProps): JSX.Element => {
	const [speakerNameForAdd, setSpeakerNameForAdd] = useState<string>(''); // имя спикера для добавления

	const dispatch = useAppDispatch();
	const speakersList = useAppSelector(selectSpeakers); // store - список спикеров
	const addSpeakerStatus = useAppSelector(selectAddSpeaker); // store - статус добавления спикера
	const editSpeakerStatus = useAppSelector(selectEditSpeaker); // store - статус дополнения спикера

	const translate = useTranslate(); // hook для перевода текста

	// следим за статусом добавления/дополнения спикера
	useEffect(() => {
		// и при добавлении или дополнении
		if (addSpeakerStatus.status === RequestStatus.LOADING || (isAddition && editSpeakerStatus.status === RequestStatus.LOADING)) {
			setShowNotification(true); // включаем уведомление
			setShowModal(false); // закрываем форму
		}
	}, [addSpeakerStatus.status, editSpeakerStatus.status]);

	return (
		<ModalFormWindow
			showModal={showModal}
			setShowModal={setShowModal}
			headerTitle={isAddition ?
				`${translate('formHeader_additionSpeaker')} "${speakersList.activeSpeaker?.name}"`
				:
				'formHeader_addingSpeaker'}
		>
			<>
				{/* имя спикера */}
				{!isAddition &&
					<FormControl fullWidth margin='dense'>
						<TextField
							autoFocus
							required
							id="speakerNameForAdd"
							label={translate('input_name')}
							variant="outlined"
							value={speakerNameForAdd}
							onChange={(e) => setSpeakerNameForAdd(e.target.value)}
							InputProps={{
								style: {
									height: 33,
									fontSize: 13,
								},
							}}
							InputLabelProps={{
								style: {
									fontSize: 13,
								},
							}}
							sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
						/>
					</FormControl>
				}
				<FormSendAudio
					formFor={isAddition ? 'additionSpeaker' : 'addingSpeaker'}
					disableFile={isAddition ? false : speakerNameForAdd === ''}
					disableSubmit={isAddition ? false : speakerNameForAdd === ''}
					submitFunction={({ formData, text }) => {
						if (isAddition) speakersList.activeSpeaker?.id && dispatch(editSpeaker({ speakerId: speakersList.activeSpeaker?.id, formData, text: text || undefined })); // дополнение спикера
						else dispatch(addSpeaker({ speakerName: speakerNameForAdd, formData, text: text || undefined })); // добавление спикера
					}}
				/>
			</>
		</ModalFormWindow>
	);
};

export default FormAddingSpeaker;
