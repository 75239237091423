import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { Fade, Slide } from '@mui/material';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { clearRoleAdd, getRolesList, selectRoleAddStatus, selectRolesList } from '../../../store/authSlice';
import useAccessRight from '../../../hooks/useAccessRight';
import useTranslate from '../../../hooks/useTranslate';
import { USERS } from '../../../constants/routes';
import { ROLE, SERVICE } from '../../../constants/accessRights';
import { ROLE_ID } from '../../../constants/cookieNames';
import { RequestStatus } from '../../../types/statusTypes';
import FormAddingRole from '../../Forms/FormAddingRole/FormAddingRole';
import ProgressCircle from '../../ProgressCircle/ProgressCircle';
import Notification from '../../Notification/Notification';
import { IRoleNavbarProps } from './RoleNavbar.props';
import styles from './RoleNavbar.module.scss';

const RoleNavbar = ({ activeRole, setActiveRole, setChangeFlg, setShowPage }: IRoleNavbarProps): JSX.Element => {
	const [showModal, setShowModal] = useState<boolean>(false); // показ формы добавления роли
	const [showNotification, setShowNotification] = useState<boolean>(false); // показ уведомлений
	const listRef = useRef<HTMLUListElement | null>(null); // ссылка на список ролей

	const dispatch = useAppDispatch();
	const rolesList = useAppSelector(selectRolesList); // store - список ролей

	const navigate = useNavigate(); // hook для навигации
	const [cookies, setCookie] = useCookies([ROLE_ID]); // hook для работы с cookie
	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// следим за списком ролей
	useEffect(() => {
		// только если список ролей не пустой
		if (rolesList.data.length > 0) {
			// если есть запись в cookie и список ролей содержит эту запись, то передаем ее в обработчик выбора активной роли, иначе выбираем первую роль из списка ролей
			const foundIdxRole = rolesList.data.findIndex(roleItem => roleItem.id === cookies.roleId);
			cookies.roleId && foundIdxRole >= 0 ?
				roleHandler(cookies.roleId, foundIdxRole)
				:
				roleHandler(rolesList.data[0].id, 0);
		}
	}, [rolesList.data]);

	// следим за активной ролью
	useEffect(() => {
		listRef.current?.children[activeRole.idx]?.scrollIntoView({ block: "center" }); // показ активной роли в центре списка с имеющейся полосой прокрутки
	}, [activeRole.id]);

	// обработчик выбора активной роли
	const roleHandler = (id: string, idx: number): void => {
		setChangeFlg(false); // сбрасываем флаг о не сохраненных данных
		setActiveRole({ id, idx }); // устанавливаем активную роль
		setCookie(ROLE_ID, id, { path: '/', maxAge: 2_592_000 }); // устанавливаем cookie на месяц
	};

	// задержка для перехода на другую страницу
	const delayToHidePage = (): void => {
		setShowPage(false); // уводим страницу в фон
		setTimeout(() => {
			navigate(USERS);
		}, 500);
	};

	return (
		<Slide direction="right" in={true} mountOnEnter unmountOnExit timeout={800}>
			<div className={styles.sidebar}>

				<div className={styles.navbar}>
					{/* ошибка загрузки списка ролей */}
					{rolesList.status === RequestStatus.FAILED &&
						<div className={styles.navbarFailedText}>
							<span>{translate(rolesList.message || 'title_loadFailed')}</span>
							{isAccess(ROLE.LIST) &&
								<span className={styles.navbarFailedUpdate} onClick={() => dispatch(getRolesList())}>{translate('link_update')}</span>
							}
						</div>
					}

					{/* пустой список ролей */}
					{rolesList.status === RequestStatus.IDLE && rolesList.data.length === 0 &&
						<div className={styles.navbarNoRoles}>{translate('title_emptyList')}</div>
					}

					{/* загрузка списка ролей */}
					{rolesList.status === RequestStatus.LOADING &&
						<div className={styles.navbarLoading}>
							<ProgressCircle title={translate('spinnerTitle_loading')} />
						</div>
					}

					{/* список ролей */}
					<ul className={styles.navbarRoleList} ref={listRef}>
						{rolesList.data.map(({ id, name }, idx) => (
							<li className={styles.navbarRole} key={id}>
								<Fade in={true}>
									<div
										className={cn({
											[styles.navbarRoleLink]: activeRole.id !== id,
											[styles.navbarRoleLinkActive]: activeRole.id === id,
										})}
										onClick={() => id !== activeRole.id && roleHandler(id, idx)}
									>
										<div className={styles.navbarRoleLinkLeftBlock} title={name}>
											{name}
										</div>
										<FontAwesomeIcon icon={faAngleRight} />
									</div>
								</Fade>
							</li>
						))}
					</ul>

					<div className={styles.functionButtons}>
						{/* добавление роли */}
						{isAccess(ROLE.ADD) && rolesList.status === RequestStatus.IDLE &&
							<Fade in={true} timeout={500}>
								<div className={styles.functionButtonsAddRole} onClick={() => setShowModal(true)} >
									{translate('link_addRole')}
								</div>
							</Fade>
						}
						{/* табы */}
						{isAccess(SERVICE.AUTH) &&
							<div className={styles.functionButtonsTabs}>
								<div
									className={cn(styles.functionButtonsTab, styles.functionButtonsTabNonActive)}
									onClick={delayToHidePage}>
									{translate('tab_users')}
								</div>
								<div className={styles.functionButtonsTab}>{translate('tab_roles')}</div>
							</div>
						}
					</div>
				</div>

				{showModal && <FormAddingRole showModal={showModal} setShowModal={setShowModal} setShowNotification={setShowNotification} />}

				{showNotification &&
					<Notification
						showNotification={showNotification}
						setShowNotification={setShowNotification}
						selectDataResponse={selectRoleAddStatus}
						clearDataResponse={clearRoleAdd}
						titleFailed='noticeAddition_failed'
						titleSuccess='noticeAddition_success'
					/>
				}
			</div>
		</Slide>
	);
};

export default RoleNavbar;
