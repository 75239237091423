import { useEffect, useState } from 'react';
import { Button, Slide } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { clearErrors, getErrorsModel, selectActiveType, selectErrorsModel, selectModelName } from '../../store/modelSlice';
import { clearPutResponseCorpus, selectCorpusPutStatus } from '../../store/corpusSlice';
import useAccessRight from '../../hooks/useAccessRight';
import useTranslate from '../../hooks/useTranslate';
import { CORPUS } from '../../constants/accessRights';
import { RequestStatus } from '../../types/statusTypes';
import ErrorsTable from './ErrorsTable/ErrorsTable';
import ProgressCircle from '../ProgressCircle/ProgressCircle';
import ManualCheckSmc from '../ManualCheck/ManualCheckSmc';
import ManualCheckSee from '../ManualCheck/ManualCheckSee';
import FormErrorsInCorpus from '../Forms/FormErrorsInCorpus/FormErrorsInCorpus';
import Notification from '../Notification/Notification';
import { IErrorsProps } from './Errors.props';
import styles from './Errors.module.scss';

const Errors = ({ showErrors, setShowErrors, serviceType, showManualCheck, setShowManualCheck, typeErrors }: IErrorsProps): JSX.Element => {
	const [showModal, setShowModal] = useState<boolean>(false); // показ формы для преобразования ошибок в корпус
	const [showNotification, setShowNotification] = useState<boolean>(false); // показ уведомлений

	const dispatch = useAppDispatch();
	const errors = useAppSelector(selectErrorsModel); // store - список ошибок модели
	const modelName = useAppSelector(selectModelName); // store - имя активной модели
	const activeType = useAppSelector(selectActiveType); // store - активный типа модели
	const putStatus = useAppSelector(selectCorpusPutStatus); // store - статус изменения корпуса

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// следим за состоянием вкладки ошибок
	useEffect(() => {
		errors.errors.length > 0 && dispatch(clearErrors()); // если есть список ошибок - очищаем
		showErrors && modelName && dispatch(getErrorsModel({ modelName: modelName, modelType: activeType, serviceType, typeErrors })); // если вкладка ошибок открыта - получаем ошибки
	}, [showErrors]);

	// обработчик закрытия вкладки ошибок
	const handleClose = (): void => {
		setShowErrors(false);  // закрытие вкладки ошибок
		showManualCheck && setShowManualCheck(false); // закрытие доп.блока ручной проверки, если был открыт
		setShowNotification(false); // выключение уведомления
		putStatus.message !== '' && dispatch(clearPutResponseCorpus()); // если был изменен статус изменения (преобразования в корпус) корпуса - очищаем статус
	};

	// функция преобразования массива ошибок в строку для сохранения в .csv файле
	const arrErrorsInCsvFormat = (): string => {
		let str: string = '';
		errors.errors.forEach(row => str += row[0] + '\t' + row[1] + '\t' + row[2] + '\n');
		return str;
	};

	return (
		<Slide direction="left" in={showErrors} mountOnEnter unmountOnExit>
			<div className={styles.modal} onMouseDown={handleClose}>

				<div className={styles.errorsWrapper} onMouseDown={(e) => e.stopPropagation()}>
					{errors.status === RequestStatus.LOADING && <div className={styles.loading}><ProgressCircle title={translate('spinnerTitle_loading')} /></div>}
					<div className={styles.errorsContainer}>
						{errors.status === RequestStatus.IDLE && (errors.errors.length === 0 || !Array.isArray(errors.errors)) && <p className={styles.noData}>{translate('title_noData')}</p>}
						{errors.status === RequestStatus.FAILED && <p className={styles.failed}>{translate('title_loadFailed')}</p>}
						<div className={styles.errors}>
							{errors.status === RequestStatus.IDLE && Array.isArray(errors.errors) && errors.errors.length > 0 && <ErrorsTable errors={errors} />}
							{showManualCheck && serviceType === 'smc' && <ManualCheckSmc showManualCheck={showManualCheck} withErrors modelName={modelName} />}
							{showManualCheck && serviceType === 'see' && <ManualCheckSee showManualCheck={showManualCheck} withErrors modelName={modelName} />}
						</div>
						{Array.isArray(errors.errors) && errors.errors.length > 0 &&
							<div className={styles.btns}>
								{isAccess(CORPUS.SAVE) && <Button variant="outlined" onClick={() => setShowModal(true)} sx={{ fontSize: 11 }}>
									{translate('button_transformInCorpus')}
								</Button>}
								<a
									href={'data:text/plain;charset=utf-8,' + encodeURIComponent(arrErrorsInCsvFormat())}
									target='_blank'
									rel="noreferrer"
									download={`errors_${serviceType}_${modelName}_${activeType}_${typeErrors}.csv`}
								>
									<Button variant="outlined" sx={{ fontSize: 11 }}>
										{translate('button_export')}
									</Button>
								</a>
							</div>
						}
					</div>
					<div className={styles.tagClose} onClick={handleClose}>
						{translate('tag_close')}
					</div>
					{showModal && <FormErrorsInCorpus showModal={showModal} setShowModal={setShowModal} serviceType={serviceType} setShowNotification={setShowNotification} />}
				</div>

				{showNotification && <Notification showNotification={showNotification} setShowNotification={setShowNotification} selectDataResponse={selectCorpusPutStatus} clearDataResponse={clearPutResponseCorpus} titleFailed='noticeAddition_failed' titleSuccess='noticeAddition_success' />}
			</div>
		</Slide>
	);
};

export default Errors;
