import { FormEvent, useEffect, useState } from 'react';
import { Button, Fade, FormControl, Slide } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faPen, faTrashCan, faXmark } from '@fortawesome/free-solid-svg-icons';
import cn from 'classnames';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { clearAddingChannel, clearChannelList, clearDeletingChannel, clearEditingChannel, deleteChannel, editChannel, getChannelList, selectAddingChannel, selectChannelList, selectDeletingChannel, selectEditingChannel } from '../../store/qasSlice';
import useAccessRight from '../../hooks/useAccessRight';
import useTranslate from '../../hooks/useTranslate';
import { QAS } from '../../constants/accessRights';
import { colorGreen, colorPrimary, colorRed } from '../../constants/colors';
import { IChannel } from '../../types/qasTypes';
import { RequestStatus, ResponseStatus } from '../../types/statusTypes';
import FormAddingChannel from '../Forms/FormAddingChannel/FormAddingChannel';
import ScreenLock from '../ScreenLock/ScreenLock';
import AlertDialog from '../AlertDialog/AlertDialog';
import Notification from '../Notification/Notification';
import ProgressCircle from '../ProgressCircle/ProgressCircle';
import { IChannelsProps } from './Channels.props';
import styles from './Channels.module.scss';

const Channels = ({ showChannels, setShowChannels }: IChannelsProps): JSX.Element => {
	const [showModalAddChannel, setShowModalAddChannel] = useState<boolean>(false); // показ формы добавления канала
	const [inputRenameChannel, setInputRenameChannel] = useState<string>(''); // имя канала для переименования
	const [renameChannelFlg, setRenameChannelFlg] = useState<{ isOpen: boolean, id: null | string }>({ isOpen: false, id: null }); // флаг и индекс для формы переименования канала
	const [selectedChannelToBeRemoved, setSelectedChannelToBeRemoved] = useState<IChannel>({ id: '', name: '' }); // выбранный канал для удаления
	const [noticeExistingChannel, setNoticeExistingChannel] = useState<boolean>(false); // уведомление о существующем канале
	const [showAlertDialogDelChannel, setShowAlertDialogDelChannel] = useState<boolean>(false); // показ диалогового окна при удалении канала
	const [showNotificationAdd, setShowNotificationAdd] = useState<boolean>(false); // показ уведомления о добавлении канала
	const [showNotificationEdit, setShowNotificationEdit] = useState<boolean>(false); // показ уведомления об изменении канала
	const [showNotificationDelete, setShowNotificationDelete] = useState<boolean>(false); // показ уведомления об удалении канала
	const [showScreenLock, setShowScreenLock] = useState<{ isShow: boolean, title: string }>({ isShow: false, title: '' }); // показ экрана блокировки и подпись

	const dispatch = useAppDispatch();
	const channelList = useAppSelector(selectChannelList); // store - список каналов
	const addingChanelStatus = useAppSelector(selectAddingChannel); // store - статус добавления канала
	const editingChannelStatus = useAppSelector(selectEditingChannel); // store - статус изменения канала
	const deletingChannelStatus = useAppSelector(selectDeletingChannel); // store - статус удаления канала

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// следим за статусом изменения/удаления канала
	useEffect(() => {
		// если идет изменение/удаление - включаем экран блокировки с подписью
		if (editingChannelStatus.status === RequestStatus.LOADING) setShowScreenLock({ isShow: true, title: translate('spinnerTitle_saving') });
		else if (deletingChannelStatus.status === RequestStatus.LOADING) setShowScreenLock({ isShow: true, title: translate('spinnerTitle_deletion') });
		else setShowScreenLock({ isShow: false, title: '' }); // иначе выключаем

		// если изменилось или удалилось успешно 
		if ((editingChannelStatus.status === RequestStatus.IDLE && editingChannelStatus.error === ResponseStatus.SUCCESS && editingChannelStatus.message !== '') || (deletingChannelStatus.error === ResponseStatus.SUCCESS && deletingChannelStatus.status === RequestStatus.IDLE && deletingChannelStatus.message !== '')) {
			dispatch(clearChannelList()); // очищаем список каналов
			dispatch(getChannelList()); // получаем заново список каналов
		}
	}, [editingChannelStatus, deletingChannelStatus]);

	// обработчик нажатия кнопки переименования канала
	const renameChnnelHandler = (channelName: string, channelId: string): void => {
		setRenameChannelFlg({ isOpen: true, id: channelId }); // открываем форму для переименования
		setInputRenameChannel(channelName); // записываем в input название канала
	};

	// обработчик нажатия клавишы мыши
	const mouseDownHandler = (e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
		e.stopPropagation();
		// закрываем уведомления и очищаем статусы добавления/изменения/удаления канала, если были
		if (addingChanelStatus.status === RequestStatus.FAILED || addingChanelStatus.error === ResponseStatus.FAILED || addingChanelStatus.message !== '') {
			dispatch(clearAddingChannel());
			setShowNotificationAdd(false);
		}
		if (editingChannelStatus.status === RequestStatus.FAILED || editingChannelStatus.error === ResponseStatus.FAILED || editingChannelStatus.message !== '') {
			dispatch(clearEditingChannel());
			setShowNotificationEdit(false);
		}
		if (deletingChannelStatus.status === RequestStatus.FAILED || deletingChannelStatus.error === ResponseStatus.FAILED || deletingChannelStatus.message !== '') {
			dispatch(clearDeletingChannel());
			setShowNotificationDelete(false);
		}
	};

	// обработчик нажатия клавиши в поле переименования канала
	const keyDownRenameChannelHandler = (e: React.KeyboardEvent<HTMLInputElement>): void => {
		if (e.code === 'Escape') setRenameChannelFlg({ isOpen: false, id: null });
	};

	// отправка формы переименования канала
	const submitRenameChannel = (e: FormEvent<HTMLFormElement>): void => {
		e.preventDefault();
		// если имя не поменялось - закрываем форму
		if (channelList.data.find(channelItem => channelItem.id === renameChannelFlg.id)?.name === inputRenameChannel) {
			setRenameChannelFlg({ isOpen: false, id: null });
		}
		// если переименовываем на существующий канал - включаем предупреждение
		else if (channelList.data.find(channelItem => channelItem.name === inputRenameChannel)) {
			setNoticeExistingChannel(true);
			setTimeout(() => {
				setNoticeExistingChannel(false);
			}, 5000); // выключаем через 5сек
		}
		else {
			setShowNotificationEdit(true); // включаем уведомление
			renameChannelFlg.id && dispatch(editChannel({ channelId: renameChannelFlg.id, channelName: inputRenameChannel })); // переименовываем канал
			setRenameChannelFlg({ isOpen: false, id: null }); // закрываем форму переименования канала
		}
	};

	// обработчик удаления канала
	const deleteChannelHandler = (): void => {
		setShowAlertDialogDelChannel(false); // закрываем диалоговое окно
		setShowNotificationDelete(true); // включаем уведомление
		dispatch(deleteChannel(selectedChannelToBeRemoved.id)); // удаляем канал
	};

	// обработчик закрытия вкладки
	const closeHandler = (): void => {
		setShowChannels(false);
		renameChannelFlg.isOpen && setRenameChannelFlg({ isOpen: false, id: null }); // закрываем форму переименования
	};

	return (
		<Slide direction="left" in={showChannels} mountOnEnter unmountOnExit>
			<div className={styles.modal} onMouseDown={closeHandler}>
				<div className={styles.channels} onMouseDown={(e) => mouseDownHandler(e)}>
					<div className={styles.channelsInner}>
						<h3 className={styles.channelsHeader}>{translate('title_channels')}</h3>

						{/* загрузка списка каналов */}
						{channelList.status === RequestStatus.LOADING &&
							<div className={styles.loading}>
								<ProgressCircle title={translate('spinnerTitle_loading')} />
							</div>
						}

						{/* ошибка получения списка каналов */}
						{(channelList.status === RequestStatus.FAILED || channelList.error === ResponseStatus.FAILED) &&
							<div className={styles.failed}>
								{translate(channelList.message || 'title_loadFailed')}
							</div>
						}

						{/* пустой список */}
						{channelList.data.length === 0 && channelList.status === RequestStatus.IDLE && channelList.error === ResponseStatus.SUCCESS &&
							<div className={styles.empty}>
								{translate('title_emptyList')}
							</div>
						}

						{/* список каналов */}
						{channelList.data.length > 0 &&
							<Fade in={true} timeout={500}>
								<div className={styles.channelsList}>
									<ul>
										{channelList.data.map((channel) => (
											<li className={styles.channelsItem} key={channel.id}>
												{renameChannelFlg.isOpen && renameChannelFlg.id === channel.id ?
													<form className={styles.renameForm} onSubmit={e => submitRenameChannel(e)}>
														<input
															className={cn(styles.renameFormInput, {
																[styles.renameFormInputWarn]: noticeExistingChannel,
															})}
															autoFocus
															value={inputRenameChannel}
															onChange={e => setInputRenameChannel(e.target.value)}
															onKeyDown={(e) => keyDownRenameChannelHandler(e)}
														/>
														<button className={styles.renameFormBtn} type="submit" title={translate('buttonTitle_rename')}>
															<FontAwesomeIcon icon={faCheck} style={{ cursor: 'pointer' }} color={colorGreen} size="lg" />
														</button>
														<button className={styles.renameFormBtn} type="reset" title={translate('buttonTitle_cancel')}>
															<FontAwesomeIcon icon={faXmark} style={{ cursor: 'pointer' }} color={colorRed} size="lg" onClick={() => setRenameChannelFlg({ isOpen: false, id: null })} />
														</button>
													</form>
													:
													<>
														<p className={styles.channelsItemName} title={channel.name}>
															{channel.name}
														</p>
														<div className={styles.channelsItemEdit}>
															{isAccess(QAS.CHANNEL_EDIT) &&
																<FontAwesomeIcon
																	icon={faPen}
																	size="1x"
																	color={colorPrimary}
																	onClick={() => renameChnnelHandler(channel.name, channel.id)}
																	title={translate('buttonTitle_rename')}
																	style={{ cursor: 'pointer' }}
																/>
															}
															{isAccess(QAS.CHANNEL_DELETE) &&
																<FontAwesomeIcon
																	icon={faTrashCan}
																	size="1x"
																	color={colorRed}
																	onClick={() => {
																		setShowAlertDialogDelChannel(true);
																		setSelectedChannelToBeRemoved(channel);
																	}}
																	title={translate('buttonTitle_deleteChannel')}
																	style={{ cursor: 'pointer' }}
																/>
															}
														</div>
													</>
												}
											</li>
										))}
									</ul>
									{isAccess(QAS.CHANNEL_ADD) &&
										<FormControl fullWidth>
											<Button
												variant="outlined"
												sx={{ fontSize: 11, margin: '0 16px' }}
												onClick={() => setShowModalAddChannel(true)}
											>
												{translate('button_addChannel')}
											</Button>
										</FormControl>
									}
								</div>
							</Fade>
						}

						{showModalAddChannel && <FormAddingChannel showModal={showModalAddChannel} setShowModal={setShowModalAddChannel} setShowNotification={setShowNotificationAdd} />}

						<AlertDialog
							showAlertDialog={showAlertDialogDelChannel}
							setShowAlertDialog={setShowAlertDialogDelChannel}
							submitHandler={deleteChannelHandler}
							title='dialog_deleteChannel'
							description='dialog_deleteChannelConfirm'
							name={selectedChannelToBeRemoved.name}
						/>

						{showScreenLock.isShow && <ScreenLock title={translate(showScreenLock.title)} />}

						{showNotificationAdd && <Notification showNotification={showNotificationAdd} setShowNotification={setShowNotificationAdd} selectDataResponse={selectAddingChannel} clearDataResponse={clearAddingChannel} titleFailed='noticeAddition_failed' titleSuccess='noticeAddition_success' />}
						{showNotificationEdit && <Notification showNotification={showNotificationEdit} setShowNotification={setShowNotificationEdit} selectDataResponse={selectEditingChannel} clearDataResponse={clearEditingChannel} titleFailed='noticeSaving_failed' titleSuccess='noticeSaving_success' />}
						{showNotificationDelete && <Notification showNotification={showNotificationDelete} setShowNotification={setShowNotificationDelete} selectDataResponse={selectDeletingChannel} clearDataResponse={clearDeletingChannel} titleFailed='noticeDeletion_failed' titleSuccess='noticeDeletion_success' />}
					</div>
				</div>

				<div className={styles.tagClose} onClick={closeHandler}>
					{translate('tag_close')}
				</div>
			</div>
		</Slide>
	);
};

export default Channels;
