import { FormEvent, useEffect, useState } from 'react';
import { Button, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { generateQuestions, getStatusGenerateQuestions, selectCategoriesList, selectGeneratingQuestionsStartStatus } from '../../../store/qasSlice';
import useAccessRight from '../../../hooks/useAccessRight';
import useTranslate from '../../../hooks/useTranslate';
import { QAS } from '../../../constants/accessRights';
import { RequestStatus, ResponseStatus } from '../../../types/statusTypes';
import ModalFormWindow from '../../../HOC/ModalFormWindow/ModalFormWindow';
import ProgressCircle from '../../ProgressCircle/ProgressCircle';
import { IFormGeneratingQuestionsProps } from './FormGeneratingQuestions.props';

const FormGeneratingQuestions = ({ showModal, setShowModal, setShowNotification }: IFormGeneratingQuestionsProps): JSX.Element => {
	const [categoryId, setCategoryId] = useState<string>(''); // id категории

	const dispatch = useAppDispatch();
	const categoriesList = useAppSelector(selectCategoriesList); // store - список категорий
	const startGenerateStatus = useAppSelector(selectGeneratingQuestionsStartStatus); // store - статус начала генерации вопросов

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// следим за статусом начала генерации
	useEffect(() => {
		if (startGenerateStatus.error !== ResponseStatus.SUCCESS || startGenerateStatus.status === RequestStatus.FAILED || startGenerateStatus.message !== '') {
			handleClose(); // закрываем форму
			setShowNotification(true); // включаем уведомление
		}
		// если успешно - запрашиваем статус процесса генерации
		if (startGenerateStatus.error === ResponseStatus.SUCCESS && startGenerateStatus.message !== '') {
			isAccess(QAS.QUESTION_MAKER_STATUS) && dispatch(getStatusGenerateQuestions());
		}
	}, [startGenerateStatus]);

	// обработчик генерации вопросов
	const submitHandler = (e: FormEvent<HTMLFormElement>): void => {
		e.preventDefault();
		dispatch(generateQuestions(categoryId)); // генерация вопросов
	};

	// обработчик закрытия формы
	const handleClose = (): void => {
		// если идет отправка - запрещаем покидать форму
		if (startGenerateStatus.status === RequestStatus.LOADING) return;
		setShowModal(false);
	};

	return (
		<ModalFormWindow showModal={showModal} setShowModal={setShowModal} headerTitle='formHeader_questionGeneration' close={handleClose}>
			<form onSubmit={(e) => submitHandler(e)}>
				<FormControl fullWidth margin='dense' sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}>
					<InputLabel id="category-label" sx={{ fontSize: 13 }}>{translate('select_category')}</InputLabel>
					<Select
						required
						labelId="category-label"
						id="category"
						label={translate('select_category')}
						disabled={startGenerateStatus.status === RequestStatus.LOADING}
						value={categoryId}
						onChange={(e) => setCategoryId(e.target.value)}
						style={{ fontSize: 13, height: 33 }}
					>
						{categoriesList.data.map((category) => (
							<MenuItem key={category.id} value={category.id} sx={{ fontSize: 13 }}>{category.name}</MenuItem>
						))}
					</Select>
				</FormControl>
				<FormControl fullWidth margin='dense'>
					<Button
						variant="outlined"
						type="submit"
						sx={{ fontSize: 11 }}
						disabled={startGenerateStatus.status === RequestStatus.LOADING}
					>
						{startGenerateStatus.status === RequestStatus.LOADING ?
							<>
								{translate('button_start')}
								<ProgressCircle isBtnDisabled />
							</>
							:
							translate('button_start')
						}
					</Button>
				</FormControl>
			</form>
		</ModalFormWindow>
	);
};

export default FormGeneratingQuestions;
