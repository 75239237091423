import { FormEvent, useState } from 'react';
import { Button, FormControl, TextField } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { addingAddEndpointFormData, selectEndpointList } from '../../../store/sesSlice';
import useTranslate from '../../../hooks/useTranslate';
import { colorPrimary } from '../../../constants/colors';
import ModalFormWindow from '../../../HOC/ModalFormWindow/ModalFormWindow';
import { IFormAddingEndpointProps } from './FormAddingEndpoint.props';

const FormAddingEndpoint = ({ showModal, setShowModal, setShowElem }: IFormAddingEndpointProps): JSX.Element => {
	const [inputName, setInputName] = useState<string>(''); // название конечной точки
	const [showErrorExistingEndpoint, setShowErrorExistingEndpoint] = useState<boolean>(false); // флаг существующего имени конечной точки

	const dispatch = useAppDispatch();
	const endpointList = useAppSelector(selectEndpointList); // список конечных точек

	const translate = useTranslate(); // hook для перевода текста

	// обработчик добавления конечной точки
	const submitHandler = (e: FormEvent<HTMLFormElement>): void => {
		e.preventDefault();
		const existingEndpointName = endpointList.data.find(endpoint => endpoint.name === inputName); // существующее имя конечной точки
		if (existingEndpointName) {
			setShowErrorExistingEndpoint(true); // подсвечиваем ошибку
		} else {
			showErrorExistingEndpoint && setShowErrorExistingEndpoint(false); // убираем ошибку, если была
			dispatch(addingAddEndpointFormData(inputName)); // добавление данных формы в store
			setShowModal(false); // закрываем форму
			setShowElem(true); // открываем вкладку для редактирования
		}
	};

	return (
		<ModalFormWindow showModal={showModal} setShowModal={setShowModal} headerTitle='formHeader_addingEndpoint'>
			<form onSubmit={(e) => submitHandler(e)}>
				<FormControl fullWidth margin='dense'>
					<TextField
						autoFocus
						required
						id="endpointName"
						label={translate('input_name')}
						variant="outlined"
						value={inputName}
						onChange={(e) => setInputName(e.target.value)}
						error={showErrorExistingEndpoint}
						helperText={showErrorExistingEndpoint ? translate('title_endpointExists') : ''}
						InputProps={{
							style: {
								height: 33,
								fontSize: 13,
								color: colorPrimary,
							},
						}}
						InputLabelProps={{
							style: {
								fontSize: 13,
							},
						}}
						sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
					/>
				</FormControl>
				<FormControl fullWidth margin='dense'>
					<Button
						variant="outlined"
						type="submit"
						sx={{ fontSize: 11 }}
					>
						{translate('button_add')}
					</Button>
				</FormControl>
			</form>
		</ModalFormWindow>
	);
};

export default FormAddingEndpoint;
