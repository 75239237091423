import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudArrowUp } from '@fortawesome/free-solid-svg-icons';
import useTranslate from '../../../../hooks/useTranslate';
import { backgroundColor } from '../../../../constants/colors';
import FormImportMarks from '../../../Forms/FormImportMarks/FormImportMarks';
import FormImportRobot from '../../../Forms/FormImportRobot/FormImportRobot';
import Notification from '../../../Notification/Notification';
import { IImportProps } from './Import.props';

const Import = ({ styleNotActive, isAvailable, selectDataResponse, clearDataResponse, placeOfImport }: IImportProps): JSX.Element => {
	const [showModal, setShowModal] = useState<boolean>(false); // показ формы импорта
	const [showNotification, setShowNotification] = useState<boolean>(false); // показ уведомления

	const translate = useTranslate(); // hook для перевода текста

	return (
		<>
			<div
				className={isAvailable ? '' : styleNotActive}
				onClick={() => isAvailable && setShowModal(true)}
				title={translate('buttonTitle_import')}>
				<FontAwesomeIcon icon={faCloudArrowUp} size="2xl" color={backgroundColor} />
			</div>

			{showModal && placeOfImport === 'marks' &&
				<FormImportMarks
					showModal={showModal}
					setShowModal={setShowModal}
					setShowNotification={setShowNotification}
				/>
			}

			{showModal && placeOfImport === 'robot' &&
				<FormImportRobot
					showModal={showModal}
					setShowModal={setShowModal}
					setShowNotification={setShowNotification}
				/>
			}

			<section style={{ position: 'absolute' }}>
				{showNotification &&
					<Notification
						showNotification={showNotification}
						setShowNotification={setShowNotification}
						selectDataResponse={selectDataResponse}
						clearDataResponse={clearDataResponse}
						titleFailed='noticeImport_failed'
						titleSuccess='noticeImport_success'
					/>
				}
			</section>
		</>
	);
};

export default Import;
