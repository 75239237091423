import { BIOMETRY, CLASSIFIER, ENTITIES, QUESTIONNAIRE, RECOGNIZER, REPORTS, ROBOTS, SYNTHESIS } from '../constants/routes';
import { ServiceType } from '../types/modelTypes';

// функция для перевода маршрутов
export const routeTranslation = (serviceType: ServiceType): string => {
	switch (serviceType) {
		case 'smc':
			return CLASSIFIER;
		case 'see':
			return ENTITIES;
		case 'spr':
			return RECOGNIZER;
		case 'sbs':
			return BIOMETRY;
		case 'tts':
			return SYNTHESIS;
		case 'qas':
			return QUESTIONNAIRE;
		case 'ses':
			return ROBOTS;
		case 'res':
			return REPORTS;
		default:
			const defaultServiceType: never = serviceType;
			return defaultServiceType;
	};
};
