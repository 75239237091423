import { FormEvent, Fragment, useEffect, useRef, useState } from 'react';
import { Button, Checkbox, FormControl, FormControlLabel, InputAdornment, InputLabel, ListItemText, MenuItem, Select, SelectChangeEvent, Slide, TextField } from '@mui/material';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { selectActiveType } from '../../store/modelSlice';
import { clearManualCheckModelSee, manualCheckModelSee, selectManualCheckModelSee } from '../../store/seeSlice';
import { clearClusterServer, getClusterServerModels, selectClusterServer } from '../../store/serverSlice';
import useAccessRight from '../../hooks/useAccessRight';
import useTranslate from '../../hooks/useTranslate';
import { SERVER } from '../../constants/accessRights';
import { IManualCheckData } from '../../types/seeTypes';
import { RequestStatus } from '../../types/statusTypes';
import ProgressCircle from '../ProgressCircle/ProgressCircle';
import { IManualCheckProps } from './ManualCheck.props';
import styles from './ManualCheck.module.scss';

const ManualCheckSee = ({ showManualCheck, withErrors, modelName, modelNameAsIs = false }: IManualCheckProps): JSX.Element => {
	const [inputText, setInputText] = useState<string>(''); // текст для проверки
	const [similarity, setSimilarity] = useState<number>(70); // процент соответствия для проверки
	const [inputModelNames, setInputModelNames] = useState<string[]>([]); // список продовых моделей для расширенной проверки
	const [keysData, setKeysData] = useState<string[]>([]); // список ключей из ответа о результате проверки
	const [modelNamesProd, setModelNamesProd] = useState<string[]>([]); // список моделей из прода для расширенного режима проверки

	const dispatch = useAppDispatch();

	const [advancedMode, setAdvancedMode] = useState<boolean>(modelName === null ? true : false); // флаг для расширенного режима

	const activeType = useAppSelector(selectActiveType); // store - активный тип модели
	const manualCheck = useAppSelector(selectManualCheckModelSee); // store - результат ручной проверки see
	const clusterServer = useAppSelector(selectClusterServer); // store - список моделей на сервере cluster
	const inputTextRef = useRef<HTMLInputElement>(null); // ссылка на поле для ввода текста

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// обработчик ручной проверки модели
	const submitHandler = (e: FormEvent<HTMLFormElement>): void => {
		e.preventDefault();
		setKeysData([]); // очищаем список ключей
		manualCheck.data !== null && dispatch(clearManualCheckModelSee()); // если есть данные ручной проверки - очищаем
		dispatch(manualCheckModelSee({
			modelName: modelName ?
				((activeType === 'future' && !modelNameAsIs) ? `${modelName}-new` : modelName) + (inputModelNames.length > 0 ? `,${inputModelNames.join(',')}` : '')
				:
				(inputModelNames.length > 0 ? inputModelNames.join(',') : '')
			,
			text: inputText,
			similarity
		})); // ручная проверка c перечислением имен моделей, если выбраны в расширенном режиме
	};

	// следим за расширенным режимом
	useEffect(() => {
		// если расширенный режим включен - получаем продовые модели с сервера cluster
		if (advancedMode) {
			dispatch(getClusterServerModels({ serviceType: 'see' }));
		} else {
			setInputModelNames([]); // очищаем список имен продовых моделей
			manualCheck.data !== null && dispatch(clearManualCheckModelSee()); // если есть результат проверки - очищаем
			(clusterServer.data !== null || clusterServer.status === RequestStatus.FAILED) && dispatch(clearClusterServer()); // если есть список продовых моделей или не удалось их получить - очищаем
		}
	}, [advancedMode]);

	// следим за статусом получения продовых моделей с серверов cluster
	useEffect(() => {
		// если нет ошибок и есть данные
		if (clusterServer.status === RequestStatus.IDLE && clusterServer.data !== null && typeof clusterServer.data !== 'string' && Object.keys(clusterServer.data).length > 0) {
			const clusterServers = Object.keys(clusterServer.data); // пишем сервера cluster'а
			const arrModelNames: string[] = [];
			clusterServers.forEach(server => {
				clusterServer.data && clusterServer.data[server]?.forEach(modelName => {
					if (!/-new$/m.test(modelName)) {
						!arrModelNames.includes(modelName) && arrModelNames.push(modelName); // оставляем все уникальные модели без постфикса -new
					}
				});
			});
			setModelNamesProd(arrModelNames); // пишем список моделей в state
		}
	}, [clusterServer.status]);

	// следим за показом вкладки ручной проверки модели
	useEffect(() => {
		inputText.length > 0 && setInputText(''); // если был написан текст - очищаем
		(manualCheck.data !== null || manualCheck.status === RequestStatus.FAILED) && dispatch(clearManualCheckModelSee()); // если есть данные проверки и статус "Ошибка" - очищаем данные проверки
		(clusterServer.data !== null || clusterServer.status === RequestStatus.FAILED) && dispatch(clearClusterServer()); // если есть данные по продовым моделям серверов cluster и статус "Ошибка" - очищаем данные моделей
	}, [showManualCheck]);

	// следим за результатом ручной проверки
	useEffect(() => {
		// если есть данные результата ручной проверки
		if (manualCheck.data && typeof manualCheck.data === 'object' && Object.keys(manualCheck.data).length > 0 && !('error' in manualCheck.data) && !('message' in manualCheck.data)) {
			Object.keys(manualCheck.data[Object.keys(manualCheck.data)[0]]).length > 0 && setKeysData(Object.keys(manualCheck.data[Object.keys(manualCheck.data)[0]][0])); // пишем ключи данных проверки от первой найденной модели, если есть
		}
	}, [manualCheck]);

	// обработчик выбора продовых моделей для расширенной проверки
	const handleChange = (event: SelectChangeEvent<typeof inputModelNames>) => {
		const { target: { value } } = event;
		setInputModelNames(typeof value === 'string' ? value.split(',') : value);
	};

	return (
		<Slide direction="left" in={showManualCheck} mountOnEnter unmountOnExit>
			<div className={cn(styles.manualCheck, {
				[styles.manualCheckWithErrors]: withErrors
			})} onMouseDown={(e) => e.stopPropagation()}
			>
				<form className={styles.manualCheckForm} onSubmit={e => submitHandler(e)}>
					<FormControl fullWidth margin='dense'>
						<TextField
							autoFocus
							inputRef={inputTextRef}
							required
							id="text"
							label={translate('input_text')}
							variant="outlined"
							multiline
							rows={3}
							value={inputText}
							onChange={(e) => setInputText(e.target.value)}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end" >
										{inputText.length > 0 && <FontAwesomeIcon icon={faXmark} onClick={() => { setInputText(''); inputTextRef.current?.focus(); }} style={{ cursor: 'pointer ' }} />}
									</InputAdornment>
								),
								style: {
									fontSize: 13,
									padding: 8
								},
							}}
							InputLabelProps={{
								style: {
									fontSize: 13,
								},
							}}
							sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
						/>
					</FormControl>
					<FormControl fullWidth margin='dense'>
						<TextField
							id="outlined-basic"
							label={translate('input_similarity')}
							variant="outlined"
							type='number'
							value={similarity}
							onChange={(e) => setSimilarity(Math.round(Number(e.target.value)))}
							InputProps={{
								style: {
									height: 33,
									fontSize: 13
								},
								inputProps: { min: 0, max: 100 }
							}}
							InputLabelProps={{
								style: {
									fontSize: 13,
								},
							}}
							sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
						/>
					</FormControl>
					{isAccess(SERVER.MODEL_LIST) &&
						<FormControlLabel sx={{ width: '100%', overflow: 'hidden', '.MuiTypography-root': { fontSize: 13, marginTop: '3px' } }} control={
							<Checkbox checked={advancedMode} disabled={modelName === null} onChange={e => setAdvancedMode(e.target.checked)} size='small' />
						} label={translate('checkbox_advancedMode')} />
					}
					{advancedMode &&
						<>
							{clusterServer.status === RequestStatus.LOADING && <div className={styles.loading}><ProgressCircle title={translate('spinnerTitle_loading')} inLine /></div>}
							{clusterServer.status === RequestStatus.FAILED && <p className={styles.failedText}>{translate('title_functionNotAvailable')}</p>}
							{clusterServer.status === RequestStatus.IDLE &&
								<>
									{modelNamesProd.length > 0 ?
										<FormControl fullWidth sx={{ marginBottom: '8px', '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}>
											<InputLabel id="modelName-label" sx={{ fontSize: 13 }}>{translate('select_model')}</InputLabel>
											<Select
												required
												labelId="modelName-label"
												id="modelName"
												multiple
												label={translate('select_model')}
												value={inputModelNames}
												onChange={e => handleChange(e)}
												renderValue={(selected) => selected.join(', ')}
												style={{ fontSize: 13, height: 33 }}
											>
												{modelNamesProd.map((modelName) => (
													<MenuItem key={modelName} value={modelName} sx={{ textAlign: 'left', padding: 0 }}>
														<Checkbox checked={inputModelNames.indexOf(modelName) > -1} size='small' sx={{ height: 25, width: 25 }} />
														<ListItemText primary={modelName} primaryTypographyProps={{ fontSize: 11 }} />
													</MenuItem>
												))}
											</Select>
										</FormControl>
										:
										<p className={styles.failedText}>{translate('title_noData')}</p>
									}
								</>
							}
						</>
					}
					<FormControl fullWidth>
						{manualCheck.status === RequestStatus.LOADING ?
							<Button variant="outlined" type="submit" disabled sx={{ fontSize: 11 }}>
								{translate('button_search')}
								<ProgressCircle isBtnDisabled />
							</Button>
							:
							<Button variant="outlined" type="submit" sx={{ fontSize: 11 }}>{translate('button_search')}</Button>
						}
					</FormControl>
				</form>
				<div className={styles.manualCheckData}>
					{manualCheck.status === RequestStatus.LOADING &&
						<div className={styles.loading}><ProgressCircle title={translate('spinnerTitle_search')} /></div>
					}
					{manualCheck.status === RequestStatus.FAILED &&
						<p className={styles.failedText}>{translate('title_loadFailed')}</p>
					}
					{manualCheck.data !== null && typeof manualCheck.data !== 'string' &&
						(!('error' in manualCheck.data) && !('message' in manualCheck.data) ?
							<>
								{keysData.length > 0 ?
									<>
										{modelName &&
											manualCheck.data[(activeType === 'future' && !modelNameAsIs) ? `${modelName}-new` : modelName]?.map((obj, idx) => (
												<div key={idx}>
													{manualCheck.data && Object.keys(manualCheck.data).length > 1 && <div className={styles.manualCheckDataPair} key={idx}>
														<span className={styles.manualCheckDataKey}>{translate('title_model')}: "{(activeType === 'future' && !modelNameAsIs) ? `${modelName}-new` : modelName}"</span>
													</div>}
													{keysData.map((key, idx) =>
														key in obj &&
														<div className={styles.manualCheckDataPair} key={idx}>
															<span className={styles.manualCheckDataKey}>{translate(key)}:</span> {obj[key as (keyof IManualCheckData)]}
														</div>
													)}
												</div>
											))
										}

										{advancedMode && inputModelNames.map((prodModelName, idx) => (
											<Fragment key={prodModelName + idx}>
												{manualCheck.data && !('error' in manualCheck.data) && !('message' in manualCheck.data) &&
													manualCheck.data[prodModelName]?.map((obj, idx) => (
														<div key={idx}>
															<div className={styles.manualCheckDataPair} key={idx}>
																<span className={styles.manualCheckDataKey}>{translate('title_model')}: "{prodModelName}"</span>
															</div>
															{keysData.map((key, idx) =>
																<>
																	{key in obj &&
																		<div className={styles.manualCheckDataPair} key={idx}>
																			<span className={styles.manualCheckDataKey}>{translate(key)}:</span> {obj[key as (keyof IManualCheckData)]}
																		</div>}
																</>
															)}
														</div>
													))}
											</Fragment>
										))}
									</>
									:
									<p className={styles.notFoundText}>{translate('title_notFound')}</p>
								}
							</>
							:
							<p className={styles.notFoundText}>
								{translate((typeof manualCheck.data.message === 'string' && manualCheck.data.message) || 'title_loadFailed')}
							</p>
						)
					}
					{typeof manualCheck.data === 'string' && <p className={styles.internalError}>{translate('title_loadFailed')}</p>}
				</div>
			</div>
		</Slide>
	);
};

export default ManualCheckSee;
