import { FormEvent, useEffect, useState } from 'react';
import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { generateAnswers, getStatusGenerateAnswers, selectCategoriesList, selectGeneratingAnswersStartStatus } from '../../../store/qasSlice';
import useAccessRight from '../../../hooks/useAccessRight';
import useTranslate from '../../../hooks/useTranslate';
import { QAS } from '../../../constants/accessRights';
import { RequestStatus, ResponseStatus } from '../../../types/statusTypes';
import { GenerateModeType, GenerateOptionType } from '../../../types/qasTypes';
import ModalFormWindow from '../../../HOC/ModalFormWindow/ModalFormWindow';
import ProgressCircle from '../../ProgressCircle/ProgressCircle';
import { IFormGeneratingAnswersProps } from './FormGeneratingAnswers.props';

const FormGeneratingAnswers = ({ showModal, setShowModal, setShowNotification }: IFormGeneratingAnswersProps): JSX.Element => {
	const [variants, setVariants] = useState<number>(5); // количество вариантов
	const [categoryId, setCategoryId] = useState<string>(''); // id категории
	const [mode, setMode] = useState<GenerateModeType>('generate'); // режим генерации
	const [option, setOption] = useState<GenerateOptionType>('all'); // опции генерации

	const dispatch = useAppDispatch();
	const categoriesList = useAppSelector(selectCategoriesList); // store - список категорий
	const startGenerateStatus = useAppSelector(selectGeneratingAnswersStartStatus); // store - статус начала генерации ответов

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// следим за статусом начала генерации
	useEffect(() => {
		if (startGenerateStatus.error !== ResponseStatus.SUCCESS || startGenerateStatus.status === RequestStatus.FAILED || startGenerateStatus.message !== '') {
			handleClose(); // закрываем форму
			setShowNotification(true); // включаем уведомление
		}
		// если успешно - запрашиваем статус процесса генерации
		if (startGenerateStatus.error === ResponseStatus.SUCCESS && startGenerateStatus.message !== '') {
			isAccess(QAS.ANSWER_MAKER_STATUS) && dispatch(getStatusGenerateAnswers());
		}
	}, [startGenerateStatus]);

	// обработчик генерации ответов
	const submitHandler = (e: FormEvent<HTMLFormElement>): void => {
		e.preventDefault();
		dispatch(generateAnswers({ categoryId, mode, option, variants })); // генерация ответов
	};

	// обработчик закрытия формы
	const handleClose = (): void => {
		// если идет отправка - запрещаем покидать форму
		if (startGenerateStatus.status === RequestStatus.LOADING) return;
		setShowModal(false);
	};

	return (
		<ModalFormWindow showModal={showModal} setShowModal={setShowModal} headerTitle='formHeader_generatingAnswers' close={handleClose}>
			<form onSubmit={(e) => submitHandler(e)}>
				<FormControl fullWidth margin='dense'>
					<TextField
						required
						id="variants"
						label={translate('input_quantityAnswerVariants')}
						variant="outlined"
						type='number'
						value={variants}
						onChange={(e) => setVariants(Number(e.target.value))}
						disabled={startGenerateStatus.status === RequestStatus.LOADING}
						InputProps={{
							style: {
								height: 33,
								fontSize: 13
							},
							inputProps: { step: 1, min: 1, max: 9 }
						}}
						InputLabelProps={{
							style: {
								fontSize: 13,
							},
						}}
						sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
					/>
				</FormControl>
				<FormControl fullWidth margin='dense' sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}>
					<InputLabel id="category-label" sx={{ fontSize: 13 }}>{translate('select_category')}</InputLabel>
					<Select
						required
						labelId="category-label"
						id="category"
						label={translate('select_category')}
						disabled={startGenerateStatus.status === RequestStatus.LOADING}
						value={categoryId}
						onChange={(e) => setCategoryId(e.target.value)}
						style={{ fontSize: 13, height: 33 }}
					>
						{categoriesList.data.map((category) => (
							<MenuItem key={category.id} value={category.id} sx={{ fontSize: 13 }}>{category.name}</MenuItem>
						))}
					</Select>
				</FormControl>
				<FormControl fullWidth margin='dense' sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}>
					<InputLabel id="mode-label" sx={{ fontSize: 13 }}>{translate('select_generationMode')}</InputLabel>
					<Select
						required
						labelId="mode-label"
						id="mode"
						label={translate('select_generationMode')}
						disabled={startGenerateStatus.status === RequestStatus.LOADING}
						value={mode}
						onChange={(e) => setMode(e.target.value as GenerateModeType)}
						style={{ fontSize: 13, height: 33 }}
					>
						<MenuItem value='generate' sx={{ fontSize: 13 }}>{translate('selectItem_generationByDocuments')}</MenuItem>
						<MenuItem value='paraphrase' sx={{ fontSize: 13 }}>{translate('selectItem_paraphrasing')}</MenuItem>
					</Select>
				</FormControl>
				<FormControl fullWidth margin='dense' sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}>
					<InputLabel id="option-label" sx={{ fontSize: 13 }}>{translate('select_generateFor')}</InputLabel>
					<Select
						required
						labelId="option-label"
						id="option"
						label={translate('select_generateFor')}
						disabled={startGenerateStatus.status === RequestStatus.LOADING}
						value={option}
						onChange={(e) => setOption(e.target.value as GenerateOptionType)}
						style={{ fontSize: 13, height: 33 }}
					>
						<MenuItem value='all' sx={{ fontSize: 13 }}>{translate('selectItem_allQuestions')}</MenuItem>
						<MenuItem value='empty' sx={{ fontSize: 13 }}>{translate('selectItem_emptyQuestions')}</MenuItem>
					</Select>
				</FormControl>
				<FormControl fullWidth margin='dense'>
					<Button
						variant="outlined"
						type="submit"
						sx={{ fontSize: 11 }}
						disabled={startGenerateStatus.status === RequestStatus.LOADING}
					>
						{startGenerateStatus.status === RequestStatus.LOADING ?
							<>
								{translate('button_start')}
								<ProgressCircle isBtnDisabled />
							</>
							:
							translate('button_start')
						}
					</Button>
				</FormControl>
			</form>
		</ModalFormWindow>
	);
};

export default FormGeneratingAnswers;
