import { useEffect, useState } from 'react';
import { Button, Fade, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import cn from 'classnames';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { changeDaysInterval, changeNameInterval, changeDataInterval, addTimeInterval, selectActiveRobotVersion } from '../../../store/sesRobotSlice';
import useAccessRight from '../../../hooks/useAccessRight';
import useTranslate from '../../../hooks/useTranslate';
import { SES } from '../../../constants/accessRights';
import { DAYS_PRODUCTION_CALENDAR_LIST, MONTHS_LIST, WEEK_DAYS_LIST } from '../../../constants/robotConfigLists';
import { colorPrimary } from '../../../constants/colors';
import Slider from './Slider/Slider';
import { DaysType } from '../../../types/sesRobotTypes';
import { ITimeIntervalProps } from './TimeInterval.props';
import styles from './TimeInterval.module.scss';

const TimeInterval = ({ timeinterval, intervalName, setIntervalName, setChangeFlg }: ITimeIntervalProps): JSX.Element => {
	const [name, setName] = useState<string>(''); // название
	const [days, setDays] = useState<DaysType>('all'); // дни по производственному календарю
	const [weekdays, setWeekdays] = useState<number[]>([]); // дни недели
	const [months, setMonths] = useState<number[]>([]); // месяцы
	const [dates, setDates] = useState<number[]>([]); // дни месяца

	const dispatch = useAppDispatch();
	const activeRobotVersion = useAppSelector(selectActiveRobotVersion); // store - версия активного робота

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// следим за именем интервала
	useEffect(() => {
		// вписываем поля
		setName(intervalName);
		setDays(timeinterval.days);
		setWeekdays(timeinterval.weekdays);
		setMonths(timeinterval.months);
		setDates(timeinterval.dates);
	}, [timeinterval]);

	// создание массива данных месяца
	const createMonthArray = (): number[][] => {
		const dataArray: number[][] = [[]];
		for (let i = 1; i <= 31; i++) {
			dataArray[dataArray.length - 1].push(i);
			if (i % 7 === 0) dataArray.push([]);
		}
		return dataArray;
	};

	// обработчик изменения названия
	const changeNameHandler = (): void => {
		if (intervalName !== name) {
			dispatch(changeNameInterval({ oldName: intervalName, newName: name })); // меняем старое на новое название
			setIntervalName(name); // смена имени в выборе интервала
			setChangeFlg(true); // ставим флаг о несохраненных данных
		}
	};

	// обработчик изменения дней по производственному календарю
	const changeDaysHandler = (e: SelectChangeEvent<DaysType>): void => {
		dispatch(changeDaysInterval({ intervalName, days: e.target.value as DaysType }));
		setChangeFlg(true); // ставим флаг о несохраненных данных
	};

	// обработчик изменения дней недели
	const changeWeekDaysHandler = (day: number): void => {
		dispatch(changeDataInterval({
			intervalName,
			data: weekdays.includes(day) ? weekdays.filter(weekday => weekday !== day) : [...weekdays, day],
			objectOfChange: 'weekdays',
		}));
		setChangeFlg(true); // ставим флаг о несохраненных данных
	};

	// обработчик изменения месяцев
	const changeMonthsHandler = (month: number): void => {
		dispatch(changeDataInterval({
			intervalName,
			data: months.includes(month) ? months.filter(monthNum => monthNum !== month) : [...months, month],
			objectOfChange: 'months',
		}));
		setChangeFlg(true); // ставим флаг о несохраненных данных
	};

	// обработчик изменения дней месяца
	const changeDateHandler = (date: number): void => {
		dispatch(changeDataInterval({
			intervalName,
			data: dates.includes(date) ? dates.filter(dateNum => dateNum !== date) : [...dates, date],
			objectOfChange: 'dates',
		}));
		setChangeFlg(true); // ставим флаг о несохраненных данных
	};

	// обработчик добавления времени интервала
	const addTimeIntervalHandler = (): void => {
		dispatch(addTimeInterval(intervalName));
		setChangeFlg(true); // ставим флаг о несохраненных данных
	};

	return (
		<Fade in={true} timeout={500}>
			<div className={styles.container}>
				{/* название */}
				<FormControl fullWidth margin='dense'>
					<TextField
						required
						label={translate('input_name')}
						variant="outlined"
						disabled={!isAccess(SES.ROBOT_EDIT) || activeRobotVersion !== 'draft'}
						value={name}
						onChange={(e) => setName(e.target.value)}
						onBlur={changeNameHandler}
						InputProps={{
							style: {
								height: 33,
								fontSize: 13,
								color: colorPrimary,
							},
						}}
						InputLabelProps={{
							style: {
								fontSize: 13,
							},
						}}
						sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
					/>
				</FormControl>

				{/* дни по производственному календарю */}
				<FormControl fullWidth margin='dense' sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}>
					<InputLabel sx={{ fontSize: 13 }}>{translate('select_days')}</InputLabel>
					<Select
						required
						label={translate('select_days')}
						disabled={!isAccess(SES.ROBOT_EDIT) || activeRobotVersion !== 'draft'}
						value={days}
						onChange={changeDaysHandler}
						style={{ fontSize: 13, height: 33, color: colorPrimary }}
					>
						{DAYS_PRODUCTION_CALENDAR_LIST.map(({ day, translation }) =>
							<MenuItem key={day} value={day} sx={{ fontSize: 13, color: colorPrimary }}>{translate(translation)}</MenuItem>
						)}
					</Select>
				</FormControl>

				{/* дни недели */}
				<div className={styles.intervalBlock}>
					{WEEK_DAYS_LIST.map(({ day, translationShort }) => (
						<div key={day} className={cn(styles.weekDay, {
							[styles.weekDayChecked]: weekdays.includes(day),
							[styles.weekDayAccess]: isAccess(SES.ROBOT_EDIT),
						})}
							onClick={() => isAccess(SES.ROBOT_EDIT) && activeRobotVersion === 'draft' && changeWeekDaysHandler(day)}
						>
							{translate(translationShort)}
						</div>
					))}
				</div>

				{/* месяцы */}
				<div className={styles.intervalBlock}>
					{MONTHS_LIST.map(({ month, translationShort }) => (
						<div key={month} className={cn(styles.month, {
							[styles.monthChecked]: months.includes(month),
							[styles.monthAccess]: isAccess(SES.ROBOT_EDIT),
						})}
							onClick={() => isAccess(SES.ROBOT_EDIT) && activeRobotVersion === 'draft' && changeMonthsHandler(month)}
						>
							{translate(translationShort)}
						</div>
					))}
				</div>

				{/* дни месяца */}
				<div className={styles.intervalBlock}>
					<table className={styles.dateTable}>
						<tbody>
							{createMonthArray().map(tr => (
								<tr key={tr.join(',')}>
									{tr.map((day) => (
										<td
											key={day}
											onClick={() => isAccess(SES.ROBOT_EDIT) && activeRobotVersion === 'draft' && changeDateHandler(day)}
											className={cn({
												[styles.dateTableChecked]: dates.includes(day),
												[styles.dateTableAccess]: isAccess(SES.ROBOT_EDIT),
											})}
										>
											{day}
										</td>
									))}
								</tr>
							))}
						</tbody>
					</table>
				</div>

				{/* временные интервалы */}
				{timeinterval.intervals.map((time, idx, array) => (
					<Slider
						key={time.join(',' + idx)}
						intervalName={intervalName}
						time={time}
						idx={idx}
						array={array}
						setChangeFlg={setChangeFlg}
					/>
				))}

				{isAccess(SES.ROBOT_EDIT) && activeRobotVersion === 'draft' &&
					<FormControl fullWidth margin='dense'>
						<Button
							variant="outlined"
							sx={{ fontSize: 11 }}
							onClick={addTimeIntervalHandler}
							disabled={timeinterval.intervals.length > 3}
						>
							{translate('button_addPeriod')}
						</Button>
					</FormControl>
				}
			</div>
		</Fade>
	);
};

export default TimeInterval;
