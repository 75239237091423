import { Slide } from '@mui/material';
import useTranslate from '../../../hooks/useTranslate';
import UserMenu from '../Buttons/UserMenu/UserMenu';
import { IReportsControlsProps } from './ReportsControls.props';
import styles from './ReportsControls.module.scss';

const ReportsControls = ({ setShowPage }: IReportsControlsProps): JSX.Element => {
	const translate = useTranslate(); // hook для перевода текста

	return (
		<>
			<div className={styles.controls}>
				<Slide direction="down" in={true} mountOnEnter unmountOnExit timeout={500}>
					<div className={styles.controlsWrapper}>
						<div className={styles.controlsTitle}>{translate('res')}</div>
						<section className={styles.controlsTypeAndFunc}>
							<div className={styles.controlsFunctions}></div>

							<div className={styles.controlsFunctions}>
								<div className={styles.controlsFunctionsRightBlock}>
									<UserMenu setShowPage={setShowPage} />
								</div>
							</div>
						</section>
					</div>
				</Slide>
			</div>
		</>
	);
};

export default ReportsControls;
