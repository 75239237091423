import { ChangeEvent, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { Checkbox, FormControl, FormControlLabel, TextField } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { deleteAnswer, editingAnswerChecked, editingAnswerText, selectChannelList } from '../../../store/qasSlice';
import useAccessRight from '../../../hooks/useAccessRight';
import useTranslate from '../../../hooks/useTranslate';
import { QAS } from '../../../constants/accessRights';
import { colorPrimary, colorRed, colorSecondaryLight3 } from '../../../constants/colors';
import FormCopyAnswer from '../../Forms/FormCopyAnswer/FormCopyAnswer';
import FormMoveAnswer from '../../Forms/FormMoveAnswer/FormMoveAnswer';
import { IAnswerProps } from './Answer.props';
import styles from './Answer.module.scss';

const Answer = ({ checked, answer, channelId, setChangeFlg, idx, answersArray, filterByAnswer, selectChannelId }: IAnswerProps): JSX.Element => {
	const [checkedAnswer, setCheckedAnswer] = useState<boolean>(checked); // флаг проверенного ответа
	const [inputAnswer, setInputAnswer] = useState<string>(answer); // текст ответа
	const [showCopyForm, setShowCopyForm] = useState<boolean>(false); // показ формы копирования ответа
	const [showMoveForm, setShowMoveForm] = useState<boolean>(false); // показ формы перемещения ответа

	const dispatch = useAppDispatch();
	const channelList = useAppSelector(selectChannelList); // store - список каналов

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// обработчик изменения статуса проверки ответа
	const checkedHandler = (e: ChangeEvent<HTMLInputElement>): void => {
		setCheckedAnswer(e.target.checked); // меняем значение в state
		dispatch(editingAnswerChecked({ idx, value: e.target.checked })); // меняем значение в store
		setChangeFlg(true); // ставим флаг о несохраненных данных
	};

	// обработчик увода фокуса с поля с ответом
	const blurHandler = (): void => {
		if (inputAnswer !== answer) {
			dispatch(editingAnswerText({ idx, value: inputAnswer }));
			setChangeFlg(true); // ставим флаг о несохраненных данных
		}
	};

	return (
		<div className={styles.answer}>
			<div className={styles.answerTopBlock}>
				<FormControlLabel sx={{ marginTop: '-5px', '.MuiTypography-root': { fontSize: 12, marginTop: '2px' } }} control={
					<Checkbox
						checked={checkedAnswer}
						disabled={!isAccess(QAS.QUESTION_EDIT)}
						onChange={e => checkedHandler(e)}
						size='small'
					/>
				} label={translate('checkbox_checked')} />
				{filterByAnswer !== '' && selectChannelId === 'notSelected' &&
					<div className={styles.answerTopBlockChannel}>{channelList.data.find(channel => channel.id === channelId)?.name}</div>
				}
				<div className={styles.answerTopBlockControls}>
					{isAccess(QAS.QUESTION_EDIT) && answer !== '' && channelList.data.length > 0 &&
						<button
							className={styles.answerTopBlockControlsBtn}
							onClick={() => setShowCopyForm(true)}
							title={translate('buttonTitle_copy')}
						>
							<svg xmlns="http://www.w3.org/2000/svg" height="12" viewBox="0 0 448 512">
								{/* !Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc. */}
								<path fill="#918c8c" d="M384 336H192c-8.8 0-16-7.2-16-16V64c0-8.8 7.2-16 16-16l140.1 0L400 115.9V320c0 8.8-7.2 16-16 16zM192 384H384c35.3 0 64-28.7 64-64V115.9c0-12.7-5.1-24.9-14.1-33.9L366.1 14.1c-9-9-21.2-14.1-33.9-14.1H192c-35.3 0-64 28.7-64 64V320c0 35.3 28.7 64 64 64zM64 128c-35.3 0-64 28.7-64 64V448c0 35.3 28.7 64 64 64H256c35.3 0 64-28.7 64-64V416H272v32c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V192c0-8.8 7.2-16 16-16H96V128H64z" />
							</svg>
						</button>
					}
					{isAccess(QAS.QUESTION_EDIT) && answer !== '' &&
						<button
							className={styles.answerTopBlockControlsBtn}
							onClick={() => setShowMoveForm(true)}
							title={translate('buttonTitle_move')}
						>
							<FontAwesomeIcon
								icon={faCopy}
								size='sm'
							/>
						</button>
					}
					{isAccess(QAS.QUESTION_EDIT) && answersArray.length > 1 &&
						<button
							className={styles.answerTopBlockControlsBtn}
							onClick={() => { dispatch(deleteAnswer(idx)); setChangeFlg(true); }}
							title={translate('buttonTitle_deleteAnswer')}
						>
							<FontAwesomeIcon
								icon={faTrashCan}
								size='sm'
								color={colorRed}
							/>
						</button>
					}
				</div>
			</div>

			<FormControl fullWidth>
				<TextField
					id="answer"
					variant="outlined"
					multiline
					maxRows={12}
					value={inputAnswer}
					onChange={(e) => setInputAnswer(e.target.value)}
					onBlur={blurHandler}
					disabled={!isAccess(QAS.QUESTION_EDIT)}
					InputProps={{
						style: {
							padding: 8,
							fontSize: 12,
							color: colorPrimary,
							backgroundColor: (answer.toLowerCase().includes(filterByAnswer.toLowerCase()) && filterByAnswer !== '') ? colorSecondaryLight3 : undefined,
						},
					}}
				/>
			</FormControl>

			{showCopyForm && <FormCopyAnswer showModal={showCopyForm} setShowModal={setShowCopyForm} checkedAnswer={checkedAnswer} inputAnswer={inputAnswer} setChangeFlg={setChangeFlg} />}
			{showMoveForm && <FormMoveAnswer showModal={showMoveForm} setShowModal={setShowMoveForm} idx={idx} channelId={channelId} setChangeFlg={setChangeFlg} />}
		</div>
	);
};

export default Answer;
