import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { Button, FormControl, TextField } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getDictionary, importDictionary, selectImportDictionaryStatus } from '../../../store/ttsSlice';
import useTranslate from '../../../hooks/useTranslate';
import { RequestStatus, ResponseStatus } from '../../../types/statusTypes';
import ModalFormWindow from '../../../HOC/ModalFormWindow/ModalFormWindow';
import ProgressCircle from '../../ProgressCircle/ProgressCircle';
import { IFormImportDictionaryProps } from './FormImportDictionary.props';

const FormImportDictionary = ({ showModal, setShowModal, setShowNotification }: IFormImportDictionaryProps): JSX.Element => {
	const [file, setFile] = useState<File>(); // файл для импортирования модели

	const dispatch = useAppDispatch();
	const importStatusDictionary = useAppSelector(selectImportDictionaryStatus); // state - статус об импортировании словаря
	const translate = useTranslate(); // hook для перевода текста

	// следим за статусом импортирования словаря
	useEffect(() => {
		// если нет ошибок - получаем словарь заново
		importStatusDictionary.error === ResponseStatus.SUCCESS && importStatusDictionary.status === RequestStatus.IDLE && importStatusDictionary.message === 'success' && dispatch(getDictionary());
		// если изменился статус импортирования
		if (importStatusDictionary.error !== ResponseStatus.SUCCESS || importStatusDictionary.status === RequestStatus.FAILED || importStatusDictionary.message !== '') {
			handleClose(); // закрываем форму
			setShowNotification(true); // включаем уведомление
		}
	}, [importStatusDictionary]);

	// обработчик импортированяи модели
	const submitHandler = (e: FormEvent<HTMLFormElement>): void => {
		e.preventDefault();
		const formData = new FormData();
		if (file) {
			formData.append('file', file);
		}
		dispatch(importDictionary({ formData })); // импорт словаря ударений
	};

	// обработчик закрытия формы
	const handleClose = (): void => {
		// если идет импорт словаря ударений  - запрещаем покидать форму
		if (importStatusDictionary.status === RequestStatus.LOADING) return;
		setShowModal(false);
	};

	return (
		<ModalFormWindow showModal={showModal} setShowModal={setShowModal} headerTitle='formHeader_importStressDictionary' close={handleClose}>
			<form onSubmit={(e) => submitHandler(e)}>
				<FormControl fullWidth margin='dense'>
					<TextField
						required
						id="outlined-basic"
						variant="outlined"
						type="file"
						onChange={(e: ChangeEvent<HTMLInputElement>) => e.target.files && setFile(e.target.files[0])}
						InputProps={{
							style: {
								height: 33,
								fontSize: 13
							},
							inputProps: { accept: "text/csv" }
						}}
						InputLabelProps={{
							style: {
								fontSize: 13,
							},
						}}
						sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
					/>
				</FormControl>

				<FormControl fullWidth margin='dense'>
					{importStatusDictionary.status === RequestStatus.LOADING ?
						<Button variant="outlined" type="submit" disabled sx={{ fontSize: 11 }}>
							{translate('button_import')}
							<ProgressCircle isBtnDisabled />
						</Button>
						:
						<Button variant="outlined" type="submit" sx={{ fontSize: 11 }}>
							{translate('button_import')}
						</Button>
					}
				</FormControl>
			</form>
		</ModalFormWindow>
	);
};

export default FormImportDictionary;
