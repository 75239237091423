import { FormEvent, useState } from 'react';
import { Button, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import useTranslate from '../../../hooks/useTranslate';
import ModalFormWindow from '../../../HOC/ModalFormWindow/ModalFormWindow';
import { IFormExportDocFileProps } from './FormExportDocFile.props';
import { colorPrimary } from '../../../constants/colors';

const FormExportDocFile = ({ showModal, setShowModal, exportDocxFileHandler, exportTxtFileHandler }: IFormExportDocFileProps): JSX.Element => {
	const [docType, setDocType] = useState<'docx' | 'txt'>('docx'); // тип документа

	const translate = useTranslate(); // hook для перевода текста

	// обработчик экспорта документа
	const submitHandler = (e: FormEvent<HTMLFormElement>): void => {
		e.preventDefault();
		setShowModal(false); // закрываем форму
		docType === 'docx' ? exportDocxFileHandler() : exportTxtFileHandler(); // экспорт
	};

	return (
		<ModalFormWindow showModal={showModal} setShowModal={setShowModal} headerTitle='formExportDocFile_header'>
			<form onSubmit={(e) => submitHandler(e)}>formHeader_exportDocument
				<FormControl fullWidth sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}>
					<InputLabel id="docType-label" sx={{ fontSize: 13 }}>{translate('select_documentType')}</InputLabel>
					<Select
						required
						id="docType"
						labelId="docType-label"
						label={translate('select_documentType')}
						value={docType}
						onChange={(e) => setDocType(e.target.value as ('docx' | 'txt'))}
						style={{ fontSize: 13, height: 33, color: colorPrimary }}
					>
						<MenuItem value='docx' sx={{ fontSize: 13, color: colorPrimary }}>{translate('selectItem_docx')}</MenuItem>
						<MenuItem value='txt' sx={{ fontSize: 13, color: colorPrimary }}>{translate('selectItem_txt')}</MenuItem>
					</Select>
				</FormControl>
				<FormControl fullWidth margin='dense'>
					<Button
						variant="outlined"
						type="submit"
						sx={{ fontSize: 11 }}
					>
						{translate('button_export')}
					</Button>
				</FormControl>
			</form>
		</ModalFormWindow>
	);
};

export default FormExportDocFile;
