export const ACCESS_TOKEN = 'accessToken';
export const REFRESH_TOKEN = 'refreshToken';

export const SPR_TASK_ID = 'sprTaskId';
export const SPR_RECORD_ID = 'sprRecordId';

export const QAS_FILE_ID = 'qasFileId';
export const QAS_QUESTION_ID = 'qasQuestionId';
export const QAS_FILTER_CATEGORY = 'qasFilterCategory';
export const QAS_FILTER_QUESTION = 'qasFilterQuestion';
export const QAS_FILTER_ANSWER = 'qasFilterAnswer';
export const QAS_FILTER_ANSWER_TYPE = 'qasFilterAnswerType';

export const AUTOSAVE_QUESTION = 'autosaveQuestion';
export const AUTOSAVE_TRANSCRIPT = 'autosaveTranscript';
export const BACKGROUND_IMAGE = 'backgroundImage';
export const COLORING_CONFIDENCE = 'coloringConfidence';

export const ROLE_ID = 'roleId';

export const SPEAKER_ID = 'speakerId';

export const ROBOT_ID = 'robotId';
export const ROBOT_AUDIO_ANSWERS = 'robotAudioAnswers';
export const ROBOT_AUTO_PLAY = 'robotAutoPlay';

export const MARK_ID = 'markId';
export const AUTO_MARK_TASK_ID = 'autoMarkTaskId';

export const DASHBOARD_NAME = 'dashboardName';
