import { useEffect, useState } from 'react';
import { Button, Fade, FormControl, MenuItem, Select, Slide } from '@mui/material';
import cn from 'classnames';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { editRobot, selectActiveRobotVersion, selectEditingRobot, selectRobot } from '../../store/sesRobotSlice';
import useAccessRight from '../../hooks/useAccessRight';
import useTranslate from '../../hooks/useTranslate';
import { SES } from '../../constants/accessRights';
import { MONTHS_LIST, WEEK_DAYS_LIST } from '../../constants/robotConfigLists';
import { colorPrimary } from '../../constants/colors';
import { RequestStatus, ResponseStatus } from '../../types/statusTypes';
import ProgressCircle from '../ProgressCircle/ProgressCircle';
import { ICalendarProps } from './Calendar.props';
import styles from './Calendar.module.scss';

const Calendar = ({ showCalendar, setShowCalendar }: ICalendarProps): JSX.Element => {
	const [year, setYear] = useState<number>(new Date().getFullYear()); // год
	const [holidays, setHolidays] = useState<string[]>([]); // список выходных
	const [changeFlg, setChangeFlg] = useState<boolean>(false); // флаг, уведомляющий об изменении данных и возможности сохранить эти изменения

	const dispatch = useAppDispatch();
	const robotInfo = useAppSelector(selectRobot); // store - информация о роботе
	const editingRobot = useAppSelector(selectEditingRobot); // store - статус изменения данных робота
	const activeRobotVersion = useAppSelector(selectActiveRobotVersion); // store - версия активного робота

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// следим за открытием вкладки
	useEffect(() => {
		// при закрытии
		if (!showCalendar) {
			setYear(new Date().getFullYear()); // сбрасываем год на текущий
		}
	}, [showCalendar]);

	// следим за получением данных робота
	useEffect(() => {
		// если есть данные
		if (robotInfo.data) {
			setHolidays(robotInfo.data.holidays); // вписываем выходные
			setChangeFlg(false); // сбрасываем флаг возможности сохранения
		}
	}, [robotInfo.data]);

	// следим за статусом изменения робота
	useEffect(() => {
		// если изменение данных робота прошло успешно
		if (editingRobot.status === RequestStatus.IDLE && editingRobot.error === ResponseStatus.SUCCESS && editingRobot.message !== '') {
			setChangeFlg(false); // сбрасываем флаг о несохраненных данных
		}
	}, [editingRobot]);

	// обработчик сохранения данных робота
	const editRobotHandler = (): void => {
		robotInfo.data && dispatch(editRobot({
			robotId: robotInfo.data.id,
			data: {
				holidays,
			}
		})); // изменяем данные робота
	};

	// обработчик закрытия вкладки
	const closeHandler = (): void => {
		setShowCalendar(false);
	};

	// создание массива данных месяца
	const createMonthArray = (year: number, month: number): [(string | number), string][][] => {
		const mon = month - 1; // корректировка месяцев (в JS идут от 0 до 11)
		const date = new Date(year, mon);
		const dataArray: [(string | number), string][][] = [[]];

		// пробелы для первого ряда с понедельника до первого дня месяца (* * * 1  2  3  4)
		for (let i = 0; i < getDay(date); i++) {
			dataArray[0].push(['', '']);
		}

		// заполнение ячеек календаря с датами
		while (date.getMonth() === mon) {
			dataArray[dataArray.length - 1].push([date.getDate(), formatDate(year, month, date.getDate())]);
			// вс, последний день - перевод строки
			if (getDay(date) % 7 === 6) dataArray.push([]);
			date.setDate(date.getDate() + 1);
		}

		// добить таблицу пустыми ячейками, если нужно (29 30 31 * * * *)
		if (getDay(date) !== 0) {
			for (let i = getDay(date); i < 7; i++) {
				dataArray[dataArray.length - 1].push(['', '']);
			}
		}
		return dataArray;
	};

	// получение номера дня недели, от 0 (пн) до 6 (вс)
	const getDay = (date: Date): number => {
		let day = date.getDay();
		if (day === 0) day = 7; // сделать воскресенье (0) последним днем
		return day - 1;
	};

	// форматирование даты в YYYY-MM-DD
	const formatDate = (year: number, month: number, day: number): string => {
		const formatMonth = month < 10 ? '0' + month : month;
		const formatDay = day < 10 ? '0' + day : day;
		return `${year}-${formatMonth}-${formatDay}`;
	};

	// обработчик нажатия мыши на ячейку календаря
	const clickHandler = (date: string) => {
		if (date !== '') {
			if (holidays.includes(date)) setHolidays(prev => prev.filter(data => data !== date));
			else setHolidays(prev => ([...prev, date].sort()));
			setChangeFlg(true); // ставим флаг о несохраненных данных
		}
	};

	// текущая дата в формате YYYY-MM-DD
	const getCurrentDate = (): string => {
		const date = new Date();
		return formatDate(date.getFullYear(), date.getMonth() + 1, date.getDate());
	};

	return (
		<Slide direction="left" in={showCalendar} mountOnEnter unmountOnExit>
			<div className={styles.modal} onMouseDown={closeHandler}>
				<div className={styles.configRobot} onMouseDown={e => e.stopPropagation()}>

					{/* загрузка настроек робота */}
					{robotInfo.status === RequestStatus.LOADING &&
						<div className={styles.loading}>
							<ProgressCircle title={translate('spinnerTitle_loading')} />
						</div>
					}

					{/* ошибка получения настроек робота */}
					{(robotInfo.status === RequestStatus.FAILED || robotInfo.error === ResponseStatus.FAILED) &&
						<div className={styles.failed}>
							{translate(robotInfo.message || 'title_loadFailed')}
						</div>
					}

					{/* настройки робота */}
					{robotInfo.status === RequestStatus.IDLE && robotInfo.data &&
						<Fade in={true} timeout={500}>
							<div className={styles.configRobotWrapper}>
								<div className={styles.configRobotTop}>
									{/* текущий и следующий год */}
									<FormControl sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}>
										<Select
											required
											value={year}
											onChange={e => setYear(+e.target.value)}
											style={{ fontSize: 13, height: 33, color: colorPrimary }}
										>
											{[new Date().getFullYear(), new Date().getFullYear() + 1].map((year) =>
												<MenuItem key={year} value={year} sx={{ fontSize: 13, color: colorPrimary }}>{year}</MenuItem>
											)}
										</Select>
									</FormControl>

									<div className={styles.calendar}>
										{MONTHS_LIST.map(({ month, translationFull }) => (
											<div className={styles.month} key={month}>
												<h3 className={styles.monthHeader}>{translate(translationFull)}</h3>
												<table className={styles.monthTable}>
													<thead>
														<tr>
															{WEEK_DAYS_LIST.map(({ day, translationShort }) => (
																<th key={day} className={cn({
																	[styles.holiday]: day === 6 || day === 7,
																})}
																>
																	{translate(translationShort)}
																</th>
															))}
														</tr>
													</thead>
													<tbody>
														{createMonthArray(year, month).map(tr => (
															<tr key={tr.join(',')}>
																{tr.map(([day, date], idx) => (
																	<td
																		key={`${date}${idx}`}
																		data-value={day ? day : undefined}
																		onClick={() => activeRobotVersion === 'draft' && clickHandler(date)}
																		className={cn({
																			[styles.currentDay]: date === getCurrentDate(),
																			[styles.holiday]: holidays.includes(date)
																		})}
																	>
																		{day}
																	</td>
																))}
															</tr>
														))}
													</tbody>
												</table>
											</div>
										))}
									</div>
								</div>

								{activeRobotVersion === 'draft' &&
									<div className={styles.configRobotBottom}>
										{/* сохранение */}
										{isAccess(SES.ROBOT_EDIT) &&
											<FormControl>
												<Button
													variant="outlined"
													disabled={!changeFlg}
													sx={{ fontSize: 11 }}
													onClick={editRobotHandler}
												>
													{translate('button_save')}
												</Button>
											</FormControl>
										}
									</div>
								}
							</div>
						</Fade>
					}

					<div className={styles.tagClose} onClick={closeHandler}>
						{translate('tag_close')}
					</div>
				</div>
			</div>
		</Slide>
	);
};

export default Calendar;
