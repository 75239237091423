import { useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import cn from 'classnames';
import { useAppDispatch } from '../../../store/hooks';
import { addConditionRunActionDataElem, addConditionRunActionEndpoint } from '../../../store/sesSlice';
import { addConditionRunActionRobot } from '../../../store/sesRobotSlice';
import useTranslate from '../../../hooks/useTranslate';
import { CONDITION_RUN_ACTION_TYPE_EXTENDED_LIST, ConditionTypeExtended } from '../../../constants/robotConfigLists';
import { colorPrimary } from '../../../constants/colors';
import { ConditionType, ConditionVariantType, DataElemActionEventType } from '../../../types/sesTypes';
import { RobotActionEventType } from '../../../types/sesRobotTypes';
import { IFormAddingConditionRunActionProps } from './FormAddingConditionRunAction.props';
import styles from './FormAddingConditionRunAction.module.scss';

const FormAddingConditionRunAction = ({ actionEvent, itsAction, channel, channelIdx, conditionBlockIdx, changeFlg, setChangeFlg }: IFormAddingConditionRunActionProps): JSX.Element => {
	const [conditionType, setConditionType] = useState<ConditionTypeExtended>('chooseType'); // тип условия запуска действия

	const dispatch = useAppDispatch();

	const translate = useTranslate(); // hook для перевода текста

	// функция сброса всех полей
	const resetInputAndSelect = (): void => {
		setConditionType('chooseType');
	};

	const creatingObjectWithCondition = (type: ConditionType): ConditionVariantType => {
		switch (type) {
			case 'data':
				return {
					type,
					depth: 5,
					id: '',
					operation: '=',
					value: '',
				};
			case 'variable':
				return {
					type,
					id: '',
					operation: '=',
					value: '',
				};
			case 'interval':
				return {
					type,
					id: '',
				};
			default:
				const defaultType: never = type;
				return defaultType;
		};
	};

	// обработчик добавления условия запуска действия
	const addConditionRunActionHandler = (e: SelectChangeEvent<ConditionTypeExtended>): void => {
		setConditionType(e.target.value as ConditionTypeExtended);
		itsAction === 'robot' && dispatch(addConditionRunActionRobot({
			actionEvent: actionEvent as RobotActionEventType,
			channel,
			channelIdx,
			conditionBlockIdx,
			data: creatingObjectWithCondition(e.target.value as ConditionType),
		}));
		itsAction === 'dataElement' && dispatch(addConditionRunActionDataElem({
			actionEvent: actionEvent as DataElemActionEventType,
			channel,
			channelIdx,
			conditionBlockIdx,
			data: creatingObjectWithCondition(e.target.value as ConditionType),
		}));
		itsAction === 'endpoint' && dispatch(addConditionRunActionEndpoint({
			channel,
			channelIdx,
			conditionBlockIdx,
			data: creatingObjectWithCondition(e.target.value as ConditionType),
		}));
		(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('actions')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'actions'] }));  // ставим флаг о несохраненных данных
		resetInputAndSelect(); // очищаем поля
	};

	return (
		<div className={cn(styles.container, {
			[styles.containerOpacity]: conditionType === 'chooseType'
		})}>
			{/* тип условия запуска действия */}
			<FormControl fullWidth margin='dense' sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}>
				<InputLabel sx={{ fontSize: 13 }}>{translate('select_type')}</InputLabel>
				<Select
					required
					label={translate('select_type')}
					value={conditionType}
					onChange={addConditionRunActionHandler}
					style={{ fontSize: 13, height: 33, color: colorPrimary }}
				>
					{CONDITION_RUN_ACTION_TYPE_EXTENDED_LIST.map(({ type, translation }) =>
						<MenuItem key={type} value={type} sx={{ fontSize: 13, color: colorPrimary }} disabled={type === 'chooseType'}>
							{translate(translation)}
						</MenuItem>
					)}
				</Select>
			</FormControl>
		</div>
	);
};

export default FormAddingConditionRunAction;
