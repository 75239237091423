import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudArrowDown } from '@fortawesome/free-solid-svg-icons';
import { useAppSelector } from '../../../../store/hooks';
import useTranslate from '../../../../hooks/useTranslate';
import { backgroundColor } from '../../../../constants/colors';
import Notification from '../../../Notification/Notification';
import NoticeSingleAction from '../../../Notification/NoticeSingleAction/NoticeSingleAction';
import { IExportProps } from './Export.props';

const Export = ({ styleNotActive, isAvailable, selectDataResponse, clearDataResponse, submitHandler }: IExportProps): JSX.Element => {
	const [showNotification, setShowNotification] = useState<boolean>(false); // показ уведомления
	const [showNotificationLoading, setShowNotificationLoading] = useState<boolean>(false); // показ уведомления загрузки

	const dataResponse = useAppSelector(selectDataResponse); // store - данные ответа

	const translate = useTranslate(); // hook для перевода текста

	// обработчик экспорта словаря меток
	const exportHandler = () => {
		setShowNotificationLoading(true); // включаем уведомление для начала экспорта
		setShowNotification(true); // включаем уведомление для полученных данных
		submitHandler(); // экспорт
	};

	return (
		<>
			<div
				className={isAvailable ? '' : styleNotActive}
				onClick={() => isAvailable && exportHandler()}
				title={translate('buttonTitle_export')}>
				<FontAwesomeIcon icon={faCloudArrowDown} size="2xl" color={backgroundColor} />
			</div>

			<section style={{ position: 'absolute' }}>
				{showNotification &&
					<Notification
						showNotification={showNotification}
						setShowNotification={setShowNotification}
						selectDataResponse={selectDataResponse}
						clearDataResponse={clearDataResponse}
						titleFailed='noticeExport_failed'
						titleSuccess='noticeExport_success'
					/>
				}
				{showNotificationLoading &&
					<NoticeSingleAction
						showNotification={showNotificationLoading}
						setShowNotification={setShowNotificationLoading}
						title='noticeExport_loading'
						severity='info'
						dataResponse={dataResponse}
					/>
				}
			</section>
		</>
	);
};

export default Export;
