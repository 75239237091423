import { useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch, useAppSelector, } from '../../../store/hooks';
import { changeCategoryDataException, deleteCategoryDataException, selectActiveRobotVersion } from '../../../store/sesRobotSlice';
import { selectDataList } from '../../../store/sesSlice';
import useAccessRight from '../../../hooks/useAccessRight';
import useTranslate from '../../../hooks/useTranslate';
import { SES } from '../../../constants/accessRights';
import { CONDITION_RUN_ACTION_TYPE_LIST, OPERATION_CONDITIONS_LIST } from '../../../constants/robotConfigLists';
import { colorPrimary, colorRed } from '../../../constants/colors';
import { ConditionType, OperationType } from '../../../types/sesTypes';
import { ICategoryDataProps } from './CategoryData.props';
import styles from './CategoryData.module.scss';

const CategoryData = ({ categoryData, exception, categoryId, categoryBlockIdx, categoryIdx, changeFlg, setChangeFlg }: ICategoryDataProps): JSX.Element => {
	const [conditionType, setConditionType] = useState<ConditionType>(categoryData.type); // тип условия запуска действия
	const [inputDepth, setInputDepth] = useState<number>(categoryData.depth) || 5; // глубина
	const [inputId, setInputId] = useState<string>(categoryData.id); // id
	const [inputOperation, setInputOperation] = useState<OperationType>(categoryData.operation); // операция
	const [inputValue, setInputValue] = useState<string>(categoryData.value); // значение

	const dispatch = useAppDispatch();
	const dataElemList = useAppSelector(selectDataList); // список элементов данных
	const activeRobotVersion = useAppSelector(selectActiveRobotVersion); // store - версия активного робота

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// обработчик изменения значений условия присвоения категорий
	const changeValuesConditionCategoryHandler = (): void => {
		dispatch(changeCategoryDataException({
			exception,
			categoryId,
			categoryBlockIdx,
			categoryIdx,
			data: {
				type: 'data',
				depth: inputDepth,
				id: inputId,
				operation: inputOperation,
				value: inputValue,
			},
		}));
		(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('exceptions')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'exceptions'] }));  // ставим флаг о несохраненных данных
	};

	// обработчик удаления данных категории
	const deleteDataHandler = () => {
		dispatch(deleteCategoryDataException({
			exception,
			categoryId,
			categoryBlockIdx,
			categoryIdx,
		}));
		(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('exceptions')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'exceptions'] }));  // ставим флаг о несохраненных данных
	};

	return (
		<div className={styles.categoryDataBlock}>
			{/* тип условия запуска конечной точки */}
			<FormControl margin='dense' sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 }, flexShrink: 0 }}>
				<InputLabel sx={{ fontSize: 13 }}>{translate('select_type')}</InputLabel>
				<Select
					required
					label={translate('select_type')}
					value={conditionType}
					onChange={(e) => setConditionType(e.target.value as ConditionType)}
					disabled
					style={{ fontSize: 13, height: 33, color: colorPrimary }}
				>
					{CONDITION_RUN_ACTION_TYPE_LIST.map(({ type, translation }) =>
						<MenuItem key={type} value={type} sx={{ fontSize: 13, color: colorPrimary }}>
							{translate(translation)}
						</MenuItem>
					)}
				</Select>
			</FormControl>

			{/* глубина */}
			<FormControl margin='dense' sx={{ flexShrink: 0 }}>
				<TextField
					label={translate('input_depth')}
					variant="outlined"
					type='number'
					value={inputDepth}
					onChange={(e) => setInputDepth(+e.target.value)}
					onBlur={changeValuesConditionCategoryHandler}
					disabled={!isAccess(SES.ROBOT_EDIT) || activeRobotVersion !== 'draft'}
					InputProps={{
						style: {
							height: 33,
							fontSize: 13,
							color: colorPrimary,
						},
						inputProps: { min: 1, max: 20 }
					}}
					InputLabelProps={{
						style: {
							fontSize: 13,
						},
					}}
					sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
				/>
			</FormControl>

			{/* название */}
			<FormControl fullWidth margin='dense' sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}>
				<InputLabel sx={{ fontSize: 13 }}>{translate('select_name')}</InputLabel>
				<Select
					label={translate('select_name')}
					disabled={!isAccess(SES.ROBOT_EDIT) || activeRobotVersion !== 'draft'}
					value={inputId}
					onChange={(e) => setInputId(e.target.value)}
					onBlur={changeValuesConditionCategoryHandler}
					error={inputId === ''}
					style={{ fontSize: 13, height: 33, color: colorPrimary }}
					title={dataElemList.dictionary[inputId]}
				>
					{/* не найденный элемент данных из списка */}
					{!dataElemList.data.find(elem => elem.id === inputId) && inputId !== '' &&
						<MenuItem value={inputId} sx={{ fontSize: 13, color: colorRed }}>{inputId} ({translate('selectItem_notFound')})</MenuItem>
					}
					{dataElemList.data.map((elem) => (
						<MenuItem key={elem.id} value={elem.id} sx={{ fontSize: 13, color: colorPrimary }}>{elem.name}</MenuItem>
					))}
				</Select>
			</FormControl>

			{/* операция */}
			<FormControl margin='dense' sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 }, flexShrink: 0 }}>
				<Select
					required
					value={inputOperation}
					onChange={(e) => setInputOperation(e.target.value as OperationType)}
					onBlur={changeValuesConditionCategoryHandler}
					disabled={!isAccess(SES.ROBOT_EDIT) || activeRobotVersion !== 'draft'}
					style={{ fontSize: 13, height: 33, color: colorPrimary }}
				>
					{OPERATION_CONDITIONS_LIST.map(({ operation, translation }) =>
						<MenuItem key={operation} value={operation} sx={{ fontSize: 13, color: colorPrimary }}>{translate(translation)}</MenuItem>
					)}
				</Select>
			</FormControl>
			{/* значение */}
			<FormControl fullWidth margin='dense'>
				<TextField
					label={translate('input_value')}
					variant="outlined"
					value={inputValue}
					onChange={(e) => setInputValue(e.target.value)}
					onBlur={changeValuesConditionCategoryHandler}
					disabled={!isAccess(SES.ROBOT_EDIT) || activeRobotVersion !== 'draft'}
					InputProps={{
						style: {
							height: 33,
							fontSize: 13,
							color: colorPrimary,
						},
					}}
					InputLabelProps={{
						style: {
							fontSize: 13,
						},
					}}
					sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
				/>
			</FormControl>

			{/* удаление данных категории */}
			{isAccess(SES.ROBOT_EDIT) && activeRobotVersion === 'draft' &&
				<FontAwesomeIcon
					icon={faTrashCan}
					color={colorRed}
					size='lg'
					onClick={deleteDataHandler}
					title={translate('buttonTitle_deleteData')}
					style={{ cursor: 'pointer' }}
				/>
			}
		</div>
	);
};

export default CategoryData;
