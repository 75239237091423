import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError, AxiosResponse } from 'axios';
import { RootState } from './store';
import axiosApi from '../helpers/axios';
import { downloadFile } from '../helpers/downloadFile';
import { ENDPOINTS_CORPUS, URL_CORPUS } from '../constants/api_endpoints';
import { CorpusListResponseType, ICorpusState, ICopyCorpusProps, ICopyCorpusResponse, ICorpusListProps, ICorpusProps, IDeleteCorpusProps, IDeleteCorpusResponse, IImportCorpusFileProps, IImportCorpusFromModelProps, IImportCorpusResponse, IPutCorpusProps, IPutCorpusResponse, IRenameCorpusProps, IRenameCorpusResponse, IExportCorpusResponse, IExportCorpusProps, ICorpusData, IStartAutomark, IStartAutomarkResponse, IAutomarkStatusResponse, IAutomarkResultResponse, IAutomarkListResponse } from '../types/corpusTypes';
import { RequestStatus, ResponseStatus } from '../types/statusTypes';
import { IResponse } from '../types/authTypes';

const initialState: ICorpusState = {
	corpusList: {
		data: [],
		status: RequestStatus.IDLE,
	},
	corpus: {
		corpusName: null,
		data: {
			classes: [],
			data: [],
			groups: [],
		},
		classes: [],
		classAndCount: {},
		groups: [],
		classNameFiltering: null,
		status: RequestStatus.IDLE,
		error: ResponseStatus.SUCCESS,
		message: '',
	},
	corpusForJoin: {
		status: RequestStatus.IDLE,
		data: {
			classes: [],
			data: [],
			groups: [],
		},
	},
	exportCorpus: {
		status: RequestStatus.IDLE,
		error: ResponseStatus.SUCCESS,
		message: '',
	},
	importCorpus: {
		status: RequestStatus.IDLE,
		error: ResponseStatus.SUCCESS,
		message: '',
		newName: null,
		progress: 0,
	},
	copyCorpus: {
		status: RequestStatus.IDLE,
		error: ResponseStatus.SUCCESS,
		message: '',
	},
	renameCorpus: {
		status: RequestStatus.IDLE,
		error: ResponseStatus.SUCCESS,
		message: '',
	},
	putCorpus: {
		status: RequestStatus.IDLE,
		error: ResponseStatus.SUCCESS,
		message: '',
	},
	deleteCorpus: {
		status: RequestStatus.IDLE,
		error: ResponseStatus.SUCCESS,
		message: '',
	},

	autoMarkingStart: {
		taskId: null,
		status: RequestStatus.IDLE,
		error: ResponseStatus.SUCCESS,
		message: '',
	},
	autoMarkingStop: {
		status: RequestStatus.IDLE,
		error: ResponseStatus.SUCCESS,
		message: '',
	},
	autoMarkingStatus: {
		requestStatus: RequestStatus.IDLE,
		error: '',
		responseStatus: 'stopped',
		progress: 0,
	},
	autoMarkList: {
		data: [],
		status: RequestStatus.IDLE,
		error: ResponseStatus.SUCCESS,
		message: '',
	},
	autoMark: {
		data: [],
		status: RequestStatus.IDLE,
		error: ResponseStatus.SUCCESS,
		message: '',
		started: '',
		finished: '',
	},
};

// получение списка корпусов
export const getCorpusList = createAsyncThunk(
	'corpus/getCorpusList',
	async ({ serviceType }: ICorpusListProps): Promise<CorpusListResponseType> => {
		const response: AxiosResponse<CorpusListResponseType> = await axiosApi.get(`${URL_CORPUS}/${ENDPOINTS_CORPUS.LIST}/${serviceType}`);
		return response.data;
	}
);

// получение корпуса данных
export const getCorpus = createAsyncThunk(
	'corpus/getCorpus',
	async ({ corpusName, serviceType }: ICorpusProps, { rejectWithValue, signal }) => {
		try {
			const response: AxiosResponse<ICorpusData | IResponse> = await axiosApi.get(`${URL_CORPUS}/${ENDPOINTS_CORPUS.GET}/${serviceType}`, {
				params: {
					name: corpusName,
				},
				signal,
			});
			return response.data;
		} catch (error) {
			if (error) {
				// возвращаем данные ошибки, пришедшие с бэка
				return rejectWithValue(error);
			}
		}
	}
);

// получение корпуса для склеивания с другим корпусом
export const getCorpusForJoin = createAsyncThunk(
	'corpus/getCorpusForJoin',
	async ({ corpusName, serviceType }: ICorpusProps): Promise<ICorpusData | IResponse> => {
		const response: AxiosResponse<ICorpusData | IResponse> = await axiosApi.get(`${URL_CORPUS}/${ENDPOINTS_CORPUS.GET}/${serviceType}`, {
			params: {
				name: corpusName
			}
		});
		return response.data;
	}
);

// экспорт корпуса (.csv файл)
export const exportCorpus = createAsyncThunk(
	'corpus/exportCorpus',
	async ({ corpusName, serviceType }: IExportCorpusProps, { rejectWithValue }) => {
		try {
			const response: AxiosResponse<Blob | IExportCorpusResponse> = await axiosApi.get(`${URL_CORPUS}/${ENDPOINTS_CORPUS.EXPORT}/${serviceType}`, {
				params: {
					name: corpusName
				},
				responseType: 'blob',
			});
			if (response.data instanceof Blob) {
				downloadFile(response.data, `${corpusName}.csv`);
			} else return response.data;
		} catch (error) {
			if (error) {
				// возвращаем данные ошибки, пришедшие с бэка
				return rejectWithValue(error);
			}
		}
	}
);

// импортирование корпуса из .csv файла
export const importCorpusFile = createAsyncThunk(
	'corpus/importCorpusFile',
	async ({ corpusName, formData, serviceType }: IImportCorpusFileProps, { dispatch, rejectWithValue }) => {
		try {
			const response: AxiosResponse<IImportCorpusResponse> = await axiosApi.post(`${URL_CORPUS}/${ENDPOINTS_CORPUS.IMPORT}/${serviceType}`, {
				csv: formData.get('file'),
				name: corpusName,
			}, {
				headers: {
					'Content-Type': 'multipart/form-data'
				},
				onUploadProgress: (progressEvent) => {
					if (progressEvent.progress) {
						const progress = Math.round(progressEvent.progress * 100);
						dispatch(changeProgressImportCorpus(progress));
					}
				},
			});
			return response.data;
		} catch (error) {
			if (error) {
				// возвращаем данные ошибки, пришедшие с бэка
				return rejectWithValue(error);
			}
		}
	}
);

// импортирование корпуса из модели
export const importCorpusFromModel = createAsyncThunk(
	'corpus/importCorpusFromModel',
	async ({ corpusName, startDate, stopDate, modelName, limit, serviceType }: IImportCorpusFromModelProps, { rejectWithValue }) => {
		try {
			const response: AxiosResponse<IImportCorpusResponse> = await axiosApi.post(`${URL_CORPUS}/${ENDPOINTS_CORPUS.IMPORT}/${serviceType}`, {
				name: corpusName,
				startDate,
				stopDate,
				model: modelName,
				limit,
			}, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			});
			return response.data;
		} catch (error) {
			if (error) {
				// возвращаем данные ошибки, пришедшие с бэка
				return rejectWithValue(error);
			}
		}
	}
);

// копирование корпуса
export const copyCorpus = createAsyncThunk(
	'corpus/copyCorpus',
	async ({ corpusNameSource, corpusNameDestination, serviceType }: ICopyCorpusProps, { rejectWithValue }) => {
		try {
			const response: AxiosResponse<ICopyCorpusResponse> = await axiosApi.post(`${URL_CORPUS}/${ENDPOINTS_CORPUS.COPY}/${serviceType}`, {
				srcname: corpusNameSource,
				dstname: corpusNameDestination
			});
			return response.data;
		} catch (error) {
			if (error) {
				// возвращаем данные ошибки, пришедшие с бэка
				return rejectWithValue(error);
			}
		}
	}
);

// переименовывание корпуса
export const renameCorpus = createAsyncThunk(
	'corpus/renameCorpus',
	async ({ corpusNameSource, corpusNameDestination, serviceType }: IRenameCorpusProps, { rejectWithValue }) => {
		try {
			const response: AxiosResponse<IRenameCorpusResponse> = await axiosApi.post(`${URL_CORPUS}/${ENDPOINTS_CORPUS.RENAME}/${serviceType}`, {
				srcname: corpusNameSource,
				dstname: corpusNameDestination
			});
			return response.data;
		} catch (error) {
			if (error) {
				// возвращаем данные ошибки, пришедшие с бэка
				return rejectWithValue(error);
			}
		}
	}
);

// изменение корпуса (а также создание)
export const putCorpus = createAsyncThunk(
	'corpus/putCorpus',
	async ({ corpusName, text, serviceType }: IPutCorpusProps, { rejectWithValue }) => {
		try {
			const response: AxiosResponse<IPutCorpusResponse> = await axiosApi.post(`${URL_CORPUS}/${ENDPOINTS_CORPUS.PUT}/${serviceType}`, {
				json: JSON.stringify(text),
				name: corpusName
			});
			return response.data;
		} catch (error) {
			if (error) {
				// возвращаем данные ошибки, пришедшие с бэка
				return rejectWithValue(error);
			}
		}
	}
);

// удаление корпуса
export const deleteCorpus = createAsyncThunk(
	'corpus/deleteCorpus',
	async ({ corpusName, serviceType }: IDeleteCorpusProps, { rejectWithValue }) => {
		try {
			const response: AxiosResponse<IDeleteCorpusResponse> = await axiosApi.delete(`${URL_CORPUS}/${ENDPOINTS_CORPUS.DELETE}/${serviceType}`, {
				params: {
					name: corpusName
				}
			});
			return response.data;
		} catch (error) {
			if (error) {
				// возвращаем данные ошибки, пришедшие с бэка
				return rejectWithValue(error);
			}
		}
	}
);

// запуск авторазметки
export const startAutomark = createAsyncThunk(
	'corpus/startAutomark',
	async ({ taskName, corpusName, marksId, appendMarks }: IStartAutomark, { rejectWithValue }) => {
		try {
			const response: AxiosResponse<IStartAutomarkResponse> = await axiosApi.get(`${URL_CORPUS}/${ENDPOINTS_CORPUS.AUTOMARK_START}`, {
				params: {
					corpus: corpusName,
					marks: marksId,
					taskname: taskName,
					appendMarks,
				},
			});
			return response.data;
		} catch (error) {
			if (error) {
				// возвращаем данные ошибки, пришедшие с бэка
				return rejectWithValue(error);
			}
		}
	}
);

// остановка авторазметки
export const stopAutomark = createAsyncThunk(
	'corpus/stopAutomark',
	async (taskId: string, { rejectWithValue }) => {
		try {
			const response: AxiosResponse<IResponse> = await axiosApi.get(`${URL_CORPUS}/${ENDPOINTS_CORPUS.AUTOMARK_STOP}/${taskId}`);
			return response.data;
		} catch (error) {
			if (error) {
				// возвращаем данные ошибки, пришедшие с бэка
				return rejectWithValue(error);
			}
		}
	}
);

// статус авторазметки
export const getStatusAutomark = createAsyncThunk(
	'corpus/getStatusAutomark',
	async (taskId: string, { rejectWithValue }) => {
		try {
			const response: AxiosResponse<IAutomarkStatusResponse | IResponse> = await axiosApi.get(`${URL_CORPUS}/${ENDPOINTS_CORPUS.AUTOMARK_STATUS}/${taskId}`);
			return response.data;
		} catch (error) {
			if (error) {
				// возвращаем данные ошибки, пришедшие с бэка
				return rejectWithValue(error);
			}
		}
	}
);

// список задач авторазметки
export const getAutomarkList = createAsyncThunk(
	'corpus/getAutomarkList',
	async (_, { rejectWithValue }) => {
		try {
			const response: AxiosResponse<IAutomarkListResponse> = await axiosApi.get(`${URL_CORPUS}/${ENDPOINTS_CORPUS.AUTOMARK_LIST}`);
			return response.data;
		} catch (error) {
			if (error) {
				// возвращаем данные ошибки, пришедшие с бэка
				return rejectWithValue(error);
			}
		}
	}
);

// результат авторазметки
export const getAutomarkResult = createAsyncThunk(
	'corpus/getAutomarkResult',
	async (taskId: string, { rejectWithValue }) => {
		try {
			const response: AxiosResponse<IAutomarkResultResponse> = await axiosApi.get(`${URL_CORPUS}/${ENDPOINTS_CORPUS.AUTOMARK_RESULT}/${taskId}`);
			return response.data;
		} catch (error) {
			if (error) {
				// возвращаем данные ошибки, пришедшие с бэка
				return rejectWithValue(error);
			}
		}
	}
);

const corpusSlice = createSlice({
	name: 'corpus',
	initialState,
	reducers: {
		// добавление имени корпуса
		addCorpusName: (state, action: PayloadAction<string | null>) => {
			state.corpus.corpusName = action.payload;
		},
		// добавление имени импортируемого корпуса
		addImportCorpusName: (state, action: PayloadAction<string>) => {
			state.importCorpus.newName = action.payload;
		},
		// изменение имени корпуса (для переименовывания) без перезагрузки страницы
		editCorpusName: (state, action: PayloadAction<{ prevName: string, newName: string }>) => {
			state.corpusList.data = state.corpusList.data.map(corpusName => {
				// если изменяемое имя совпало с именем из списка корпусов - меняем на новое
				if (action.payload.prevName === corpusName) return action.payload.newName;
				else return corpusName; // иначе оставляем как есть
			});
			state.corpus.corpusName = action.payload.newName; // меняем активное имя на новое
		},
		// изменение прогресса импорта корпуса в %
		changeProgressImportCorpus: (state, action: PayloadAction<number>) => {
			state.importCorpus.progress = action.payload;
		},
		// добавление классов
		addClasses: (state, action: PayloadAction<string[]>) => {
			state.corpus.classes = action.payload;
		},
		// добавление классов и их количества
		addClassAndCount: (state, action: PayloadAction<Record<string, number>>) => {
			state.corpus.classAndCount = action.payload;
		},
		// добавление групп
		addGroups: (state, action: PayloadAction<string[]>) => {
			state.corpus.groups = action.payload;
		},
		// изменение ячейки таблицы корпуса (по факту изменение строки полностью)
		editCorpusDataCell: (state, action: PayloadAction<{ index: number, row: [string[], string], type: keyof ICorpusData }>) => {
			state.corpus.data[action.payload.type].splice(action.payload.index, 1, action.payload.row);
		},
		// добавление классов в ячейки класса таблицы корпуса
		addClassToRows: (state, action: PayloadAction<{ rows: number[], classNames: string[], type: keyof ICorpusData }>) => {
			state.corpus.data[action.payload.type] = state.corpus.data[action.payload.type].map((row, idx) => {
				if (action.payload.rows.includes(idx)) {
					row[0].push(...action.payload.classNames);
					row[0].sort(); // восстанавливаем сортировку
					const newSet = new Set(row[0]); // убираем повторные классы
					const uniqueClasses = Array.from(newSet);
					return [uniqueClasses, row[1]];
				} else return row;
			});
		},
		// удаление классов из ячеек класса таблицы корпуса
		deleteClassFromRows: (state, action: PayloadAction<{ rows: number[], classNames: string[], type: keyof ICorpusData }>) => {
			state.corpus.data[action.payload.type] = state.corpus.data[action.payload.type].map((row, idx) => {
				if (action.payload.rows.includes(idx)) {
					const difference = row[0].filter(classItem => !action.payload.classNames.includes(classItem));
					return [difference, row[1]];
				} else return row;
			});
		},
		// изменение корпуса данных
		editCorpusData: (state, action: PayloadAction<ICorpusData>) => {
			state.corpus.data = action.payload;
		},
		// добавление строк в таблицу корпуса
		addRows: (state, action: PayloadAction<{ data: [string[], string][], type: keyof ICorpusData }>) => {
			state.corpus.data[action.payload.type].push(...action.payload.data);
		},
		// удаление строки из таблицы корпуса
		// deleteRow: (state, action: PayloadAction<number>) => {
		// 	state.corpus.data = state.corpus.data.filter((_, idx) => action.payload !== idx);
		// },
		// удаление строк из таблицы корпуса
		deleteRows: (state, action: PayloadAction<{ rows: number[], type: keyof ICorpusData }>) => {
			state.corpus.data[action.payload.type] = state.corpus.data[action.payload.type].filter((_row, idx) => !action.payload.rows.includes(idx));
		},
		// переименование класса
		renameClass: (state, action: PayloadAction<{ oldClassName: string, newClassName: string, isParent?: boolean }>) => {
			const renameClassesCallback = (row: [string[], string]): [string[], string] => {
				if (row[0].includes(action.payload.oldClassName)) {
					const classIndex = row[0].findIndex(elem => elem === action.payload.oldClassName); // индекс класса на переименование
					row[0].splice(classIndex, 1, action.payload.newClassName); // замена имени
					row[0].sort(); // восстанавливаем сортировку
					// если есть повторы классов после переименования
					if (row[0].filter(classItem => classItem === action.payload.newClassName).length > 1) {
						const newSet = new Set(row[0]); // убираем повторные классы
						const uniqueClasses = Array.from(newSet);
						return [uniqueClasses, row[1]];
					} else return row;
				} else return row;
			};

			// если переименовывается родитель
			if (action.payload.isParent) {
				// если уже существует имя класса
				if (state.corpus.data.classes.find(row => row[1] === action.payload.newClassName)) {
					// удаленная строка (дерево)
					const removedTree = state.corpus.data.classes.splice(state.corpus.data.classes.findIndex(row => row[1] === action.payload.oldClassName), 1);
					// добавляем к родителю, на который переименовали - потомков с переименованного родителя
					state.corpus.data.classes = state.corpus.data.classes.map((row) => {
						if (row[1] === action.payload.newClassName) return [[...row[0], ...removedTree[0][0]].sort(), row[1]];
						else return row;
					});
				} else {
					state.corpus.data.classes = state.corpus.data.classes.map((row) => {
						if (row[1] === action.payload.oldClassName) return [row[0], action.payload.newClassName];
						else return row;
					});
				}
			}
			// если переименовывается потомок
			else state.corpus.data.classes = state.corpus.data.classes.map(renameClassesCallback);
			state.corpus.data.data = state.corpus.data.data.map(renameClassesCallback);
			state.corpus.data.groups = state.corpus.data.groups.map(renameClassesCallback);
		},
		// переименование группы
		renameGroup: (state, action: PayloadAction<{ oldGroupName: string, newGroupName: string }>) => {
			state.corpus.data.groups = state.corpus.data.groups.map(row => {
				if (row[1] === action.payload.oldGroupName) return [row[0], action.payload.newGroupName];
				else return row;
			});
		},
		// удаление класса
		deleteClass: (state, action: PayloadAction<string>) => {
			const deleteClassCallback = (row: [string[], string]): [string[], string] => {
				if (row[0].includes(action.payload)) {
					const classIndex = row[0].findIndex(elem => elem === action.payload); // индекс класса на удаление
					row[0].splice(classIndex, 1); // удаление класса
					return row;
				} else return row;
			};

			// для родительских классов
			state.corpus.data.classes = state.corpus.data.classes.filter(row => row[1] !== action.payload);
			// для дочерних классов
			state.corpus.data.classes = state.corpus.data.classes.map(deleteClassCallback);
			state.corpus.data.data = state.corpus.data.data.map(deleteClassCallback);
			state.corpus.data.groups = state.corpus.data.groups.map(deleteClassCallback);
		},
		// удаление группы
		deleteGroup: (state, action: PayloadAction<string>) => {
			state.corpus.data.groups = state.corpus.data.groups.filter(row => row[1] !== action.payload);
		},
		// добавление имени класса из блока списка классов для фильтрации в таблице
		addFilterByClass: (state, action: PayloadAction<string | null>) => {
			state.corpus.classNameFiltering = action.payload;
		},
		// изменение класса на класс-родитель
		changeClassToParent: (state, action: PayloadAction<string>) => {
			const intermediateResult = state.corpus.data.classes;
			intermediateResult.push([[], action.payload]);
			intermediateResult.sort();
			state.corpus.data.classes = intermediateResult;
		},
		// изменение класса-родителя на обычный
		changeParentToNormal: (state, action: PayloadAction<{ parent: string, children: string[] }>) => {
			state.corpus.data.classes = state.corpus.data.classes.filter(row => row[1] !== action.payload.parent);
			// удаление класса-родителя от классов-потомков в разметке корпуса
			state.corpus.data.data = state.corpus.data.data.map((row) => {
				if (row[0].includes(action.payload.parent) && row[0].filter(classItem => action.payload.children.includes(classItem)).length > 0) {
					return [row[0].filter(classItem => classItem !== action.payload.parent), row[1]];
				}
				else return row;
			});
		},
		// перемещение класса 
		movingClass: (state, action: PayloadAction<{ child: string, parent: string | null }>) => {
			state.corpus.data.classes = state.corpus.data.classes.map((row) => {
				if (row[0].includes(action.payload.child)) {
					return [row[0].filter(children => children !== action.payload.child), row[1]]; // убираем из родителя
				}
				else if (row[1] === action.payload.parent) return [[...row[0], action.payload.child].sort(), row[1]]; // добавляем в родителя
				else return row;
			});
		},
		// добавление класса-родителя к классу-потомку в разметке корпуса
		addingParentToChild: (state, action: PayloadAction<{ child: string, parent: string }>) => {
			state.corpus.data.data = state.corpus.data.data.map((row) => {
				if (row[0].includes(action.payload.child) && !row[0].includes(action.payload.parent)) {
					const editingRow: [string[], string] = [[...row[0], action.payload.parent].sort(), row[1]];
					return editingRow;
				}
				else return row;
			});
		},
		// удаление класса-родителя от класса-потомка в разметке корпуса
		removingParentFromChild: (state, action: PayloadAction<{ child: string, oldParent: string }>) => {
			state.corpus.data.data = state.corpus.data.data.map((row) => {
				if (row[0].includes(action.payload.child) && row[0].includes(action.payload.oldParent)) {
					return [row[0].filter(classItem => classItem !== action.payload.oldParent), row[1]];
				}
				else return row;
			});
		},
		// изменение класса-родителя в разметке корпуса
		changeParentClass: (state, action: PayloadAction<{ child: string, oldParent: string, newParent: string }>) => {
			state.corpus.data.data = state.corpus.data.data.map((row) => {
				if (row[0].includes(action.payload.child) && row[0].includes(action.payload.oldParent)) {
					row[0].splice(row[0].indexOf(action.payload.oldParent), 1, action.payload.newParent);
					return row;
				}
				else return row;
			});
		},
		// очистка state
		clearState: (state) => {
			state.corpusList = initialState.corpusList;
			state.corpus = initialState.corpus;
			state.corpusForJoin = initialState.corpusForJoin;
			state.importCorpus = initialState.importCorpus;
			state.copyCorpus = initialState.copyCorpus;
			state.renameCorpus = initialState.renameCorpus;
			state.putCorpus = initialState.putCorpus;
			state.deleteCorpus = initialState.deleteCorpus;
			state.autoMarkingStart = initialState.autoMarkingStart;
			state.autoMarkingStop = initialState.autoMarkingStop;
			state.autoMarkingStatus = initialState.autoMarkingStatus;
			state.autoMarkList = initialState.autoMarkList;
			state.autoMark = initialState.autoMark;
		},
		// очистка корпуса для склеивания с другим корпусом
		clearCorpusDataForJoin: (state) => {
			state.corpusForJoin = initialState.corpusForJoin;
		},
		// очистка статуса экспорта корпуса
		clearExportResponseCorpus: (state) => {
			state.exportCorpus = initialState.exportCorpus;
		},
		// очистка статуса импорта корпуса
		clearImportResponseCorpus: (state) => {
			state.importCorpus = initialState.importCorpus;
		},
		// очистка статуса копирования корпуса
		clearCopyResponseCorpus: (state) => {
			state.copyCorpus = initialState.copyCorpus;
		},
		// очистка статуса переименования корпуса
		clearRenameResponseCorpus: (state) => {
			state.renameCorpus = initialState.renameCorpus;
		},
		// очистка статуса изменения корпуса
		clearPutResponseCorpus: (state) => {
			state.putCorpus = initialState.putCorpus;
		},
		// очистка статуса удаления корпуса
		clearDeleteResponseCorpus: (state) => {
			state.deleteCorpus = initialState.deleteCorpus;
		},
		// очистка корпуса данных
		clearCorpus: (state) => {
			state.corpus = initialState.corpus;
		},
		// очистка списка корпусов и корпуса данных
		clearCorpuses: (state) => {
			state.corpusList = initialState.corpusList;
			state.corpus = initialState.corpus;
		},
		// очистка статуса старта авторазметки
		clearAutomarkingStart: (state) => {
			state.autoMarkingStart = initialState.autoMarkingStart;
		},
		// очистка статуса остановки авторазметки
		clearAutomarkingStop: (state) => {
			state.autoMarkingStop = initialState.autoMarkingStop;
		},
		// очистка статуса процесса авторазметки
		clearAutomarkingStatus: (state) => {
			state.autoMarkingStatus = initialState.autoMarkingStatus;
		},
		// очистка списка задач авторазметки
		clearAutomarkList: (state) => {
			state.autoMarkList = initialState.autoMarkList;
		},
		// очистка данных авторазметки
		clearAutomark: (state) => {
			state.autoMark = initialState.autoMark;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getCorpusList.pending, (state) => {
				state.corpusList.status = RequestStatus.LOADING;
			})
			.addCase(getCorpusList.fulfilled, (state, action) => {
				state.corpusList.status = RequestStatus.IDLE;
				state.corpusList.data = action.payload.sort();
			})
			.addCase(getCorpusList.rejected, (state) => {
				state.corpusList.status = RequestStatus.FAILED;
			})
			.addCase(getCorpus.pending, (state) => {
				state.corpus.status = RequestStatus.LOADING;
			})
			.addCase(getCorpus.fulfilled, (state, action) => {
				state.corpus.status = RequestStatus.IDLE;
				if (action.payload && typeof action.payload === 'object' && 'classes' in action.payload && Array.isArray(action.payload.classes)) {

					const usedClasses: string[] = []; // задействованные классы
					const treeData: [string[], string][] = []; // фильтрованные классы без повторений и многомерных вложенностей

					action.payload.classes.forEach(([children, parent]) => {
						if (!usedClasses.includes(parent)) {
							usedClasses.push(parent);
							const childrenNewData: string[] = [];

							children.forEach(child => {
								if (!usedClasses.includes(child)) {
									childrenNewData.push(child);
									usedClasses.push(child);
								}
							});
							treeData.push([childrenNewData.sort(), parent]);
						}
					});

					state.corpus.data.classes = treeData.sort();

					// state.corpus.data.classes = action.payload.classes.map(row => {
					// 	return [row[0].sort(), row[1]];
					// });
					state.corpus.data.data = action.payload.data.map(row => {
						return [row[0].sort(), row[1]];
					});
					state.corpus.data.groups = action.payload.groups.map(row => {
						return [row[0].sort(), row[1]];
					});
				}
			})
			.addCase(getCorpus.rejected, (state, action: PayloadAction<unknown>) => {
				state.corpus.status = RequestStatus.FAILED;
				if (action.payload instanceof AxiosError) {
					state.corpus.error = action.payload.response?.data.error;
					state.corpus.message = action.payload.response?.data.message;
				}
			})
			.addCase(getCorpusForJoin.pending, (state) => {
				state.corpusForJoin.status = RequestStatus.LOADING;
			})
			.addCase(getCorpusForJoin.fulfilled, (state, action) => {
				state.corpusForJoin.status = RequestStatus.IDLE;
				if (action.payload && typeof action.payload === 'object' && 'classes' in action.payload && Array.isArray(action.payload.classes)) {
					state.corpusForJoin.data.classes = action.payload.classes.map(row => {
						return [row[0].sort(), row[1]];
					});
					state.corpusForJoin.data.data = action.payload.data.map(row => {
						return [row[0].sort(), row[1]];
					});
					state.corpusForJoin.data.groups = action.payload.groups.map(row => {
						return [row[0].sort(), row[1]];
					});
				}
			})
			.addCase(getCorpusForJoin.rejected, (state) => {
				state.corpusForJoin.status = RequestStatus.FAILED;
			})
			.addCase(exportCorpus.pending, (state) => {
				state.exportCorpus.status = RequestStatus.LOADING;
			})
			.addCase(exportCorpus.fulfilled, (state, action) => {
				state.exportCorpus.status = RequestStatus.IDLE;
				if (action.payload && 'error' in action.payload) {
					state.exportCorpus.error = action.payload.error;
					state.exportCorpus.message = action.payload.message;
				}
			})
			.addCase(exportCorpus.rejected, (state, action: PayloadAction<unknown>) => {
				state.exportCorpus.status = RequestStatus.FAILED;
				if (action.payload instanceof AxiosError) {
					state.exportCorpus.error = action.payload.response?.data.error;
					state.exportCorpus.message = action.payload.response?.data.message;
				}
			})
			.addCase(importCorpusFile.pending, (state) => {
				state.importCorpus.status = RequestStatus.LOADING;
			})
			.addCase(importCorpusFile.fulfilled, (state, action) => {
				state.importCorpus.status = RequestStatus.IDLE;
				if (action.payload) {
					state.importCorpus.error = action.payload.error;
					state.importCorpus.message = action.payload.message;
				}
			})
			.addCase(importCorpusFile.rejected, (state, action: PayloadAction<unknown>) => {
				state.importCorpus.status = RequestStatus.FAILED;
				if (action.payload instanceof AxiosError) {
					state.importCorpus.error = action.payload.response?.data.error;
					state.importCorpus.message = action.payload.response?.data.message;
				}
			})
			.addCase(importCorpusFromModel.pending, (state) => {
				state.importCorpus.status = RequestStatus.LOADING;
			})
			.addCase(importCorpusFromModel.fulfilled, (state, action) => {
				state.importCorpus.status = RequestStatus.IDLE;
				if (action.payload) {
					state.importCorpus.error = action.payload.error;
					state.importCorpus.message = action.payload.message;
				}
			})
			.addCase(importCorpusFromModel.rejected, (state, action: PayloadAction<unknown>) => {
				state.importCorpus.status = RequestStatus.FAILED;
				if (action.payload instanceof AxiosError) {
					state.importCorpus.error = action.payload.response?.data.error;
					state.importCorpus.message = action.payload.response?.data.message;
				}
			})
			.addCase(copyCorpus.pending, (state) => {
				state.copyCorpus.status = RequestStatus.LOADING;
			})
			.addCase(copyCorpus.fulfilled, (state, action) => {
				state.copyCorpus.status = RequestStatus.IDLE;
				if (action.payload) {
					state.copyCorpus.error = action.payload.error;
					state.copyCorpus.message = action.payload.message;
				}
			})
			.addCase(copyCorpus.rejected, (state, action: PayloadAction<unknown>) => {
				state.copyCorpus.status = RequestStatus.FAILED;
				if (action.payload instanceof AxiosError) {
					state.copyCorpus.error = action.payload.response?.data.error;
					state.copyCorpus.message = action.payload.response?.data.message;
				}
			})
			.addCase(renameCorpus.pending, (state) => {
				state.renameCorpus.status = RequestStatus.LOADING;
			})
			.addCase(renameCorpus.fulfilled, (state, action) => {
				state.renameCorpus.status = RequestStatus.IDLE;
				if (action.payload) {
					state.renameCorpus.error = action.payload.error;
					state.renameCorpus.message = action.payload.message;
				}
			})
			.addCase(renameCorpus.rejected, (state, action: PayloadAction<unknown>) => {
				state.renameCorpus.status = RequestStatus.FAILED;
				if (action.payload instanceof AxiosError) {
					state.renameCorpus.error = action.payload.response?.data.error;
					state.renameCorpus.message = action.payload.response?.data.message;
				}
			})
			.addCase(putCorpus.pending, (state) => {
				state.putCorpus.status = RequestStatus.LOADING;
			})
			.addCase(putCorpus.fulfilled, (state, action) => {
				state.putCorpus.status = RequestStatus.IDLE;
				if (action.payload) {
					state.putCorpus.error = action.payload.error;
					state.putCorpus.message = action.payload.message;
				}
			})
			.addCase(putCorpus.rejected, (state, action: PayloadAction<unknown>) => {
				state.putCorpus.status = RequestStatus.FAILED;
				if (action.payload instanceof AxiosError) {
					state.putCorpus.error = action.payload.response?.data.error;
					state.putCorpus.message = action.payload.response?.data.message;
				}
			})
			.addCase(deleteCorpus.pending, (state) => {
				state.deleteCorpus.status = RequestStatus.LOADING;
			})
			.addCase(deleteCorpus.fulfilled, (state, action) => {
				state.deleteCorpus.status = RequestStatus.IDLE;
				if (action.payload) {
					state.deleteCorpus.error = action.payload.error;
					state.deleteCorpus.message = action.payload.message;
				}
			})
			.addCase(deleteCorpus.rejected, (state, action: PayloadAction<unknown>) => {
				state.deleteCorpus.status = RequestStatus.FAILED;
				if (action.payload instanceof AxiosError) {
					state.deleteCorpus.error = action.payload.response?.data.error;
					state.deleteCorpus.message = action.payload.response?.data.message;
				}
			})
			.addCase(startAutomark.pending, (state) => {
				state.autoMarkingStart.status = RequestStatus.LOADING;
			})
			.addCase(startAutomark.fulfilled, (state, action) => {
				if (action.payload) {
					state.autoMarkingStart.status = RequestStatus.IDLE;
					state.autoMarkingStart.error = action.payload.error;
					state.autoMarkingStart.message = action.payload.message;
					if (action.payload.id) state.autoMarkingStart.taskId = action.payload.id;
				} else state.autoMarkingStart.status = RequestStatus.FAILED;
			})
			.addCase(startAutomark.rejected, (state, action: PayloadAction<unknown>) => {
				state.autoMarkingStart.status = RequestStatus.FAILED;
				if (action.payload instanceof AxiosError) {
					state.autoMarkingStart.error = action.payload.response?.data.error;
					state.autoMarkingStart.message = action.payload.response?.data.message;
				}
			})
			.addCase(stopAutomark.pending, (state) => {
				state.autoMarkingStop.status = RequestStatus.LOADING;
			})
			.addCase(stopAutomark.fulfilled, (state, action) => {
				if (action.payload) {
					state.autoMarkingStop.status = RequestStatus.IDLE;
					state.autoMarkingStop.error = action.payload.error;
					state.autoMarkingStop.message = action.payload.message;
				} else state.autoMarkingStop.status = RequestStatus.FAILED;
			})
			.addCase(stopAutomark.rejected, (state, action: PayloadAction<unknown>) => {
				state.autoMarkingStop.status = RequestStatus.FAILED;
				if (action.payload instanceof AxiosError) {
					state.autoMarkingStop.error = action.payload.response?.data.error;
					state.autoMarkingStop.message = action.payload.response?.data.message;
				}
			})
			.addCase(getStatusAutomark.pending, (state) => {
				state.autoMarkingStatus.requestStatus = RequestStatus.LOADING;
			})
			.addCase(getStatusAutomark.fulfilled, (state, action) => {
				if (action.payload && typeof action.payload == 'object' && 'status' in action.payload) {
					state.autoMarkingStatus.requestStatus = RequestStatus.IDLE;
					state.autoMarkingStatus.error = action.payload.error;
					state.autoMarkingStatus.responseStatus = action.payload.status;
					state.autoMarkingStatus.progress = action.payload.progress;
				} else state.autoMarkingStatus.requestStatus = RequestStatus.FAILED;
			})
			.addCase(getStatusAutomark.rejected, (state, action: PayloadAction<unknown>) => {
				state.autoMarkingStatus.requestStatus = RequestStatus.FAILED;
				if (action.payload instanceof AxiosError) {
					state.autoMarkingStatus.error = action.payload.response?.data.error;
				}
			})
			.addCase(getAutomarkList.pending, (state) => {
				state.autoMarkList.status = RequestStatus.LOADING;
			})
			.addCase(getAutomarkList.fulfilled, (state, action) => {
				if (action.payload) {
					state.autoMarkList.status = RequestStatus.IDLE;
					if (action.payload.tasks) state.autoMarkList.data = action.payload.tasks;
				} else state.autoMarkList.status = RequestStatus.FAILED;
			})
			.addCase(getAutomarkList.rejected, (state, action: PayloadAction<unknown>) => {
				state.autoMarkList.status = RequestStatus.FAILED;
				if (action.payload instanceof AxiosError) {
					state.autoMarkList.error = action.payload.response?.data.error;
					state.autoMarkList.message = action.payload.response?.data.message;
				}
			})
			.addCase(getAutomarkResult.pending, (state) => {
				state.autoMark.status = RequestStatus.LOADING;
			})
			.addCase(getAutomarkResult.fulfilled, (state, action) => {
				if (action.payload) {
					if (typeof action.payload === 'object' && 'corpus' in action.payload && Array.isArray(action.payload.corpus)) {
						state.autoMark.status = RequestStatus.IDLE;
						state.autoMark.data = action.payload.corpus;
						if (action.payload.started) state.autoMark.started = action.payload.started;
						if (action.payload.finished) state.autoMark.finished = action.payload.finished;
					} else {
						state.autoMark.status = RequestStatus.FAILED;
						state.autoMark.error = action.payload.error;
						state.autoMark.message = action.payload.message;
					}
				}
			})
			.addCase(getAutomarkResult.rejected, (state, action: PayloadAction<unknown>) => {
				state.autoMark.status = RequestStatus.FAILED;
				if (action.payload instanceof AxiosError) {
					state.autoMark.error = action.payload.response?.data.error;
					state.autoMark.message = action.payload.response?.data.message;
				}
			});
	},
});

export const { addCorpusName, addImportCorpusName, editCorpusName, changeProgressImportCorpus, addClasses, addClassAndCount, addGroups, editCorpusDataCell, addClassToRows, deleteClassFromRows, editCorpusData, addRows, /* deleteRow, */ deleteRows, renameClass, renameGroup, deleteClass, deleteGroup, addFilterByClass, changeClassToParent, changeParentToNormal, movingClass, addingParentToChild, removingParentFromChild, changeParentClass, clearState, clearCorpusDataForJoin, clearExportResponseCorpus, clearImportResponseCorpus, clearCopyResponseCorpus, clearRenameResponseCorpus, clearPutResponseCorpus, clearDeleteResponseCorpus, clearCorpus, clearCorpuses, clearAutomarkingStart, clearAutomarkingStop, clearAutomarkingStatus, clearAutomarkList, clearAutomark } = corpusSlice.actions;

export const selectCorpusList = (state: RootState) => state.corpus.corpusList;
export const selectCorpus = (state: RootState) => state.corpus.corpus;
export const selectCorpusName = (state: RootState) => state.corpus.corpus.corpusName;
export const selectCorpusForJoin = (state: RootState) => state.corpus.corpusForJoin;

export const selectClasses = (state: RootState) => state.corpus.corpus.classes;

export const selectCorpusImportStatus = (state: RootState) => state.corpus.importCorpus;
export const selectCorpusExportStatus = (state: RootState) => state.corpus.exportCorpus;
export const selectCorpusCopyStatus = (state: RootState) => state.corpus.copyCorpus;
export const selectCorpusRenameStatus = (state: RootState) => state.corpus.renameCorpus;
export const selectCorpusPutStatus = (state: RootState) => state.corpus.putCorpus;
export const selectCorpusDeleteStatus = (state: RootState) => state.corpus.deleteCorpus;

export const selectAutoMarkingStart = (state: RootState) => state.corpus.autoMarkingStart;
export const selectAutoMarkingStop = (state: RootState) => state.corpus.autoMarkingStop;
export const selectAutoMarkingStatus = (state: RootState) => state.corpus.autoMarkingStatus;
export const selectAutoMarkList = (state: RootState) => state.corpus.autoMarkList;
export const selectAutoMark = (state: RootState) => state.corpus.autoMark;

export default corpusSlice.reducer;
