import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { Fade, FormControl, InputLabel, MenuItem, Select, Slide, TextField } from '@mui/material';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { changeCategoryFilter, clearAddFileStatus, clearFragmentForSelection, getFilesList, selectAddingFileStatus, selectCategoriesList, selectDocument, selectFilesList, selectFragmentToQuestion } from '../../../store/qasSlice';
import useAccessRight from '../../../hooks/useAccessRight';
import useTranslate from '../../../hooks/useTranslate';
import { QAS } from '../../../constants/accessRights';
import { CANDIDATES, QUESTION } from '../../../constants/routes';
import { QAS_FILE_ID } from '../../../constants/cookieNames';
import { colorPrimary } from '../../../constants/colors';
import { IFileData } from '../../../types/qasTypes';
import { RequestStatus } from '../../../types/statusTypes';
import HideSidebar from '../HideSidebar/HideSidebar';
import FormAddingDocumentFile from '../../Forms/FormAddingDocumentFile/FormAddingDocumentFile';
import ProgressCircle from '../../ProgressCircle/ProgressCircle';
import Notification from '../../Notification/Notification';
import { IFileNavBarProps } from './FileNavBar.props';
import styles from './FileNavBar.module.scss';

const FileNavBar = ({ fileHandler, showSidebar, setShowSidebar, setShowPage, filterByCategory, searchDocument, setSearchDocument }: IFileNavBarProps): JSX.Element => {
	const [selectCategoryId, setSelectCategoryId] = useState<string>(filterByCategory); // фильтр по категории
	const [showModal, setShowModal] = useState<boolean>(false); // показ формы добавления файла
	const [showNotification, setShowNotification] = useState<boolean>(false); // показ уведомлений
	const listRef = useRef<HTMLUListElement | null>(null); // ссылка на список файлов

	const dispatch = useAppDispatch();
	const filesList = useAppSelector(selectFilesList); // store - список файлов
	const categoriesList = useAppSelector(selectCategoriesList); // store - список категорий
	const documentFile = useAppSelector(selectDocument); // store - документ (файл)
	const { fragmentForSelection } = useAppSelector(selectFragmentToQuestion); // store - данные поиска фрагмента на вопрос

	const navigate = useNavigate(); // hook для навигации
	const [cookies] = useCookies([QAS_FILE_ID]); // hook для работы с cookie
	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// следим за списком файлов, фильтрацией по категории/поиску документов
	useEffect(() => {
		// если есть доступ и список файлов не пустой
		if (isAccess(QAS.DOC_GET) && Array.isArray(filesList.data) && filesList.data.filter(filterFunction).length > 0) {
			// если есть запись в cookie и список файлов содержит эту запись, то передаем ее в обработчик выбора активного файла, иначе выбираем первый файл из списка
			filesList.data.filter(filterFunction).find(fileItem => fileItem.id === cookies[QAS_FILE_ID]) && cookies[QAS_FILE_ID] ?
				fileHandler(cookies[QAS_FILE_ID])
				:
				fileHandler(filesList.data.filter(filterFunction)[0].id);
		}
	}, [filesList.data, searchDocument, selectCategoryId]);

	// следим за активным документом
	useEffect(() => {
		Array.isArray(filesList.data) && listRef.current?.children[filesList.data.filter(filterFunction).findIndex(fileItem => fileItem.id === documentFile.fileId)]?.scrollIntoView({ block: "center" }); // показ активного файла в центре списка с имеющейся полосой прокрутки
	}, [documentFile.fileId]);

	// обработчик выбора активного файла
	const fileSelectionHandler = (id: string) => {
		// если текущий id не равен id выбираемого файла
		if (id !== documentFile.fileId) {
			fragmentForSelection && dispatch(clearFragmentForSelection()); // очищаем фрагмент для выделения в документе, если есть
			fileHandler(id); // запускаем обработчик получения файла
		}
	};

	// функция фильтрации списка документов
	const filterFunction = (document: IFileData): boolean => {
		return document.title.toLowerCase().includes(searchDocument.toLowerCase()) && (document.category === selectCategoryId || selectCategoryId === '');
	};

	// задержка для перехода на другую страницу
	const delayToHidePage = (link: string): void => {
		setShowPage(false); // уводим страницу в фон
		setTimeout(() => {
			navigate(link);
		}, 500);
	};

	return (
		<Slide direction="right" in={showSidebar} timeout={800} style={{ visibility: 'visible', zIndex: 1 }}>
			<div>
				<div className={styles.sidebar}>
					<HideSidebar showSidebar={showSidebar} setShowSidebar={setShowSidebar} />

					<div className={styles.navbar}>
						{/* ошибка загрузки списка файлов */}
						{(filesList.status === RequestStatus.FAILED || (filesList.data && 'error' in filesList.data)) &&
							<div className={styles.navbarFailedText}>
								{filesList.data && 'message' in filesList.data ?
									<span>{translate(filesList.data.message)}</span>
									:
									<span>{translate('title_loadFailed')}</span>
								}
								{isAccess(QAS.DOC_LIST) &&
									<span className={styles.navbarFailedUpdate} onClick={() => dispatch(getFilesList())}>{translate('link_update')}</span>
								}
							</div>
						}

						{/* пустой список файлов */}
						{filesList.status === RequestStatus.IDLE && Array.isArray(filesList.data) && filesList.data.length === 0 &&
							<div className={styles.navbarNoFiles}>{translate('title_emptyList')}</div>
						}

						{/* загрузка списка файлов */}
						{filesList.status === RequestStatus.LOADING &&
							<div className={styles.navbarLoading}>
								<ProgressCircle title={translate('spinnerTitle_loading')} />
							</div>
						}

						{/* поиск документа */}
						{filesList.status === RequestStatus.IDLE && Array.isArray(filesList.data) && filesList.data.length > 0 &&
							<div className={styles.navbarSearchBlock}>
								<FormControl fullWidth>
									<TextField
										id="fileSearch"
										label={translate("input_search")}
										variant="outlined"
										value={searchDocument}
										onChange={(e) => setSearchDocument(e.target.value)}
										InputProps={{
											style: {
												height: 33,
												fontSize: 13,
												color: colorPrimary,
											},
										}}
										InputLabelProps={{
											style: {
												fontSize: 13,
											},
										}}
										sx={{
											'.MuiInputLabel-root[data-shrink="false"]': { top: -8 },
											'.MuiInputBase-input': { padding: '0 14px' },
										}}
									/>
								</FormControl>
								{categoriesList.data.length > 0 &&
									<FormControl fullWidth sx={{
										'.MuiInputLabel-root[data-shrink="false"]': { top: -8 },
										'.MuiInputBase-input': { padding: '8px 14px' },
									}}>
										<InputLabel id="categoryFilter-label" sx={{ fontSize: 12 }}>{translate('select_filterByCategory')}</InputLabel>
										<Select
											labelId="categoryFilter-label"
											id="categoryFilter"
											label={translate('select_filterByCategory')}
											value={selectCategoryId}
											onChange={(e) => { setSelectCategoryId(e.target.value); dispatch(changeCategoryFilter(e.target.value)); }}
											style={{ fontSize: 13, height: 33, color: colorPrimary }}
										>
											<MenuItem value='' sx={{ fontSize: 12 }}>{translate('selectItem_notSelected')}</MenuItem>
											{categoriesList.data.map((category) => (
												<MenuItem key={category.id} value={category.id} sx={{ fontSize: 12, color: colorPrimary }}>{category.name}</MenuItem>
											))}
										</Select>
									</FormControl>
								}

								{/* пустой список после поиска и фильтрации по категории */}
								{filesList.data.filter(filterFunction).length === 0 &&
									<p className={styles.navbarSearchEmpty}>{translate('title_notFound')}</p>
								}
							</div>
						}

						{/* список файлов */}
						{filesList.status === RequestStatus.IDLE && Array.isArray(filesList.data) && filesList.data.length > 0 &&
							<ul className={styles.navbarFilesList} ref={listRef}>
								{filesList.data
									.filter(filterFunction)
									.map(({ id, title }) => (
										<li className={styles.navbarFilesItem} key={id}>
											<Fade in={filesList.status !== RequestStatus.LOADING || (Array.isArray(filesList.data) && filesList.data.length > 0)} timeout={500}>
												<div
													className={cn({
														[styles.navbarFilesLink]: documentFile.fileId !== id,
														[styles.navbarFilesLinkActive]: documentFile.fileId === id,
													})}
													onClick={() => isAccess(QAS.DOC_GET) && fileSelectionHandler(id)}
												>
													<div className={styles.navbarFilesLinkLeftBlock} title={title}>
														{title}
													</div>
													<div className={cn(styles.navbarFilesLinkIcon, {
														[styles.navbarFilesLinkIconActive]: documentFile.fileId === id,
													})}>
														<FontAwesomeIcon icon={faAngleRight} />
													</div>
												</div>
											</Fade>
										</li>
									))}
							</ul>
						}

						<div className={styles.functionButtons}>
							{/* добавление файла */}
							{isAccess(QAS.DOC_ADD) && (filesList.status === RequestStatus.IDLE || Array.isArray(filesList.data)) &&
								<Fade in={true} timeout={500}>
									<div className={styles.functionButtonsAddFile} onClick={() => setShowModal(true)} >
										{translate('link_addDocument')}
									</div>
								</Fade>
							}
							{/* табы */}
							{isAccess([QAS.QUESTION_LIST, QAS.QUESTION_GET]) &&
								<div className={styles.functionButtonsTabs}>
									<div className={styles.functionButtonsTab}>{translate('tab_documents')}</div>
									<div
										className={cn(styles.functionButtonsTab, styles.functionButtonsTabNonActive)}
										onClick={() => delayToHidePage(QUESTION)}
									>
										{translate('tab_questions')}
									</div>
									<div
										className={cn(styles.functionButtonsTab, styles.functionButtonsTabNonActive)}
										onClick={() => delayToHidePage(CANDIDATES)}
									>
										{translate('tab_candidates')}
									</div>
								</div>
							}
						</div>
					</div>
				</div>

				{showModal && <FormAddingDocumentFile showModal={showModal} setShowModal={setShowModal} setShowNotification={setShowNotification} />}
				{showNotification && <Notification showNotification={showNotification} setShowNotification={setShowNotification} selectDataResponse={selectAddingFileStatus} clearDataResponse={clearAddFileStatus} titleFailed={translate('noticeAddition_failed')} titleSuccess={translate('noticeAddition_success')} />}
			</div>
		</Slide>
	);
};

export default FileNavBar;
