import { FormEvent, useEffect, useState } from 'react';
import { Button, ButtonGroup, FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { selectErrorsModel } from '../../../store/modelSlice';
import { clearCorpus, getCorpus, getCorpusList, putCorpus, selectCorpus, selectCorpusList, selectCorpusPutStatus } from '../../../store/corpusSlice';
import useTranslate from '../../../hooks/useTranslate';
import { RequestStatus, ResponseStatus } from '../../../types/statusTypes';
import ModalFormWindow from '../../../HOC/ModalFormWindow/ModalFormWindow';
import ProgressCircle from '../../ProgressCircle/ProgressCircle';
import { IFormErrorsInCorpusProps } from './FormErrorsInCorpus.props';
import styles from './FormErrorsInCorpus.module.scss';

const FormErrorsInCorpus = ({ showModal, setShowModal, serviceType, setShowNotification }: IFormErrorsInCorpusProps): JSX.Element => {
	const [corpusNameInput, setCorpusNameInput] = useState<string>(''); // имя нового корпуса
	const [corpusName, setCorpusName] = useState<string>(''); // имя существующего корпуса
	const [corpusAdditionType, setCorpusAdditionType] = useState<'new' | 'exist'>('new'); // выбор, куда будут преобразовываться ошибки
	const [existCorpusFlg, setExistCorpusFlg] = useState<boolean>(false); // флаг для уведомления о существующем корпусе

	const dispatch = useAppDispatch();
	const putStatus = useAppSelector(selectCorpusPutStatus); // store - статус изменения (преобразования) ошибок модели в корпус
	const corpusList = useAppSelector(selectCorpusList); // store - список корпусов
	const corpus = useAppSelector(selectCorpus); // store - корпус
	const errors = useAppSelector(selectErrorsModel); // store - список ошибок модели

	const translate = useTranslate(); // hook для перевода текста

	useEffect(() => {
		dispatch(getCorpusList({ serviceType })); // получаем список корпусов
	}, []);

	// следим за выбранным именем корпуса из списка существующих
	useEffect(() => {
		if (corpusName !== '') {
			dispatch(clearCorpus()); // очищаем данные корпуса
			dispatch(getCorpus({ corpusName: corpusName, serviceType })); // получение информации о корпусе
		}
	}, [corpusName]);

	// следим за статусом изменения (преобразования) ошибок модели в корпус
	useEffect(() => {
		// если есть изменения
		if (putStatus.error !== ResponseStatus.SUCCESS || putStatus.status === RequestStatus.FAILED || putStatus.message !== '') {
			handleClose(); // закрываем форму
			setShowNotification(true); // включаем уведомление
		}
	}, [putStatus]);

	// обработчик преобразования ошибок модели в корпус
	const submitHandler = (e: FormEvent<HTMLFormElement>): void => {
		e.preventDefault();
		const transformErrors: [string[], string][] = errors.errors.filter(row => row[0] !== '<unk>').map(row => [row[0].trim().split(','), row[2]]); // отсеиваем строки с классом <unk> и преобразовываем в формат корпуса
		// если открытая вкладка - преобразование ошибок в новый корпус и имя корпуса уже существует - ставим флаг на существование такого корпуса и выходим
		if (corpusAdditionType === 'new' && corpusList.data.includes(corpusNameInput)) {
			setExistCorpusFlg(true);
			return;
		}
		// если открытая вкладка - преобразование ошибок в новый корпус - преобразовываем ошибки модели в новый корпус
		corpusAdditionType === 'new' &&
			dispatch(putCorpus({ corpusName: corpusNameInput, text: { classes: [], data: transformErrors, groups: [] }, serviceType }));

		// для преобразования в существующий корпус
		// если была нажата кнопка "Дополнить" - дописываем в существующий корпус
		if ((e.nativeEvent as SubmitEvent).submitter?.id === 'additionBtn') {
			dispatch(putCorpus({ corpusName, text: { classes: [], data: [...corpus.data.data, ...transformErrors], groups: [] }, serviceType }));
		}
		// если была нажата кнопка "Заменить" - перезаписываем существующий корпус
		if ((e.nativeEvent as SubmitEvent).submitter?.id === 'replacementBtn') {
			dispatch(putCorpus({ corpusName, text: { classes: [], data: transformErrors, groups: [] }, serviceType }));
		}
	};

	// обработчик закрытия формы
	const handleClose = (): void => {
		// если идет преобразование - запрещаем покидать форму
		if (putStatus.status === RequestStatus.LOADING) return;
		setShowModal(false);
	};

	return (
		<ModalFormWindow showModal={showModal} setShowModal={setShowModal} headerTitle='formHeader_transformModelErrorsInCorpus' close={handleClose}>
			<>
				{corpusList.status === RequestStatus.LOADING && <ProgressCircle title={translate('spinnerTitle_loading')} />}
				{corpusList.status === RequestStatus.IDLE &&
					<form onSubmit={(e) => submitHandler(e)}>
						<ButtonGroup fullWidth sx={{ marginBottom: '8px' }}>
							<Button variant={corpusAdditionType === 'new' ? "contained" : "outlined"} sx={{ width: '50%', overflow: 'hidden', fontSize: 11 }} onClick={() => setCorpusAdditionType('new')}>{translate('button_new')}</Button>
							<Button variant={corpusAdditionType === 'exist' ? "contained" : "outlined"} sx={{ width: '50%', overflow: 'hidden', fontSize: 11 }} onClick={() => { setCorpusAdditionType('exist'); existCorpusFlg && setExistCorpusFlg(false); }}>{translate('button_exists')}</Button>
						</ButtonGroup>
						{corpusAdditionType === 'new' &&
							<FormControl fullWidth margin='dense'>
								<TextField
									autoFocus
									required
									id="outlined-basic"
									label={translate('input_name')}
									variant="outlined"
									value={corpusNameInput}
									onChange={(e) => { setCorpusNameInput(e.target.value); existCorpusFlg && setExistCorpusFlg(false); }}
									error={existCorpusFlg}
									helperText={existCorpusFlg ? translate("title_corpusExists") : ''}
									InputProps={{
										style: {
											height: 33,
											fontSize: 13
										},
									}}
									InputLabelProps={{
										style: {
											fontSize: 13,
										},
									}}
									sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
								/>
							</FormControl>
						}
						{corpusAdditionType === 'exist' &&
							<>
								{Array.isArray(corpusList.data) && corpusList.data.length > 0 ?
									<FormControl fullWidth margin='dense' sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }} error={corpus.status === RequestStatus.FAILED}>
										<InputLabel id="corpus-label" sx={{ fontSize: 13 }}>{translate('select_corpus')}</InputLabel>
										<Select
											required
											labelId="corpus-label"
											id="corpus"
											label={translate('select_corpus')}
											value={corpusName}
											onChange={(e) => setCorpusName(e.target.value)}
											style={{ fontSize: 13, height: 33 }}
											disabled={corpus.status === RequestStatus.LOADING}
										>
											{corpusList.data.map(corpusName => <MenuItem key={corpusName} value={corpusName} sx={{ fontSize: 13 }}>{corpusName}</MenuItem>)}
										</Select>
										{corpus.status === RequestStatus.FAILED && <FormHelperText>{translate(corpus.message || 'title_loadFailed')}</FormHelperText>}
									</FormControl>
									:
									<Typography variant="caption" gutterBottom>
										{translate('title_emptyList')}
									</Typography>
								}
							</>
						}
						{putStatus.status !== RequestStatus.LOADING ?
							<div className={styles.buttonsImportCorpus}>
								{corpusAdditionType === 'exist' ?
									<>
										{Array.isArray(corpusList.data) && corpusList.data.length > 0 &&
											<>
												{corpus.status === RequestStatus.LOADING ?
													<Button variant="outlined" type="submit" disabled sx={{ width: '60%', overflow: 'hidden', fontSize: 11 }}>
														{translate('button_loading')}
														<ProgressCircle isBtnDisabled />
													</Button>
													:
													<Button variant="outlined" type='submit' id='additionBtn' sx={{ width: '60%', overflow: 'hidden', fontSize: 11 }} disabled={corpus.status === RequestStatus.FAILED}>
														{translate('button_addition')}
													</Button>
												}
												<Button variant="outlined" type='submit' id='replacementBtn' sx={{ width: '60%', overflow: 'hidden', fontSize: 11 }}>
													{translate('button_replace')}
												</Button>
											</>
										}
									</>
									:
									<Button variant="outlined" type='submit' id='createBtn' sx={{ width: '50%', overflow: 'hidden', fontSize: 11 }}>
										{translate('button_create')}
									</Button>
								}
								<Button variant="outlined" onClick={handleClose} sx={{
									width: (corpusAdditionType === 'exist' && (!Array.isArray(corpusList.data) || corpusList.data.length === 0)) ? '100%' : '50%',
									overflow: 'hidden',
									fontSize: 11
								}}>
									{translate('button_cancel')}
								</Button>
							</div>
							:
							<FormControl fullWidth margin='dense' size="small">
								<Button variant="outlined" type="submit" disabled sx={{ fontSize: 11 }}>
									{translate('button_transformation')}
									<ProgressCircle isBtnDisabled />
								</Button>
							</FormControl>
						}
					</form>
				}
			</>
		</ModalFormWindow>
	);
};

export default FormErrorsInCorpus;
