import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { Button, FormControl, TextField } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getDataList, getEndpointList } from '../../../store/sesSlice';
import { getRobot, importRobot, selectImportRobot, selectRobot } from '../../../store/sesRobotSlice';
import useTranslate from '../../../hooks/useTranslate';
import { RequestStatus, ResponseStatus } from '../../../types/statusTypes';
import ModalFormWindow from '../../../HOC/ModalFormWindow/ModalFormWindow';
import ProgressCircle from '../../ProgressCircle/ProgressCircle';
import ProgressLinear from '../../ProgressLinear/ProgressLinear';
import { IFormImportRobotProps } from './FormImportRobot.props';

const FormImportRobot = ({ showModal, setShowModal, setShowNotification }: IFormImportRobotProps): JSX.Element => {
	const [file, setFile] = useState<File>(); // файл для импорта робота

	const dispatch = useAppDispatch();
	const robotInfo = useAppSelector(selectRobot); // store - информация о роботе
	const importRobotStatus = useAppSelector(selectImportRobot); // store - статус импорта робота

	const translate = useTranslate(); // hook для перевода текста

	// следим за статусом импорта робота
	useEffect(() => {
		// если нет ошибок
		if (importRobotStatus.error === ResponseStatus.SUCCESS && importRobotStatus.status === RequestStatus.IDLE && importRobotStatus.message !== '') {
			if (robotInfo.data?.id) {
				dispatch(getRobot(robotInfo.data.id)); // обновляем данные робота
				dispatch(getDataList(robotInfo.data.id)); // обновляем список элементов данных
				dispatch(getEndpointList(robotInfo.data.id)); // обновляем список конечных точек
			}
		}

		// если изменился статус импорта
		if (importRobotStatus.error !== ResponseStatus.SUCCESS || importRobotStatus.status === RequestStatus.FAILED || importRobotStatus.message !== '') {
			handleClose(); // закрываем форму
			setShowNotification(true); // включаем уведомление
		}
	}, [importRobotStatus]);

	// обработчик закрытия формы
	const handleClose = (): void => {
		// если идет импорт робота - запрещаем покидать форму
		if (importRobotStatus.status === RequestStatus.LOADING) return;
		setShowModal(false);
	};

	// обработчик импорта робота
	const submitHandler = (e: FormEvent<HTMLFormElement>): void => {
		e.preventDefault();
		const formData = new FormData();
		if (file) formData.append('file', file);
		robotInfo.data?.id && dispatch(importRobot({ formData, robotId: robotInfo.data.id }));
	};

	return (
		<ModalFormWindow
			showModal={showModal}
			setShowModal={setShowModal}
			headerTitle={`${translate("formHeader_importRobot")} "${robotInfo.data?.name}"`}
			close={handleClose}
		>
			<>
				<form onSubmit={(e) => submitHandler(e)}>
					<FormControl fullWidth margin='dense'>
						<TextField
							required
							id="outlined-basic"
							variant="outlined"
							size="small"
							type="file"
							onChange={(e: ChangeEvent<HTMLInputElement>) => e.target.files && setFile(e.target.files[0])}
							InputProps={{
								style: {
									height: 33,
									fontSize: 13,
								},
								inputProps: { accept: "application/zip" }
							}}
							InputLabelProps={{
								style: {
									fontSize: 13,
								},
							}}
							sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
						/>
					</FormControl>

					<FormControl fullWidth margin='dense'>
						<Button
							variant="outlined"
							type="submit"
							disabled={importRobotStatus.status === RequestStatus.LOADING}
							sx={{ fontSize: 11 }}
						>
							{translate('button_import')}
							{importRobotStatus.status === RequestStatus.LOADING && <ProgressCircle isBtnDisabled />}
						</Button>
					</FormControl>
				</form>
				{importRobotStatus.status === RequestStatus.LOADING &&
					<ProgressLinear value={importRobotStatus.progress} />
				}
			</>
		</ModalFormWindow>
	);
};

export default FormImportRobot;
