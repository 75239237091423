import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { clearSearchSpeaker, searchSpeaker, selectSearchSpeaker } from '../../store/sbsSlice';
import useTranslate from '../../hooks/useTranslate';
import { RequestStatus, ResponseStatus } from '../../types/statusTypes';
import FormSendAudio from '../Forms/FormSendAudio/FormSendAudio';
import NoticeSingleAction from '../Notification/NoticeSingleAction/NoticeSingleAction';
import styles from './SearchSpeaker.module.scss';

const SearchSpeaker = (): JSX.Element => {
	const [showNotificationSuccess, setShowNotificationSuccess] = useState<boolean>(false); // показ удачного уведомления поиска спикера
	const [showNotificationFailed, setShowNotificationFailed] = useState<boolean>(false); // показ не удачного уведомления поиска спикера

	const dispatch = useAppDispatch();
	const searchSpeakerData = useAppSelector(selectSearchSpeaker); // store - поиск спикера

	const translate = useTranslate(); // hook для перевода текста

	// следим за данными поиска спикера и включаем соответствующее уведомление
	useEffect(() => {
		((searchSpeakerData.data && searchSpeakerData.data.error === ResponseStatus.FAILED) || searchSpeakerData.status === RequestStatus.FAILED) && setShowNotificationFailed(true); // failed
		searchSpeakerData.data && 'id' in searchSpeakerData.data && setShowNotificationSuccess(true); // success
	}, [searchSpeakerData.data]);

	return (
		<div className={styles.searchSpeaker}>

			<h3 className={styles.searchSpeakerTitle}>{translate('title_speakerSearch')}</h3>

			<FormSendAudio
				formFor='search'
				disableFile={searchSpeakerData.status === RequestStatus.LOADING}
				disableSubmit={searchSpeakerData.status === RequestStatus.LOADING}
				submitFunction={({ formData, text, threshold }) => {
					searchSpeakerData.data !== null && dispatch(clearSearchSpeaker()); // если есть данные поиска спикера - очищаем
					threshold && dispatch(searchSpeaker({ threshold, formData, text: text || undefined })); // поиск спикера
				}}
			/>

			{showNotificationFailed &&
				<NoticeSingleAction
					showNotification={showNotificationFailed}
					setShowNotification={setShowNotificationFailed}
					title={(searchSpeakerData.data && 'message' in searchSpeakerData.data && searchSpeakerData.data.message) || 'noticeNotFound'}
				/>
			}
			{showNotificationSuccess &&
				<NoticeSingleAction
					showNotification={showNotificationSuccess}
					setShowNotification={setShowNotificationSuccess}
					title={`${translate('noticeSimilarity')}: ${(searchSpeakerData.data && 'id' in searchSpeakerData.data && (searchSpeakerData.data.similarity * 100).toFixed(3))} %` || '_'}
					severity='info'
				/>
			}

		</div>
	);
};

export default SearchSpeaker;
