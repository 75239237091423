import { FormEvent, useState } from 'react';
import { Button, Checkbox, FormControl, FormControlLabel, Switch } from '@mui/material';
import { selectCategoriesList } from '../../../store/qasSlice';
import { useAppSelector } from '../../../store/hooks';
import useTranslate from '../../../hooks/useTranslate';
import { downloadFile } from '../../../helpers/downloadFile';
import ModalFormWindow from '../../../HOC/ModalFormWindow/ModalFormWindow';
import { IFormExportQuestionsProps } from './FormExportQuestions.props';
import styles from './FormExportQuestions.module.scss';

const FormExportQuestions = ({ showModal, setShowModal, filteredQuestionsList }: IFormExportQuestionsProps): JSX.Element => {
	const [switchChecked, setSwitchChecked] = useState<boolean>(false); // переключатель выбора формата данных (false - отчет, true - корпус)
	const [сheckedMain, setCheckedMain] = useState<boolean>(true); // основные
	const [сheckedAlias, setCheckedAlias] = useState<boolean>(false); // алиасы
	const [сheckedCandidate, setCheckedCandidate] = useState<boolean>(false); // кандидаты

	const translate = useTranslate(); // hook для перевода текста
	const { dictionary } = useAppSelector(selectCategoriesList); // store - словарь категорий

	// обработчик экспорта вопросов
	const submitHandler = (e: FormEvent<HTMLFormElement>): void => {
		e.preventDefault();
		setShowModal(false); // закрываем форму
		exportCsv(); // экспорт
	};

	// функция формирования и экспорта уникальных вопросов в формате .csv
	const exportCsv = (): void => {
		let str: string = '';
		const arrUniqueQuestions: string[] = []; // список уникальных вопросов

		// вставка обозначения вопроса для отчета
		const questionDesc = (desc: string): string => {
			if (switchChecked) return '';
			else return `\t${desc}`;
		};

		filteredQuestionsList.forEach(questionItem => {
			const category = dictionary[questionItem.category || ''] || questionItem.category; // поиск категории по id

			// основной
			if (!arrUniqueQuestions.includes(questionItem.question) && сheckedMain) {
				arrUniqueQuestions.push(questionItem.question);
				str += `${category}\t${questionItem.question.replace(/\r|\n/g, '')}${questionDesc('main')}\n`;
			}

			// алиасы
			сheckedAlias && questionItem.aliases.forEach(alias => {
				if (!arrUniqueQuestions.includes(alias)) {
					arrUniqueQuestions.push(alias);
					str += `${category}\t${alias.replace(/\r|\n/g, '')}${questionDesc('alias')}\n`;
				}
			});

			// кандидаты
			сheckedCandidate && questionItem.candidates.forEach(candidate => {
				if (!arrUniqueQuestions.includes(candidate)) {
					arrUniqueQuestions.push(candidate);
					str += `${category}\t${candidate.replace(/\r|\n/g, '')}${questionDesc('candidate')}\n`;
				}
			});
		});

		downloadFile(str, 'questions_list.csv'); // экспорт файла
	};

	return (
		<ModalFormWindow showModal={showModal} setShowModal={setShowModal} headerTitle='formHeader_exportQuestions'>
			<form onSubmit={(e) => submitHandler(e)}>

				<div className={styles.switchBlock}>
					{translate('title_forReport')}
					<Switch
						checked={switchChecked}
						onChange={e => setSwitchChecked(e.target.checked)}
					/>
					{translate('title_forCorpus')}
				</div>

				<FormControlLabel sx={{ width: '100%', overflow: 'hidden', marginTop: '-10px', '.MuiTypography-root': { fontSize: 13, marginTop: '3px' } }} control={
					<Checkbox
						checked={сheckedMain}
						onChange={e => setCheckedMain(e.target.checked)}
						size='small'
					/>
				} label={translate('checkbox_main')} />

				<FormControlLabel sx={{ width: '100%', overflow: 'hidden', marginTop: '-10px', '.MuiTypography-root': { fontSize: 13, marginTop: '3px' } }} control={
					<Checkbox
						checked={сheckedAlias}
						onChange={e => setCheckedAlias(e.target.checked)}
						size='small'
					/>
				} label={translate('checkbox_aliases')} />

				<FormControlLabel sx={{ width: '100%', overflow: 'hidden', marginTop: '-10px', '.MuiTypography-root': { fontSize: 13, marginTop: '3px' } }} control={
					<Checkbox
						checked={сheckedCandidate}
						onChange={e => setCheckedCandidate(e.target.checked)}
						size='small'
					/>
				} label={translate('checkbox_candidates')} />

				<FormControl fullWidth margin='dense'>
					<Button
						variant="outlined"
						type="submit"
						sx={{ fontSize: 11 }}
						disabled={!сheckedMain && !сheckedAlias && !сheckedCandidate}
					>
						{translate('button_export')}
					</Button>
				</FormControl>

			</form>
		</ModalFormWindow>
	);
};

export default FormExportQuestions;
