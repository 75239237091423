import { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useAppDispatch } from '../../../store/hooks';
import { addSpeakerList as addSpeakerListSpr } from '../../../store/sprSlice';
import { addSpeakerList as addSpeakerListTranscript } from '../../../store/transcriptionSlice';
import useTranslate from '../../../hooks/useTranslate';
import { backgroundColor, colorSecondary } from '../../../constants/colors';
import SpeakerRow from './SpeakerRow/SpeakerRow';
import { ISpeakersProps } from './Speakers.props';

const Speakers = ({ speakers, speakerNameList, from, setChangeFlg, audioUrl }: ISpeakersProps): JSX.Element => {
	const [renameSpeakerFlg, setRenameSpeakerFlg] = useState<{ isOpen: boolean, idx: number }>({ isOpen: false, idx: -1 }); // флаг и индекс для формы переименования спикера
	const [playableAudioFlg, setPlayableAudioFlg] = useState<{ isPlay: boolean, idx: number }>({ isPlay: false, idx: -1 }); // флаг и индекс проигрываемого аудио

	const dispatch = useAppDispatch();
	const translate = useTranslate(); // hook для перевода текста

	// следим за данными спикеров и добавляем список имен спикеров
	useEffect(() => {
		const names: string[] = [];
		speakers.forEach(speaker => {
			names.push(speaker.id);
		});
		from === 'recognition' && dispatch(addSpeakerListSpr(names));
		from === 'transcript' && dispatch(addSpeakerListTranscript(names));
	}, [speakers]);

	return (
		<Table aria-label="simple table" size="small" stickyHeader>
			<TableHead>
				<TableRow>
					<TableCell sx={{ backgroundColor: backgroundColor }}></TableCell>
					<TableCell sx={{ color: colorSecondary, fontSize: 13, backgroundColor: backgroundColor }}>{translate('columnTitle_speaker')}</TableCell>
					<TableCell sx={{ color: colorSecondary, fontSize: 13, backgroundColor: backgroundColor }}>{translate('columnTitle_fragments')}</TableCell>
					<TableCell sx={{ color: colorSecondary, fontSize: 13, backgroundColor: backgroundColor }}>{translate('columnTitle_time')}</TableCell>
					{speakers[0]?.gender &&
						<TableCell sx={{ color: colorSecondary, fontSize: 13, backgroundColor: backgroundColor }}>{translate('columnTitle_gender')}</TableCell>
					}
					{speakers[0]?.age &&
						<TableCell sx={{ color: colorSecondary, fontSize: 13, backgroundColor: backgroundColor }}>{translate('columnTitle_age')}</TableCell>
					}
				</TableRow>
			</TableHead>
			<TableBody>
				{speakers.map((speaker, idx) =>
					<SpeakerRow
						key={speaker.id}
						speaker={speaker}
						idx={idx}
						audioUrl={audioUrl}
						from={from}
						setChangeFlg={setChangeFlg}
						speakerNameList={speakerNameList}
						renameSpeakerFlg={renameSpeakerFlg}
						setRenameSpeakerFlg={setRenameSpeakerFlg}
						playableAudioFlg={playableAudioFlg}
						setPlayableAudioFlg={setPlayableAudioFlg}
					/>
				)}
			</TableBody>
		</Table>
	);
};

export default Speakers;
