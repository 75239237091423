import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRotate } from '@fortawesome/free-solid-svg-icons';
import useTranslate from '../../../../hooks/useTranslate';
import { backgroundColor } from '../../../../constants/colors';
import { IUpdateProps } from './Update.props';

const Update = ({ styleNotActive, isAvailable, title = 'buttonTitle_update', handler }: IUpdateProps): JSX.Element => {
	const translate = useTranslate(); // hook для перевода текста

	return (
		<div
			className={isAvailable ? '' : styleNotActive}
			onClick={() => isAvailable && handler()}
			title={translate(title)}>
			<FontAwesomeIcon icon={faRotate} size="2xl" color={backgroundColor} />
		</div>
	);
};

export default Update;
