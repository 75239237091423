import { useEffect, useState } from 'react';
import { Slide } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { clearDeletion, clearEditing, deleteTranscription, editTranscription, getTranscriptionList, selectDeletion, selectEditing, selectTranscriptionData, selectTranscriptionList } from '../../../store/transcriptionSlice';
import useAccessRight from '../../../hooks/useAccessRight';
import useTranslate from '../../../hooks/useTranslate';
import { TRANSCRIPTION } from '../../../constants/accessRights';
import { AUTOSAVE_TRANSCRIPT } from '../../../constants/cookieNames';
import { RequestStatus, ResponseStatus } from '../../../types/statusTypes';
import ToggleColoring from '../Buttons/ToggleColoring/ToggleColoring';
import ToggleAutosave from '../Buttons/ToggleAutosave/ToggleAutosave';
import Delete from '../Buttons/Delete/Delete';
import ExportToDoc from '../Buttons/ExportToDoc/ExportToDoc';
import Save from '../Buttons/Save/Save';
import UserMenu from '../Buttons/UserMenu/UserMenu';
import ScreenLock from '../../ScreenLock/ScreenLock';
import AlertDialog from '../../AlertDialog/AlertDialog';
import Notification from '../../Notification/Notification';
import { ITranscriptControlsProps } from './TranscriptControls.props';
import styles from './TranscriptControls.module.scss';

const TranscriptControls = ({ changeFlg, setChangeFlg, activeRecordId, setShowPage }: ITranscriptControlsProps): JSX.Element => {
	const [showAlertDialog, setShowAlertDialog] = useState<boolean>(false); // показ диалогового окна при сохранении
	const [showNotification, setShowNotification] = useState<boolean>(false); // показ уведомления

	const [showAlertDialogDelete, setShowAlertDialogDelete] = useState<boolean>(false); // показ диалогового окна при удалении стенограммы
	const [showNotificationDelete, setShowNotificationDelete] = useState<boolean>(false); // показ уведомления об удалении стенограммы
	const [showScreenLock, setShowScreenLock] = useState<{ isShow: boolean, title: string }>({ isShow: false, title: '' }); // показ экрана блокировки и подпись

	const dispatch = useAppDispatch();
	const transcriptionList = useAppSelector(selectTranscriptionList); // store - список сохранненных записей распознавания
	const transcription = useAppSelector(selectTranscriptionData); // store - данные сохранненной записи распознавания
	const deleteStatus = useAppSelector(selectDeletion); // store -  статус удаления стенограммы
	const editStatus = useAppSelector(selectEditing); // store -  статус сохранения стенограммы

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// следим за статусом изменения/удаления стенограммы
	useEffect(() => {
		if (editStatus.status === RequestStatus.LOADING && !editStatus.autosave) setShowScreenLock({ isShow: true, title: 'spinnerTitle_saving' }); // если идет сохранение (не автоматическое)
		else if (deleteStatus.status === RequestStatus.LOADING) setShowScreenLock({ isShow: true, title: 'spinnerTitle_deletion' }); // если идет удаление
		else setShowScreenLock({ isShow: false, title: '' }); // иначе выключаем экран блокировки

		// если сохранилось успешно
		if (editStatus.status === RequestStatus.IDLE && editStatus.error === ResponseStatus.SUCCESS && editStatus.message === 'success') {
			setChangeFlg(false); // выключаем флаг сохранения данных
			editStatus.autosave && dispatch(clearEditing()); // очищаем статус (для режима автосохранения)
		}
		// если удаление прошло успешно - получаем заново список распознаваний
		deleteStatus.status === RequestStatus.IDLE && deleteStatus.error === ResponseStatus.SUCCESS && deleteStatus.message === 'success' && dispatch(getTranscriptionList());
	}, [editStatus, deleteStatus]);

	// обработчик сохранения изменений
	const changeHandler = (): void => {
		// если есть не сохраненные изменения
		if (changeFlg) {
			setShowAlertDialog(false); // закрываем диалоговое окно
			setShowNotification(true); // включаем уведомление
			dispatch(editTranscription({ id: activeRecordId })); // сохранение (изменение)
		}
	};

	// обработчик удаления стенограммы
	const deleteTranscriptHandler = (): void => {
		setShowAlertDialogDelete(false); // закрываем диалоговое окно
		setShowNotificationDelete && setShowNotificationDelete(true); // включаем уведомление об удалении стенограммы
		activeRecordId && dispatch(deleteTranscription(activeRecordId)); // удаление стенограммы
	};

	return (
		<>
			<div className={styles.controls}>
				<Slide direction="down" in={true} mountOnEnter unmountOnExit timeout={500}>
					<div className={styles.controlsWrapper}>
						<div className={styles.controlsTitle}>{translate('spr')}</div>
						<section className={styles.controlsTypeAndFunc}>

							<div className={styles.controlsFunctions}>
								{/* если есть список сохраненных записей */}
								{transcriptionList.status === RequestStatus.IDLE && Array.isArray(transcriptionList.data) && transcriptionList.data.length > 0 &&
									<>
										<div className={styles.controlsFunctionsLeftBlock}>
											<ToggleColoring
												styleNotActive={styles.controlsFunctionBtnNotActive}
												isAvailable={transcription.data !== null && 'splitted' in transcription.data && Array.isArray(transcription.data.splitted) && transcription.data.splitted.length > 0}
											/>
										</div>
										<div className={styles.controlsFunctionsCenterBlock}>
											{isAccess(TRANSCRIPTION.SAVE) &&
												<ToggleAutosave
													styleNotActive={styles.controlsFunctionBtnNotActive}
													isAvailable={true}
													cookieName={AUTOSAVE_TRANSCRIPT}
													changeFlg={changeFlg}
													saveFunction={() => dispatch(editTranscription({ id: activeRecordId, autosave: true }))}
												/>
											}
										</div>
									</>
								}
							</div>

							<div className={styles.controlsFunctions}>

								{/* если есть список сохраненных записей */}
								{transcriptionList.status === RequestStatus.IDLE && Array.isArray(transcriptionList.data) && transcriptionList.data.length > 0 &&
									<>
										<div className={styles.controlsFunctionsLeftBlock}>
											{isAccess(TRANSCRIPTION.SAVE) &&
												<Save
													styleNotActive={styles.controlsFunctionBtnNotActive}
													changeFlg={changeFlg}
													showNotification={showNotification}
													setShowNotification={setShowNotification}
													dataResponse={selectEditing}
													clearDataResponse={clearEditing}
													setShowAlertDialog={setShowAlertDialog}
												/>
											}
											<ExportToDoc
												styleNotActive={styles.controlsFunctionBtnNotActive}
												isAvailable={transcription.data !== null && 'splitted' in transcription.data && Array.isArray(transcription.data.splitted) && transcription.data.splitted.length > 0}
												fileName={transcription.data && 'name' in transcription.data ? transcription.data.name : 'document'}
												data={{
													type: 'dialog',
													data: (transcription.data !== null && 'splitted' in transcription.data && Array.isArray(transcription.data.splitted) && transcription.data.splitted) || [],
												}}
												speakerList={transcription.speakerList}
											/>
											{isAccess(TRANSCRIPTION.DELETE) &&
												<Delete
													styleNotActive={styles.controlsFunctionBtnNotActive}
													isAvailable={transcription.status !== RequestStatus.LOADING}
													title='buttonTitle_deleteTranscript'
													setShowAlertDialog={setShowAlertDialogDelete}
												/>
											}
										</div>
									</>
								}

								<div className={styles.controlsFunctionsRightBlock}>
									<UserMenu setShowPage={setShowPage} />
								</div>
							</div>

						</section>
					</div>
				</Slide>
			</div>

			{showScreenLock.isShow && <ScreenLock title={translate(showScreenLock.title)} />}

			{showAlertDialog &&
				<AlertDialog
					showAlertDialog={showAlertDialog}
					setShowAlertDialog={setShowAlertDialog}
					submitHandler={changeHandler}
					title='dialog_saveTranscript'
					description='dialog_saveChangesConfirm'
					name={transcription.data && typeof transcription.data === 'object' && 'speakers' in transcription.data ? transcription.data.name : ''}
				/>
			}
			{showAlertDialogDelete &&
				<AlertDialog
					showAlertDialog={showAlertDialogDelete}
					setShowAlertDialog={setShowAlertDialogDelete}
					submitHandler={deleteTranscriptHandler}
					title='dialog_deleteTranscript'
					description='dialog_deleteTranscriptConfirm'
					name={transcription.data && 'name' in transcription.data && transcription.data.name ? transcription.data.name : ''}
				/>
			}
			{/* уведомление для удаления стенограммы */}
			{showNotificationDelete &&
				<Notification
					showNotification={showNotificationDelete}
					setShowNotification={setShowNotificationDelete}
					selectDataResponse={selectDeletion}
					clearDataResponse={clearDeletion}
					titleFailed='noticeDeletion_failed'
					titleSuccess='noticeDeletion_success'
				/>
			}
		</>
	);
};

export default TranscriptControls;
