import { useEffect, useState } from 'react';
import { Fade } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { clearDataServers, getDataServers } from '../../store/serverSlice';
import { clearState, getSpeakers, selectSpeakers } from '../../store/sbsSlice';
import useAccessRight from '../../hooks/useAccessRight';
import useTranslate from '../../hooks/useTranslate';
import { SBS, SERVER, SERVICE } from '../../constants/accessRights';
import { RequestStatus } from '../../types/statusTypes';
import PageWrapper from '../../HOC/PageWrapper/PageWrapper';
import BiometryControls from '../../components/Controls/BiometryControls/BiometryControls';
import BiometryNavbar from '../../components/Navbars/BiometryNavbar/BiometryNavbar';
import BioVerify from '../../components/BioVerify/BioVerify';
import { IBiometryProps } from './Biometry.props';
import styles from './Biometry.module.scss';

const Biometry = ({ serviceType }: IBiometryProps): JSX.Element => {
	const [showPage, setShowPage] = useState<boolean>(true); // показ страницы

	const dispatch = useAppDispatch();
	const speakersList = useAppSelector(selectSpeakers); // store - список спикеров

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	useEffect(() => {
		isAccess(SBS.SPEAKERS) && dispatch(getSpeakers()); // получаем список спикеров
		isAccess(SERVER.ADDRESSES) && dispatch(getDataServers({ serviceType })); // получаем данные о серверах

		// автозапрос списка серверов каждые 30 сек
		const interval = setInterval(() => {
			isAccess(SERVER.ADDRESSES) && dispatch(getDataServers({ serviceType }));
		}, 30000);

		// при уходе со страницы
		return () => {
			clearInterval(interval); // удаляем автозапрос получения данных о серверах
			dispatch(clearDataServers()); // очищаем данные по серверам
			dispatch(clearState()); // очищаем state биометрии
		};
	}, []);

	return (
		<PageWrapper showPage={showPage} setShowPage={setShowPage} accessToService={[SERVICE.SBS, SBS.SPEAKERS]}>
			<>
				<BiometryControls setShowPage={setShowPage} />
				<BiometryNavbar />

				<Fade in={true} timeout={700} style={{ transitionDelay: '500ms' }}>
					<div className={styles.wrapper}>
						{speakersList.status === RequestStatus.IDLE && speakersList.data.length > 0 ?
							isAccess([SBS.SPEAKERS, SBS.VERIFY]) && <BioVerify />
							:
							<div className={styles.noData}><div>{translate('title_noData')}</div></div>
						}
					</div>
				</Fade>
			</>
		</PageWrapper>
	);
};

export default Biometry;
