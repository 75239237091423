import { useEffect, useRef } from 'react';
import { Fade, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import useTranslate from '../../../hooks/useTranslate';
import { backgroundColor, colorPrimary, colorSecondary } from '../../../constants/colors';
import { ILogQuestionsTableProps } from './LogQuestionsTable.props';

const LogQuestionsTable = ({ logs, responseStatus }: ILogQuestionsTableProps) => {
	const bodyRef = useRef<HTMLTableSectionElement | null>(null); // ссылка на список логов
	const translate = useTranslate(); // hook для перевода текста

	// следим за логами и скроллим вниз, если идет обработка
	useEffect(() => {
		responseStatus === 'started' && logs.length > 0 &&
			bodyRef.current?.children[logs.length - 1]?.scrollIntoView({ block: "start", behavior: 'smooth' });
	}, [logs]);

	return (
		<TableContainer component={Paper} sx={{ maxHeight: '100%', backgroundColor: backgroundColor, boxShadow: '0 0 8px #cacaca', borderRadius: '4px' }}>
			<Table stickyHeader aria-label="simple table" size="small">
				<TableHead>
					<TableRow>
						<TableCell sx={{ color: colorSecondary, fontSize: 13, backgroundColor: backgroundColor }}>{translate('columnTitle_error')}</TableCell>
						<TableCell sx={{ color: colorSecondary, fontSize: 13, backgroundColor: backgroundColor }}>{translate('columnTitle_confidence')}</TableCell>
						<TableCell sx={{ color: colorSecondary, fontSize: 13, backgroundColor: backgroundColor }}>{translate('columnTitle_question')}</TableCell>
						<TableCell sx={{ color: colorSecondary, fontSize: 13, backgroundColor: backgroundColor }}>{translate('columnTitle_answer')}</TableCell>
					</TableRow>
				</TableHead>
				<TableBody ref={bodyRef}>
					{logs.map((log, idx) => (
						<Fade in={true} timeout={500} key={idx}>
							<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
								<TableCell align="left" sx={{ color: colorPrimary, fontSize: 11, whiteSpace: 'pre-wrap' }}>{log.error}</TableCell>
								<TableCell align="left" sx={{ color: colorPrimary, fontSize: 11, whiteSpace: 'pre-wrap' }}>
									{typeof log.confidence === 'number' ? log.confidence.toFixed(2) : '-'}
								</TableCell>
								<TableCell align="left" sx={{ color: colorPrimary, fontSize: 11, whiteSpace: 'pre-wrap' }}>{log.question}</TableCell>
								<TableCell align="left" sx={{ color: colorPrimary, fontSize: 11, whiteSpace: 'pre-wrap' }}>{log.answer}</TableCell>
							</TableRow>
						</Fade>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export default LogQuestionsTable;
