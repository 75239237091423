import { useEffect, useState } from 'react';
import { Button, Fade, FormControl, InputLabel, MenuItem, Select, Slide } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { selectActiveRobotId, selectActiveRobotVersion } from '../../store/sesRobotSlice';
import { addScript, changeNameInScriptList, deleteScript, editScript, getScript, getScriptList, selectAddingScript, selectDeletingScript, selectEditingScript, selectScript, selectScriptList } from '../../store/sesScriptSlice';
import useAccessRight from '../../hooks/useAccessRight';
import useTranslate from '../../hooks/useTranslate';
import { SES } from '../../constants/accessRights';
import { colorPrimary } from '../../constants/colors';
import { RequestStatus, ResponseStatus } from '../../types/statusTypes';
import Script from './Script/Script';
import AlertDialog from '../AlertDialog/AlertDialog';
import ProgressCircle from '../ProgressCircle/ProgressCircle';
import { IScriptsProps } from './Scripts.props';
import styles from './Scripts.module.scss';

const Scripts = ({ showScripts, setShowScripts }: IScriptsProps): JSX.Element => {
	const [selectScriptId, setSelectScriptId] = useState<string>(''); // выбранный id скрипта
	const [name, setName] = useState<string>(''); // имя скрипта
	const [code, setCode] = useState<string>(''); // код скрипта
	const [changeFlg, setChangeFlg] = useState<{ thisIs: boolean, listOfChanges: ('name' | 'code')[] }>({ thisIs: false, listOfChanges: [] }); // флаг, уведомляющий об изменении данных и возможности сохранить эти изменения
	const [showAlertDialogDelete, setShowAlertDialogDelete] = useState<boolean>(false); // показ диалогового окна для удаления скрипта

	const dispatch = useAppDispatch();
	const activeRobotId = useAppSelector(selectActiveRobotId); // store - id активного робота
	const activeRobotVersion = useAppSelector(selectActiveRobotVersion); // store - версия активного робота
	const scriptList = useAppSelector(selectScriptList); // store - список скриптов
	const script = useAppSelector(selectScript); // store - скрипт

	const addingScript = useAppSelector(selectAddingScript); // store - статус добавления скрипта
	const editingScript = useAppSelector(selectEditingScript); // store - статус изменения скрипта
	const deletingScript = useAppSelector(selectDeletingScript); // store - статус удаления скрипта

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// следим за id робота
	useEffect(() => {
		setSelectScriptId(''); // очищаем id скрипта
	}, [activeRobotId]);

	// следим за получением списка скриптов
	useEffect(() => {
		// если не выбран скрипт и есть данные - вписываем в поле первый id из списка, иначе очищаем
		selectScriptId === '' && scriptList.data.length > 0 && setSelectScriptId(scriptList.data[0].id);
	}, [scriptList.data]);

	// следим за выбранным скриптом и открытием вкладки
	useEffect(() => {
		if (selectScriptId !== '' && activeRobotId && showScripts) {
			setChangeFlg({ thisIs: false, listOfChanges: [] }); // сбрасываем флаг о несохраненных данных
			dispatch(getScript({ robotId: activeRobotId, scriptId: selectScriptId })); // получаем скрипт
		}
	}, [selectScriptId, showScripts]);

	// следим за статусом добавления скрипта
	useEffect(() => {
		// если успешно
		if (addingScript.status === RequestStatus.IDLE && addingScript.error === ResponseStatus.SUCCESS && addingScript.message !== '') {
			setChangeFlg({ thisIs: false, listOfChanges: [] }); // сбрасываем флаг о несохраненных данных
			addingScript.id && setSelectScriptId(addingScript.id); // вписываем id нового скрипта
			isAccess(SES.SCRIPT_LIST) && activeRobotId && dispatch(getScriptList(activeRobotId)); // обновляем список скриптов
		}
	}, [addingScript]);

	// следим за статусом изменения скрипта
	useEffect(() => {
		// если успешно
		if (editingScript.status === RequestStatus.IDLE && editingScript.error === ResponseStatus.SUCCESS && editingScript.message !== '') {
			setChangeFlg({ thisIs: false, listOfChanges: [] }); // сбрасываем флаг о несохраненных данных
			script.name !== name && dispatch(changeNameInScriptList({ oldName: script.name, newName: name })); // если изменилось имя - меняем в списке скриптов
		}
	}, [editingScript]);

	// следим за статусом удаления скрипта
	useEffect(() => {
		// если успешно
		if (deletingScript.status === RequestStatus.IDLE && deletingScript.error === ResponseStatus.SUCCESS && deletingScript.message !== '') {
			setChangeFlg({ thisIs: false, listOfChanges: [] }); // сбрасываем флаг о несохраненных данных
			setSelectScriptId(''); // сбрасываем выбор скрипта
			isAccess(SES.SCRIPT_LIST) && activeRobotId && dispatch(getScriptList(activeRobotId)); // обновляем список скриптов
		}
	}, [deletingScript]);

	// обработчик добавления скрипта
	const addScriptHandler = (): void => {
		if (activeRobotId) {
			const newName = `${translate('title_newScript')} ${scriptList.data.length + 1}`; // создаем новое имя
			dispatch(addScript({ robotId: activeRobotId, name: newName })); // добавляем новый скрипт
		}
	};

	// обработчик сохранения скрипта
	const editScriptHandler = (): void => {
		if (activeRobotId) {
			dispatch(editScript({
				robotId: activeRobotId,
				scriptId: selectScriptId,
				name: changeFlg.listOfChanges.includes('name') ? name : undefined,
				code: changeFlg.listOfChanges.includes('code') ? code : undefined,
			})); // сохраняем изменения
		}
	};

	// обработчик удаления скрипта
	const deleteScriptHandler = (): void => {
		if (activeRobotId) {
			setShowAlertDialogDelete(false); // закрываем диалоговое окно
			dispatch(deleteScript({ robotId: activeRobotId, scriptId: selectScriptId })); // удаляем скрипт
		}
	};

	// обработчик закрытия вкладки
	const closeHandler = (): void => {
		setShowScripts(false);
	};

	return (
		<Slide direction="left" in={showScripts} mountOnEnter unmountOnExit>
			<div className={styles.modal} onMouseDown={closeHandler}>
				<div className={styles.scripts} onMouseDown={e => e.stopPropagation()}>

					{/* загрузка списка скриптов */}
					{scriptList.status === RequestStatus.LOADING &&
						<div className={styles.loading}>
							<ProgressCircle title={translate('spinnerTitle_loading')} />
						</div>
					}

					{/* ошибка получения списка скриптов */}
					{(scriptList.status === RequestStatus.FAILED || scriptList.error === ResponseStatus.FAILED) &&
						<div className={styles.failed}>
							{translate(scriptList.message || 'title_loadFailed')}
						</div>
					}

					{/* скрипты */}
					{scriptList.status === RequestStatus.IDLE &&
						<Fade in={true} timeout={500}>
							<div className={styles.scriptsWrapper}>
								<div className={styles.scriptsTop}>
									{/* выбор скрипта */}
									<FormControl fullWidth margin='dense' sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}>
										<InputLabel sx={{ fontSize: 13 }}>{translate('select_script')}</InputLabel>
										<Select
											required
											label={translate('select_script')}
											disabled={!isAccess(SES.SCRIPT_GET)}
											value={selectScriptId}
											onChange={e => e.target.value && setSelectScriptId(e.target.value)}
											style={{ fontSize: 13, height: 33, color: colorPrimary }}
										>
											{isAccess(SES.SCRIPT_ADD) && activeRobotVersion === 'draft' && <MenuItem sx={{ fontSize: 13 }} onClick={addScriptHandler}>{translate('selectItem_addScript')}</MenuItem>}
											{scriptList.data.map(({ id, name }) =>
												<MenuItem key={id} value={id} sx={{ fontSize: 13, color: colorPrimary }}>{name}</MenuItem>
											)}
										</Select>
									</FormControl>

									{/* скрипт */}
									{scriptList.data.length > 0 ?
										<Script changeFlg={changeFlg} setChangeFlg={setChangeFlg} name={name} setName={setName} code={code} setCode={setCode} />
										:
										<div className={styles.scriptsTopEmptyTitle}>{translate('title_emptyList')}</div>
									}
								</div>

								{activeRobotVersion === 'draft' &&
									<div className={styles.scriptsBottom}>
										{/* сохранение скрипта */}
										{isAccess(SES.SCRIPT_EDIT) &&
											<FormControl fullWidth>
												<Button
													variant="outlined"
													disabled={!changeFlg.thisIs}
													sx={{ fontSize: 11 }}
													onClick={editScriptHandler}
												>
													{translate('button_save')}
												</Button>
											</FormControl>
										}
										{/* удаление скрипта */}
										{isAccess(SES.SCRIPT_DELETE) &&
											<FormControl fullWidth>
												<Button
													variant="outlined"
													sx={{ fontSize: 11 }}
													disabled={scriptList.data.length === 0 || selectScriptId === '' || script.status === RequestStatus.LOADING}
													onClick={() => setShowAlertDialogDelete(true)}
													color='error'
												>
													{translate('button_delete')}
												</Button>
											</FormControl>
										}
									</div>
								}
							</div>
						</Fade>
					}

					{/* подтверждение удаления скрипта */}
					<AlertDialog
						showAlertDialog={showAlertDialogDelete}
						setShowAlertDialog={setShowAlertDialogDelete}
						submitHandler={deleteScriptHandler}
						title='dialog_deleteScript'
						description='dialog_deleteScriptConfirm'
					/>

					<div className={styles.tagClose} onClick={closeHandler}>
						{translate('tag_close')}
					</div>
				</div>
			</div>
		</Slide>
	);
};

export default Scripts;
