import { useEffect, useState } from 'react';
import { Slide } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { clearAddingData, clearAddingEndpoint, clearDataList, clearDeletingData, clearDeletingEndpoint, clearEditingData, clearEditingEndpoint, clearEndpointList, getDataList, getEndpointList, selectAddingData, selectAddingEndpoint, selectDeletingData, selectDeletingEndpoint, selectEditingData, selectEditingEndpoint } from '../../../store/sesSlice';
import { clearDeletingRobot, clearEditingRobot, clearRobotList, selectCommitConfigRobot, selectDeletingRobot, selectEditingRobot, selectRobot, getRobotList, selectActiveRobotId, selectActiveRobotVersion, changeActiveRobotVersion, applyRobot, clearDraftRobot, restoreRobot, clearApplyingRobot, selectApplyingRobot, selectRestorationRobot, selectClearDraftingRobot, clearClearDraftingRobot, clearRestorationRobot, changeRobotPossibleVersionsInList, getRobot, clearRobotExceptVersions, clearExportRobot, selectExportRobot, exportRobot, selectImportRobot, clearImportRobot } from '../../../store/sesRobotSlice';
import { clearAddingScript, clearDeletingScript, clearEditingScript, getScriptList, selectAddingScript, selectDeletingScript, selectEditingScript } from '../../../store/sesScriptSlice';
import { selectDataServers } from '../../../store/serverSlice';
import useAccessRight from '../../../hooks/useAccessRight';
import useTranslate from '../../../hooks/useTranslate';
import { SERVER, SES } from '../../../constants/accessRights';
import { RequestStatus, ResponseStatus } from '../../../types/statusTypes';
import ApplyConfigRobot from '../Buttons/ApplyConfigRobot/ApplyConfigRobot';
import UserMenu from '../Buttons/UserMenu/UserMenu';
import Scripts from '../../Scripts/Scripts';
import Calendar from '../../Calendar/Calendar';
import Intervals from '../../Intervals/Intervals';
import RobotVersion from '../Buttons/RobotVersion/RobotVersion';
import Export from '../Buttons/Export/Export';
import Import from '../Buttons/Import/Import';
import ApplyRobot from '../Buttons/ApplyRobot/ApplyRobot';
import RestoreRobot from '../Buttons/RestoreRobot/RestoreRobot';
import ClearDraftRobot from '../Buttons/ClearDraftRobot/ClearDraftRobot';
import ConfigRobot from '../../ConfigRobot/ConfigRobot';
import ServerStatus from '../../ServerStatus/ServerStatus';
import ScreenLock from '../../ScreenLock/ScreenLock';
import AlertDialog from '../../AlertDialog/AlertDialog';
import Notification from '../../Notification/Notification';
import { IRobotsControlsProps } from './RobotsControls.props';
import styles from './RobotsControls.module.scss';

const RobotsControls = ({ setShowPage }: IRobotsControlsProps): JSX.Element => {
	const [showAlertDialogApplyRobot, setShowAlertDialogApplyRobot] = useState<boolean>(false); // показ диалогового окна применения черновика в прод
	const [showAlertDialogClearDraftRobot, setShowAlertDialogClearDraftRobot] = useState<boolean>(false); // показ диалогового окна замены черновика продом
	const [showAlertDialogRestoreRobot, setShowAlertDialogRestoreRobot] = useState<boolean>(false); // показ диалогового окна при восстановлении прода из бэкапа

	const [showNotificationEditRobot, setShowNotificationEditRobot] = useState<boolean>(false); // показ уведомления об изменении данных робота
	const [showNotificationDelRobot, setShowNotificationDelRobot] = useState<boolean>(false); // показ уведомления об удалении робота

	const [showNotificationApplyRobot, setShowNotificationApplyRobot] = useState<boolean>(false); // показ уведомления применения черновика в прод
	const [showNotificationClearDraftRobot, setShowNotificationClearDraftRobot] = useState<boolean>(false); // показ уведомления замены черновика продом
	const [showNotificationRestoreRobot, setShowNotificationRestoreRobot] = useState<boolean>(false); // показ уведомления о восстановлении прода из бэкапа

	const [showNotificationAddDataElem, setShowNotificationAddDataElem] = useState<boolean>(false); // показ уведомления о добавлении элемента данных
	const [showNotificationEditDataElem, setShowNotificationEditDataElem] = useState<boolean>(false); // показ уведомления об изменении элемента данных
	const [showNotificationDelDataElem, setShowNotificationDelDataElem] = useState<boolean>(false); // показ уведомления об удалении элемента данных

	const [showNotificationAddEndpoint, setShowNotificationAddEndpoint] = useState<boolean>(false); // показ уведомления о добавлении конечной точки
	const [showNotificationEditEndpoint, setShowNotificationEditEndpoint] = useState<boolean>(false); // показ уведомления об изменении конечной точки
	const [showNotificationDelEndpoint, setShowNotificationDelEndpoint] = useState<boolean>(false); // показ уведомления об удалении конечной точки

	const [showNotificationAddScript, setShowNotificationAddScript] = useState<boolean>(false); // показ уведомления о добавлении скрипта
	const [showNotificationEditScript, setShowNotificationEditScript] = useState<boolean>(false); // показ уведомления об изменении скрипта
	const [showNotificationDelScript, setShowNotificationDelScript] = useState<boolean>(false); // показ уведомления об удалении скрипта

	const [showScreenLock, setShowScreenLock] = useState<{ isShow: boolean, title: string }>({ isShow: false, title: '' }); // показ экрана блокировки и подпись

	const [showScripts, setShowScripts] = useState<boolean>(false); // показ вкладки скриптов
	const [showCalendar, setShowCalendar] = useState<boolean>(false); // показ вкладки календаря
	const [showIntervals, setShowIntervals] = useState<boolean>(false); // показ вкладки настроек интервалов работы робота
	const [showSettings, setShowSettings] = useState<boolean>(false); // показ вкладки настроек робота
	const [showServers, setShowServers] = useState<boolean>(false); // показ вкладки серверов

	const dispatch = useAppDispatch();
	const robotInfo = useAppSelector(selectRobot); // store - информация о роботе
	const activeRobotId = useAppSelector(selectActiveRobotId); // store - id активного робота
	const activeRobotVersion = useAppSelector(selectActiveRobotVersion); // store - версия активного робота
	const dataServers = useAppSelector(selectDataServers); // store - информация о серверах
	const applyingRobot = useAppSelector(selectApplyingRobot); // store - статус о применении черновика робота в прод
	const restorationRobot = useAppSelector(selectRestorationRobot); // store - статус о восстановлении робота в прод из бэкапа
	const clearDraftingRobot = useAppSelector(selectClearDraftingRobot); // store - статус обнуления черновика робота продом

	const editingRobot = useAppSelector(selectEditingRobot); // store - статус изменения данных робота
	const deletingRobot = useAppSelector(selectDeletingRobot); // store - статус удаления робота
	const commitConfigRobot = useAppSelector(selectCommitConfigRobot); // store - применения настроек робота

	const addingDataElem = useAppSelector(selectAddingData); // store - статус добавления элемента данных
	const editingDataElem = useAppSelector(selectEditingData); // store - статус изменения элемента данных
	const deletingDataElem = useAppSelector(selectDeletingData); // store - статус удаления элемента данных

	const addingEndpoint = useAppSelector(selectAddingEndpoint); // store - статус добавления конечной точки
	const editingEndpoint = useAppSelector(selectEditingEndpoint); // store - статус изменения конечной точки
	const deletingEndpoint = useAppSelector(selectDeletingEndpoint); // store - статус удаления конечной точки

	const addingScript = useAppSelector(selectAddingScript); // store - статус добавления скрипта
	const editingScript = useAppSelector(selectEditingScript); // store - статус изменения скрипта
	const deletingScript = useAppSelector(selectDeletingScript); // store - статус удаления скрипта

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// следим за статусом изменения/удаления/применения настроек/применения черновика/восстановления из бэкапа/обнуления черновика робота, добавления/изменения/удаления элемента данных, добавления/изменения/удаления конечной точки, добавления/изменения/удаления скрипта
	useEffect(() => {
		if (editingRobot.status === RequestStatus.LOADING) {
			setShowScreenLock({ isShow: true, title: 'spinnerTitle_saving' });
			setShowNotificationEditRobot(true);
		} // если идет изменение данных робота
		else if (deletingRobot.status === RequestStatus.LOADING) {
			setShowScreenLock({ isShow: true, title: 'spinnerTitle_deletion' });
			setShowNotificationDelRobot(true);
		} // если идет удаление робота
		else if (commitConfigRobot.status === RequestStatus.LOADING) {
			setShowScreenLock({ isShow: true, title: 'spinnerTitle_applying' });
		} // если идет применение настроек робота
		else if (applyingRobot.status === RequestStatus.LOADING) {
			setShowScreenLock({ isShow: true, title: 'spinnerTitle_applying' });
		} // если идет применение черновика в прод
		else if (restorationRobot.status === RequestStatus.LOADING) {
			setShowScreenLock({ isShow: true, title: 'spinnerTitle_recovery' });
		} // если идет восстановление робота из бэкапа
		else if (clearDraftingRobot.status === RequestStatus.LOADING) {
			setShowScreenLock({ isShow: true, title: 'spinnerTitle_clearingDraft' });
		} // если идет обнуление черновика продовым роботом
		else if (addingDataElem.status === RequestStatus.LOADING) {
			setShowScreenLock({ isShow: true, title: 'spinnerTitle_addition' });
			setShowNotificationAddDataElem(true);
		} // если идет добавление элемента данных
		else if (editingDataElem.status === RequestStatus.LOADING) {
			setShowScreenLock({ isShow: true, title: 'spinnerTitle_saving' });
			setShowNotificationEditDataElem(true);
		} // если идет изменение элемента данных
		else if (deletingDataElem.status === RequestStatus.LOADING) {
			setShowScreenLock({ isShow: true, title: 'spinnerTitle_deletion' });
			setShowNotificationDelDataElem(true);
		} // если идет удаление элемента данных
		else if (addingEndpoint.status === RequestStatus.LOADING) {
			setShowScreenLock({ isShow: true, title: 'spinnerTitle_addition' });
			setShowNotificationAddEndpoint(true);
		} // если идет добавление конечной точки
		else if (editingEndpoint.status === RequestStatus.LOADING) {
			setShowScreenLock({ isShow: true, title: 'spinnerTitle_saving' });
			setShowNotificationEditEndpoint(true);
		} // если идет изменение конечной точки
		else if (deletingEndpoint.status === RequestStatus.LOADING) {
			setShowScreenLock({ isShow: true, title: 'spinnerTitle_deletion' });
			setShowNotificationDelEndpoint(true);
		} // если идет удаление конечной точки
		else if (addingScript.status === RequestStatus.LOADING) {
			setShowScreenLock({ isShow: true, title: 'spinnerTitle_addition' });
			setShowNotificationAddScript(true);
		} // если идет добавление скрипта
		else if (editingScript.status === RequestStatus.LOADING) {
			setShowScreenLock({ isShow: true, title: 'spinnerTitle_saving' });
			setShowNotificationEditScript(true);
		} // если идет изменение скрипта
		else if (deletingScript.status === RequestStatus.LOADING) {
			setShowScreenLock({ isShow: true, title: 'spinnerTitle_deletion' });
			setShowNotificationDelScript(true);
		} // если идет удаление скрипта
		else setShowScreenLock({ isShow: false, title: '' }); // иначе выключаем экран блокировки

		// если удаление робота прошло успешно
		if (deletingRobot.status === RequestStatus.IDLE && deletingRobot.error === ResponseStatus.SUCCESS && deletingRobot.message !== '') {
			setShowSettings(false); // закрываем вкладку
			dispatch(clearRobotList()); // очищаем список роботов
			isAccess(SES.ROBOT_LIST) && dispatch(getRobotList()); // получаем заново список роботов
		}
		// если применение черновика робота прошло успешно
		if (applyingRobot.status === RequestStatus.IDLE && applyingRobot.error === ResponseStatus.SUCCESS && applyingRobot.message !== '') {
			dispatch(changeRobotPossibleVersionsInList({
				hasProd: true,
				hasBackup: robotInfo.hasProd ? true : false,
			})); // включаем hasProd и hasBackup, если был hasProd
			dispatch(changeActiveRobotVersion('prod')); // переходим на продовую версию
		}
		// если восстановление прода робота из бэкапа прошло успешно
		if (restorationRobot.status === RequestStatus.IDLE && restorationRobot.error === ResponseStatus.SUCCESS && restorationRobot.message !== '') {
			dispatch(changeRobotPossibleVersionsInList({ hasBackup: false })); // выключаем hasBackup
			dispatch(changeActiveRobotVersion('prod')); // переходим на продовую версию
		}
		// если обнуление черновика робота продом прошло успешно
		if (clearDraftingRobot.status === RequestStatus.IDLE && clearDraftingRobot.error === ResponseStatus.SUCCESS && clearDraftingRobot.message !== '') {
			// обновляем данные
			robotInfo.data && dispatch(clearRobotExceptVersions()); // очищаем данные о роботе, если есть
			isAccess(SES.ROBOT_GET) && activeRobotId && dispatch(getRobot(activeRobotId)); // получаем инфо о роботе
			isAccess(SES.DATA_LIST) && activeRobotId && dispatch(getDataList(activeRobotId)); // получаем список элементов данных
			isAccess(SES.ENDPOINT_LIST) && activeRobotId && dispatch(getEndpointList(activeRobotId)); // получаем список конечных точек
			isAccess(SES.SCRIPT_LIST) && activeRobotId && dispatch(getScriptList(activeRobotId)); // получаем список скриптов
		}

		// если добавление элемента данных прошло успешно
		if (addingDataElem.status === RequestStatus.IDLE && addingDataElem.error === ResponseStatus.SUCCESS && addingDataElem.message !== '') {
			dispatch(clearDataList()); // очищаем список элементов
			isAccess(SES.DATA_LIST) && activeRobotId && dispatch(getDataList(activeRobotId)); // получаем заново список элементов
		}
		// если удаление элемента данных прошло успешно
		if (deletingDataElem.status === RequestStatus.IDLE && deletingDataElem.error === ResponseStatus.SUCCESS && deletingDataElem.message !== '') {
			dispatch(clearDataList()); // очищаем список элементов
			isAccess(SES.DATA_LIST) && activeRobotId && dispatch(getDataList(activeRobotId)); // получаем заново список элементов
		}

		// если добавление конечной точки прошло успешно
		if (addingEndpoint.status === RequestStatus.IDLE && addingEndpoint.error === ResponseStatus.SUCCESS && addingEndpoint.message !== '') {
			dispatch(clearEndpointList()); // очищаем список конечных точек
			isAccess(SES.ENDPOINT_LIST) && activeRobotId && dispatch(getEndpointList(activeRobotId)); // получаем заново список конечных точек
		}
		// если удаление конечной точки прошло успешно
		if (deletingEndpoint.status === RequestStatus.IDLE && deletingEndpoint.error === ResponseStatus.SUCCESS && deletingEndpoint.message !== '') {
			dispatch(clearEndpointList()); // очищаем список конечных точек
			isAccess(SES.ENDPOINT_LIST) && activeRobotId && dispatch(getEndpointList(activeRobotId)); // получаем заново список конечных точек
		}
	}, [editingRobot, deletingRobot, commitConfigRobot, applyingRobot, restorationRobot, clearDraftingRobot, addingDataElem, editingDataElem, deletingDataElem, addingEndpoint, editingEndpoint, deletingEndpoint, addingScript, editingScript, deletingScript]);

	// обработчик экспорта робота
	const exportHandler = (): void => {
		activeRobotId && robotInfo.data?.name && dispatch(exportRobot({ robotId: activeRobotId, robotName: robotInfo.data.name })); // экспорт робота
	};

	// обработчик применения черновика робота в прод
	const applyRobotHandler = (): void => {
		setShowNotificationApplyRobot(true); // включаем уведомление
		activeRobotId && dispatch(applyRobot(activeRobotId)); // применяем робота в прод
		setShowAlertDialogApplyRobot(false); // выключаем диалоговое окно
	};

	// обработчик обнуления черновика робота из прода
	const clearDraftRobotHandler = (): void => {
		setShowNotificationClearDraftRobot(true); // включаем уведомление
		activeRobotId && dispatch(clearDraftRobot(activeRobotId)); // обнуляем черновик продом
		setShowAlertDialogClearDraftRobot(false); // выключаем диалоговое окно
	};

	// обработчик восстановления робота прода из бэкапа
	const restoreRobotHandler = (): void => {
		setShowNotificationRestoreRobot(true); // включаем уведомление
		activeRobotId && dispatch(restoreRobot(activeRobotId)); // обнуляем черновик продом
		setShowAlertDialogRestoreRobot(false); // выключаем диалоговое окно
	};

	return (
		<>
			<div className={styles.controls}>
				<Slide direction="down" in={true} mountOnEnter unmountOnExit timeout={500}>
					<div className={styles.controlsWrapper}>
						<div className={styles.controlsTitle}>{translate('ses')}</div>
						<section className={styles.controlsTypeAndFunc}>
							<div className={styles.controlsFunctions}>
								<div className={styles.controlsFunctionsLeftBlock}>
									<RobotVersion
										styleNotActive={styles.controlsFunctionBtnNotActive}
										isAvailable={activeRobotId !== null && activeRobotVersion !== 'draft' && robotInfo.status !== RequestStatus.LOADING}
										isActive={activeRobotVersion === 'draft'}
										version='draft'
										onClickFunction={() => dispatch(changeActiveRobotVersion('draft'))}
									/>
									{robotInfo.hasProd &&
										<RobotVersion
											styleNotActive={styles.controlsFunctionBtnNotActive}
											isAvailable={activeRobotId !== null && activeRobotVersion !== 'prod' && robotInfo.status !== RequestStatus.LOADING}
											isActive={activeRobotVersion === 'prod'}
											version='prod'
											onClickFunction={() => dispatch(changeActiveRobotVersion('prod'))}
										/>
									}
									{robotInfo.hasBackup &&
										<RobotVersion
											styleNotActive={styles.controlsFunctionBtnNotActive}
											isAvailable={activeRobotId !== null && activeRobotVersion !== 'backup' && robotInfo.status !== RequestStatus.LOADING}
											isActive={activeRobotVersion === 'backup'}
											version='backup'
											onClickFunction={() => dispatch(changeActiveRobotVersion('backup'))}
										/>
									}
								</div>
							</div>

							<div className={styles.controlsFunctions}>
								<div className={styles.controlsFunctionsLeftBlock}>
									{isAccess(SES.ROBOT_EXPORT) &&
										<Export
											styleNotActive={styles.controlsFunctionBtnNotActive}
											isAvailable={activeRobotId !== null && robotInfo.status === RequestStatus.IDLE}
											selectDataResponse={selectExportRobot}
											clearDataResponse={clearExportRobot}
											submitHandler={exportHandler}
										/>
									}
									{isAccess(SES.ROBOT_IMPORT) &&
										<Import
											styleNotActive={styles.controlsFunctionBtnNotActive}
											isAvailable={activeRobotId !== null && robotInfo.status === RequestStatus.IDLE}
											selectDataResponse={selectImportRobot}
											clearDataResponse={clearImportRobot}
											placeOfImport='robot'
										/>
									}
								</div>
								<div className={styles.controlsFunctionsBlock}>
									{isAccess(SES.ROBOT_CLEAR_DRAFT) &&
										<ClearDraftRobot
											styleNotActive={styles.controlsFunctionBtnNotActive}
											isAvailable={activeRobotId !== null && activeRobotVersion === 'draft' && robotInfo.hasProd && robotInfo.status === RequestStatus.IDLE}
											showNotification={showNotificationClearDraftRobot}
											setShowNotification={setShowNotificationClearDraftRobot}
											dataResponse={selectClearDraftingRobot}
											clearDataResponse={clearClearDraftingRobot}
											setShowAlertDialog={setShowAlertDialogClearDraftRobot}
										/>
									}
									{isAccess(SES.ROBOT_APPLY) &&
										<ApplyRobot
											styleNotActive={styles.controlsFunctionBtnNotActive}
											isAvailable={activeRobotId !== null && activeRobotVersion === 'draft' && robotInfo.status === RequestStatus.IDLE}
											showNotification={showNotificationApplyRobot}
											setShowNotification={setShowNotificationApplyRobot}
											dataResponse={selectApplyingRobot}
											clearDataResponse={clearApplyingRobot}
											setShowAlertDialog={setShowAlertDialogApplyRobot}
										/>
									}
									{isAccess(SES.ROBOT_RESTORE) &&
										<RestoreRobot
											styleNotActive={styles.controlsFunctionBtnNotActive}
											isAvailable={activeRobotId !== null && activeRobotVersion === 'backup' && robotInfo.status === RequestStatus.IDLE}
											showNotification={showNotificationRestoreRobot}
											setShowNotification={setShowNotificationRestoreRobot}
											dataResponse={selectRestorationRobot}
											clearDataResponse={clearRestorationRobot}
											setShowAlertDialog={setShowAlertDialogRestoreRobot}
										/>
									}
								</div>
								<div className={styles.controlsFunctionsCenterBlock}>
									{isAccess(SES.ROBOT_COMMIT_CONFIG) &&
										<ApplyConfigRobot
											styleNotActive={styles.controlsFunctionBtnNotActive}
											isAvailable={activeRobotId !== null && robotInfo.status === RequestStatus.IDLE}
										/>
									}
								</div>
								<div className={styles.controlsFunctionsRightBlock}>
									<UserMenu setShowPage={setShowPage} />
								</div>
							</div>
						</section>
					</div>
				</Slide>

				{/* бирки */}
				<Slide direction="left" in={true} mountOnEnter unmountOnExit timeout={1000}>
					<section className={styles.tags}>
						{isAccess([SES.SCRIPT_LIST, SES.SCRIPT_GET]) && activeRobotId &&
							<div className={styles.tag} onClick={() => setShowScripts(prev => !prev)}>
								<span className={styles.tagTitle}>{translate('tag_scripts')}</span>
							</div>
						}
						{isAccess(SES.ROBOT_GET) && activeRobotId &&
							<div className={styles.tag} onClick={() => setShowCalendar(prev => !prev)}>
								<span className={styles.tagTitle}>{translate('tag_calendar')}</span>
							</div>
						}
						{isAccess(SES.ROBOT_GET) && activeRobotId &&
							<div className={styles.tag} onClick={() => setShowIntervals(prev => !prev)}>
								<span className={styles.tagTitle}>{translate('tag_intervals')}</span>
							</div>
						}
						{isAccess(SES.ROBOT_GET) && activeRobotId &&
							<div className={styles.tag} onClick={() => setShowSettings(prev => !prev)}>
								<span className={styles.tagTitle}>{translate('tag_settings')}</span>
							</div>
						}
						{isAccess(SERVER.ADDRESSES) &&
							<div className={styles.tag} onClick={() => setShowServers(prev => !prev)}>
								<span className={styles.tagTitle}>
									<span className={dataServers && dataServers.fail && !Object.keys(dataServers?.fail).length ? styles.serversTagStatus : styles.serversTagStatusFailed}></span>
									{translate('tag_servers')}
								</span>
							</div>
						}
					</section>
				</Slide>

				<Scripts showScripts={showScripts} setShowScripts={setShowScripts} />
				<Calendar showCalendar={showCalendar} setShowCalendar={setShowCalendar} />
				<Intervals showIntervals={showIntervals} setShowIntervals={setShowIntervals} />
				<ConfigRobot showSettings={showSettings} setShowSettings={setShowSettings} />
				<ServerStatus showServers={showServers} setShowServers={setShowServers} />
			</div>

			{showScreenLock.isShow && <ScreenLock title={translate(showScreenLock.title)} />}

			<AlertDialog
				showAlertDialog={showAlertDialogApplyRobot}
				setShowAlertDialog={setShowAlertDialogApplyRobot}
				submitHandler={applyRobotHandler}
				title='dialog_applyRobot'
				description='dialog_applyRobotConfirm'
				name={robotInfo.data?.name}
			/>

			<AlertDialog
				showAlertDialog={showAlertDialogClearDraftRobot}
				setShowAlertDialog={setShowAlertDialogClearDraftRobot}
				submitHandler={clearDraftRobotHandler}
				title='dialog_clearDraftRobot'
				description='dialog_сlearDraftRobotConfirm'
				name={robotInfo.data?.name}
			/>

			<AlertDialog
				showAlertDialog={showAlertDialogRestoreRobot}
				setShowAlertDialog={setShowAlertDialogRestoreRobot}
				submitHandler={restoreRobotHandler}
				title='dialog_restoreRobot'
				description='dialog_restoreRobotConfirm'
				name={robotInfo.data?.name}
			/>

			{/* уведомление об изменении данных робота */}
			{showNotificationEditRobot &&
				<Notification
					showNotification={showNotificationEditRobot}
					setShowNotification={setShowNotificationEditRobot}
					selectDataResponse={selectEditingRobot}
					clearDataResponse={clearEditingRobot}
					titleFailed='noticeSaving_failed'
					titleSuccess='noticeSaving_success'
				/>
			}
			{/* уведомление об удалении робота */}
			{showNotificationDelRobot &&
				<Notification
					showNotification={showNotificationDelRobot}
					setShowNotification={setShowNotificationDelRobot}
					selectDataResponse={selectDeletingRobot}
					clearDataResponse={clearDeletingRobot}
					titleFailed='noticeDeletion_failed'
					titleSuccess='noticeDeletion_success'
				/>
			}

			{/* уведомление о добавлении элемента данных */}
			{showNotificationAddDataElem &&
				<Notification
					showNotification={showNotificationAddDataElem}
					setShowNotification={setShowNotificationAddDataElem}
					selectDataResponse={selectAddingData}
					clearDataResponse={clearAddingData}
					titleFailed='noticeAddition_failed'
					titleSuccess='noticeAddition_success'
				/>
			}
			{/* уведомление об изменении элемента данных */}
			{showNotificationEditDataElem &&
				<Notification
					showNotification={showNotificationEditDataElem}
					setShowNotification={setShowNotificationEditDataElem}
					selectDataResponse={selectEditingData}
					clearDataResponse={clearEditingData}
					titleFailed='noticeSaving_failed'
					titleSuccess='noticeSaving_success'
				/>
			}
			{/* уведомление об удалении элемента данных */}
			{showNotificationDelDataElem &&
				<Notification
					showNotification={showNotificationDelDataElem}
					setShowNotification={setShowNotificationDelDataElem}
					selectDataResponse={selectDeletingData}
					clearDataResponse={clearDeletingData}
					titleFailed='noticeDeletion_failed'
					titleSuccess='noticeDeletion_success'
				/>
			}

			{/* уведомление о добавлении конечной точки */}
			{showNotificationAddEndpoint &&
				<Notification
					showNotification={showNotificationAddEndpoint}
					setShowNotification={setShowNotificationAddEndpoint}
					selectDataResponse={selectAddingEndpoint}
					clearDataResponse={clearAddingEndpoint}
					titleFailed='noticeAddition_failed'
					titleSuccess='noticeAddition_success'
				/>
			}
			{/* уведомление об изменении конечной точки */}
			{showNotificationEditEndpoint &&
				<Notification
					showNotification={showNotificationEditEndpoint}
					setShowNotification={setShowNotificationEditEndpoint}
					selectDataResponse={selectEditingEndpoint}
					clearDataResponse={clearEditingEndpoint}
					titleFailed='noticeSaving_failed'
					titleSuccess='noticeSaving_success'
				/>
			}
			{/* уведомление об удалении конечной точки */}
			{showNotificationDelEndpoint &&
				<Notification
					showNotification={showNotificationDelEndpoint}
					setShowNotification={setShowNotificationDelEndpoint}
					selectDataResponse={selectDeletingEndpoint}
					clearDataResponse={clearDeletingEndpoint}
					titleFailed='noticeDeletion_failed'
					titleSuccess='noticeDeletion_success'
				/>
			}

			{/* уведомление о добавлении скрипта */}
			{showNotificationAddScript &&
				<Notification
					showNotification={showNotificationAddScript}
					setShowNotification={setShowNotificationAddScript}
					selectDataResponse={selectAddingScript}
					clearDataResponse={clearAddingScript}
					titleFailed='noticeAddition_failed'
					titleSuccess='noticeAddition_success'
				/>
			}
			{/* уведомление об изменении скрипта */}
			{showNotificationEditScript &&
				<Notification
					showNotification={showNotificationEditScript}
					setShowNotification={setShowNotificationEditScript}
					selectDataResponse={selectEditingScript}
					clearDataResponse={clearEditingScript}
					titleFailed='noticeSaving_failed'
					titleSuccess='noticeSaving_success'
				/>
			}
			{/* уведомление об удалении скрипта */}
			{showNotificationDelScript &&
				<Notification
					showNotification={showNotificationDelScript}
					setShowNotification={setShowNotificationDelScript}
					selectDataResponse={selectDeletingScript}
					clearDataResponse={clearDeletingScript}
					titleFailed='noticeDeletion_failed'
					titleSuccess='noticeDeletion_success'
				/>
			}
		</>
	);
};

export default RobotsControls;
