import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faReply } from '@fortawesome/free-solid-svg-icons';
import useTranslate from '../../../../hooks/useTranslate';
import { backgroundColor } from '../../../../constants/colors';
import Notification from '../../../Notification/Notification';
import { IClearDraftRobotProps } from './ClearDraftRobot.props';

const ClearDraftRobot = ({ styleNotActive, isAvailable, showNotification, setShowNotification, dataResponse, clearDataResponse, setShowAlertDialog }: IClearDraftRobotProps): JSX.Element => {
	const translate = useTranslate(); // hook для перевода текста

	return (
		<>
			<div
				className={isAvailable ? '' : styleNotActive}
				onClick={() => isAvailable && setShowAlertDialog(true)}
				title={translate('buttonTitle_clearDraft')}>
				<FontAwesomeIcon icon={faReply} size="2xl" color={backgroundColor} />
			</div>

			<section style={{ position: 'absolute' }}>
				{showNotification &&
					<Notification
						showNotification={showNotification}
						setShowNotification={setShowNotification}
						selectDataResponse={dataResponse}
						clearDataResponse={clearDataResponse}
						titleFailed='noticeClearDraft_failed'
						titleSuccess='noticeClearDraft_success'
					/>
				}
			</section>
		</>
	);
};

export default ClearDraftRobot;
