import { useEffect, useRef, useState } from 'react';
import { Button, Fade, FormControl, TextField } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { changeActiveFileName, clearFragmentForSelection, editDocument, selectCategoriesList, selectDocument, selectFragmentToQuestion } from '../../store/qasSlice';
import useAccessRight from '../../hooks/useAccessRight';
import useTranslate from '../../hooks/useTranslate';
import { QAS } from '../../constants/accessRights';
import { colorPrimary } from '../../constants/colors';
import { RequestStatus } from '../../types/statusTypes';
import DocumentParameters from '../DocumentParameters/DocumentParameters';
import ProgressCircle from '../ProgressCircle/ProgressCircle';
import { IDocumentEditorProps } from './DocumentEditor.props';
import styles from './DocumentEditor.module.scss';

const DocumentEditor = ({ changeFlg, setChangeFlg, setShowAlertDialogSave, setShowAlertDialogDelete }: IDocumentEditorProps): JSX.Element => {
	const [inputFileName, setInputFileName] = useState<string>(''); // название документа
	const [inputCategory, setInputCategory] = useState<string>(''); // категория документа
	const [inputText, setInputText] = useState<string>(''); // текст документа
	const documentWrapperRef = useRef<HTMLDivElement>(null); // ссылка на обертку документа
	const documentRef = useRef<HTMLTextAreaElement>(null); // ссылка на textarea документа
	const [errorCategoryFlg, setErrorCategoryFlg] = useState<boolean>(false); // флаг ошибки добавления категории
	const [showDocumentParams, setShowDocumentParams] = useState<boolean>(false); // показ вкладки доп.параметров документа

	const dispatch = useAppDispatch();
	const documentFile = useAppSelector(selectDocument); // store - документ (файл)
	const fragments = useAppSelector(selectFragmentToQuestion); // store - данные поиска фрагмента на вопрос
	const categoriesList = useAppSelector(selectCategoriesList); // store - список категорий

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// следим за данными документа и фрагментом для выделения
	useEffect(() => {
		documentFile.fileName && setInputFileName(documentFile.fileName); // пишем имя
		writeCategoryInField(); // пишем категорию в поле
		typeof documentFile.data === 'string' && setInputText(documentFile.data); // пишем текст
		errorCategoryFlg && setErrorCategoryFlg(false); // сбрасываем ошибку, если была
		// если без ошибок и есть текст фрагмента для выделения
		if (typeof documentFile.data === 'string' && fragments.fragmentForSelection && documentRef.current) {
			const matchRow = fragments.fragmentForSelection.match(/.+(\r|\n)/); // совпадение первой строки
			const startIndexInDocument = matchRow && documentFile.data.indexOf(matchRow[0]); // индекс начала фрагмента в документе
			// если найден стартовый индекс совпадения фрагмента в документе
			if (typeof startIndexInDocument === 'number' && startIndexInDocument >= 0) {
				documentRef.current.focus(); // ставим фокус в поле текста
				// отложенный вызов, чтобы фокус успел встать в поле текста
				setTimeout(() => {
					if (documentRef.current && fragments.fragmentForSelection) {
						// выделение фрагмента
						documentRef.current.setSelectionRange(startIndexInDocument, startIndexInDocument + fragments.fragmentForSelection.length);
						if (typeof documentFile.data === 'string' && documentWrapperRef.current) {

							const clone = documentRef.current.cloneNode(); // клонируем textarea
							(clone as HTMLTextAreaElement).value = documentFile.data.slice(0, startIndexInDocument); // пишем в клон часть документа до фрагмента для выделения
							documentWrapperRef.current.appendChild(clone); // добавляем клона в DOM
							const scrollHeightClone = (clone as HTMLTextAreaElement).scrollHeight; // полная высота клона
							// если высота клона больше видимой клиентской высоты оригинала - скроллим до этого места
							if (scrollHeightClone > documentRef.current.offsetHeight) documentRef.current.scrollTop = scrollHeightClone - 25;
							// иначе скроллим наверх
							else documentRef.current.scrollTop = 0;
							documentWrapperRef.current.removeChild(clone); // удаляем клона из DOM
						}
					}
				});
			}
		}
	}, [documentFile.data, fragments]);

	// запись категории в поле
	const writeCategoryInField = (): void => {
		const foundCategory = categoriesList.data.find(categoryItem => categoryItem.id === documentFile.category);
		if (foundCategory) {
			setInputCategory(foundCategory.name); // пишем категорию, если нашли
		} else {
			documentFile.category && setInputCategory(`${documentFile.category} (${translate('title_notFound')})`); // пишем категорию, которой нет в списке
		}
	};

	// обработчик размытия (увода из фокуса поля)
	const blurHandler = (value: string, input: 'fileName' | 'text'): void => {
		fragments.fragmentForSelection && dispatch(clearFragmentForSelection()); // очищаем фрагмент из store для выделения
		switch (input) {
			case 'fileName':
				value !== documentFile.fileName && dispatch(changeActiveFileName({ fileName: value, change: true })); // изменяем имя файла
				break;
			case 'text':
				value !== documentFile.data && dispatch(editDocument(value)); // изменяем документ
				break;
			default:
				break;
		}
		// если изменилось любое поле - ставим флаг о несохраненных данных
		if (documentFile.fileName !== inputFileName || documentFile.data !== inputText) setChangeFlg(true);
	};

	// обработчик нажатия клавиши
	const keyDownHandler = (event: React.KeyboardEvent<HTMLTextAreaElement>): void => {
		if (event.code === 'Tab') {
			event.preventDefault();

			const textarea = event.currentTarget;
			let selStart = textarea.selectionStart;
			let selEnd = textarea.selectionEnd;
			let before = textarea.value.substring(0, selStart);
			let slection = textarea.value.substring(selStart, selEnd);
			const after = textarea.value.substr(selEnd);
			let slection_new = '';

			// remove TAB indent
			if (event.shiftKey) {
				// fix selection
				const selectBefore = before.substr(before.lastIndexOf('\n') + 1);
				const isfix = /^\s/.test(selectBefore);
				if (isfix) {
					const fixed_selStart = selStart - selectBefore.length;
					before = textarea.value.substring(0, fixed_selStart);
					slection = textarea.value.substring(fixed_selStart, selEnd);
				}

				let once = false;
				slection_new = slection.replace(/^(\t|[ ]{2,4})/gm, (mm: string) => {
					if (isfix && !once) {
						once = true; // do it once - for first line only
						selStart -= mm.length;
					}
					selEnd -= mm.length;
					return '';
				});
			}
			// add TAB indent
			else {
				selStart++;
				// has selection
				if (slection.trim()) {
					slection_new = slection.replace(/^/gm, () => {
						selEnd++;
						return '\t';
					});
				} else {
					slection_new = '\t';
					selEnd++;
				}
			}

			// textarea.value = before + slection_new + after;
			setInputText(textarea.value = before + slection_new + after);
			textarea.setSelectionRange(selStart, selEnd); // cursor
		}
	};

	return (
		<Fade in={true} timeout={500}>
			<div className={styles.container}>
				{/* загрузка документа */}
				{documentFile.status === RequestStatus.LOADING &&
					<div className={styles.loading}><ProgressCircle title={translate('spinnerTitle_loading')} /></div>
				}

				{/* ошибка загрузки документа */}
				{(documentFile.status === RequestStatus.FAILED || (documentFile.data && typeof documentFile.data === 'object' && 'message' in documentFile.data)) &&
					<p className={styles.failedText}>
						{translate(documentFile.data && typeof documentFile.data === 'object' && 'message' in documentFile.data ? documentFile.data.message : 'title_notFound')}
					</p>
				}

				{/* текст документа */}
				{documentFile.status === RequestStatus.IDLE && typeof documentFile.data === 'string' &&
					<Fade in={true} timeout={500}>
						<div className={styles.documentWrapper} ref={documentWrapperRef}>
							<h3 className={styles.documentTitle}>
								{translate('title_editingDocument')}
							</h3>
							<FormControl>
								<TextField
									id="fileName"
									disabled={!isAccess(QAS.DOC_EDIT)}
									label={translate("input_name")}
									variant="outlined"
									value={inputFileName}
									onChange={(e) => setInputFileName(e.target.value)}
									onBlur={e => blurHandler(e.target.value, 'fileName')}
									InputProps={{
										style: {
											height: 33,
											fontSize: 13,
											color: colorPrimary,
										},
									}}
									InputLabelProps={{
										style: {
											fontSize: 13,
										},
									}}
									sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
								/>
							</FormControl>
							<textarea
								ref={documentRef}
								className={styles.documentTextarea}
								id="text"
								value={inputText}
								onChange={(e) => isAccess(QAS.DOC_EDIT) && setInputText(e.target.value)}
								onBlur={e => blurHandler(e.target.value, 'text')}
								onKeyDown={e => keyDownHandler(e)}
							/>
							<div className={styles.documentButtons}>
								<div className={styles.documentButtonsParams} onClick={() => setShowDocumentParams(true)}>
									{translate('link_additionally')}
								</div>
								{isAccess(QAS.DOC_EDIT) &&
									<Button
										variant="outlined"
										disabled={!changeFlg}
										sx={{ fontSize: 11 }}
										onClick={() => setShowAlertDialogSave(true)}
									>
										{translate('button_save')}
									</Button>
								}
							</div>
						</div>
					</Fade>
				}

				<DocumentParameters
					showDocumentParams={showDocumentParams}
					setShowDocumentParams={setShowDocumentParams}
					changeFlg={changeFlg}
					setChangeFlg={setChangeFlg}
					inputCategory={inputCategory}
					setInputCategory={setInputCategory}
					errorCategoryFlg={errorCategoryFlg}
					setErrorCategoryFlg={setErrorCategoryFlg}
					setShowAlertDialogSave={setShowAlertDialogSave}
					setShowAlertDialogDelete={setShowAlertDialogDelete}
					writeCategoryInField={writeCategoryInField}
				/>
			</div>
		</Fade>
	);
};

export default DocumentEditor;
