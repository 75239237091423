import { FormEvent, useState } from 'react';
import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { addingAddElementFormData, selectDataList } from '../../../store/sesSlice';
import useTranslate from '../../../hooks/useTranslate';
import { colorPrimary } from '../../../constants/colors';
import { DataElemType } from '../../../types/cloudTypes';
import ModalFormWindow from '../../../HOC/ModalFormWindow/ModalFormWindow';
import { IFormAddingDataElemProps } from './FormAddingDataElem.props';
import { DATA_ELEM_TYPE_LIST } from '../../../constants/robotConfigLists';

const FormAddingDataElem = ({ showModal, setShowModal, setShowElem }: IFormAddingDataElemProps): JSX.Element => {
	const [inputName, setInputName] = useState<string>(''); // название элемента данных
	const [selectType, setSelectType] = useState<DataElemType>('smc'); // тип
	const [showErrorExistingDataElem, setShowErrorExistingDataElem] = useState<boolean>(false); // флаг существующего имени элемента данных

	const dispatch = useAppDispatch();
	const dataList = useAppSelector(selectDataList); // список элементов данных

	const translate = useTranslate(); // hook для перевода текста

	// обработчик добавления элемента данных
	const submitHandler = (e: FormEvent<HTMLFormElement>): void => {
		e.preventDefault();
		const existingDataElemName = dataList.data.find(dataElem => dataElem.name === inputName); // существующее имя элемента данных
		if (existingDataElemName) {
			setShowErrorExistingDataElem(true); // подсвечиваем ошибку
		} else {
			showErrorExistingDataElem && setShowErrorExistingDataElem(false); // убираем ошибку, если была
			dispatch(addingAddElementFormData({ name: inputName, type: selectType })); // добавление данных формы в store
			setShowModal(false); // закрываем форму
			setShowElem(true); // открываем вкладку для редактирования
		}
	};

	return (
		<ModalFormWindow showModal={showModal} setShowModal={setShowModal} headerTitle='formHeader_addingDataElem' >
			<form onSubmit={(e) => submitHandler(e)}>
				<FormControl fullWidth margin='dense'>
					<TextField
						autoFocus
						required
						id="dataElemName"
						label={translate('input_name')}
						variant="outlined"
						value={inputName}
						onChange={(e) => setInputName(e.target.value)}
						error={showErrorExistingDataElem}
						helperText={showErrorExistingDataElem ? translate('title_dataElemExists') : ''}
						InputProps={{
							style: {
								height: 33,
								fontSize: 13,
								color: colorPrimary,
							},
						}}
						InputLabelProps={{
							style: {
								fontSize: 13,
							},
						}}
						sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
					/>
				</FormControl>

				<FormControl fullWidth margin='dense' sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}>
					<InputLabel id="type-label" sx={{ fontSize: 13 }}>{translate('select_type')}</InputLabel>
					<Select
						labelId="type-label"
						id="type"
						label={translate('select_type')}
						required
						value={selectType}
						onChange={(e) => setSelectType(e.target.value as DataElemType)}
						style={{ fontSize: 13, height: 33, color: colorPrimary }}
					>
						{DATA_ELEM_TYPE_LIST.map(({ type, translation }) =>
							<MenuItem key={type} value={type} sx={{ fontSize: 13, color: colorPrimary }}>{translate(translation)}</MenuItem>
						)}
					</Select>
				</FormControl>

				<FormControl fullWidth margin='dense'>
					<Button
						variant="outlined"
						type="submit"
						sx={{ fontSize: 11 }}
					>
						{translate('button_add')}
					</Button>
				</FormControl>
			</form>
		</ModalFormWindow>
	);
};

export default FormAddingDataElem;
