import { useEffect, useState } from 'react';
import { Fade } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { clearDatasetsList, clearRolesList, getDatasetsList, getRolesList, selectRolesList } from '../../store/authSlice';
import useAccessRight from '../../hooks/useAccessRight';
import useTranslate from '../../hooks/useTranslate';
import { DATASET, ROLE, SERVICE } from '../../constants/accessRights';
import { RequestStatus } from '../../types/statusTypes';
import PageWrapper from '../../HOC/PageWrapper/PageWrapper';
import RoleNavbar from '../../components/Navbars/RoleNavbar/RoleNavbar';
import RoleControls from '../../components/Controls/RoleControls/RoleControls';
import Role from '../../components/Role/Role';
import styles from './Roles.module.scss';

const RolesPage = (): JSX.Element => {
	const [activeRole, setActiveRole] = useState<{ id: string, idx: number }>({ id: '', idx: 0 }); // активная роль
	const [rolesRights, setRolesRights] = useState<string[]>([]); // список прав в роли
	const [changeFlg, setChangeFlg] = useState<boolean>(false); // флаг, уведомляющий об изменении прав в роли и возможности сохранить эти изменения
	const [showPage, setShowPage] = useState<boolean>(true); // показ страницы

	const dispatch = useAppDispatch();
	const rolesList = useAppSelector(selectRolesList); // store - список ролей

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	useEffect(() => {
		isAccess(ROLE.LIST) && dispatch(getRolesList()); // получаем список ролей
		isAccess(DATASET.LIST) && dispatch(getDatasetsList()); // получаем список наборов данных

		return () => {
			dispatch(clearRolesList()); // очистка списка ролей
			dispatch(clearDatasetsList()); // очистка списка наборов данных
		};
	}, []);

	return (
		<PageWrapper showPage={showPage} setShowPage={setShowPage} accessToService={[SERVICE.AUTH, ROLE.LIST]}>
			<>
				<RoleNavbar
					activeRole={activeRole}
					setActiveRole={setActiveRole}
					setChangeFlg={setChangeFlg}
					setShowPage={setShowPage}
				/>
				<RoleControls
					changeFlg={changeFlg}
					setChangeFlg={setChangeFlg}
					activeRole={activeRole}
					rolesRights={rolesRights}
					setShowPage={setShowPage}
				/>

				<Fade in={true} timeout={700} style={{ transitionDelay: '500ms' }}>
					<div className={styles.wrapper}>
						{rolesList.status === RequestStatus.IDLE && rolesList.data.length > 0 ?
							<Role
								role={rolesList.data[activeRole.idx]}
								rolesRights={rolesRights}
								setRolesRights={setRolesRights}
								setChangeFlg={setChangeFlg}
							/>
							:
							<div className={styles.noData}><div>{translate('title_noData')}</div></div>
						}
					</div>
				</Fade>
			</>
		</PageWrapper>
	);
};

export default RolesPage;
