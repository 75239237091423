import { useState } from 'react';
import { useCookies } from 'react-cookie';
import { CircularProgress } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition, faFileCircleQuestion, faStop, faWandMagicSparkles } from '@fortawesome/free-solid-svg-icons';
import cn from 'classnames';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { clearAnswerMakerStart, clearAnswerMakerStop, clearProcessingQuestionsStart, clearProcessingQuestionsStop, clearQuestionMakerStart, clearQuestionMakerStop, selectGeneratingAnswersProcessStatus, selectGeneratingAnswersStartStatus, selectGeneratingAnswersStopStatus, selectGeneratingQuestionsProcessStatus, selectGeneratingQuestionsStartStatus, selectGeneratingQuestionsStopStatus, selectProcessingQuestionsProcessStatus, selectProcessingQuestionsStartStatus, selectProcessingQuestionsStopStatus, stopGenerateAnswers, stopGenerateQuestions, stopProcessingQuestions } from '../../../../store/qasSlice';
import { clearAutomarkingStart, clearAutomarkingStop, selectAutoMarkingStart, selectAutoMarkingStatus, selectAutoMarkingStop, stopAutomark } from '../../../../store/corpusSlice';
import useTranslate from '../../../../hooks/useTranslate';
import { AUTO_MARK_TASK_ID } from '../../../../constants/cookieNames';
import { backgroundColor } from '../../../../constants/colors';
import FormGeneratingAnswers from '../../../Forms/FormGeneratingAnswers/FormGeneratingAnswers';
import FormGeneratingQuestions from '../../../Forms/FormGeneratingQuestions/FormGeneratingQuestions';
import FormProcessingQuestions from '../../../Forms/FormProcessingQuestions/FormProcessingQuestions';
import FormAutoMark from '../../../Forms/FormAutoMark/FormAutoMark';
import Notification from '../../../Notification/Notification';
import { GenerateType, IDictionary, IGeneratingProps } from './Generating.props';
import styles from './Generating.module.scss';

const dictionary: Record<GenerateType, IDictionary> = {
	// массовая обработка вопросов
	all: {
		icon: faFileCircleQuestion,
		startTitle: 'buttonTitle_processingQuestions',
		stopTitle: 'buttonTitle_stopProcessingQuestions',
		dataResponseProcessStatus: selectProcessingQuestionsProcessStatus,
		dataResponseStartStatus: selectProcessingQuestionsStartStatus,
		dataResponseStopStatus: selectProcessingQuestionsStopStatus,
		clearDataResponseStart: clearProcessingQuestionsStart,
		clearDataResponseStop: clearProcessingQuestionsStop,
		noticeStartFailedTitle: 'noticeStart_failed',
		noticeStartSuccessTitle: 'noticeStart_success',
		noticeStopFailedTitle: 'noticeStop_failed',
		noticeStopSuccessTitle: 'noticeStop_success',
	},
	// генерация ответов
	answers: {
		icon: faWandMagicSparkles,
		startTitle: 'buttonTitle_generatingAnswers',
		stopTitle: 'buttonTitle_stopGeneratingAnswers',
		dataResponseProcessStatus: selectGeneratingAnswersProcessStatus,
		dataResponseStartStatus: selectGeneratingAnswersStartStatus,
		dataResponseStopStatus: selectGeneratingAnswersStopStatus,
		clearDataResponseStart: clearAnswerMakerStart,
		clearDataResponseStop: clearAnswerMakerStop,
		noticeStartFailedTitle: 'noticeStart_failed',
		noticeStartSuccessTitle: 'noticeStart_success',
		noticeStopFailedTitle: 'noticeStop_failed',
		noticeStopSuccessTitle: 'noticeStop_success',
	},
	// генерация вопросов
	questions: {
		icon: faWandMagicSparkles,
		startTitle: 'buttonTitle_generatingQuestions',
		stopTitle: 'buttonTitle_stopGeneratingQuestions',
		dataResponseProcessStatus: selectGeneratingQuestionsProcessStatus,
		dataResponseStartStatus: selectGeneratingQuestionsStartStatus,
		dataResponseStopStatus: selectGeneratingQuestionsStopStatus,
		clearDataResponseStart: clearQuestionMakerStart,
		clearDataResponseStop: clearQuestionMakerStop,
		noticeStartFailedTitle: 'noticeStart_failed',
		noticeStartSuccessTitle: 'noticeStart_success',
		noticeStopFailedTitle: 'noticeStop_failed',
		noticeStopSuccessTitle: 'noticeStop_success',
	},
	automark: {
		icon: faWandMagicSparkles,
		startTitle: 'buttonTitle_automarking',
		stopTitle: 'buttonTitle_stopAutomarking',
		dataResponseProcessStatus: selectAutoMarkingStatus,
		dataResponseStartStatus: selectAutoMarkingStart,
		dataResponseStopStatus: selectAutoMarkingStop,
		clearDataResponseStart: clearAutomarkingStart,
		clearDataResponseStop: clearAutomarkingStop,
		noticeStartFailedTitle: 'noticeStart_failed',
		noticeStartSuccessTitle: 'noticeStart_success',
		noticeStopFailedTitle: 'noticeStop_failed',
		noticeStopSuccessTitle: 'noticeStop_success',
	}
};

const Generating = ({ styleNotActive, isAvailable, generate }: IGeneratingProps): JSX.Element => {
	const [icon, setIcon] = useState<IconDefinition>(dictionary[generate].icon); // иконка кнопки

	const [showModalStartGenerating, setShowModalStartGenerating] = useState<boolean>(false); // показ формы для генерации
	const [showNotificationStart, setShowNotificationStart] = useState<boolean>(false); // показ уведомления начала генерации
	const [showNotificationStop, setShowNotificationStop] = useState<boolean>(false); // показ уведомления остановки генерации

	const dispatch = useAppDispatch();
	const processStatus = useAppSelector(dictionary[generate].dataResponseProcessStatus); // store - статус процесса генерации

	const [cookies] = useCookies([AUTO_MARK_TASK_ID]); // hook для работы с cookie
	const translate = useTranslate(); // hook для перевода текста

	// обработчик нажатия на кнопку
	const clickHandler = (): void => {
		if (!isAvailable) return; // если не доступно - выходим
		if (processStatus.responseStatus === 'stopped' || processStatus.responseStatus === 'finished') setShowModalStartGenerating(true); // открываем форму генерации
		else {
			setShowNotificationStop(true); // включаем уведомление
			// останавливаем генерацию
			generate === 'all' && dispatch(stopProcessingQuestions()); // массовая обработка вопросов
			generate === 'answers' && dispatch(stopGenerateAnswers()); // ответы
			generate === 'questions' && dispatch(stopGenerateQuestions()); // вопросы
			generate === 'automark' && cookies.autoMarkTaskId && dispatch(stopAutomark(cookies.autoMarkTaskId)); // авторазметка
		}
	};

	return (
		<>
			<div
				className={cn({
					[styleNotActive]: !isAvailable || processStatus.responseStatus === 'started',
					[styles.hover]: processStatus.responseStatus === 'started',
				})}
				onClick={clickHandler}
				title={translate((processStatus.responseStatus === 'stopped' || processStatus.responseStatus === 'finished') ?
					dictionary[generate].startTitle
					:
					dictionary[generate].stopTitle
				)}
				onMouseOver={() => processStatus.responseStatus === 'started' && setIcon(faStop)}
				onMouseOut={() => setIcon(dictionary[generate].icon)}
			>
				<FontAwesomeIcon icon={icon} size="2xl" color={backgroundColor} />
				{processStatus.responseStatus === 'started' &&
					<CircularProgress size={35} variant="determinate" value={processStatus.progress} sx={{ position: 'absolute', color: backgroundColor }} />
				}
			</div>

			{showModalStartGenerating &&
				<>
					{generate === 'answers' &&
						<FormGeneratingAnswers
							showModal={showModalStartGenerating}
							setShowModal={setShowModalStartGenerating}
							setShowNotification={setShowNotificationStart}
						/>
					}
					{generate === 'questions' &&
						<FormGeneratingQuestions
							showModal={showModalStartGenerating}
							setShowModal={setShowModalStartGenerating}
							setShowNotification={setShowNotificationStart}
						/>
					}
					{generate === 'all' &&
						<FormProcessingQuestions
							showModal={showModalStartGenerating}
							setShowModal={setShowModalStartGenerating}
							setShowNotification={setShowNotificationStart}
						/>
					}
					{generate === 'automark' &&
						<FormAutoMark
							showModal={showModalStartGenerating}
							setShowModal={setShowModalStartGenerating}
							setShowNotification={setShowNotificationStart}
						/>
					}
				</>
			}

			<section style={{ position: 'absolute' }}>
				{showNotificationStart &&
					<Notification
						showNotification={showNotificationStart}
						setShowNotification={setShowNotificationStart}
						selectDataResponse={dictionary[generate].dataResponseStartStatus}
						clearDataResponse={dictionary[generate].clearDataResponseStart}
						titleFailed={dictionary[generate].noticeStartFailedTitle}
						titleSuccess={dictionary[generate].noticeStartSuccessTitle}
					/>
				}
				{showNotificationStop &&
					<Notification
						showNotification={showNotificationStop}
						setShowNotification={setShowNotificationStop}
						selectDataResponse={dictionary[generate].dataResponseStopStatus}
						clearDataResponse={dictionary[generate].clearDataResponseStop}
						titleFailed={dictionary[generate].noticeStopFailedTitle}
						titleSuccess={dictionary[generate].noticeStopSuccessTitle}
					/>
				}
			</section>
		</>
	);
};

export default Generating;
