import { FormEvent, Ref, forwardRef, useEffect, useState } from 'react';
import { Autocomplete, Box, Button, FormControl, InputLabel, MenuItem, Popper, Select, TextField } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import cn from 'classnames';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { approveCandidateList, selectQuestionsList, setupDeletionOnlyCandidates } from '../../../../store/qasSlice';
import useAccessRight from '../../../../hooks/useAccessRight';
import useTranslate from '../../../../hooks/useTranslate';
import { wordDeclination } from '../../../../helpers/wordDeclination';
import { QAS } from '../../../../constants/accessRights';
import { colorPrimary, colorRed } from '../../../../constants/colors';
import { IQuestionItem } from '../../../../types/qasTypes';
import TableCustomPagination from '../../TableCustomPagination/TableCustomPagination';
import { ICustomFooterProps } from './CustomFooter.props';
import styles from './CustomFooter.module.scss';

const CustomFooter = ({ table, rowSelection, tableContainerRef, data }: ICustomFooterProps): JSX.Element => {
	const [destinationQuestion, setDestinationQuestion] = useState<IQuestionItem | null>(null); // конечный вопрос 
	const [selectedCandidates, setSelectedCandidates] = useState<{ id: string, candidate: string }[]>([]); // список выделенных кандидатов

	const dispatch = useAppDispatch();
	const questionsList = useAppSelector(selectQuestionsList); // store - список вопросов

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// следим за выбранными строками
	useEffect(() => {
		const list: { id: string, candidate: string }[] = [];
		const keys = Object.keys(rowSelection).map(value => +value); // пишем номера строк
		// если есть выделенные строки
		if (keys.length > 0) {
			// перебираем данные таблицы и пишем id вопросов, к которым принадлежат кандидаты
			data.forEach((row, idx) => {
				keys.includes(idx) && list.push({ id: row.questionId, candidate: row.candidate });
			});
			setSelectedCandidates(list);
			setDestinationQuestion(questionsList.data.find(questionItem => questionItem.id === list[0].id) || null); // устанавливаем первый вопрос по умолчанию
		} else {
			setDestinationQuestion(null); // очищаем конечный вопрос для утверждения кандидатов
		}
	}, [rowSelection]);

	// обработчик удаления кандидатов
	const deleteCandidatesHandler = (): void => {
		dispatch(setupDeletionOnlyCandidates()); // установка флага только удаления кандидатов
		dispatch(approveCandidateList({
			destinationQuestionId: '-1',
			candidateList: selectedCandidates,
		})); // удаление кандидатов
	};

	// обработчик утверждения кандидатов
	const approveCandidatesHandler = (e: FormEvent<HTMLFormElement>): void => {
		e.preventDefault();
		destinationQuestion && dispatch(approveCandidateList({
			destinationQuestionId: destinationQuestion.id,
			candidateList: selectedCandidates,
		}));
	};

	return (
		<div className={cn(styles.footer, {
			[styles.footerBoxShadow]: tableContainerRef.current && tableContainerRef.current.scrollHeight - tableContainerRef.current.offsetHeight > Math.ceil(tableContainerRef.current.scrollTop) + 1
		})}>
			{Object.keys(rowSelection).length > 0 && isAccess(QAS.QUESTION_APPROVE) &&
				<>
					<div className={styles.selectedRow}>
						<span>{translate('title_selected')}: {Object.keys(rowSelection).length} {wordDeclination(Object.keys(rowSelection).length, [translate('title_row'), translate('title_rows'), translate('title_rows2')])}</span>
						<FontAwesomeIcon icon={faTrashCan} color={colorRed} onClick={deleteCandidatesHandler} title={translate('buttonTitle_deleteCandidates')} style={{ cursor: 'pointer' }} />
					</div>
					<form className={styles.formApproveCandidates} onSubmit={approveCandidatesHandler}>
						<Autocomplete
							options={questionsList.data}
							noOptionsText={<div className={styles.formApproveCandidatesNoDataTitle}>{translate('title_notFound')}</div>}
							autoHighlight
							value={destinationQuestion}
							onChange={(_, value) => setDestinationQuestion(value)}
							renderInput={(params) =>
								<TextField
									autoFocus
									{...params}
									variant="outlined"
								/>
							}
							sx={{
								".MuiInputBase-root": { minHeight: 20, fontSize: 12, color: colorPrimary },
								".MuiOutlinedInput-root": { padding: '0 0 0 3px', paddingRight: "5px!important" },
								".MuiAutocomplete-input.MuiAutocomplete-input": { padding: 0, color: colorPrimary },
								".Mui-focused .MuiOutlinedInput-notchedOutline": { border: `1px solid ${colorPrimary}!important`, borderRadius: '3px' },
								overflow: 'hidden',
								flexGrow: 1,
							}}
							getOptionLabel={option => option.question}
							renderOption={(props, option) => {
								return (
									<li {...props} style={{ padding: '0 5px', textAlign: 'left', fontSize: 11, color: colorPrimary }} key={option.id}>
										{option.question}
									</li>
								);
							}}
							PopperComponent={(props) =>
								<Popper {...props} placement={'top'} sx={{ ".MuiAutocomplete-listbox": { maxHeight: 130 }, boxShadow: `0 0 5px 1px ${colorPrimary}`, borderRadius: 3 }}>
									{props.children}
								</Popper>
							}
							ListboxComponent={forwardRef((props, ref: Ref<HTMLUListElement>) =>
								<ul {...props} ref={ref}>
									{props.children}
									<div className={styles.blockFillGradientTop} />
									<div className={styles.blockFillGradientBottom} />
								</ul>
							)}
						/>
						<Button
							variant="outlined"
							type='submit'
							disabled={destinationQuestion === null}
							sx={{ flexShrink: 0, height: 20, fontSize: 11, overflow: 'hidden' }}
						>
							{translate('button_approve')}
						</Button>
					</form>
				</>
			}
			<Box sx={{ display: 'flex', gap: '8px', alignItems: 'center', justifyContent: 'flex-end' }}>
				<div className={styles.pagination}>
					<FormControl sx={{ width: 120, '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}>
						<InputLabel id="demo-simple-select-label" sx={{ fontSize: 13 }}>{translate('select_quantityRows')}</InputLabel>
						<Select
							labelId="demo-simple-select-label"
							id="simple-select"
							value={table.getState().pagination.pageSize}
							label={translate('select_quantityRows')}
							onChange={e => table.setPageSize(Number(e.target.value))}
							style={{ fontSize: 13, height: 36, color: colorPrimary }}
						>
							{[10, 20, 50, 100, 500, 1000].map(value => <MenuItem key={value} value={value} sx={{ fontSize: 13, color: colorPrimary }}>{value}</MenuItem>)}
						</Select>
					</FormControl>
					<TableCustomPagination table={table} tableContainerRef={tableContainerRef} />
				</div>
			</Box>
		</div>
	);
};

export default CustomFooter;
