import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError, AxiosResponse } from 'axios';
import { RootState } from './store';
import axiosApi from '../helpers/axios';
import { downloadFile } from '../helpers/downloadFile';
import { ENDPOINTS_MARKS, URL_MARKS } from '../constants/api_endpoints';
import { IAddMarksProps, IAddMarksResponse, IExportMarksProps, IImportMarksProps, IMarksListResponse, IMarksResponse, IMarksState, IPutMarksProps, IRenameMarksProps, IResponse } from '../types/marksTypes';
import { RequestStatus, ResponseStatus } from '../types/statusTypes';

const initialState: IMarksState = {
	marksList: {
		data: [],
		activeMarksId: null,
		activeMarksName: null,
		status: RequestStatus.IDLE,
		error: ResponseStatus.SUCCESS,
		message: '',
	},
	marks: {
		data: [],
		status: RequestStatus.IDLE,
		error: ResponseStatus.SUCCESS,
		message: '',
	},
	addMarks: {
		id: null,
		status: RequestStatus.IDLE,
		error: ResponseStatus.SUCCESS,
		message: '',
	},
	putMarks: {
		status: RequestStatus.IDLE,
		error: ResponseStatus.SUCCESS,
		message: '',
	},
	deleteMarks: {
		status: RequestStatus.IDLE,
		error: ResponseStatus.SUCCESS,
		message: '',
	},
	renameMarks: {
		status: RequestStatus.IDLE,
		error: ResponseStatus.SUCCESS,
		message: '',
	},
	importMarks: {
		progress: 0,
		status: RequestStatus.IDLE,
		error: ResponseStatus.SUCCESS,
		message: '',
	},
	exportMarks: {
		status: RequestStatus.IDLE,
		error: ResponseStatus.SUCCESS,
		message: '',
	},
};

// получение списка словарей меток
export const getMarksList = createAsyncThunk(
	'marks/getMarksList',
	async (_, { rejectWithValue }) => {
		try {
			const response: AxiosResponse<IMarksListResponse> = await axiosApi.get(`${URL_MARKS}/${ENDPOINTS_MARKS.LIST}`);
			return response.data;
		} catch (error) {
			if (error) {
				// возвращаем данные ошибки, пришедшие с бэка
				return rejectWithValue(error);
			}
		}
	}
);

// получение словаря меток
export const getMarks = createAsyncThunk(
	'marks/getMarks',
	async (marksId: string, { rejectWithValue, signal }) => {
		try {
			const response: AxiosResponse<IMarksResponse> = await axiosApi.get(`${URL_MARKS}/${ENDPOINTS_MARKS.GET}/${marksId}`, {
				signal,
			});
			return response.data;
		} catch (error) {
			if (error) {
				// возвращаем данные ошибки, пришедшие с бэка
				return rejectWithValue(error);
			}
		}
	}
);

// добавление словаря меток
export const addMarks = createAsyncThunk(
	'marks/addMarks',
	async ({ marksName, data }: IAddMarksProps, { rejectWithValue }) => {
		try {
			const response: AxiosResponse<IAddMarksResponse> = await axiosApi.post(`${URL_MARKS}/${ENDPOINTS_MARKS.ADD}`, {
				name: marksName,
				json: JSON.stringify(data),
			});
			return response.data;
		} catch (error) {
			if (error) {
				// возвращаем данные ошибки, пришедшие с бэка
				return rejectWithValue(error);
			}
		}
	}
);

// изменение словаря меток
export const putMarks = createAsyncThunk(
	'marks/putMarks',
	async ({ marksId, data }: IPutMarksProps, { rejectWithValue }) => {
		try {
			const response: AxiosResponse<IResponse> = await axiosApi.post(`${URL_MARKS}/${ENDPOINTS_MARKS.PUT}/${marksId}`, {
				json: JSON.stringify(data),
			});
			return response.data;
		} catch (error) {
			if (error) {
				// возвращаем данные ошибки, пришедшие с бэка
				return rejectWithValue(error);
			}
		}
	}
);

// удаление словаря меток
export const deleteMarks = createAsyncThunk(
	'marks/deleteMarks',
	async (marksId: string, { rejectWithValue }) => {
		try {
			const response: AxiosResponse<IResponse> = await axiosApi.delete(`${URL_MARKS}/${ENDPOINTS_MARKS.DELETE}/${marksId}`);
			return response.data;
		} catch (error) {
			if (error) {
				// возвращаем данные ошибки, пришедшие с бэка
				return rejectWithValue(error);
			}
		}
	}
);

// переименование словаря меток
export const renameMarks = createAsyncThunk(
	'marks/renameMarks',
	async ({ newMarksName, marksId }: IRenameMarksProps, { rejectWithValue }) => {
		try {
			const response: AxiosResponse<IResponse> = await axiosApi.post(`${URL_MARKS}/${ENDPOINTS_MARKS.RENAME}${marksId}`, {
				name: newMarksName,
			});
			return response.data;
		} catch (error) {
			if (error) {
				// возвращаем данные ошибки, пришедшие с бэка
				return rejectWithValue(error);
			}
		}
	}
);

// импорт словаря меток из .csv файла
export const importMarks = createAsyncThunk(
	'marks/importMarks',
	async ({ marksId, formData }: IImportMarksProps, { dispatch, rejectWithValue }) => {
		try {
			const response: AxiosResponse<IResponse> = await axiosApi.post(`${URL_MARKS}/${ENDPOINTS_MARKS.IMPORT}/${marksId}`, {
				csv: formData.get('file'),
			}, {
				headers: {
					'Content-Type': 'multipart/form-data'
				},
				onUploadProgress: (progressEvent) => {
					if (progressEvent.progress) {
						const progress = Math.round(progressEvent.progress * 100);
						dispatch(changeProgressImportMarks(progress));
					}
				},
			});
			return response.data;
		} catch (error) {
			if (error) {
				// возвращаем данные ошибки, пришедшие с бэка
				return rejectWithValue(error);
			}
		}
	}
);

// экспорт словаря меток (.csv файл)
export const exportMarks = createAsyncThunk(
	'marks/exportMarks',
	async ({ marksId, marksName }: IExportMarksProps, { rejectWithValue }) => {
		try {
			const response: AxiosResponse<Blob | IResponse> = await axiosApi.get(`${URL_MARKS}/${ENDPOINTS_MARKS.EXPORT}/${marksId}`, {
				responseType: 'blob',
			});
			if (response.data instanceof Blob) {
				downloadFile(response.data, `${marksName}.csv`);
			} else return response.data;
		} catch (error) {
			if (error) {
				// возвращаем данные ошибки, пришедшие с бэка
				return rejectWithValue(error);
			}
		}
	}
);

const marksSlice = createSlice({
	name: 'marks',
	initialState,
	reducers: {
		// добавление id активного словаря меток
		addingActiveMarksId: (state, action: PayloadAction<string>) => {
			state.marksList.activeMarksId = action.payload;
		},
		// добавление имени активного словаря меток
		addingActiveMarksName: (state, action: PayloadAction<string>) => {
			state.marksList.activeMarksName = action.payload;
		},
		// изменение имени корпуса (для переименовывания) без перезагрузки страницы
		// editCorpusName: (state, action: PayloadAction<{ prevName: string, newName: string }>) => {
		// 	state.corpusList.data = state.corpusList.data.map(corpusName => {
		// 		// если изменяемое имя совпало с именем из списка корпусов - меняем на новое
		// 		if (action.payload.prevName === corpusName) return action.payload.newName;
		// 		else return corpusName; // иначе оставляем как есть
		// 	});
		// 	state.corpus.corpusName = action.payload.newName; // меняем активное имя на новое
		// },
		// изменение прогресса импорта корпуса в %
		changeProgressImportMarks: (state, action: PayloadAction<number>) => {
			state.importMarks.progress = action.payload;
		},
		// изменение ячейки таблицы словаря меток (по факту изменение строки полностью)
		editMarksDataCell: (state, action: PayloadAction<{ index: number, row: [string, string[], number] }>) => {
			state.marks.data.splice(action.payload.index, 1, action.payload.row);
		},
		// добавление классов в ячейки класса таблицы корпуса
		// addClassToRows: (state, action: PayloadAction<{ rows: number[], classNames: string[], type: keyof ICorpusData }>) => {
		// 	state.corpus.data[action.payload.type] = state.corpus.data[action.payload.type].map((row, idx) => {
		// 		if (action.payload.rows.includes(idx)) {
		// 			row[0].push(...action.payload.classNames);
		// 			row[0].sort(); // восстанавливаем сортировку
		// 			const newSet = new Set(row[0]); // убираем повторные классы
		// 			const uniqueClasses = Array.from(newSet);
		// 			return [uniqueClasses, row[1]];
		// 		} else return row;
		// 	});
		// },
		// удаление классов из ячеек класса таблицы корпуса
		// deleteClassFromRows: (state, action: PayloadAction<{ rows: number[], classNames: string[], type: keyof ICorpusData }>) => {
		// 	state.corpus.data[action.payload.type] = state.corpus.data[action.payload.type].map((row, idx) => {
		// 		if (action.payload.rows.includes(idx)) {
		// 			const difference = row[0].filter(classItem => !action.payload.classNames.includes(classItem));
		// 			return [difference, row[1]];
		// 		} else return row;
		// 	});
		// },
		// изменение корпуса данных
		// editCorpusData: (state, action: PayloadAction<ICorpusData>) => {
		// 	state.corpus.data = action.payload;
		// },
		// добавление строк в таблицу меток
		addRows: (state, action: PayloadAction<[string, string[], number]>) => {
			state.marks.data.push(action.payload);
		},
		// удаление строк из таблицы меток
		deleteRows: (state, action: PayloadAction<{ rows: number[] }>) => {
			state.marks.data = state.marks.data.filter((_row, idx) => !action.payload.rows.includes(idx));
		},
		// переименование класса
		// renameClass: (state, action: PayloadAction<{ oldClassName: string, newClassName: string, isParent?: boolean }>) => {
		// 	const renameClassesCallback = (row: [string[], string]): [string[], string] => {
		// 		if (row[0].includes(action.payload.oldClassName)) {
		// 			const classIndex = row[0].findIndex(elem => elem === action.payload.oldClassName); // индекс класса на переименование
		// 			row[0].splice(classIndex, 1, action.payload.newClassName); // замена имени
		// 			row[0].sort(); // восстанавливаем сортировку
		// 			// если есть повторы классов после переименования
		// 			if (row[0].filter(classItem => classItem === action.payload.newClassName).length > 1) {
		// 				const newSet = new Set(row[0]); // убираем повторные классы
		// 				const uniqueClasses = Array.from(newSet);
		// 				return [uniqueClasses, row[1]];
		// 			} else return row;
		// 		} else return row;
		// 	};

		// если переименовывается родитель
		// 	if (action.payload.isParent) {
		// если уже существует имя класса
		// 		if (state.corpus.data.classes.find(row => row[1] === action.payload.newClassName)) {
		// удаленная строка (дерево)
		// 			const removedTree = state.corpus.data.classes.splice(state.corpus.data.classes.findIndex(row => row[1] === action.payload.oldClassName), 1);
		// добавляем к родителю, на который переименовали - потомков с переименованного родителя
		// 			state.corpus.data.classes = state.corpus.data.classes.map((row) => {
		// 				if (row[1] === action.payload.newClassName) return [[...row[0], ...removedTree[0][0]].sort(), row[1]];
		// 				else return row;
		// 			});
		// 		} else {
		// 			state.corpus.data.classes = state.corpus.data.classes.map((row) => {
		// 				if (row[1] === action.payload.oldClassName) return [row[0], action.payload.newClassName];
		// 				else return row;
		// 			});
		// 		}
		// 	}
		// если переименовывается потомок
		// 	else state.corpus.data.classes = state.corpus.data.classes.map(renameClassesCallback);
		// 	state.corpus.data.data = state.corpus.data.data.map(renameClassesCallback);
		// 	state.corpus.data.groups = state.corpus.data.groups.map(renameClassesCallback);
		// },
		// удаление класса
		// deleteClass: (state, action: PayloadAction<string>) => {
		// 	const deleteClassCallback = (row: [string[], string]): [string[], string] => {
		// 		if (row[0].includes(action.payload)) {
		// 			const classIndex = row[0].findIndex(elem => elem === action.payload); // индекс класса на удаление
		// 			row[0].splice(classIndex, 1); // удаление класса
		// 			return row;
		// 		} else return row;
		// 	};

		// для родительских классов
		// 	state.corpus.data.classes = state.corpus.data.classes.filter(row => row[1] !== action.payload);
		// для дочерних классов
		// 	state.corpus.data.classes = state.corpus.data.classes.map(deleteClassCallback);
		// 	state.corpus.data.data = state.corpus.data.data.map(deleteClassCallback);
		// 	state.corpus.data.groups = state.corpus.data.groups.map(deleteClassCallback);
		// },
		// очистка state
		clearState: (state) => {
			state.marksList = initialState.marksList;
			state.marks = initialState.marks;
			state.addMarks = initialState.addMarks;
			state.putMarks = initialState.putMarks;
			state.deleteMarks = initialState.deleteMarks;
			state.renameMarks = initialState.renameMarks;
			state.importMarks = initialState.importMarks;
			state.exportMarks = initialState.exportMarks;
		},
		// очистка списка словарей меток
		clearMarksList: (state) => {
			state.marksList = initialState.marksList;
		},
		// очистка словаря меток
		clearMarks: (state) => {
			state.marks = initialState.marks;
		},
		// очистка статуса добавления словаря меток
		clearAddMarks: (state) => {
			state.addMarks = initialState.addMarks;
		},
		// очистка статуса изменения словаря меток
		clearPutMarks: (state) => {
			state.putMarks = initialState.putMarks;
		},
		// очистка статуса удаления словаря меток
		clearDeleteMarks: (state) => {
			state.deleteMarks = initialState.deleteMarks;
		},
		// очистка статуса переименования словаря меток
		clearRenameMarks: (state) => {
			state.renameMarks = initialState.renameMarks;
		},
		// очистка статуса импорта словаря меток
		clearImportMarks: (state) => {
			state.importMarks = initialState.importMarks;
		},
		// очистка статуса экспорта словаря меток
		clearExportMarks: (state) => {
			state.exportMarks = initialState.exportMarks;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getMarksList.pending, (state) => {
				state.marksList.status = RequestStatus.LOADING;
			})
			.addCase(getMarksList.fulfilled, (state, action) => {
				if (action.payload && typeof action.payload === 'object' && 'result' in action.payload && Array.isArray(action.payload.result)) {
					state.marksList.status = RequestStatus.IDLE;
					state.marksList.data = action.payload.result.sort((a, b) => {
						if (a.name > b.name) return 1;
						else if (a.name < b.name) return -1;
						else return 0;
					});
				} else state.marksList.status = RequestStatus.FAILED;
			})
			.addCase(getMarksList.rejected, (state, action: PayloadAction<unknown>) => {
				state.marksList.status = RequestStatus.FAILED;
				if (action.payload instanceof AxiosError) {
					state.marksList.error = action.payload.response?.data.error;
					state.marksList.message = action.payload.response?.data.message;
				}
			})
			.addCase(getMarks.pending, (state) => {
				state.marks.status = RequestStatus.LOADING;
			})
			.addCase(getMarks.fulfilled, (state, action) => {
				if (action.payload && typeof action.payload === 'object' && 'data' in action.payload && Array.isArray(action.payload.data)) {
					state.marks.status = RequestStatus.IDLE;
					state.marks.data = action.payload.data;
				} else state.marks.status = RequestStatus.FAILED;
			})
			.addCase(getMarks.rejected, (state, action: PayloadAction<unknown>) => {
				state.marks.status = RequestStatus.FAILED;
				if (action.payload instanceof AxiosError) {
					state.marks.error = action.payload.response?.data.error;
					state.marks.message = action.payload.response?.data.message;
				}
			})
			.addCase(addMarks.pending, (state) => {
				state.addMarks.status = RequestStatus.LOADING;
			})
			.addCase(addMarks.fulfilled, (state, action) => {
				if (action.payload) {
					state.addMarks.status = RequestStatus.IDLE;
					state.addMarks.error = action.payload.error;
					state.addMarks.message = action.payload.message;
					if (action.payload.id) {
						state.addMarks.id = action.payload.id;
					}
				} else state.addMarks.status = RequestStatus.FAILED;
			})
			.addCase(addMarks.rejected, (state, action: PayloadAction<unknown>) => {
				state.addMarks.status = RequestStatus.FAILED;
				if (action.payload instanceof AxiosError) {
					state.addMarks.error = action.payload.response?.data.error;
					state.addMarks.message = action.payload.response?.data.message;
				}
			})
			.addCase(putMarks.pending, (state) => {
				state.putMarks.status = RequestStatus.LOADING;
			})
			.addCase(putMarks.fulfilled, (state, action) => {
				if (action.payload) {
					state.putMarks.status = RequestStatus.IDLE;
					state.putMarks.error = action.payload.error;
					state.putMarks.message = action.payload.message;
				} else state.putMarks.status = RequestStatus.FAILED;
			})
			.addCase(putMarks.rejected, (state, action: PayloadAction<unknown>) => {
				state.putMarks.status = RequestStatus.FAILED;
				if (action.payload instanceof AxiosError) {
					state.putMarks.error = action.payload.response?.data.error;
					state.putMarks.message = action.payload.response?.data.message;
				}
			})
			.addCase(deleteMarks.pending, (state) => {
				state.deleteMarks.status = RequestStatus.LOADING;
			})
			.addCase(deleteMarks.fulfilled, (state, action) => {
				if (action.payload) {
					state.deleteMarks.status = RequestStatus.IDLE;
					state.deleteMarks.error = action.payload.error;
					state.deleteMarks.message = action.payload.message;
				} else state.deleteMarks.status = RequestStatus.FAILED;
			})
			.addCase(deleteMarks.rejected, (state, action: PayloadAction<unknown>) => {
				state.deleteMarks.status = RequestStatus.FAILED;
				if (action.payload instanceof AxiosError) {
					state.deleteMarks.error = action.payload.response?.data.error;
					state.deleteMarks.message = action.payload.response?.data.message;
				}
			})
			.addCase(renameMarks.pending, (state) => {
				state.renameMarks.status = RequestStatus.LOADING;
			})
			.addCase(renameMarks.fulfilled, (state, action) => {
				if (action.payload) {
					state.renameMarks.status = RequestStatus.IDLE;
					state.renameMarks.error = action.payload.error;
					state.renameMarks.message = action.payload.message;
				} else state.renameMarks.status = RequestStatus.FAILED;
			})
			.addCase(renameMarks.rejected, (state, action: PayloadAction<unknown>) => {
				state.renameMarks.status = RequestStatus.FAILED;
				if (action.payload instanceof AxiosError) {
					state.renameMarks.error = action.payload.response?.data.error;
					state.renameMarks.message = action.payload.response?.data.message;
				}
			})
			.addCase(importMarks.pending, (state) => {
				state.importMarks.status = RequestStatus.LOADING;
			})
			.addCase(importMarks.fulfilled, (state, action) => {
				if (action.payload) {
					state.importMarks.status = RequestStatus.IDLE;
					state.importMarks.error = action.payload.error;
					state.importMarks.message = action.payload.message;
				} else state.importMarks.status = RequestStatus.FAILED;
			})
			.addCase(importMarks.rejected, (state, action: PayloadAction<unknown>) => {
				state.importMarks.status = RequestStatus.FAILED;
				if (action.payload instanceof AxiosError) {
					state.importMarks.error = action.payload.response?.data.error;
					state.importMarks.message = action.payload.response?.data.message;
				}
			})
			.addCase(exportMarks.pending, (state) => {
				state.exportMarks.status = RequestStatus.LOADING;
			})
			.addCase(exportMarks.fulfilled, (state, action) => {
				state.exportMarks.status = RequestStatus.IDLE;
				if (action.payload) {
					state.exportMarks.error = action.payload.error;
					state.exportMarks.message = action.payload.message;
				}
			})
			.addCase(exportMarks.rejected, (state, action: PayloadAction<unknown>) => {
				state.exportMarks.status = RequestStatus.FAILED;
				if (action.payload instanceof AxiosError) {
					state.exportMarks.error = action.payload.response?.data.error;
					state.exportMarks.message = action.payload.response?.data.message;
				}
			});
	},
});

export const { addingActiveMarksId, addingActiveMarksName, changeProgressImportMarks, editMarksDataCell, addRows, deleteRows, clearState, clearMarksList, clearMarks, clearAddMarks, clearPutMarks, clearDeleteMarks, clearRenameMarks, clearImportMarks, clearExportMarks } = marksSlice.actions;

export const selectMarksList = (state: RootState) => state.marks.marksList;
export const selectMarks = (state: RootState) => state.marks.marks;
export const selectAddMarks = (state: RootState) => state.marks.addMarks;
export const selectPutMarks = (state: RootState) => state.marks.putMarks;
export const selectDeleteMarks = (state: RootState) => state.marks.deleteMarks;
export const selectRenameMarks = (state: RootState) => state.marks.renameMarks;
export const selectImportMarks = (state: RootState) => state.marks.importMarks;
export const selectExportMarks = (state: RootState) => state.marks.exportMarks;

export default marksSlice.reducer;
