import { useEffect, useState } from 'react';
import { Fade } from '@mui/material';
import cn from 'classnames';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { clearState, getMarksList, selectMarks, selectMarksList } from '../../store/marksSlice';
import { clearDataServers, getDataServers } from '../../store/serverSlice';
import useAccessRight from '../../hooks/useAccessRight';
import useTranslate from '../../hooks/useTranslate';
import { MARKS, SERVER, SERVICE } from '../../constants/accessRights';
import { RequestStatus, ResponseStatus } from '../../types/statusTypes';
import PageWrapper from '../../HOC/PageWrapper/PageWrapper';
import MarksControls from '../../components/Controls/MarksControls/MarksControls';
import MarksNavbar from '../../components/Navbars/MarksNavbar/MarksNavbar';
import MarksTable from '../../components/Tables/Marks/Marks';
import ProgressCircle from '../../components/ProgressCircle/ProgressCircle';
import { IMarksProps } from './Marks.props';
import styles from './Marks.module.scss';

const Marks = ({ serviceType }: IMarksProps): JSX.Element => {
	const [showPage, setShowPage] = useState<boolean>(true); // показ страницы
	const [changeFlg, setChangeFlg] = useState<boolean>(false); // флаг, уведомляющий об изменении данных и возможности сохранить эти изменения
	const [showSidebar, setShowSidebar] = useState<boolean>(true); // показ боковой панели

	const dispatch = useAppDispatch();
	const marksList = useAppSelector(selectMarksList); // store - список словарей меток
	const marks = useAppSelector(selectMarks); // store - словарь меток

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	useEffect(() => {
		isAccess(MARKS.LIST) && dispatch(getMarksList()); // получаем список словарей меток
		isAccess(SERVER.ADDRESSES) && dispatch(getDataServers({ serviceType })); // получаем данные о серверах

		// автозапрос списка серверов каждые 30 сек
		const interval = setInterval(() => {
			isAccess(SERVER.ADDRESSES) && dispatch(getDataServers({ serviceType }));
		}, 30000);

		return () => {
			clearInterval(interval); // удаляем автозапрос получения данных о серверах
			dispatch(clearDataServers()); // очищаем данные по серверам
			dispatch(clearState()); // очистка всего state marks
		};
	}, []);

	return (
		<PageWrapper showPage={showPage} setShowPage={setShowPage} accessToService={[SERVICE.SMC, MARKS.LIST]}>
			<>
				<MarksControls
					changeFlg={changeFlg}
					setChangeFlg={setChangeFlg}
					setShowPage={setShowPage}
				/>

				<div className={styles.bottomContainer}>
					<MarksNavbar
						setChangeFlg={setChangeFlg}
						serviceType={serviceType}
						showSidebar={showSidebar}
						setShowSidebar={setShowSidebar}
						setShowPage={setShowPage}
					/>

					<div className={cn(styles.wrapper, {
						[styles.wrapperFullWidth]: !showSidebar,
					})}>
						{marksList.status === RequestStatus.IDLE && marksList.data.length > 0 ?
							<>
								{/* ошибка загрузки словаря меток */}
								{(marks.status === RequestStatus.FAILED || marks.error === ResponseStatus.FAILED) &&
									<div className={styles.notFound}><div>{translate(marks.message || 'title_notFound')}</div></div>
								}

								{/* загрузка словаря меток */}
								{marks.status === RequestStatus.LOADING &&
									<div className={styles.loading}>
										<ProgressCircle title={translate('spinnerTitle_loading')} />
									</div>
								}

								{/* словарь меток */}
								{marks.status === RequestStatus.IDLE &&
									<Fade in={true} timeout={500}>
										<div className={styles.wrapperTable}>
											<MarksTable setChangeFlg={setChangeFlg} />
										</div>
									</Fade>
								}
							</>
							:
							<div className={styles.noData}><div>{translate('title_noData')}</div></div>
						}
					</div>
				</div>
			</>
		</PageWrapper>
	);
};

export default Marks;
