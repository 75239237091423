import { FormEvent, useEffect, useState } from 'react';
import { Button, FormControl, TextField } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { copyCorpus, getCorpusList, selectCorpusCopyStatus } from '../../../store/corpusSlice';
import useTranslate from '../../../hooks/useTranslate';
import { RequestStatus, ResponseStatus } from '../../../types/statusTypes';
import ModalFormWindow from '../../../HOC/ModalFormWindow/ModalFormWindow';
import ProgressCircle from '../../ProgressCircle/ProgressCircle';
import { IFormExportProps } from './FormCopy.props';

const FormCopy = ({ showModal, setShowModal, corpusName, serviceType, setShowNotification }: IFormExportProps): JSX.Element => {
	const [newCorpusName, setNewCorpusName] = useState<string>(''); // имя корпуса для копирования
	const dispatch = useAppDispatch();
	const copyStatus = useAppSelector(selectCorpusCopyStatus); // store - статус копирования корпуса
	const translate = useTranslate(); // hook для перевода текста

	// следим за статусом копирования корпуса
	useEffect(() => {
		// если копирование прошло успешно - получаем заново список корпусов
		copyStatus.error === ResponseStatus.SUCCESS && copyStatus.status === RequestStatus.IDLE && copyStatus.message !== '' && dispatch(getCorpusList({ serviceType }));
		// если были изменения в статусе копирования
		if (copyStatus.error !== ResponseStatus.SUCCESS || copyStatus.status === RequestStatus.FAILED || copyStatus.message !== '') {
			handleClose(); // закрываем форму
			setShowNotification(true); // включаем уведомление
		}
	}, [copyStatus]);

	// обработчик копирования корпуса
	const submitHandler = (e: FormEvent<HTMLFormElement>): void => {
		e.preventDefault();
		corpusName && dispatch(copyCorpus({ corpusNameSource: corpusName, corpusNameDestination: newCorpusName, serviceType })); // копирование корпуса
	};

	// обработчик закрытия формы
	const handleClose = (): void => {
		// если идет копирование корпуса - запрещаем покидать форму
		if (copyStatus.status === RequestStatus.LOADING) return;
		setShowModal(false);
	};

	return (
		<ModalFormWindow showModal={showModal} setShowModal={setShowModal} headerTitle='formHeader_copyingCorpus' close={handleClose}>
			<form onSubmit={(e) => submitHandler(e)}>
				<FormControl fullWidth margin='dense'>
					<TextField
						autoFocus
						required
						id="outlined-basic"
						label={translate('input_newCorpusName')}
						variant="outlined"
						value={newCorpusName}
						onChange={(e) => setNewCorpusName(e.target.value)}
						InputProps={{
							style: {
								height: 33,
								fontSize: 13
							},
						}}
						InputLabelProps={{
							style: {
								fontSize: 13,
							},
						}}
						sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
					/>
				</FormControl>
				<FormControl fullWidth margin='dense'>
					{copyStatus.status === RequestStatus.LOADING ?
						<Button variant="outlined" type="submit" disabled sx={{ fontSize: 11 }}>
							{translate('button_copy')}
							<ProgressCircle isBtnDisabled />
						</Button>
						:
						<Button variant="outlined" type="submit" sx={{ fontSize: 11 }}>{translate('button_copy')}</Button>
					}
				</FormControl>
			</form>
		</ModalFormWindow>
	);
};

export default FormCopy;
