import { DataElemType, ServiceTypeModelRobot, ServiceTypeRobot } from '../types/cloudTypes';
import { Action, ActionType, ConditionType, DataElemActionEventType, OperationType, ServiceType } from '../types/sesTypes';
import { DaysType, ExceptionType, RobotActionEventType, ServiceDataType } from '../types/sesRobotTypes';

export type ActionExtended = Action | 'chooseAction';
export type ActionTypeExtended = ActionType | 'chooseActionType';
export type ServiceTypeExtended = ServiceType | 'chooseService';
export type TypeExtended = DataElemType | 'chooseType';
export type ServiceTypeModelRobotExtended = ServiceTypeModelRobot | 'chooseType';
export type ConditionTypeExtended = ConditionType | 'chooseType';

// список событий робота
export const ROBOT_EVENT_LIST: { event: RobotActionEventType, translation: string }[] = [
	{ event: 'start', translation: 'selectItem_start' },
	{ event: 'hotstart', translation: 'selectItem_hotStart' },
	{ event: 'loop', translation: 'selectItem_returnToStart' },
	{ event: 'not found', translation: 'selectItem_notFound' },
	{ event: 'repeat', translation: 'selectItem_repeatRequest' },
];

// список исключений робота
export const ROBOT_EXCEPTION_LIST: { exception: ExceptionType, translation: string }[] = [
	{ exception: 'silence', translation: 'selectItem_silence' },
	{ exception: 'not found', translation: 'selectItem_notFound' },
];

// список сервисных данных робота
export const ROBOT_SERVICE_DATA_LIST: { serviceData: ServiceDataType, translation: string }[] = [
	{ serviceData: 'yes', translation: 'selectItem_agreement' },
	{ serviceData: 'no', translation: 'selectItem_negation' },
	{ serviceData: 'repeat', translation: 'selectItem_repeat' },
];

// список действий
export const ACTION_LIST: { action: Action, translation: string }[] = [
	{ action: 'say', translation: 'selectItem_say' },
	{ action: 'end', translation: 'selectItem_endDialogue' },
];

// список типов действий
export const ACTION_TYPE_LIST: { actionType: ActionType, translation: string }[] = [
	{ actionType: 'linear', translation: 'selectItem_linear' },
	{ actionType: 'random', translation: 'selectItem_randomPhrase' },
	{ actionType: 'external', translation: 'selectItem_fromExternalSource' },
	{ actionType: 'internal', translation: 'selectItem_fromInternalSource' },
];

// список сервисов
export const SERVICE_TYPE_LIST: { serviceType: ServiceType, translation: string }[] = [
	{ serviceType: 'qas', translation: 'QAS' },
];

// список типов элемента данных
export const DATA_ELEM_TYPE_LIST: { type: DataElemType, translation: string }[] = [
	{ type: 'smc', translation: 'selectItem_class' },
	{ type: 'see', translation: 'selectItem_entity' },
	{ type: 'script', translation: 'selectItem_external' },
];

// список доступных сервисов робота
export const ROBOT_SERVICE_TYPE_LIST: { type: ServiceTypeRobot, translation: string }[] = [
	{ type: 'smc', translation: 'selectItem_class' },
	{ type: 'see', translation: 'selectItem_entity' },
];

// список сервисов моделей робота
export const ROBOT_MODEL_SERVICE_TYPE_LIST: { type: ServiceTypeModelRobot, translation: string }[] = [
	{ type: 'smc', translation: 'selectItem_class' },
	{ type: 'see', translation: 'selectItem_entity' },
	{ type: 'tts', translation: 'selectItem_voice' },
];

// список событий элемента данных
export const DATA_ELEM_EVENT_LIST: { event: DataElemActionEventType, translation: string }[] = [
	{ event: 'alone', translation: 'selectItem_ifOnlyCurrentOneIsFound' },
	{ event: 'nearest', translation: 'selectItem_missingElement' },
];

// список типов условий запуска действий
export const CONDITION_RUN_ACTION_TYPE_LIST: { type: ConditionType, translation: string }[] = [
	{ type: 'interval', translation: 'selectItem_interval' },
	{ type: 'variable', translation: 'selectItem_variable' },
	{ type: 'data', translation: 'selectItem_data' },
];

// список операций условий
export const OPERATION_CONDITIONS_LIST: { operation: OperationType, translation: string }[] = [
	{ operation: '=', translation: '=' },
	{ operation: '!=', translation: '!=' },
	{ operation: '>', translation: '>' },
	{ operation: '>=', translation: '>=' },
	{ operation: '<', translation: '<' },
	{ operation: '<=', translation: '<=' },
	{ operation: 'exists', translation: 'selectItem_exists' },
	{ operation: 'len=', translation: 'selectItem_lengthIsEqual' },
	{ operation: 'len>', translation: 'selectItem_lengthIsLonger' },
	{ operation: 'len<', translation: 'selectItem_lengthIsLess' },
];

// список дней с опорой на производственный календарь
export const DAYS_PRODUCTION_CALENDAR_LIST: { day: DaysType, translation: string }[] = [
	{ day: 'all', translation: 'selectItem_all' },
	{ day: 'workdays', translation: 'selectItem_workingDays' },
	{ day: 'holidays', translation: 'selectItem_weekends' },
];

// список дней недели
export const WEEK_DAYS_LIST: { day: number, translationFull: string, translationShort: string }[] = [
	{ day: 1, translationFull: 'Понедельник', translationShort: 'title_weekDayShort_mon' },
	{ day: 2, translationFull: 'Вторник', translationShort: 'title_weekDayShort_tue' },
	{ day: 3, translationFull: 'Среда', translationShort: 'title_weekDayShort_wed' },
	{ day: 4, translationFull: 'Четверг', translationShort: 'title_weekDayShort_thu' },
	{ day: 5, translationFull: 'Пятница', translationShort: 'title_weekDayShort_fri' },
	{ day: 6, translationFull: 'Суббота', translationShort: 'title_weekDayShort_sat' },
	{ day: 7, translationFull: 'Воскресенье', translationShort: 'title_weekDayShort_sun' },
];

// список месяцев
export const MONTHS_LIST: { month: number, translationFull: string, translationShort: string }[] = [
	{ month: 1, translationFull: 'Январь', translationShort: 'title_monthShort_jan' },
	{ month: 2, translationFull: 'Февраль', translationShort: 'title_monthShort_feb' },
	{ month: 3, translationFull: 'Март', translationShort: 'title_monthShort_mar' },
	{ month: 4, translationFull: 'Апрель', translationShort: 'title_monthShort_apr' },
	{ month: 5, translationFull: 'Май', translationShort: 'title_monthShort_may' },
	{ month: 6, translationFull: 'Июнь', translationShort: 'title_monthShort_june' },
	{ month: 7, translationFull: 'Июль', translationShort: 'title_monthShort_july' },
	{ month: 8, translationFull: 'Август', translationShort: 'title_monthShort_aug' },
	{ month: 9, translationFull: 'Сентябрь', translationShort: 'title_monthShort_sept' },
	{ month: 10, translationFull: 'Октябрь', translationShort: 'title_monthShort_oct' },
	{ month: 11, translationFull: 'Ноябрь', translationShort: 'title_monthShort_nov' },
	{ month: 12, translationFull: 'Декабрь', translationShort: 'title_monthShort_dec' },
];

export const ACTION_EXTENDED_LIST: { action: ActionExtended, translation: string }[] = [
	{ action: 'chooseAction', translation: 'selectItem_selectAction' },
	...ACTION_LIST,
];

export const ACTION_TYPE_EXTENDED_LIST: { actionType: ActionTypeExtended, translation: string }[] = [
	{ actionType: 'chooseActionType', translation: 'selectItem_selectType' },
	...ACTION_TYPE_LIST,
];

export const SERVICE_TYPE_EXTENDED_LIST: { serviceType: ServiceTypeExtended, translation: string }[] = [
	{ serviceType: 'chooseService', translation: 'selectItem_selectService' },
	...SERVICE_TYPE_LIST,
];

export const ROBOT_SERVICE_TYPE_EXTENDED_LIST: { type: TypeExtended, translation: string }[] = [
	{ type: 'chooseType', translation: 'selectItem_selectType' },
	...ROBOT_SERVICE_TYPE_LIST,
];

export const ROBOT_MODEL_SERVICE_TYPE_EXTENDED_LIST: { type: ServiceTypeModelRobotExtended, translation: string }[] = [
	{ type: 'chooseType', translation: 'selectItem_selectType' },
	...ROBOT_MODEL_SERVICE_TYPE_LIST,
];

export const CONDITION_RUN_ACTION_TYPE_EXTENDED_LIST: { type: ConditionTypeExtended, translation: string }[] = [
	{ type: 'chooseType', translation: 'selectItem_selectType' },
	...CONDITION_RUN_ACTION_TYPE_LIST,
];
