import { useEffect, useState } from 'react';
import { Fade, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { clearRevoice, getRevoicersList, revoice, selectRevoiceData, selectRevoicersList, selectSynthesisData } from '../../store/ttsSlice';
import useAccessRight from '../../hooks/useAccessRight';
import useTranslate from '../../hooks/useTranslate';
import { TTS } from '../../constants/accessRights';
import { colorPrimary } from '../../constants/colors';
import { RequestStatus, ResponseStatus } from '../../types/statusTypes';
import FormSendAudio from '../Forms/FormSendAudio/FormSendAudio';
import NoticeSingleAction from '../Notification/NoticeSingleAction/NoticeSingleAction';
import ProgressCircle from '../ProgressCircle/ProgressCircle';
import { IRevoiceProps } from './Revoice.props';

const Revoice = ({ setWhatsPlaying }: IRevoiceProps): JSX.Element => {
	const [selectDistortionModel, setSelectDistortionModel] = useState<string>(''); // имя модели искажения
	const [showNotification, setShowNotification] = useState<boolean>(false); // показ уведомления статуса искажения

	const dispatch = useAppDispatch();
	const revoicersList = useAppSelector(selectRevoicersList); // store - список моделей искажений
	const revoiceData = useAppSelector(selectRevoiceData); // store - искажение голоса
	const synthesisData = useAppSelector(selectSynthesisData); // store - данные синтеза речи

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	useEffect(() => {
		isAccess(TTS.REVOICERS_LIST) && dispatch(getRevoicersList()); // получаем список моделей искажения
	}, []);

	// следим за данными искажения
	useEffect(() => {
		// если есть ошибка - включаем уведомление
		if (revoiceData.status === RequestStatus.FAILED) setShowNotification(true);
	}, [revoiceData]);

	return (
		<>
			{/* загрузка списка моделей искажения */}
			{revoicersList.status === RequestStatus.LOADING &&
				<div>
					<ProgressCircle title={translate('spinnerTitle_loading')} />
				</div>
			}

			{/* ошибка получения списка моделей искажений */}
			{(revoicersList.status === RequestStatus.FAILED || revoicersList.error === ResponseStatus.FAILED) &&
				<div>
					{translate(revoicersList.message || 'title_loadFailed')}
				</div>
			}

			{/* пустой список */}
			{revoicersList.data.length === 0 && revoicersList.status === RequestStatus.IDLE && revoicersList.error === ResponseStatus.SUCCESS &&
				<div>
					{translate('title_emptyList')}
				</div>
			}

			{revoicersList.data.length > 0 &&
				<Fade in={true} timeout={500}>
					<div>
						{/* модель искажения */}
						<FormControl fullWidth margin='dense' sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 }, marginBottom: '8px' }}>
							<InputLabel sx={{ fontSize: 13 }}>{translate('select_distortionModel')}</InputLabel>
							<Select
								required
								label={translate('select_distortionModel')}
								value={selectDistortionModel}
								onChange={e => setSelectDistortionModel(e.target.value)}
								style={{ fontSize: 13, height: 33, color: colorPrimary, textAlign: 'left' }}
							>
								{revoicersList.data.map((distortionModel) =>
									<MenuItem key={distortionModel} value={distortionModel} sx={{ fontSize: 13, color: colorPrimary }}>{translate(distortionModel)}</MenuItem>
								)}
							</Select>
						</FormControl>

						<FormSendAudio
							formFor='distort'
							disableFile={revoiceData.status === RequestStatus.LOADING}
							disableSubmit={revoiceData.status === RequestStatus.LOADING || synthesisData.status === RequestStatus.LOADING || !selectDistortionModel}
							submitFunction={({ formData }) => {
								revoiceData.dataUrl && dispatch(clearRevoice()); // если есть данные искажения голоса - очищаем
								setWhatsPlaying('distortion'); // обозначаем для указания ссылки плееру
								dispatch(revoice({ formData, revoicer: selectDistortionModel })); // искажение голоса
							}}
						/>
					</div>
				</Fade>
			}

			{showNotification &&
				<NoticeSingleAction
					showNotification={showNotification}
					setShowNotification={setShowNotification}
					title={revoiceData.message || 'noticeDistortion_failed'}
				/>
			}
		</>
	);
};

export default Revoice;
