import { useEffect, useState } from 'react';
import { Fade } from '@mui/material';
import cn from 'classnames';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { clearState, getQueue, selectAudio, selectDeletionTaskStatus, selectQueue, selectRecognitionAsyncData, selectRecognitionData } from '../../store/sprSlice';
import useAccessRight from '../../hooks/useAccessRight';
import useTranslate from '../../hooks/useTranslate';
import { SERVICE, SPR } from '../../constants/accessRights';
import { RequestStatus, ResponseStatus } from '../../types/statusTypes';
import PageWrapper from '../../HOC/PageWrapper/PageWrapper';
import QueueNavbar from '../../components/Navbars/QueueNavbar/QueueNavbar';
import QueueControls from '../../components/Controls/QueueControls/QueueControls';
import AudioPlayer from '../../components/AudioPlayer/AudioPlayer';
import ResultRecognition from '../../components/ResultRecognition/ResultRecognition';
import InfoRecognition from '../../components/ResultRecognition/InfoRecognition/InfoRecognition';
import Speakers from '../../components/ResultRecognition/Speakers/Speakers';
import ProgressCircle from '../../components/ProgressCircle/ProgressCircle';
import ScreenLock from '../../components/ScreenLock/ScreenLock';
import styles from './Queue.module.scss';

const Queue = (): JSX.Element => {
	const [showPage, setShowPage] = useState<boolean>(true); // показ страницы
	const [showScreenLock, setShowScreenLock] = useState<{ isShow: boolean, title: string }>({ isShow: false, title: '' }); // показ экрана блокировки и подпись

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста
	const dispatch = useAppDispatch();

	const queue = useAppSelector(selectQueue); // store - очередь распознавания
	const recognitionData = useAppSelector(selectRecognitionData); // store - распознавание речи
	const recognitionAsyncData = useAppSelector(selectRecognitionAsyncData); // store - асинхронное распознавание речи
	const audio = useAppSelector(selectAudio); // store - аудио-файл асинхронного распознавания речи
	const deletionTaskStatus = useAppSelector(selectDeletionTaskStatus); // store - статус удаления задачи

	useEffect(() => {
		isAccess(SPR.QUEUE) && dispatch(getQueue()); // получаем очередь распознавания

		// при уходе со страницы
		return () => {
			dispatch(clearState()); // очищаем state spr
		};
	}, []);

	// следим за статусом удаления задачи
	useEffect(() => {
		if (deletionTaskStatus.status === RequestStatus.LOADING) setShowScreenLock({ isShow: true, title: 'spinnerTitle_deletion' }); // если идет удаление задачи
		else setShowScreenLock({ isShow: false, title: '' }); // иначе выключаем экран блокировки

		// если удаление прошло успешно - получаем заново список очереди
		deletionTaskStatus.status === RequestStatus.IDLE && deletionTaskStatus.error === ResponseStatus.SUCCESS && deletionTaskStatus.message === 'success' && dispatch(getQueue());
	}, [deletionTaskStatus]);

	return (
		<PageWrapper showPage={showPage} setShowPage={setShowPage} accessToService={[SERVICE.SPR, SPR.QUEUE]}>
			<>
				<QueueNavbar setShowPage={setShowPage} />
				<QueueControls setShowPage={setShowPage} />

				<Fade in={true} timeout={700} style={{ transitionDelay: '500ms' }}>
					<div className={cn(styles.wrapper, {
						[styles.wrapperDirectionColumn]: recognitionData.data && typeof recognitionData.data === 'object' && !('speakers' in recognitionData.data)
					})}>
						{queue.data !== null && Object.keys(queue.data).length > 0 ?
							<>
								<div className={styles.wrapperLeft}>
									{(audio.audioStatus !== RequestStatus.IDLE || audio.audioUrl) &&
										<Fade in={true} timeout={500}>
											<div className={styles.wrapperAudioTrack}>
												<div>
													{(audio.audioStatus === RequestStatus.LOADING || audio.audioPeakStatus === RequestStatus.LOADING) &&
														<div><ProgressCircle title={translate('spinnerTitle_loading')} inLine /></div>
													}
													{audio.audioStatus === RequestStatus.FAILED && <p>{translate('title_loadFailed')}</p>}
													{audio.audioUrl && audio.audioPeakStatus !== RequestStatus.LOADING &&
														<AudioPlayer url={audio.audioUrl} sendTimestamp='recognition' timestamp={recognitionData.timestamp} peaks={audio.audioPeaks.length > 0 ? audio.audioPeaks : undefined} />
													}
												</div>
											</div>
										</Fade>
									}

									{(recognitionData.data !== null || recognitionAsyncData.requestStatus !== RequestStatus.IDLE || recognitionAsyncData.responseStatus) &&
										<Fade in={true} timeout={500}>
											<div className={styles.wrapperResultRecognition}>
												<ResultRecognition />
											</div>
										</Fade>
									}
								</div>

								{recognitionData.data && typeof recognitionData.data === 'object' && 'created' in recognitionData.data &&
									<div className={cn({
										[styles.wrapperRight]: recognitionData.data && typeof recognitionData.data === 'object' && 'speakers' in recognitionData.data
									})}>
										<Fade in={true} timeout={500}>
											<div className={styles.wrapperInfo}>
												<div>
													<InfoRecognition modelName={'model' in recognitionData.data ? recognitionData.data.model : '-'} created={recognitionData.data.created} processed={recognitionData.data.processed} />
												</div>
											</div>
										</Fade>

										{'speakers' in recognitionData.data && Array.isArray(recognitionData.data.speakers) && recognitionData.data.speakers.length > 0 &&
											<Fade in={true} timeout={500}>
												<div className={styles.wrapperSpeakers}>
													<Speakers speakers={recognitionData.data.speakers} speakerNameList={recognitionData.speakerList} from='recognition' audioUrl={audio.audioUrl ? audio.audioUrl : undefined} />
												</div>
											</Fade>
										}
									</div>
								}
							</>
							:
							<div className={styles.noData}><div>{translate('title_noData')}</div></div>
						}
					</div>
				</Fade>

				{showScreenLock.isShow && <ScreenLock title={translate(showScreenLock.title)} />}
			</>
		</PageWrapper>
	);
};

export default Queue;
