import { ChangeEvent, useEffect } from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import { useAppSelector } from '../../store/hooks';
import { selectAccessRights } from '../../store/authSlice';
import useAccessRight from '../../hooks/useAccessRight';
import useTranslate from '../../hooks/useTranslate';
import { findServiceRightByType } from '../../helpers/findServiceRightByType';
import { LIST_OF_RIGHTS_GROUPS, ROLE } from '../../constants/accessRights';
import { RequestStatus } from '../../types/statusTypes';
import { IRoleProps } from './Role.props';
import styles from './Role.module.scss';

const Role = ({ role, rolesRights, setRolesRights, setChangeFlg }: IRoleProps): JSX.Element => {
	const accessRightsList = useAppSelector(selectAccessRights); // store - список всех прав доступа

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// следим за активным id роли
	useEffect(() => {
		setRolesRights(role.rights); // пишем права роли
	}, [role.id]);

	// обработчик изменения checkbox
	const changeHandler = (e: ChangeEvent<HTMLInputElement>): void => {
		setChangeFlg(true); // включаем флаг о несохраненных данных
		// если выбран checkbox - добавляем в список
		if (e.target.checked) setRolesRights(prevState => [...prevState, e.target.id]);
		// иначе убираем его из этого списка
		else setRolesRights(prevState => prevState.filter(right => right !== e.target.id));
	};

	// обработчик изменения группы checkbox'ов
	const changeHandlerGroup = (e: ChangeEvent<HTMLInputElement>): void => {
		setChangeFlg(true); // включаем флаг о несохраненных данных
		const regex = new RegExp(`${e.target.id}/`); // регулярка с типом группы для поиска
		const serviceRight = findServiceRightByType(e.target.id); // главное право на вход в сервис, если есть

		// поиск группы прав доступа в полном списке
		const typeGroupSearch = accessRightsList.data?.all.filter(right => {
			// оставляем - что подходит под регулярку, еще нет в правах роли и не явлеяется общедоступным
			return regex.test(right) && !rolesRights.includes(right) && !accessRightsList.data?.nopassword.includes(right);
		});

		// поиск группы прав доступа в списке роли (если нет общего списка)
		const typeGroupSearchInRolesList = role.rights.filter(right => regex.test(right));

		// если выбрана группа сервиса - добавляем в список прав этой группы
		if (serviceRight && e.target.checked) {
			typeGroupSearch?.push(serviceRight);
			typeGroupSearchInRolesList.push(serviceRight);
		}

		// если выбран checkbox и есть список прав доступа - добавляем в список группу прав
		if (e.target.checked && typeGroupSearch) setRolesRights(prevState => [...prevState, ...typeGroupSearch]);
		// если выбран checkbox и нет списка прав доступа - добавляем в список другую группу прав
		else if (e.target.checked && accessRightsList.data === null) {
			setRolesRights(prevState => [...prevState, ...typeGroupSearchInRolesList]);
		}
		// иначе убираем группу прав и главное право из этого списка
		else setRolesRights(prevState => prevState.filter(right => !regex.test(right) && right !== serviceRight));
	};

	// обработчик выделения группы прав
	const checkedHandlerGroup = (type: string): boolean => {
		const regex = new RegExp(`${type}/`); // регулярка с типом группы для поиска
		const serviceRight = findServiceRightByType(type); // главное право на вход в сервис 

		// поиск группы прав доступа в полном списке
		const typeGroupSearch = accessRightsList.data?.all.filter(right => {
			// оставляем - что подходит под регулярку и не явлеяется общедоступным
			return regex.test(right) && !accessRightsList.data?.nopassword.includes(right);
		});

		// поиск группы прав доступа в списке роли (если нет общего списка)
		const typeGroupSearchInRolesList = role.rights.filter(right => regex.test(right) && !rolesRights.includes(right));

		// поиск группы прав доступа в списке прав роли (state)
		const typeGroupSearchInRolesRights = rolesRights.filter(right => {
			// оставляем - что подходит под регулярку и не явлеяется общедоступным
			return regex.test(right) && !accessRightsList.data?.nopassword.includes(right);
		});

		// если для этой группы есть главное право сервиса - проверяем, есть ли оно в списке прав роли
		if (serviceRight) return rolesRights.includes(serviceRight);
		// иначе проверяем совпадение на количество прав в общем списке и списке роли
		else if (typeGroupSearch) return typeGroupSearch?.length === typeGroupSearchInRolesRights.length;
		// или когда общего списка прав доступа нет
		else return typeGroupSearchInRolesList.length > 0 ? false : true;
	};

	return (
		<div className={styles.role}>
			{/* ошибка получения общего списка прав доступа */}
			{accessRightsList.status === RequestStatus.FAILED &&
				<p className={styles.roleFailedRights}>{translate('title_failedAccessRights')}</p>
			}

			{LIST_OF_RIGHTS_GROUPS.map(type => (
				<div className={styles.roleListByType} key={type}>
					<FormControlLabel
						sx={{ '.MuiTypography-root': { fontSize: 13, fontWeight: 'bold', marginTop: '2px' } }}
						label={translate(type)}
						control={
							<Checkbox
								size='small'
								id={type}
								onChange={e => changeHandlerGroup(e)}
								checked={checkedHandlerGroup(type)}
								disabled={!isAccess(ROLE.EDIT)}
							/>
						}
					/>
					<ul className={styles.roleList}>
						{accessRightsList.data && 'all' in accessRightsList.data ?
							accessRightsList.data?.all.map(right => {
								const regex = new RegExp(`${type}/`);
								if (regex.test(right)) return (
									<li className={styles.roleItem} key={right} title={right}>
										<FormControlLabel
											sx={{ '.MuiTypography-root': { fontSize: 12 } }}
											label={accessRightsList.data?.nopassword.includes(right) ? translate(right) + ' (public)' : translate(right)}
											control={
												<Checkbox
													size='small'
													id={right}
													onChange={e => changeHandler(e)}
													checked={rolesRights.includes(right) || accessRightsList.data?.nopassword.includes(right)}
													disabled={!isAccess(ROLE.EDIT) || accessRightsList.data?.nopassword.includes(right)}
												/>
											}
										/>
									</li>
								);
								return undefined;
							})
							:
							role.rights.map(roleRight => {
								const regex = new RegExp(`${type}/`);
								if (regex.test(roleRight)) return (
									<li className={styles.roleItem} key={roleRight} title={roleRight}>
										<FormControlLabel
											sx={{ '.MuiTypography-root': { fontSize: 12 } }}
											label={translate(roleRight)}
											control={
												<Checkbox
													size='small'
													id={roleRight}
													onChange={e => changeHandler(e)}
													checked={rolesRights.includes(roleRight)}
													disabled={!isAccess(ROLE.EDIT)}
												/>
											}
										/>
									</li>
								);
								return undefined;
							})
						}
					</ul>
				</div>
			))}
		</div>
	);
};

export default Role;
