import { FormEvent, useEffect, useState } from 'react';
import { Button, FormControl, InputAdornment, TextField } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { changePassword, selectChangePasswordStatus } from '../../../store/userSlice';
import useTranslate from '../../../hooks/useTranslate';
import { RequestStatus, ResponseStatus } from '../../../types/statusTypes';
import ModalFormWindow from '../../../HOC/ModalFormWindow/ModalFormWindow';
import ProgressCircle from '../../ProgressCircle/ProgressCircle';
import { IFormChangePasswordProps } from './FormChangePassword.props';

const FormChangePassword = ({ showModal, setShowModal, setShowNotification }: IFormChangePasswordProps): JSX.Element => {
	const [newPassword, setNewPassword] = useState<string>(''); // новый пароль
	const [newPasswordConfirm, setNewPasswordConfirm] = useState<string>(''); // подтверждение нового пароля
	const [error, setError] = useState<boolean>(false); // флаг о несовпадении паролей
	const [showPassword, setShowPassword] = useState<boolean>(false); // флаг отображения пароля

	const dispatch = useAppDispatch();
	const changePasswordStatus = useAppSelector(selectChangePasswordStatus); // store - статус изменения пароля пользователя
	const translate = useTranslate(); // hook для перевода текста

	// следим за статусом изменения пароля пользователя
	useEffect(() => {
		if (changePasswordStatus.error !== ResponseStatus.SUCCESS || changePasswordStatus.status === RequestStatus.FAILED || changePasswordStatus.message !== '') {
			handleClose(); // закрываем форму
			setShowNotification(true); // включаем уведомление
		}
	}, [changePasswordStatus]);

	// обработчик изменения пароля пользователя
	const submitHandler = (e: FormEvent<HTMLFormElement>): void => {
		e.preventDefault();
		// если пароли совпадают
		if (newPassword === newPasswordConfirm) {
			error && setError(false); // сбрасываем ошибку, если была
			dispatch(changePassword(newPassword)); // изменение пароля пользователя
		} else setError(true);
	};

	// обработчик закрытия формы
	const handleClose = (): void => {
		// если идет изменение пароля - запрещаем покидать форму
		if (changePasswordStatus.status === RequestStatus.LOADING) return;
		setShowModal(false);
	};

	return (
		<ModalFormWindow showModal={showModal} setShowModal={setShowModal} headerTitle='formHeader_changePassword' close={handleClose}>
			<form onSubmit={(e) => submitHandler(e)}>
				<FormControl fullWidth margin='dense'>
					<TextField
						autoFocus
						required
						id="newPassword"
						label={translate('input_newPassword')}
						variant="outlined"
						type={showPassword ? 'text' : 'password'}
						error={error}
						disabled={changePasswordStatus.status === RequestStatus.LOADING}
						value={newPassword}
						onChange={(e) => setNewPassword(e.target.value)}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<FontAwesomeIcon
										icon={showPassword ? faEyeSlash : faEye}
										onClick={() => changePasswordStatus.status !== RequestStatus.LOADING && setShowPassword(prev => !prev)}
										style={{ cursor: 'pointer' }}
									/>
								</InputAdornment>
							),
							style: {
								height: 33,
								fontSize: 13
							},
						}}
						InputLabelProps={{
							style: {
								fontSize: 13,
							},
						}}
						sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
					/>
				</FormControl>
				<FormControl fullWidth margin='dense'>
					<TextField
						required
						id="newPasswordConfirm"
						label={translate('input_repeatNewPassword')}
						variant="outlined"
						type={showPassword ? 'text' : 'password'}
						error={error}
						helperText={error ? translate("title_passwordsDontMatch") : ''}
						disabled={changePasswordStatus.status === RequestStatus.LOADING}
						value={newPasswordConfirm}
						onChange={(e) => setNewPasswordConfirm(e.target.value)}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<FontAwesomeIcon
										icon={showPassword ? faEyeSlash : faEye}
										onClick={() => changePasswordStatus.status !== RequestStatus.LOADING && setShowPassword(prev => !prev)}
										style={{ cursor: 'pointer' }}
									/>
								</InputAdornment>
							),
							style: {
								height: 33,
								fontSize: 13
							},
						}}
						InputLabelProps={{
							style: {
								fontSize: 13,
							},
						}}
						sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
					/>
				</FormControl>
				<FormControl fullWidth margin='dense'>
					<Button
						variant="outlined"
						type="submit"
						sx={{ fontSize: 11 }}
						disabled={changePasswordStatus.status === RequestStatus.LOADING}
					>
						{changePasswordStatus.status === RequestStatus.LOADING ?
							<>
								{translate('button_change')}
								<ProgressCircle isBtnDisabled />
							</>
							:
							translate('button_change')
						}
					</Button>
				</FormControl>
			</form>
		</ModalFormWindow>
	);
};

export default FormChangePassword;
