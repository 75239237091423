import { useLocation, useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import cn from 'classnames';

import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { clearAccessRightsList, clearAuthData } from '../../store/authSlice';
import { selectCurrentUserInfo } from '../../store/userSlice';
import useAccessRight from '../../hooks/useAccessRight';
import useTranslate from '../../hooks/useTranslate';
import { SERVICE } from '../../constants/accessRights';
import { BIOMETRY, CLASSIFIER, ENTITIES, LOGIN, QUESTIONNAIRE, RECOGNIZER, REPORTS, ROBOTS, SYNTHESIS, USERS } from '../../constants/routes';
import { ACCESS_TOKEN, REFRESH_TOKEN } from '../../constants/cookieNames';
import { backgroundColor } from '../../constants/colors';

import classifierIcon from '../../assets/smc.png';
import entityIcon from '../../assets/see.png';
import speechRecognitionIcon from '../../assets/spr.png';
import voiceBiometricsIcon from '../../assets/sbs.png';
import speechSynthesisIcon from '../../assets/tts.png';
import questionnaireIcon from '../../assets/qas.png';
import scriptMachineIcon from '../../assets/ses.png';
import reportsIcon from '../../assets/res.png';
import usersIcon from '../../assets/users.png';

import { IDockPanelProps } from './DockPanel.props';
import styles from './DockPanel.module.scss';

const routes = [
	{ right: SERVICE.SMC, route: CLASSIFIER, name: 'smc', img: classifierIcon },
	{ right: SERVICE.SEE, route: ENTITIES, name: 'see', img: entityIcon },
	{ right: SERVICE.SPR, route: RECOGNIZER, name: 'spr', img: speechRecognitionIcon },
	{ right: SERVICE.SBS, route: BIOMETRY, name: 'sbs', img: voiceBiometricsIcon },
	{ right: SERVICE.TTS, route: SYNTHESIS, name: 'tts', img: speechSynthesisIcon },
	{ right: SERVICE.QAS, route: QUESTIONNAIRE, name: 'qas', img: questionnaireIcon },
	{ right: SERVICE.SES, route: ROBOTS, name: 'ses', img: scriptMachineIcon },
	{ right: SERVICE.RES, route: REPORTS, name: 'res', img: reportsIcon },
	{ right: SERVICE.AUTH, route: USERS, name: 'auth', img: usersIcon },
];

const DockPanel = ({ setShowDockPanel, setShowPage }: IDockPanelProps): JSX.Element => {
	const dispatch = useAppDispatch();
	const currentUserInfo = useAppSelector(selectCurrentUserInfo); // store - информация о текущем пользователе

	const navigate = useNavigate(); // hook для навигации
	const location = useLocation(); // hook для определения адреса
	const [_, __, removeCookies] = useCookies([ACCESS_TOKEN, REFRESH_TOKEN]); // hook для работы с cookie
	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// logout
	const logout = () => {
		setShowDockPanel(false); // скрываем dock-панель
		// после скрытия dock-панели уводим страницу в фон
		setTimeout(() => {
			setShowPage(false);

			setTimeout(() => {
				removeCookies(ACCESS_TOKEN, { path: '/' });
				removeCookies(REFRESH_TOKEN, { path: '/' });
				dispatch(clearAuthData());
				dispatch(clearAccessRightsList());
				// задержка для удаления cookie
				setTimeout(() => {
					navigate(LOGIN);
				});
			}, 500); // после скрытия страницы переходим через 500мс

		}, 500);
	};

	// обработчик навигации
	const moveToHandler = (right: string, route: string) => {
		// если есть право и путь не содержит роут для перехода
		if (isAccess(right) && !location.pathname.includes(route)) {
			setShowDockPanel(false); // скрываем dock-панель
			// после скрытия dock-панели уводим страницу в фон
			setTimeout(() => {
				setShowPage(false);
				setTimeout(() => {
					navigate(route);
				}, 500); // после скрытия страницы переходим через 500мс
			}, 500);
		}
	};

	return (
		<div className={styles.dockPanel} onClick={e => e.stopPropagation()}>
			{currentUserInfo.id ?
				routes.map(item => (
					<div className={cn(styles.dockPanelLink, {
						[styles.dockPanelLinkNonActive]: !isAccess(item.right),
						[styles.dockPanelLinkActive]: location.pathname.includes(item.route),
					})} onClick={() => moveToHandler(item.right, item.route)} key={item.name}>
						<span className={styles.dockPanelTitle}>{translate(item.name)}</span>
						<img className={styles.dockPanelImg} src={item.img} alt={translate(item.name)} />
					</div>
				))
				:
				<p>{translate(currentUserInfo.message || 'title_failedAccessRights')}</p>
			}

			<div className={styles.dockPanelLink} onClick={logout}>
				<span className={styles.dockPanelTitle}>{translate('buttonTitle_logout')}</span>
				<div className={styles.dockPanelLogoutIcon}>
					<FontAwesomeIcon icon={faRightFromBracket} size="4x" color={backgroundColor} />
				</div>
			</div>
		</div>
	);
};

export default DockPanel;
