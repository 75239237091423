import { useEffect, useState } from 'react';
import { Button, Fade, Slide } from '@mui/material';
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/theme-iplastic";
import "ace-builds/src-noconflict/ext-language_tools";
import 'brace/ext/searchbox'; // для команд ctrl+f и ctrl+h
import cn from 'classnames';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { clearHandler, clearHandlerDelete, clearHandlerUpload, deleteHandlerPy, getHandler, selectActiveType, selectDeleteHandlerStatus, selectFullModel, selectHandler, selectModelName, selectUploadHandlerStatus, uploadHandlerPy } from '../../store/modelSlice';
import useAccessRight from '../../hooks/useAccessRight';
import useTranslate from '../../hooks/useTranslate';
import { HANDLER } from '../../constants/accessRights';
import { ModelStatus, RequestStatus, ResponseStatus } from '../../types/statusTypes';
import AlertDialog from '../AlertDialog/AlertDialog';
import Notification from '../Notification/Notification';
import ProgressCircle from '../ProgressCircle/ProgressCircle';
import { ICodeEditorPythonProps } from './CodeEditorPython.props';
import styles from './CodeEditorPython.module.scss';

const CodeEditorPython = ({ showCodeEditor, setShowCodeEditor, serviceType }: ICodeEditorPythonProps): JSX.Element => {
	const [code, setCode] = useState<string>(''); // код
	const [showAlertDialog, setShowAlertDialog] = useState<boolean>(false); // показ диалогового окна для удаления handler.py
	const [showNotificationUpload, setShowNotificationUpload] = useState<boolean>(false); // показ уведомления загрузки handler.py
	const [showNotificationDelete, setShowNotificationDelete] = useState<boolean>(false); // показ уведомления удаления handler.py

	const dispatch = useAppDispatch();
	const fullModel = useAppSelector(selectFullModel); // store - модель
	const modelName = useAppSelector(selectModelName); // store - имя модели
	const activeType = useAppSelector(selectActiveType); // store - активный типа модели
	const handler = useAppSelector(selectHandler); // store - handler.py
	const uploadHandlerStatus = useAppSelector(selectUploadHandlerStatus); // store - статус загрузки handler.py
	const deleteHandlerStatus = useAppSelector(selectDeleteHandlerStatus); // store - статус удаления handler.py

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// следим за вкладкой
	useEffect(() => {
		// // при открытии
		if (showCodeEditor) {
			handler.status === RequestStatus.IDLE && handler.data !== null && typeof handler.data === 'string' && setCode(handler.data); // вписываем/обновляем код в редактор
		}
		// при закрытии
		if (!showCodeEditor) {
			setCode(''); // сбрасываем код
			showNotificationUpload && setShowNotificationUpload(false); // если осталось уведомление о загрузке - выключаем
			showNotificationDelete && setShowNotificationDelete(false); // если осталось уведомление об удалении - выключаем
		}
	}, [showCodeEditor]);

	// следим за именем модели и активным типом модели
	useEffect(() => {
		setCode(''); // сбрасываем код
		((showCodeEditor && uploadHandlerStatus.status === RequestStatus.FAILED) || uploadHandlerStatus.error === ResponseStatus.FAILED || uploadHandlerStatus.message !== '') && dispatch(clearHandlerUpload()); // если были данные статуса загрузки handler.py - очищаем
		(deleteHandlerStatus.status === RequestStatus.FAILED || deleteHandlerStatus.error === ResponseStatus.FAILED || deleteHandlerStatus.message !== '') && dispatch(clearHandlerDelete()); // если были данные статуса удаления handler.py - очищаем
		handler.data && dispatch(clearHandler()); // очищаем handler.py, если есть
		isAccess(HANDLER.GET) && (serviceType === 'smc' || serviceType === 'see') && modelName && dispatch(getHandler({ modelName, serviceType, modelType: activeType })); // получаем handler.py
	}, [modelName, activeType]);

	// следим за получением handler.py и вписываем код в редактор
	useEffect(() => {
		handler.status === RequestStatus.IDLE && handler.data !== null && typeof handler.data === 'string' && setCode(handler.data);
	}, [handler]);

	// следим за статусом изменения/удаления handler.py
	useEffect(() => {
		// и при успехе
		if ((deleteHandlerStatus.status === RequestStatus.IDLE && deleteHandlerStatus.error === ResponseStatus.SUCCESS && deleteHandlerStatus.message === 'success') || (uploadHandlerStatus.status === RequestStatus.IDLE && uploadHandlerStatus.error === ResponseStatus.SUCCESS && uploadHandlerStatus.message === 'success')) {
			setCode(''); // очищаем код
			dispatch(clearHandler()); // очищаем handler.py
			modelName && dispatch(getHandler({ modelName, serviceType, modelType: activeType })); // заново получаем handler.py
		}
	}, [uploadHandlerStatus, deleteHandlerStatus]);

	// обработчик отправки handler.py
	const sendHandler = (): void => {
		const filePy = new File([code], 'handler.py', { type: "text/plain" }); // создаем экземпляр файла
		const formData = new FormData();
		if (filePy) {
			formData.append('filePy', filePy);
			modelName && dispatch(uploadHandlerPy({ modelName, serviceType, modelType: activeType, formData })); // отправка handler.py
			setShowNotificationUpload(true); // включаем уведомление
		}
	};

	// обработчик удаления handler.py
	const deleteHandler = (): void => {
		setShowAlertDialog(false); // закрываем диалоговое окно
		setShowNotificationDelete(true); // включаем уведомление
		modelName && dispatch(deleteHandlerPy({ modelName, serviceType, modelType: activeType })); // удаление handler.py
	};

	return (
		<Slide direction="left" in={showCodeEditor} mountOnEnter unmountOnExit>
			<div className={styles.modal} onMouseDown={() => setShowCodeEditor(false)}>
				<div className={styles.codeEditorWrapper} onMouseDown={(e) => e.stopPropagation()}>

					{handler.status === RequestStatus.LOADING ?
						<div className={styles.loading}><ProgressCircle title={translate('spinnerTitle_loading')} /></div>
						:
						<Fade in={true} timeout={500}>
							<div className={styles.codeEditor}>
								<AceEditor
									name="handlerPy"
									placeholder={translate(
										handler.status === RequestStatus.FAILED ?
											(handler.data && typeof handler.data !== 'string' && 'message' in handler.data ?
												handler.data.message
												:
												'title_loadFailed'
											)
											:
											'title_handlerMissing'
									)}
									mode="python"
									theme="iplastic"
									width='100%'
									fontSize={11}
									value={code}
									onChange={value => setCode(value)}
									focus
									editorProps={{ $blockScrolling: true }}
									setOptions={{
										enableBasicAutocompletion: true,
										enableLiveAutocompletion: true,
										// enableSnippets: true
									}}
									style={{ flexGrow: 1 }}
									readOnly={!(isAccess(HANDLER.SAVE)) || uploadHandlerStatus.status === RequestStatus.LOADING || deleteHandlerStatus.status === RequestStatus.LOADING || !modelName || activeType !== 'future' || (fullModel.fullModel[fullModel.activeType]?.status !== ModelStatus.TRAINED && fullModel.fullModel[fullModel.activeType]?.status !== ModelStatus.TESTED)}
								/>

								{(isAccess(HANDLER.SAVE) || isAccess(HANDLER.DELETE)) && activeType === 'future' &&
									<div className={styles.codeEditorButtons}>
										{isAccess(HANDLER.SAVE) && <>
											{uploadHandlerStatus.status === RequestStatus.LOADING ?
												<Button variant="outlined" disabled sx={{ overflow: 'hidden', fontSize: 11 }} size='small'>
													{translate('button_save')} <ProgressCircle isBtnDisabled />
												</Button>
												:
												<Button
													variant="outlined"
													onClick={sendHandler}
													disabled={code.length === 0 || deleteHandlerStatus.status === RequestStatus.LOADING || (fullModel.fullModel[fullModel.activeType]?.status !== ModelStatus.TRAINED && fullModel.fullModel[fullModel.activeType]?.status !== ModelStatus.TESTED)}
													sx={{ overflow: 'hidden', fontSize: 11 }}
													size='small'
												>
													{translate('button_save')}
												</Button>
											}
										</>}
										{isAccess(HANDLER.DELETE) && <>
											{deleteHandlerStatus.status === RequestStatus.LOADING ?
												<Button variant="outlined" disabled sx={{ overflow: 'hidden', fontSize: 11 }} size='small'>
													{translate('button_delete')} <ProgressCircle isBtnDisabled />
												</Button>
												:
												<Button
													variant="outlined"
													onClick={() => setShowAlertDialog(true)}
													disabled={code.length === 0 || uploadHandlerStatus.status === RequestStatus.LOADING || (fullModel.fullModel[fullModel.activeType]?.status !== ModelStatus.TRAINED && fullModel.fullModel[fullModel.activeType]?.status !== ModelStatus.TESTED)}
													sx={{ overflow: 'hidden', fontSize: 11 }}
													size='small'
												>
													{translate('button_delete')}
												</Button>
											}
										</>}
									</div>
								}
							</div>
						</Fade>
					}

					<div className={cn(styles.tagClose, {
						[styles.tagCloseLoading]: handler.status === RequestStatus.LOADING
					})} onClick={() => setShowCodeEditor(false)}>
						{translate('tag_close')}
					</div>

					<AlertDialog showAlertDialog={showAlertDialog} setShowAlertDialog={setShowAlertDialog} submitHandler={deleteHandler} title='dialog_deleteHandler' description='dialog_deleteHandlerConfirm' name={String(modelName)} />
				</div>

				{showNotificationUpload && <Notification showNotification={showNotificationUpload} setShowNotification={setShowNotificationUpload} selectDataResponse={selectUploadHandlerStatus} clearDataResponse={clearHandlerUpload} titleFailed={translate('noticeSaving_failed')} titleSuccess={translate('noticeSaving_success')} />}
				{showNotificationDelete && <Notification showNotification={showNotificationDelete} setShowNotification={setShowNotificationDelete} selectDataResponse={selectDeleteHandlerStatus} clearDataResponse={clearHandlerDelete} titleFailed={translate('noticeDeletion_failed')} titleSuccess={translate('noticeDeletion_success')} />}
			</div>
		</Slide>
	);
};

export default CodeEditorPython;
